import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import { useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import { getProjectIds, getSelectedProjectId, isProjectAdmin } from '../../../config/env';
import BreadcrumbNav from '../Nav/BreadcrumbsNav';
import { getIsAgrisoundAdmin, getProjectRole, getRoleOptions } from '../../../util/user-util';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { beekeeperUserRoleLabels, pollyUserRoleLabels } from '../../../config/userRoles';
import FormControl from '@material-ui/core/FormControl';
import { CloudUpload as CloudUploadIcon } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import { addRequestToCollection } from '../../../api/adminFunctions';
import { auth } from '../../../firebase';
import SnackbarAlert, { snackbarDefault } from '../SnackbarAlert/SnackbarAlert';
import CircularProgress from '@material-ui/core/CircularProgress';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { canUpdateRole } from '../../../util/permission-util';

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 20,
    maxWidth: '75%',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function AgrisoundUserPage() {
  const match = useRouteMatch('/users/:userId');
  const userId = match.params.userId;
  const classes = useStyles();
  const userData = useSelector((state) => state.appConfig.userData);
  const storeAs = `project-users-${userId}`;
  const user = useSelector(({ firestore: { data } }) => data[storeAs]);
  const selectedProjectId = getSelectedProjectId(userData);
  const project = useSelector((state) => state.appConfig.currentProject);
  const accountType = (project && project.accountType) || 'none';
  const isAgrisoundAdmin = getIsAgrisoundAdmin(userData);
  let projectIds = getProjectIds(userData);
  const projectId = projectIds[0];
  const projectRole = user && getProjectRole({ userData: user, accountType });

  const userQuery = [
    {
      collection: 'projects',
      doc: selectedProjectId,
      subcollections: [{ collection: 'users', doc: userId }],
      storeAs: storeAs,
    },
  ];

  useFirestoreConnect(userQuery);

  if (!isLoaded(user)) {
    return 'Loading...';
  }

  return (
    <Box className={classes.root} justifyContent="flex-start">
      <Grid container justify={'center'} spacing={2} style={{ paddingTop: '20px' }}>
        <Grid item xs={12}>
          <Breadcrumbs userId={userId} />
        </Grid>
        <Grid item xs={12}>
          <UserCard
            user={user}
            accountType={accountType}
            isAgrisoundAdmin={isAgrisoundAdmin}
            projectId={projectId}
            projectRole={projectRole}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

function UserCard({ user, accountType, isAgrisoundAdmin, projectId, projectRole }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState(snackbarDefault);
  const [role, setRole] = useState(projectRole);
  const [submitActive, setSubmitActive] = useState(false);
  const firestore = useFirestore();
  const roleOptions = getRoleOptions({ accountType, isAgrisoundAdmin });
  const userData = useSelector((state) => state.appConfig.userData);
  const hasUpdateRolePermission = canUpdateRole(userData);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar(snackbarDefault);
  };

  const updateUserRoleAction = () => {
    setLoading(true);
    const uid = auth.currentUser && auth.currentUser.uid;
    const userRoles = [role];

    if (projectId.includes('polly-')) {
      userRoles.push('admin');
    }

    const request = {
      userId: user.userId,
      projectId,
      userRoles,
      updatedBy: uid,
      dateCreated: new Date(),
    };
    const collectionName = 'updateUserRole';
    addRequestToCollection({
      request,
      collectionName,
      firestore,
      setLoading,
      setSnackbar,
    });
  };

  const buttons = (
    <Box display="flex">
      <ButtonGroup style={{ marginRight: '15px' }}>
        <Button
          color="primary"
          disabled={loading}
          variant="contained"
          onClick={updateUserRoleAction}
        >
          Update Role
        </Button>
        {loading && (
          <CircularProgress style={{ marginLeft: '15px', fontSize: 50, color: 'orange' }} />
        )}
      </ButtonGroup>
    </Box>
  );

  const roleSelect = () => {
    return (
      <FormControl className={classes.formControl}>
        <Select
          value={role}
          onChange={(e) => {
            const newRole = e.target.value;

            if (newRole !== projectRole) {
              setSubmitActive(true);
            } else {
              setSubmitActive(false);
            }

            setRole(newRole);
          }}
        >
          <MenuItem value="access" disabled>
            Choose access
          </MenuItem>
          {roleOptions.map((option, i) => {
            return (
              <MenuItem key={i} value={option}>
                {projectId.startsWith('beekeeper-')
                  ? beekeeperUserRoleLabels[option]
                  : pollyUserRoleLabels[option]}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            R
          </Avatar>
        }
        // action={
        //   <IconButton aria-label="settings">
        //     <MoreVertIcon />
        //   </IconButton>
        // }
        title={`User ID: ${user && user.userId}`}
        // subheader={`Last Activity: ${lastActivityDateCreated}`}
      />
      <CardContent>
        <SnackbarAlert snackbar={snackbar} handleClose={handleClose} />
        {user && (
          <Grid container justify={'center'} spacing={0} style={{ paddingTop: '20px' }}>
            <Grid item xs={6}>
              <Typography paragraph>First name:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography paragraph>{user.firstName}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography paragraph>Last name:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography paragraph>{user.lastName}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography paragraph>Email</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography paragraph>{user.email}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography paragraph>Role</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography paragraph>{projectRole}</Typography>
            </Grid>
            {hasUpdateRolePermission && (
              <Grid item xs={6}>
                <Typography paragraph>Update Role</Typography>
              </Grid>
            )}
            {hasUpdateRolePermission && (
              <Grid item xs={6}>
                <Typography paragraph>{roleSelect()}</Typography>
              </Grid>
            )}
            {hasUpdateRolePermission && (
              <Grid item xs={6}>
                <Typography paragraph>{''}</Typography>
              </Grid>
            )}
            {submitActive && hasUpdateRolePermission && (
              <Grid item xs={6}>
                {buttons}
              </Grid>
            )}
          </Grid>
        )}
      </CardContent>
      <CardActions disableSpacing></CardActions>
    </Card>
  );
}

function Breadcrumbs({ userId }) {
  const breadcrumbs = [
    {
      path: '/',
      text: 'Home',
    },
    {
      path: '/projects',
      text: 'Settings',
    },
    {
      path: `/users/${userId}`,
      text: userId,
    },
  ];
  return <BreadcrumbNav breadcrumbs={breadcrumbs} />;
}
