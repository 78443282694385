import React from 'react';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import AssessmentIcon from '@material-ui/icons/Assessment';

export default function PollyPageTitle({ title, subtitle }) {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <AssessmentIcon style={{ color: 'orange' }}/>
        <Typography variant={'h5'} style={{ marginLeft: '10px' }}>{title}</Typography>
      </div>
      <Divider style={{ marginBottom: '20px', marginTop: '10px' }}/>
      {/*{subtitle && <Typography variant={'h6'}>{subtitle}</Typography>}*/}
    </div>
  );
}
