export function getFilterByPossibleValues({ option, locationData, farms, groups, sites }) {
  switch (option) {
    case 'farmName':
      return farms.map((farm) => ({
        label: farm.farmName,
        value: farm.farmId,
      }));
    case 'siteName':
      return sites.map((site) => ({
        label: site.siteName,
        value: site.siteId,
      }));
    case 'groupName':
      return groups.map((group) => ({
        label: group.groupName,
        value: group.groupId,
      }));
    case 'deviceName':
      return locationData.map((device) => ({
        label: device.deviceName,
        value: device.deviceId,
      }));
    default:
      return locationData.map((device) => ({
        label: device.deviceName,
        value: device.deviceId,
      }));
  }
}

export function uniqObject(devices, fieldPrefix) {
  const uniqDevices = [];
  for (let device of devices) {
    if (!uniqDevices.find((d) => d[`${fieldPrefix}Id`] === device[`${fieldPrefix}Id`])) {
      uniqDevices.push({
        [`${fieldPrefix}Id`]: device[`${fieldPrefix}Id`],
        [`${fieldPrefix}Name`]: device[`${fieldPrefix}Name`],
      });
    }
  }
  return uniqDevices;
}
