import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export const rangeOptions = {
  _1day: '24 hours',
  _1week: '1 Week',
  _1month: '1 Month',
  _3months: '3 Months',
  max: 'Max',
};

export const rangeConfig = {
  _1day: 1,
  _1week: 7,
  _1month: 30,
  _3months: 90,
};

export default function ChartDateRange({ rangeSelected, onChange }) {
  const classes = useStyles();

  return (
    <ButtonGroup color="secondary" aria-label="contained secondary button group">
      <Button
        size="small"
        variant={rangeSelected === rangeOptions._1day ? 'contained' : 'outlined'}
        // disabled={rangeSelected === rangeOptions._1day}
        onClick={() => onChange(rangeOptions._1day)}
      >
        24 Hours
      </Button>
      <Button
        size="small"
        variant={rangeSelected === rangeOptions._1week ? 'contained' : 'outlined'}
        // disabled={rangeSelected === rangeOptions._1week}
        onClick={() => onChange(rangeOptions._1week)}
      >
        1 Week
      </Button>
      <Button
        size="small"
        variant={rangeSelected === rangeOptions._1month ? 'contained' : 'outlined'}
        // disabled={rangeSelected === rangeOptions._1month}
        onClick={() => onChange(rangeOptions._1month)}
      >
        1 Month
      </Button>
      <Button
        size="small"
        variant={rangeSelected === rangeOptions._3months ? 'contained' : 'outlined'}
        // disabled={rangeSelected === rangeOptions._3months}
        onClick={() => onChange(rangeOptions._3months)}
      >
        3 Months
      </Button>
      <Button
        size="small"
        // disabled={rangeSelected === rangeOptions.max}
        variant={rangeSelected === rangeOptions.max ? 'contained' : 'outlined'}
        onClick={() => onChange(rangeOptions.max)}
      >
        Max
      </Button>
    </ButtonGroup>
  );
}
