import React from 'react';
import { Avatar, Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { orange, green } from '@mui/material/colors';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import EmojiNatureIcon from '@mui/icons-material/EmojiNature';
import { globalElevation } from '../../../config/globalStyle';
import PhoneLinkRingIcon from '@mui/icons-material/PhonelinkRing';
import { makeStyles } from '@material-ui/core/styles';

const iconNames = {
  emojiNatureIcon: <EmojiNatureIcon />,
  phoneLinkRingIcon: <PhoneLinkRingIcon />,
};

const iconColours = {
  orange,
  green,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    fontSize: 'medium',
  },
  subheader: {
    fontSize: 'medium',
  },
  card: {
    borderRadius: '10px',
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    boxShadow: 'none',
  },
}));

export default function PollyActivityWidget({
  title = 'Total Pollinator Activity',
  activity = '24%',
  movementText = '16%',
  currentValue = 10,
  previousValue = 9,
  text,
  movementDirection = 'up',
  durationText = 'Since last month',
  height = 160,
  showIcon = true,
  iconName = 'beeActivity',
  iconColourName = 'orange',
  showMovement = true,
  suffix = ''
}) {
  const classes = useStyles();
  const icon = getIcon(iconName);
  activity = getActivity(currentValue, suffix);
  movementText = getMovementText(currentValue, previousValue);
  movementDirection = getMovementDirection(currentValue, previousValue);
  // const iconColour = iconColours[iconColourName];
  const iconColour = getIconColour(movementDirection);
  const widgetMainValue = text ? `${text} (${activity})` : activity;
  return (
    <Card elevation={globalElevation} style={{ height, borderRadius: '5px' }}>
      <CardContent>
        <Grid container spacing={1} sx={{ justifyContent: 'space-between' }}>
          <Grid item xs={10}>
            <Typography color="textSecondary" gutterBottom variant="subtitle1">
              {title}
            </Typography>
            <Typography color="textPrimary" variant="h5" style={{ fontWeight: 600 }}>
              {widgetMainValue}
            </Typography>
          </Grid>
          {showIcon && (
            <Grid item xs={2}>
              <Avatar
                // style={{ backgroundColor: green[600] }}
                sx={{
                  backgroundColor: iconColour[600],
                  height: 46,
                  width: 46,
                }}
              >
                {icon}
              </Avatar>
            </Grid>
          )}
        </Grid>
        {showMovement && (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              pt: 2,
            }}
          >
            {movementDirection === 'up' && <ArrowUpwardIcon sx={{ color: green[900] }} />}
            {movementDirection === 'down' && <ArrowDownwardIcon sx={{ color: orange[900] }} />}
            <Typography
              variant="body2"
              sx={{
                color: movementDirection === 'up' ? green[900] : orange[900],
                mr: 1,
              }}
            >
              {movementText}
            </Typography>
            <Typography color="textSecondary" variant="caption">
              {durationText}
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
}

function getIcon(iconName) {
  return iconNames[iconName];
}

function getIconColour(movementDirection) {
  return movementDirection === 'up' ? iconColours['green'] : iconColours['orange'];
}

function getActivity(currentValue, suffix) {
  return currentValue + suffix;
}

function getMovementText(currentValue, previousValue) {
  return ((currentValue - previousValue) / previousValue).toFixed(1) + '%';
}

function getMovementDirection(currentValue, previousValue) {
  return currentValue - previousValue >= 0 ? 'up' : 'down';
}
