import { pollyUserRoles } from '../config/userRoles';

export const getPermission = ({ component, permission, userData, currentProjectId }) => {
  const noUserRoles = { userRoles: [] };
  const userRoles = (userData.projects.find((p) => p.projectId === currentProjectId) || noUserRoles)
    .userRoles;

  const permissions = {
    PollyDeviceCard: {
      showSiteDropDown: {
        requiredRoles: [pollyUserRoles.agriSoundAdmin],
      },
      showInterventionTextField: {
        requiredRoles: [pollyUserRoles.agriSoundAdmin],
      },
      showSiteTextField: {
        requiredRoles: [pollyUserRoles.agriSoundAdmin],
      },
    },
    PollyDevices: {
      showSiteSearch: {
        requiredRoles: [pollyUserRoles.agriSoundAdmin],
      },
      showDeviceSearch: {
        requiredRoles: [pollyUserRoles.agriSoundAdmin],
      },
    },
    PollyDeviceEditPage: {
      edit: {
        requiredRoles: [
          pollyUserRoles.agriSoundAdmin,
          pollyUserRoles.business,
          pollyUserRoles.projectAdmin,
          pollyUserRoles.deviceInstaller,
          pollyUserRoles.projectOverview,
          pollyUserRoles.sponsor,
        ],
      },
    },
  };

  if (!permissions[component] || !(permissions[component] && permissions[component][permission])) {
    return false;
  }
  const requiredRoles = permissions[component][permission].requiredRoles;

  let hasPermission = false;
  for (let userRole of userRoles) {
    if (requiredRoles.includes(userRole)) {
      hasPermission = true;
    }
  }

  return hasPermission;
};

export function canUpdateRole(userData) {
  const project = userData.projects.find(
    (project) => project.projectId === userData.selectedProjectId
  );
  if (userData.selectedProjectId.includes('beekeeper-')) {
    return project && project.userRoles.includes('admin');
  } else if (userData.selectedProjectId.includes('polly-')) {
    return (
      (project && project.userRoles.includes(pollyUserRoles.agriSoundAdmin)) ||
      project.userRoles.includes(pollyUserRoles.projectAdmin) ||
      project.userRoles.includes(pollyUserRoles.projectOverview)
    );
  }
}
