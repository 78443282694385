export const beekeeperUserRoles = {
  read: 'read',
  admin: 'admin',
  agriSoundAdmin: 'agriSoundAdmin',
  bardsley: 'bardsley',
};

export const beekeeperUserRoleLabels = {
  read: 'Read',
  admin: 'Admin',
  agriSoundAdmin: 'Agrisound Admin',
  bardsley: 'Bardsley',
};

export const pollyUserRoles = {
  sponsor: 'sponsor',
  business: 'business',
  deviceInstaller: 'deviceInstaller',
  agriSoundAdmin: 'agriSoundAdmin',
  projectOverview: 'projectOverview',
  projectAdmin: 'projectAdmin', //added 12-Aug-2023 for Equinix but to be used by all Polly accounts
  readOnly: 'readOnly', //added 12-Aug-2023 for Equinix but to be used by all Polly accounts
};

export const pollyUserRoleLabels = {
  sponsor: 'Sponsor',
  business: 'Business',
  farmer: 'Farmer',
  deviceInstaller: 'Device Installer',
  agriSoundAdmin: 'Agrisound Admin',
  projectOverview: 'Project Overview',
  projectAdmin: 'Project Admin', //added 12-Aug-2023 for Equinix but to be used by all Polly accounts
  readOnly: 'Read Only', //added 12-Aug-2023 for Equinix but to be used by all Polly accounts
};
