export function getAccountType(projectId) {
  if(projectId.includes('beekeeper')) {
    return 'beekeeper';
  } else if(projectId.includes('polly')) {
    return 'polly';
  } else {
    // return 'none';
    throw new Error(`cannot determine accountType from ${projectId}`)
  }
}
