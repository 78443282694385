import React, { useState } from 'react';
import { useFirestore } from 'react-redux-firebase';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function DeviceExists({ classes }) {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const [deviceId, setDeviceId] = useState();
  const firestore = useFirestore();
  const checkDeviceExistsAction = () => {
    if (!deviceId || deviceId.trim() === '') {
      setMessage(`Please enter a deviceId`);
      return;
    }
    setLoading(true);

    firestore
      .doc(`devices/${deviceId}`)
      .get()
      .then((res) => {
        if (res.exists) {
          let device = res.data();
          setMessage(`Device exists with projectIds = ${device.projectIds}`);
          setLoading(false);
        } else {
          setMessage(`The Device DOES NOT EXIST in the database`);
          setLoading(false);
        }
      })
      .catch((err) => {
        setMessage(`Error: ${err.message}`);
        setLoading(false);
      });
  };
  return (
    <Grid container spacing={0}>
      <Grid container item xs={12}>
        <FormControl className={classes.formControl}>
          <TextField placeholder="deviceId" onChange={(e) => setDeviceId(e.target.value)} />
        </FormControl>
      </Grid>
      <Grid container item xs={12}>
        <FormControl className={classes.formControl}>
          <Grid container spacing={0}>
            <Grid container item xs={6}>
              <Button
                color="primary"
                variant="contained"
                // className={classes.button}
                onClick={checkDeviceExistsAction}
                disabled={loading || !deviceId}
              >
                Check for device
              </Button>
            </Grid>
            <Grid container item xs={6}>
              {loading && <CircularProgress className={classes.submitButton} />}
              {message && <div>{message}</div>}
            </Grid>
          </Grid>
        </FormControl>
      </Grid>
    </Grid>
  );
}
