import React, { useEffect, useState } from 'react';
import { log } from '../../../util/log-util';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { getProjectIds } from '../../../config/env';
import HighCharts from './HighCharts';
import { getAllDevicesQuery } from '../../../api/queryConfig';
import { cloneDeep, uniq } from 'lodash';
// import { getAnnotationsData, getAudioEventTableData, getEventTableData, getFlagsData } from '../../data/data-util';
// import { getHiveChartData } from './chart-data-util';

export default function HivesChartWithData({
  projectId,
  deviceId = undefined,
  linesToShow = {
    sensorTemp: true,
    gatewayTemp: true,
    humidity: true,
    weight: true,
    voltage: false,
    battery: false,
    peakFlight: false,
    peakFanning: false,
    hiveActivity: false,
    inCount: false,
    outCount: false
  },
  showGatewayOnly = false,
}) {
  // const classes = useStyles();
  // const [eventsRows, setEventsRows] = useState([]);
  // const [comments, setComments] = useState([]);
  // const [chartData, setChartData] = useState([]);
  // const [annotations, setAnnotations] = useState([]);
  const userData = useSelector((state) => state.appConfig.userData);
  const currentProject = useSelector((state) => state.appConfig.currentProject);
  const accountType = (currentProject && currentProject.accountType) || 'none';
  const eventsCollectionName = accountType === 'national-trust' ? 'eventsTest' : 'events';
  const storeAsWeather = `${projectId}-weather`;
  const storeAsEvents = `events`;
  const storeAsDevices = `devices-hives`;
  const eventsData = useSelector(({ firestore: { data } }) => data[storeAsEvents]);
  const devicesData = useSelector(({ firestore: { data } }) => data[storeAsDevices]);
  const weatherData = useSelector(({ firestore: { data } }) => data[storeAsWeather]);
  let projectIds = getProjectIds(userData);

  const weatherQuery = {
    collection: 'projects',
    doc: projectId,
    subcollections: [{ collection: 'weather' }],
    storeAs: storeAsWeather,
  };

  log('HivesChartWithData', projectId, deviceId, eventsCollectionName);

  const eventsQuery = {
    collection: eventsCollectionName,
    where: [['projectIds', 'array-contains-any', projectIds]],
    storeAs: storeAsEvents,
  };

  const devicesQuery = getAllDevicesQuery({ projectIds, storeAs: storeAsDevices });

  // useEffect(() => {
  //   console.log('useEffect', eventsData, devicesData);
  //   if(!eventsData || !devicesData) {
  //     return;
  //   }
  //   let eventsRows = convertEvents(eventsData);
  //   let annotations = getAnnotationsData(eventsRows);
  //   let comments = getFlagsData(eventsRows, [deviceId]);
  //   setEventsRows(eventsRows);
  //   setComments(comments);
  //   setAnnotations(annotations);
  //   let audioEvents = getAudioEventTableData(eventsRows);
  //   let tableData = getEventTableData(eventsRows);
  //   let devices = Object.values(devicesData) || [];
  //   devices = devices.filter((device) => device.active);
  //   const devicesWithScales = devices.filter((device) => device.hasOwnProperty('scaleDeviceId'));
  //   let scaleDeviceIdNameMap = devicesWithScales.reduce((obj, device) => {
  //     obj[device.scaleDeviceId] = device.deviceName;
  //     return obj;
  //   }, {});
  //   let deviceIdNameMap = devices.reduce((obj, device) => {
  //     obj[device.deviceId] = device.deviceName;
  //     if (scaleDeviceIdNameMap[device.deviceId]) {
  //       obj[device.deviceId] = scaleDeviceIdNameMap[device.deviceId];
  //     }
  //     return obj;
  //   }, {});
  //   const isMetric = currentProject && currentProject.hasOwnProperty('metric') ? currentProject.metric : true;
  //   let [maxDate, minDate2, stroke, series] = getHiveChartData({
  //     eventData: tableData,
  //     audioEvents,
  //     weatherData,
  //     deviceIdNameMap,
  //     // includeDeviceNameInLabels: deviceIds.length > 1,
  //     includeDeviceNameInLabels: true,
  //     isMetric,
  //   });
  //   let chartData = {
  //     maxDate, minDate2, stroke, series
  //   };
  //   setChartData(chartData);
  //   // console.log('HivesChartsWithData', comments);
  // }, [eventsData, devicesData]);

  useFirestoreConnect([eventsQuery, weatherQuery, devicesQuery]);

  if (!isLoaded(weatherData, eventsData, devicesData)) {
    return 'Loading charts...';
  }
  // console.log('HiveChartWithData', { series, eventsData, tableData, weatherData, projectId, projectIds });
  log('HiveChartWithData.devicesData', eventsData);
  // let eventsRows = convertEvents(eventsData);
  // let annotations = getAnnotationsData(eventsRows);
  // let comments = getFlagsData(eventsRows, [deviceId]);
  // console.log('HiveChartWithData.rows', eventsRows);
  const { eventsRows } = getChartData({ eventsData, deviceId, devicesData, currentProject, weatherData });
  // console.log('eventsRows', uniq(eventsRows.map(entry => entry.deviceId)));
  // console.log('HiveChartWithData', chartData);

  return (
    <HighCharts
      // chartData={chartData}
      projectId={projectId}
      accountType={accountType}
      weatherData={weatherData}
      eventsRows={eventsRows}
      devicesData={devicesData}
      deviceId={deviceId}
      title={`Project ${projectId}`}
      initialToggleState={{
        weatherTemp: false,
        sensorTemp: linesToShow.sensorTemp || false,
        gatewayTemp: linesToShow.gatewayTemp || false,
        humidity: linesToShow.humidity || false,
        weight: linesToShow.weight || false,
        acoustics: linesToShow.acoustics || false,
        voltage: linesToShow.voltage || false,
        battery: linesToShow.battery || false,
        peakFlight: linesToShow.peakFlight || false,
        peakFanning: linesToShow.peakFanning || false,
        hiveActivity: linesToShow.hiveActivity || false,
        inCount: linesToShow.inCount || false,
        outCount: linesToShow.outCount || false,
      }}
      showGatewayOnly={showGatewayOnly}
    />
  );
}

function convertEvents(eventsData) {
  return Object.values((eventsData && cloneDeep(eventsData)) || {});
}

function getChartData({ eventsData, deviceId, devicesData, currentProject, weatherData }) {
  let eventsRows = convertEvents(eventsData);
  // let annotations = getAnnotationsData(eventsRows);
  // let comments = getFlagsData(eventsRows, [deviceId]);
  // setEventsRows(eventsRows);
  // setComments(comments);
  // setAnnotations(annotations);
  // let audioEvents = getAudioEventTableData(eventsRows);
  // let tableData = getEventTableData(eventsRows);
  // let devices = Object.values(devicesData) || [];
  // devices = devices.filter((device) => device.active);
  // const devicesWithScales = devices.filter((device) => device.hasOwnProperty('scaleDeviceId'));
  // let scaleDeviceIdNameMap = devicesWithScales.reduce((obj, device) => {
  //   obj[device.scaleDeviceId] = device.deviceName;
  //   return obj;
  // }, {});
  // let deviceIdNameMap = devices.reduce((obj, device) => {
  //   obj[device.deviceId] = device.deviceName;
  //   if (scaleDeviceIdNameMap[device.deviceId]) {
  //     obj[device.deviceId] = scaleDeviceIdNameMap[device.deviceId];
  //   }
  //   return obj;
  // }, {});
  // const isMetric = currentProject && currentProject.hasOwnProperty('metric') ? currentProject.metric : true;
  // let [maxDate, minDate2, stroke, series] = getHiveChartData({
  //   eventData: tableData,
  //   audioEvents,
  //   weatherData,
  //   deviceIdNameMap,
  //   // includeDeviceNameInLabels: deviceIds.length > 1,
  //   includeDeviceNameInLabels: true,
  //   isMetric,
  // });
  // let chartData = {
  //   maxDate, minDate2, stroke, series
  // };

  return { eventsRows };
}
