import { CustomMapComponent } from '../Shared/CustomMapComponent';
import React from 'react';

export default function PollyMapBasic({ devices, mapHeight }) {
  const API_KEY = 'AIzaSyCAMfU_KBDnjDG_JAZtkDZYrwlcgvPCDNw';
  const mapUrl = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&v=3.exp&libraries=geometry,drawing,places`;
  return (
    <CustomMapComponent
      zoom={14}
      markers={devices}
      label={'deviceName'}
      mapHeight={mapHeight}
      googleMapURL={mapUrl}
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: mapHeight }} />}
      mapElement={<div style={{ height: `100%` }} />}
    />
  );
}
