import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import { getProjectIds, getSelectedProjectId } from '../../../config/env';
import { alertsCollectionName, getAlertsQuery, getProjectQuery } from '../../../api/queryConfig';
import { convertFirestoreCollectionToArray } from '../../../data/data-util';
import AlertsList from './AlertsList';
import BreadcrumbNav from '../Nav/BreadcrumbsNav';
import AlertsConfig from './AlertsConfig';
import { alertsConfig } from '../../../config/user-alerts-config';
import AlertsList2 from './AlertList2';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // maxWidth: '48ch',
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: '100%',
    // maxWidth: '48ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  button: {
    textTransform: 'none',
    paddingTop: '28px',
    paddingLeft: '80px',
  },
  link: {
    marginLeft: '28px',
  },
  linkNoSubtitle: {
    fontSize: '14px',
    marginLeft: '28px',
  },
  article: {
    minHeight: '300px',
  },
}));

const routePath = 'alerts';
export default function AlertsPage({
  linkWidth = 1,
  showLink = true,
  showSubtitle = true,
  isComponentWide = true,
}) {
  const classes = useStyles();
  const [days, setDays] = useState(7);

  return (
    <Grid justify={'flex-start'} container spacing={2}>
      <Grid item xs={12}>
        <Breadcrumbs />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Alerts Config</Typography>
      </Grid>
      <Grid item xs={12}>
        <AlertsConfig />
      </Grid>
      <Grid item xs={12}>
        <Period days={days} setDays={setDays} />
      </Grid>
      <Grid item xs={12}>
        <AlertsList2
          linkWidth={linkWidth}
          showSubtitle={showSubtitle}
          routePath={routePath}
          showLink={showLink}
          isComponentWide={isComponentWide}
          days={days}
          title={<Typography variant="h6">Alerts</Typography>}
        />
      </Grid>
    </Grid>
  );
}

function Period({ days, setDays }) {
  const daySelect = <Days days={days} setDays={setDays} />;
  // return (
  //   <Grid justify={'flex-start'} container spacing={2} style={{ width: '30%'}}>
  //     <Grid item xs={3}>
  //       <Typography>Period:</Typography>
  //     </Grid>
  //     <Grid item xs={3}>
  //       {daySelect}
  //     </Grid>
  //   </Grid>
  // );
  return (
    <Box display="flex" flexDirection="row">
      <Typography style={{ marginTop: 4, marginRight: 10 }}>Period:</Typography>
      {daySelect}
    </Box>
  );
}

function Title({ days, setDays }) {
  const daySelect = <Days days={days} setDays={setDays} />;
  return <Typography variant="h6">Alerts {daySelect}</Typography>;
}

function Days({ days, setDays }) {
  const handleChange = (event) => {
    setDays(event.target.value);
  };
  return (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={days}
      label="Age"
      onChange={handleChange}
    >
      <MenuItem value={0}>Today Only</MenuItem>
      <MenuItem value={1}>Since Yesterday</MenuItem>
      <MenuItem value={2}>2 days</MenuItem>
      <MenuItem value={3}>3 days</MenuItem>
      <MenuItem value={4}>4 days</MenuItem>
      <MenuItem value={5}>5 days</MenuItem>
      <MenuItem value={6}>6 days</MenuItem>
      <MenuItem value={7}>7 days</MenuItem>
      <MenuItem value={60}>60 days</MenuItem>
    </Select>
  );
}

function Breadcrumbs() {
  const breadcrumbs = [
    {
      path: '/',
      text: 'Home',
    },
    {
      path: `/alerts`,
      text: 'Alerts',
    },
  ];
  return <BreadcrumbNav breadcrumbs={breadcrumbs} />;
}
