import React from 'react';
import Paper from '@material-ui/core/Paper';
import { globalElevation } from '../../../config/globalStyle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: '100%',
    // width: '500px'
  },
  card: {
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '60%',
    },
  },
  metricSwitch: {
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      padding: theme.spacing(3),
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '100%',
      padding: theme.spacing(3),
    },
  },
  userManagement: {
    padding: theme.spacing(3),
  },
  deviceManagement: {
    padding: theme.spacing(3),
  },
  title: {
    marginBottom: '20px',
    // maxWidth: '75%',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 400,
  },
}));

export default function PollySim({ device }) {
  const classes = useStyles();
  if(!device.sim) {
    return (
      <Paper className={classes.metricSwitch} elevation={globalElevation}>
        <Grid component="label" container alignItems="center" spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6">SIM Data</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography paragraph>No data to display</Typography>
          </Grid>
        </Grid>
      </Paper>
    );
  }
  const { dataUsed, dataLimit, lastConnection } = device.sim;
  return (
    <Paper className={classes.metricSwitch} elevation={globalElevation}>
      <Grid component="label" container alignItems="center" spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h6">SIM Data</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography paragraph>Data Used:</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography paragraph>{dataUsed}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography paragraph>Data Limit:</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography paragraph>{dataLimit}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography paragraph>Last Connection:</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography paragraph>{lastConnection}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
