import React from 'react';
import Box from '@material-ui/core/Box';
import ToggleButton from '@material-ui/lab/ToggleButton';

export default function MapControl({ mapHeight, setMapHeight, classes }) {
  // const classes = useStyles();
  const heights = [
    {
      label: 'Hide Map',
      value: '0px',
    },
    {
      label: 'Small',
      value: '200px',
    },
    {
      label: 'Big',
      value: '600px',
    },
  ];
  return (
    <Box flexWrap="wrap" className={classes.mapControl}>
      {heights.map((height, index) => {
        const selected = mapHeight === height.value;
        return (
          <ToggleButton
            style={{ backgroundColor: selected ? 'lightblue' : 'white' }}
            key={index}
            onChange={() => setMapHeight(height.value)}
            size="small"
            value={height.value}
            selected={selected}
          >
            {height.label}
          </ToggleButton>
        );
      })}
    </Box>
  );
}
