// import Grid from '@material-ui/core/Grid';
// import { Typography } from '@material-ui/core';
// import AlertsList from './AlertsList';
// import React from 'react';

import List from '@material-ui/core/List';
import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import RouterLink from '../Nav/RouterLink';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { convertDateWithTimezone } from '../../../util/date-util';
import { useSelector } from 'react-redux';
import { getProjectIds, getSelectedProjectId } from '../../../config/env';
import { alertsCollectionName, getAlertsQuery, getProjectQuery } from '../../../api/queryConfig';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { convertFirestoreCollectionToArray } from '../../../data/data-util';
import { alertsConfig } from '../../../config/user-alerts-config';
import AlertsList from './AlertsList';

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '100%',
    // maxWidth: '48ch',
    backgroundColor: theme.palette.background.paper,
  },
  titleWide: {
    [theme.breakpoints.down('xs')]: {
      width: '150px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.down('sm')]: {
      width: '250px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('md')]: {
      width: '600px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    // [theme.breakpoints.up('md')]: {
    //   width: '700px',
    //   textOverflow: 'ellipsis',
    //   overflow: 'hidden',
    //   whiteSpace: 'nowrap',
    // },
    [theme.breakpoints.up('lg')]: {
      width: '800px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  subtitleWide: {
    [theme.breakpoints.down('xs')]: {
      width: '150px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.down('sm')]: {
      width: '350px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('md')]: {
      width: '600px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('lg')]: {
      width: '800px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      width: '300px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
      width: '400px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('md')]: {
      width: '500px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('lg')]: {
      width: '200px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  paper: {
    width: '100%',
    // maxWidth: '48ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  button: {
    textTransform: 'none',
    paddingTop: '28px',
    paddingLeft: '80px',
  },
  link: {
    marginLeft: '28px',
  },
  linkNoSubtitle: {
    fontSize: '14px',
    marginLeft: '28px',
  },
  listItemSecondary: {
    width: '85%',
    fontWeight: 'normal',
    color: '#7a7a7a',
    overflow: 'hidden',
  },
}));

export default function AlertsList2({
  // alerts = [],
  routePath,
  linkWidth,
  showLink = true,
  showSubtitle,
  isComponentWide = true,
  days,
  title
}) {
  const userData = useSelector((state) => state.appConfig.userData);
  let projectIds = getProjectIds(userData);
  const alertsQuery = getAlertsQuery({ projectIds, days });
  const alertsData = useSelector(({ firestore: { data } }) => data.alerts);
  const selectedProjectId = getSelectedProjectId(userData);
  const projectQuery = getProjectQuery({ projectId: selectedProjectId });
  const projectData = useSelector(
    ({ firestore: { data } }) => data.projects && data.projects[selectedProjectId]
  );

  useFirestoreConnect([alertsQuery, projectQuery]);

  if (!isLoaded(alertsData, projectData)) {
    return 'Loading...';
  }

  const projectAlertsConfig =
    (projectData &&
      projectData.alertsConfig &&
      projectData.alertsConfig.filter((alert) => alert.active === true)) ||
    [];

  const activeAlertNames = projectAlertsConfig.map((alert) => alert.alertName);

  let alerts = convertFirestoreCollectionToArray({
    collectionData: alertsData,
    convertDates: true,
    collectionName: alertsCollectionName,
  });

  let alertFiltered = alerts.filter((alert) => {
    return activeAlertNames.includes(alert.alertName);
  });

  //order by dateCreated
  const theftAlertId = alertsConfig.theftAlert.alertId;
  const excludeAlertIds = ['locationChange', theftAlertId];
  const alertsByDate = alertFiltered.sort((a, b) => b.dateCreated - a.dateCreated);
  const theftAlerts = alertsByDate.filter((alert) => alert.alertId === theftAlertId);
  const allAlerts = alertsByDate.filter((alert) => !excludeAlertIds.includes(alert.alertId));

  return (
    <Grid justify={'flex-start'} container spacing={2}>
      {theftAlerts.length > 0 && (
        <Grid item xs={12}>
          <Typography variant="h6">Theft Alerts</Typography>
        </Grid>
      )}
      {theftAlerts.length > 0 && (
        <Grid item xs={12}>
          <AlertsList
            alerts={theftAlerts}
            routePath={routePath}
            linkWidth={linkWidth}
            showLink={showLink}
            showSubtitle={showSubtitle}
            isComponentWide={isComponentWide}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        {title}
      </Grid>
      {alerts.length === 0 && <Typography>No alerts</Typography>}
      {alerts.length > 0 && (
        <Grid item xs={12}>
          <AlertsList
            alerts={allAlerts}
            routePath={routePath}
            linkWidth={linkWidth}
            showLink={showLink}
            showSubtitle={showSubtitle}
            isComponentWide={isComponentWide}
          />
        </Grid>
      )}
    </Grid>
  );
}

function Title({ days }) {
  if (days === 0) {
    return <Typography variant="h6">All Alerts (Today Only)</Typography>;
  } else if (days === 1) {
    return <Typography variant="h6">All Alerts (since Yesterday)</Typography>;
  } else {
    return <Typography variant="h6">All Alerts (in last {days} days)</Typography>;
  }
}
