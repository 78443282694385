import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { Typography } from '@material-ui/core';
import { getProjectIds } from '../../../config/env';
import { getDevicesQuery, getDevicesStoreAs, getAlertQuery } from '../../../api/queryConfig';
import BreadcrumbNav from '../Nav/BreadcrumbsNav';
import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // maxWidth: '48ch',
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: '100%',
    // maxWidth: '48ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  button: {
    textTransform: 'none',
    paddingTop: '28px',
    paddingLeft: '80px',
  },
  link: {
    marginLeft: '28px',
  },
  linkNoSubtitle: {
    fontSize: '14px',
    marginLeft: '28px',
  },
  alert: {
    // minHeight: '300px',
  },
}));

export default function AlertPage() {
  const classes = useStyles();
  const match = useRouteMatch(`/alerts/:alertId`);
  const alertId = match.params.alertId;
  const alertQuery = getAlertQuery({ alertId });
  const userData = useSelector((state) => state.appConfig.userData);
  let projectIds = getProjectIds(userData);
  const storeAs = getDevicesStoreAs();
  const devicesQuery = getDevicesQuery({ projectIds, storeAs });
  // console.log('AlertPage', alertId, alertQuery);
  const alert = useSelector(({ firestore: { data } }) => data.alerts && data.alerts[alertId]);
  const devicesData = useSelector(({ firestore: { data } }) => data[storeAs]);

  useFirestoreConnect([alertQuery, devicesQuery]);

  if (!isLoaded(alert, devicesData)) {
    return 'Loading Alerts...';
  }

  const devices = (devicesData && Object.values(devicesData)) || [];
  const deviceIdToNameMap = devices.reduce((obj, device) => {
    obj[device.deviceId] = device.deviceName;
    return obj;
  }, {});

  return (
    <Grid justify={'flex-start'} container spacing={2}>
      <Grid item xs={12}>
        <Breadcrumbs alertId={alertId} />
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper} variant="outlined">
          <Alert alert={alert} classes={classes} deviceIdToNameMap={deviceIdToNameMap} />
        </Paper>
      </Grid>
    </Grid>
  );
}

function Breadcrumbs({ alertId }) {
  const breadcrumbs = [
    {
      path: '/',
      text: 'Home',
    },
    {
      path: `/alerts`,
      text: 'Alerts',
    },
    {
      path: `/alerts/${alertId}`,
      text: alertId,
    },
  ];
  return <BreadcrumbNav breadcrumbs={breadcrumbs} />;
}

function Alert({ alert, classes, deviceIdToNameMap = {} }) {
  return (
    <Grid container spacing={1} className={classes.alert}>
      {alert && alert.alertName && (
        <>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Typography color="textPrimary" variant="h6">
              Alert Name:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={9} lg={9}>
            <Typography color="textPrimary" variant="h6">
              {alert.alertName}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Typography color="textPrimary" variant="h6">
              Device Id
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={9} lg={9}>
            <Typography color="textPrimary" variant="h6">
              {alert.deviceId}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Typography color="textPrimary" variant="h6">
              Device Name
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={9} lg={9}>
            <Typography color="textPrimary" variant="h6">
              {alert.deviceName}
            </Typography>
          </Grid>
        </>
      )}
      {/*{alert && alert.deviceId && (*/}
      {/*  <>*/}
      {/*    <Grid item xs={12} sm={6} md={3} lg={3}>*/}
      {/*      <Typography color="textPrimary" variant="h6">*/}
      {/*        Device Id:*/}
      {/*      </Typography>*/}
      {/*    </Grid>*/}
      {/*    <Grid item xs={12} sm={6} md={9} lg={9}>*/}
      {/*      <Typography color="textPrimary" variant="h6">*/}
      {/*        {deviceIdToNameMap[alert.deviceId]}*/}
      {/*      </Typography>*/}
      {/*    </Grid>*/}
      {/*  </>*/}
      {/*)}*/}
      {alert && alert.title && (
        <>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Typography color="textPrimary" variant="h6">
              Message:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={9} lg={9}>
            <Typography color="textPrimary" variant="h6">
              {alert.title}
            </Typography>
          </Grid>
        </>
      )}
      {alert && alert.dateCreated && (
        <>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Typography color="textPrimary" variant="h6">
              DateCreated:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={9} lg={9}>
            <Typography color="textPrimary" variant="h6">
              {format(alert.dateCreated.toDate(), 'dd MMM hh:mm')}
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
}
