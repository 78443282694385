export function addRequestToCollection({
  request,
  collectionName,
  firestore,
  setMessage,
  setLoading,
  setOpenConfirm,
  setSnackbar,
}) {
  firestore
    .collection(collectionName)
    .add(request)
    .then((res) => {
      const docId = res.id;
      firestore
        .collection(collectionName)
        .doc(docId)
        .onSnapshot((documentSnapshot) => {
          const docData = documentSnapshot.data();
          if (docData.message) {
            setMessage && setMessage(docData.message);
            setLoading && setLoading(false);
            setOpenConfirm && setOpenConfirm(false);
            setSnackbar &&
              setSnackbar({ message: docData.message, open: true, severity: 'success' });
          }
        });
    })
    .catch((err) => {
      setMessage && setMessage(`Error: ${err.message}`);
      setLoading && setLoading(false);
      setOpenConfirm && setOpenConfirm(false);
    });
}
