//  geolib.computeDestinationPoint
//  geolib.getCenter
import * as React from 'react';
import { isNumber } from 'lodash';
import { log } from '../../../util/log-util';
import { GoogleMap, Circle, Marker, InfoWindow } from '@react-google-maps/api';
import { useDispatch, useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { getProjectIds, getSelectedProjectId } from '../../../config/env';
import { getDevicesQuery, getDevicesStoreAs } from '../../../api/queryConfig';
import { makeStyles } from '@material-ui/core/styles';
import { getCenter } from 'geolib';
import { useHistory } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { getDevicesToDisplay } from '../Hives/hivesUtil';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
}));

export default function BeekeeperMap() {
  const classes = useStyles();
  let history = useHistory();
  const [show, setShow] = useState({});
  const userData = useSelector((state) => state.appConfig.userData);
  let projectIds = getProjectIds(userData);
  const storeAs = getDevicesStoreAs();
  const hivesQuery = getDevicesQuery({ storeAs, projectIds });
  const devicesData = useSelector(({ firestore: { data } }) => data[storeAs]);
  log('BeekeeperMap', projectIds);

  useEffect(() => {
    const devicesRaw = (devicesData && Object.values(devicesData)) || [];
    const devices = getDevicesToDisplay(devicesRaw);
    const devicesState = devices.reduce((obj, device) => {
      obj[device.deviceName] = true;
      return obj;
    }, {});
    setShow(devicesState);
  }, [devicesData]);

  useFirestoreConnect([hivesQuery]);

  if (!isLoaded(devicesData)) {
    return 'Loading...';
  }

  const devicesRaw = (devicesData && Object.values(devicesData)) || [];
  const devices = getDevicesToDisplay(devicesRaw);
  let coords = devices
    .filter(
      (device) =>
        device.location &&
        device.location.lat &&
        device.location.lng &&
        isNumber(device.location.lat) &&
        isNumber(device.location.lng)
    )
    .map((device) => {
      return {
        deviceName: device.deviceName,
        deviceId: device.deviceId,
        lat: device.location.lat,
        lng: device.location.lng,
      };
    });

  let coordsForGoogleMaps = coords.map((entry) => {
    return {
      coords: entry,
      weight: 150,
      deviceName: entry.deviceName,
      deviceId: entry.deviceId,
    };
  });
  log('BeekeeperMap', { devices, coords, coordsForGoogleMaps });
  if (!coords || coords.length === 0) {
    return <div>No coordinates available - please set the GPS location for at least one device.</div>;
  }
  const centerGeoLib = getCenter(coords);
  const center = { lat: centerGeoLib.latitude, lng: centerGeoLib.longitude };
  const mapContainerStyle = {
    height: '150px',
    width: '100%',
  };

  const circles = coordsForGoogleMaps.map((entry, index) => {
    const options = {
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.35,
      clickable: false,
      draggable: false,
      editable: false,
      visible: true,
      // radius: Math.sqrt(entry.weight),
      radius: entry.weight,
      zIndex: 1,
    };
    // return <Circle key={index} center={entry.coords} options={options} />;
    const infoWindow = (
      <InfoWindow
        open={show}
        pixelOffset={new window.google.maps.Size(20, 20)}
        onClick={() => setShow(!show)}
      >
        <div>
          <div>{entry.deviceName}</div>
          <div>{entry.deviceId}</div>
        </div>
      </InfoWindow>
    );
    return (
      <Marker
        key={index}
        position={entry.coords}
        title={entry.deviceName}
        onClick={() => {
          const val = show[entry.deviceName];
          setShow({ ...show, ...{ [entry.deviceName]: !val } });
        }}
        // icon={HiveIcon}
      >
        {show[entry.deviceName] && infoWindow}
      </Marker>
    );
  });

  return (
    <div className="map">
      <div className="map-container">
        <GoogleMap
          id="circle-example"
          mapContainerStyle={mapContainerStyle}
          zoom={13}
          center={center}
          onClick={() => {
            history.push('hives-overview');
          }}
        >
          {circles.map((circle) => circle)}
        </GoogleMap>
      </div>
    </div>
  );
}
