import React from 'react';
import { getUserId } from '../PrivateRoute/Auth';

export function getProfileMenuItems({ onAboutClick, user, onSettingsClick, onSignOutClick }) {
  const profileMenuItems = [
    {
      name: 'About',
      key: 'menu-1',
      onClick: onAboutClick,
    },
    {
      name: 'Profile',
      key: 'menu-2',
      to: user ? `/users/${getUserId(user)}` : null,
    },
    {
      name: 'Settings',
      key: 'menu-3',
      onClick: onSettingsClick,
    },
    {
      name: 'Sign out',
      key: 'menu-4',
      divide: true,
      onClick: onSignOutClick,
    },
  ];

  return profileMenuItems;
}
