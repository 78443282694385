import moment from 'moment-timezone';
import { getISOWeekYear, getMonth, format } from 'date-fns';
import { convertDate } from '../data/data-util';
export const dateOnlyFormat = 'yyyy-MM-dd';

export function convertDateWithTimezone(datetime, timezone = 'Europe/London') {
  const london = moment.utc(datetime).tz(timezone);
  return london;
}

export function getYearMonth(date) {
  return getISOWeekYear(date) + '-' + getMonth(date);
}

export function getDateOnlyFromBeeHero(timestamp) {
  return timestamp.substring(0, 10);
}
export function getDateOnlyAsString(date) {
  return format(date, dateOnlyFormat);
}
// function getFirestoreNow() {
//   return admin.firestore.Timestamp.now();
// }

export function getDateNoTime(dateTime) {
  try {
    dateTime.getTime();
  } catch (e) {
    console.log('getDateNoTime.err', dateTime);
  }

  // let date;
  // if(!dateTime) {
  //   dateTime = new Date();
  // }
  //
  // if(dateTime instanceof Date) {
  //   date = dateTime;
  // } else {
  //   date = new Date(dateTime.getTime());
  // }

  let date = new Date(dateTime.getTime());
  date.setHours(0, 0, 0, 0);
  return date;
}

export function convertDates(event) {
  if (event.dateCreated && event.dateCreated instanceof Date) event.dateCreated = convertDate(event.dateCreated);
  if (event.dateCreatedDb && event.dateCreatedDb instanceof Date) event.dateCreatedDb = convertDate(event.dateCreatedDb);
}
