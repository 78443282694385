import React from 'react';
import Logo from '../../../assets/Agrisound 100x100px logo.png';
// import Logo from '../polly/polly-assets/AgriSound2022.png';
// import { ReactComponent as HiveGraphic } from '../../polly-assets/Agrisound HiveOverview.svg';

const AgrisoundLogo = ({ imageFilePath = '../../polly-assets/Agrisound 100x100px logo.png' }) => {
  // const logo = require(imageFilePath);
  return (
    <div className="App">
      {/*<img src={Logo} alt="HiveOverview" style={{ width: 150, tintColor: 'orange' }} />*/}
      <img src={Logo} alt="HiveOverview" style={{ width: 40, tintColor: 'orange' }} />
      {/*<img src={Logo} alt="HiveOverview" />*/}
      {/*<HiveGraphic style={{ height: 300, width: 200 }} />*/}
    </div>
  );
};
export default AgrisoundLogo;
