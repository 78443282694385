import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import { useFirestore } from 'react-redux-firebase';
import { updateComment } from '../../../api/updateComment';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

export default function ChartCommentInput({ visible, setVisible, deviceId, x, y }) {
  const classes = useStyles();
  const firestore = useFirestore();
  const sizes = ['xs', 'sm', 'md', 'lg', 'xl'];
  const [comment, setComment] = useState();

  return (
    <Hidden only={visible ? [] : sizes}>
      <TextField
        id="standard-basic"
        label="comment"
        variant="outlined"
        styles={{ fontSize: '11px' }}
        onChange={(event) => {
          setComment(event.target.value);
        }}
        InputProps={{
          endAdornment: (
            <Button
              size={'small'}
              variant="outlined"
              color="primary"
              onClick={() => {
                updateComment({ firestore, deviceId, x, y, comment })
                  .then((res) => {
                    setVisible(false);
                  })
                  .catch((err) => {
                    setVisible(false);
                  });
              }}
            >
              Add
            </Button>
          ),
        }}
      />
    </Hidden>
  );
}
