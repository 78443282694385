import React, { useEffect, useState } from 'react';
// import Iframe from 'react-iframe';
import { useSelector } from 'react-redux';

export default function DataStudioReport() {
  const currentProject = useSelector((state) => state.appConfig.currentProject);
  const [dataStudioUrl, setDataStudioUrl] = useState(currentProject && currentProject.dataStudioUrl);
  useEffect(() => {
    currentProject && setDataStudioUrl(currentProject.dataStudioUrl);
  }, [currentProject]);

  // url = 'https://datastudio.google.com/embed/reporting/fe495df4-354c-43eb-a969-f229110f3ea8/page/EvBnC';
  // return (
  //   <Iframe
  //     // width="1000px"
  //     // height="750px"
  //     url="https://datastudio.google.com/embed/reporting/fe495df4-354c-43eb-a969-f229110f3ea8/page/EvBnC"
  //     // frameBorder="0"
  //     // style="border:0"
  //     allowFullScreen
  //   ></Iframe>
  // );
  const style = {
    width: '80vw',
    height: '95vh',
  };
  if(!dataStudioUrl) {
    return <div>No reports to display</div>;
  }

  return (
    <iframe
      src={dataStudioUrl}
      style={style}
      frameborder="0"
      // marginheight="0"
      // marginwidth="0"
      // width="100%"
      // height="100%"
      scrolling="auto"
    ></iframe>
  );
}
