import React, { useState } from 'react';
import { subHours } from 'date-fns';
import { isEmpty } from 'lodash';
import Box from '@material-ui/core/Box';
import NavLink from '../../beekeeper/Nav/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { useSelector } from 'react-redux';
import { convertDateWithTimezone } from '../../../util/date-util';
import PollyImage from '../../../assets/polly-assets/polly-ui.png';
import { globalElevation } from '../../../config/globalStyle';
import { getPermission } from '../../../util/permission-util';
import PollySiteTextField from './PollySiteTextField';
import PollyInterventionTextField from './PollyInterventionTextField';
import SnackbarAlert from '../../beekeeper/SnackbarAlert/SnackbarAlert';
import { getIsAgrisoundAdmin } from '../../../util/user-util';
import { getEquinixDeviceNumber } from './get-equinix-device-number';
const showGps = false;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    fontSize: 'small',
  },
  subheader: {
    fontSize: 'small',
  },
  action: {
    flex: '0 0 auto',
    alignSelf: 'flex-start',
    marginTop: -14,
    marginRight: -20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
}));

export function PollyDeviceCard({
  deviceId,
  device,
  projectTimezone,
  gatewayDeviceId,
  scaleDeviceId,
  showHeader = true,
  showFooter = true,
  routesConfig = {
    edit: 'polly-devices-edit',
    overview: 'polly-devices-overview',
  },
}) {
  // console.log('PollyDeviceCard', deviceId);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  let history = useHistory();
  //By not using an explicit storeAs value the same redux storage will be used as the getHivesQuery
  //I.e. Once the devices are retrieved (using getHivesQuery) then clicking on a single Hive will mean
  // the browser is loading the data from redux and not the db
  // const device = useSelector(({ firestore: { data } }) => data.devices && data.devices[deviceId]);
  const project = useSelector((state) => state.appConfig.currentProject);
  let dateCreated = getFormattedDate({ device, fieldName: 'dateCreated', timezone: project.timezone });

  const userData = useSelector((state) => state.appConfig.userData);
  const isAgrisoundAdmin = getIsAgrisoundAdmin(userData);

  const detailNav = (
    <Breadcrumbs>
      <NavLink
        path={`${routesConfig.overview}/${device.deviceId}`}
        text={'Detail'}
        padding={'0 1 0 1'}
        border={'2px solid #D3D3D3'}
        borderRadius={'4px'}
        disabled={isEmpty(device.latestEvent)}
      />
    </Breadcrumbs>
  );

  let showSiteTextField = getPermission({
    component: 'PollyDeviceCard',
    permission: 'showSiteTextField',
    userData,
    currentProjectId: project.projectId,
  });

  let showInterventionTextField = getPermission({
    component: 'PollyDeviceCard',
    permission: 'showInterventionTextField',
    userData,
    currentProjectId: project.projectId,
  });

  const handleDotsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEditMenuClick = () => {
    setAnchorEl(null);
    const route = `${routesConfig.edit}/${device.deviceId}`;
    history.push(route);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const dotsMenu = (
    <>
      <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleDotsClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu id="dots-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleEditMenuClick}>Edit</MenuItem>
      </Menu>
    </>
  );
  const fieldNameWidth = 70;
  const title = (
    <>
      <Box display="flex">
        <Typography style={{ width: fieldNameWidth }} variant={'caption'}>
          Name:
        </Typography>
        <Typography variant={'caption'}>{device.deviceName}</Typography>
      </Box>
      {project.isEquinix && (
        <Box display="flex">
          <Typography style={{ width: fieldNameWidth }} variant={'caption'}>
            EquinixID
          </Typography>
          <Typography variant={'caption'}>
            {getEquinixDeviceNumber({ projectHubCode: project.hubCode, device })}
          </Typography>
        </Box>
      )}
      {isAgrisoundAdmin && (
        <Box display="flex">
          <Typography style={{ width: fieldNameWidth }} variant={'caption'}>
            Id:
          </Typography>
          <Typography variant={'caption'}>{device.deviceId}</Typography>
        </Box>
      )}
      <Box display="flex">
        <Typography style={{ width: fieldNameWidth }} variant={'caption'}>
          Sticker:
        </Typography>
        <Typography variant={'caption'}>{device.stickerName}</Typography>
      </Box>
      {gatewayDeviceId && (
        <Box display="flex">
          <Typography style={{ width: fieldNameWidth }} variant={'caption'}>
            Gateway:
          </Typography>
          <Typography variant={'caption'}>{gatewayDeviceId}</Typography>
        </Box>
      )}
      {scaleDeviceId && (
        <Box display="flex">
          <Typography style={{ width: fieldNameWidth }} variant={'caption'}>
            Scale:
          </Typography>
          <Typography variant={'caption'}>{scaleDeviceId}</Typography>
        </Box>
      )}
    </>
  );

  return (
    <Card className={classes.root} elevation={globalElevation}>
      {showHeader && <Header classes={classes} dotsMenu={dotsMenu} title={title} />}
      <CardContent style={{ backgroundColor: '#E8E8E8' }}>
        <PollyDeviceData device={device} />
        {showGps && <Location device={device} />}
      </CardContent>
      {showFooter && (
        <CardActions>
          <CardFooter
            detailNav={detailNav}
            dateCreated={dateCreated}
            // siteOptions={siteOptions}
            // siteName={device.siteName}
            // deviceId={device.deviceId}
            device={device}
            // showSiteDropDown={showSiteDropDown}
            showSiteTextField={showSiteTextField}
            showInterventionTextField={showInterventionTextField}
          />
        </CardActions>
      )}
    </Card>
  );
}

function Location({ device }) {
  return (
    <div>
      {device.location && device.location.lat},{device.location && device.location.lng}
    </div>
  );
}

function PollyDeviceData({ device }) {
  const temp = (device.latestEvent && device.latestEvent.temperature) || 'n/a';
  const humidity = (device.latestEvent && device.latestEvent.humidity + '%') || 'n/a';
  const battery = (device.latestEvent && device.latestEvent.battery) || 'n/a';

  const image = <img src={PollyImage} alt={'Logo'} height={60} />;
  let labelValues = [
    {
      label: 'Temp',
      value: temp,
    },
    {
      label: 'Humidity',
      value: humidity,
    },
    {
      label: 'Battery',
      value: battery,
    },
  ];
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        {image}
      </Grid>
      <Grid item xs={8}>
        <DeviceData labelValues={labelValues} />
      </Grid>
    </Grid>
  );
}

function DeviceData({ labelValues = [] }) {
  return (
    <Grid container spacing={0}>
      {labelValues.map((labelValue) => {
        return (
          <React.Fragment key={labelValue.value}>
            <Grid item xs={6}>
              {labelValue.label}
            </Grid>
            <Grid item xs={6}>
              {labelValue.value}
            </Grid>
          </React.Fragment>
        );
      })}
    </Grid>
  );
}

function Header({ classes, dotsMenu, title }) {
  return (
    <CardHeader
      classes={{
        title: classes.title, // class name, e.g. `classes-nesting-label-x`
        subheader: classes.subheader, // class name, e.g. `classes-nesting-label-x`
        action: classes.action, // class name, e.g. `classes-nesting-label-x`
      }}
      action={dotsMenu}
      title={title}
    />
  );
}

function getFormattedDate({ device, fieldName, timezone }) {
  let date =
    device && device.latestEvent && device.latestEvent[fieldName] && device.latestEvent[fieldName].toDate();
  if (date) {
    date = subHours(date, 1);
    date = convertDateWithTimezone(date, timezone).format('DD-MMM HH:mm');
    // date = format(date, 'd/MM HH:mm');
  } else {
    date = 'n/a';
  }
  return date;
}

function CardFooter({ detailNav, dateCreated, device, showSiteTextField, showInterventionTextField }) {
  const classes = useStyles();
  const snackbarDefault = { message: undefined, open: false, severity: undefined };
  const [snackbar, setSnackbar] = useState(snackbarDefault);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar(snackbarDefault);
  };

  return (
    <Grid container spacing={0}>
      <SnackbarAlert snackbar={snackbar} handleClose={handleClose} />
      <Grid item xs={12}>
        <Box mt={2} display="flex" alignItems="center">
          <div style={{ marginRight: 15, marginLeft: 0 }}>{detailNav}</div>
          <Grid container justify={'center'} spacing={0}>
            <Grid item xs={12}>
              Updated @ {dateCreated}
            </Grid>
          </Grid>
        </Box>
      </Grid>
      {showSiteTextField && (
        <Grid item xs={12}>
          <PollySiteTextField classes={classes} device={device} setSnackbar={setSnackbar} />
        </Grid>
      )}
      {showInterventionTextField && (
        <Grid item xs={12}>
          <PollyInterventionTextField classes={classes} device={device} setSnackbar={setSnackbar} />
        </Grid>
      )}
    </Grid>
  );
}
