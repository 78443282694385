import React from 'react';
import PropTypes from 'prop-types';
import { subDays } from 'date-fns';
// import { Timestamp } from 'firebase/firestore'; //firebase 9
// import firebase from 'firebase';
import EventsHeatmap from './EventsHeatmap';
import EventsCircleMap from './EventsCircleMap';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { getFlowerProjectIds } from '../../../config/env';

FieldOverviewPage.propTypes = {
  user: PropTypes.object,
};
function FieldOverviewPage({ user, openSnackbar, type }) {
  // console.log('FieldOverviewPage', type);
  const limit = useSelector((state) => state.appConfig.limit);
  const userData = useSelector((state) => state.appConfig.userData);
  const storeAs = `events`;
  const events = useSelector((state) => {
    // console.log('FieldOverviewPage', state.firestore.data);
    return state.firestore.data[storeAs];
  });
  let projectIds = getFlowerProjectIds(userData);
  const projectId = projectIds[0];

  const oneWeekAgo = subDays(new Date(), 7);
  // const date = firebase.firestore.Timestamp.fromDate(oneWeekAgo);
  // const date = Timestamp.fromDate(oneWeekAgo);
  // console.log('FieldOverviewPage', { type, date, oneWeekAgo });

  const eventsQuery = [
    {
      collection: 'events',
      where: [['projectIds', 'array-contains-any', projectIds]],
      limit: limit,
      storeAs: storeAs,
    },
  ];

  useFirestoreConnect(eventsQuery);

  if (!isLoaded(events)) {
    return 'Loading...';
  }

  switch (type) {
    case 'heatmap':
      return (
        <div style={{ margin: 20 }}>
          <EventsHeatmap events={events} projectId={projectId} />
        </div>
      );
    case 'circles':
      return (
        <div style={{ margin: 20 }}>
          <EventsCircleMap events={events} projectId={projectId} />;
        </div>
      );
    default:
      return <EventsHeatmap />;
  }
}

export default FieldOverviewPage;
