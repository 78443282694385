import { firestore as db } from '../../../../firebase';
import { getEstimatedBeesPerSquareMetre } from '../../../polly/util/polly-calculation-util';
import { getDocsFromSnapshot } from './db-util';
const { orderBy, uniq, min, max, mean } = require('lodash');
const { Parser } = require('json2csv');
// const fs = require('fs');
// const { getEventsByDeviceId } = require('../util/data-util');
// const { getEventsByProjectId } = require('../util/db-util');
const { differenceInCalendarDays, addDays, format } = require('date-fns');
export const dataStudioFieldNames = [
  'projectId',
  'deviceId',
  'deviceName',
  'weekNumber',
  'weekText',
  'temp_mean',
  'temp_median',
  'temp_hi',
  'temp_low',
  'humidity_mean',
  'humidity_median',
  'humidity_hi',
  'humidity_low',
  'bee_mean',
  'bee_median',
  'bee_hi',
  'bee_low',
  'api_temp_mean',
  'device_location',
  'site_name',
  'sticker_name',
  'light_hi',
  'light_low',
  'light_mean',
  'light_median',
];

export const equinixExtraDataStudioFieldNames = [
  'hub_code',
  'habitat',
  'quadrant',
  'equinix_device_number',
];

export const getDataStudioFieldNames = ({ isEquinix }) => {
  return isEquinix
    ? dataStudioFieldNames.concat(equinixExtraDataStudioFieldNames)
    : dataStudioFieldNames;
};

export const dataStudioDisplayNames = [
  'Project',
  'Device ID',
  'Device Name',
  'weekNumber',
  'Week',
  '°C Temp Mean',
  '°C Temp Median',
  '°C Temp High',
  '°C Temp Low',
  '% Humidity Mean',
  '% Humidity Median',
  '% Humidity High',
  '% Humidity Low ',
  'Bee Mean',
  'Bee Median',
  'Bee High',
  'Bee Low',
  'api_temp_mean',
  'Device location',
  // 'fraction',
  // 'Estimated bees per day',
];

export const fieldNames = {
  projectId: 'projectId',
  deviceId: 'deviceId',
  deviceName: 'deviceName',
  weekNumber: 'weekNumber',
  weekText: 'weekText',
  temp_mean: 'temp_mean',
  temp_median: 'temp_median',
  temp_hi: 'temp_hi',
  temp_low: 'temp_low',
  humidity_mean: 'humidity_mean',
  humidity_median: 'humidity_median',
  humidity_hi: 'humidity_hi',
  humidity_low: 'humidity_low',
  bee_mean: 'bee_mean',
  bee_median: 'bee_median',
  bee_hi: 'bee_hi',
  bee_low: 'bee_low',
  api_temp_mean: 'api_temp_mean',
  device_location: 'device_location',
  site_name: 'site_name',
  sticker_name: 'sticker_name',
  light_hi: 'light_hi',
  light_low: 'light_low',
  light_mean: 'light_mean',
  light_median: 'light_median',
  hub_code: 'hub_code',
  habitat: 'habitat',
  quadrant: 'quadrant',
  equinix_device_number: 'equinix_device_number',
  // fraction: 'fraction',
  // estimated_bees: 'Estimated bees per day',
};

export const fieldNamesDisplay = {
  projectId: 'Project',
  deviceId: 'Device ID',
  deviceName: 'Device Name',
  weekNumber: 'weekNumber',
  weekText: 'Week',
  temp_mean: '°C Temp Mean',
  temp_median: '°C Temp Median',
  temp_hi: '°C Temp High',
  temp_low: '°C Temp Low',
  humidity_mean: '% Humidity Mean',
  humidity_median: '% Humidity Median',
  humidity_hi: '% Humidity High',
  humidity_low: '% Humidity Low',
  bee_mean: 'Bee Mean',
  bee_median: 'Bee Median',
  bee_hi: 'Bee High',
  bee_low: 'Bee Low',
  api_temp_mean: 'api_temp_mean',
  device_location: 'Device location',
  site_name: 'Site name',
  sticker_name: 'Sticker name',
  light_hi: 'Light High',
  light_low: 'Light Low',
  light_mean: 'Light Mean',
  light_median: 'Light Median',
  // fraction: 'fraction',
  // estimated_bees: 'Estimated bees per day',
};

export async function getDataStudioWeeklyFileName({
  projectId,
  deviceIds,
  reportStart,
  reportEnd,
  exportPath = '/Users/danstod/Documents/tmp',
}) {
  return `${projectId}_${format(reportStart, 'dd-MMM-yyyy')}_to_${format(
    reportEnd,
    'dd-MMM-yyyy'
  )}.csv`;
}
//TODO:
// 1. refactor so that we can test this without the db calls
// 2. test the DB calls in the dev db or emulator
export async function exportDataStudioWeekly({
  projectId,
  deviceIds,
  reportStart,
  reportEnd,
  exportPath = '/Users/danstod/Documents/tmp',
  includeMetrics = true,
  isEquinix = false,
  projectHubCode = 'n/a',
}) {
  // console.log(new Date(), 'export start');
  let docEvents = [];
  if (deviceIds) {
    for (let deviceId of deviceIds) {
      let docs = await getEventsByDeviceId({ deviceId });
      docEvents = docEvents.concat(docs);
    }
  }

  if (!deviceIds && projectId) {
    docEvents = await getEventsByProjectId({ projectId });
  }
  // console.log(new Date(), 'getEventsByProjectId END');

  let weatherEvents = await getWeatherEvents(projectId);
  // console.log(new Date(), 'getWeatherEvents END');
  let devices = await getAllDeviceLocations(projectId);
  console.log('devices', devices);

  let allEvents = [];
  for (let docEvent of docEvents) {
    let events = explodeEvents(docEvent.events, docEvent.deviceId, reportStart);
    allEvents = allEvents.concat(events);
  }

  // console.log(new Date(), 'explodeEvents END');

  let weekCount = 1;
  let weeksData = [];
  let weekStart = reportStart;
  let weekEnd = addDays(reportStart, 7);
  if (!deviceIds) {
    deviceIds = uniq(allEvents.map((e) => e.deviceId));
  }

  while (differenceInCalendarDays(reportEnd, weekEnd) >= 0) {
    // console.log(`processing ${format(weekStart, 'dd-MMM')} to ${format(weekEnd, 'dd-MMM')}`);
    for (let deviceId of deviceIds) {
      const weekEvents = filterEvents({
        events: allEvents,
        weekStart,
        weekEnd,
        deviceId,
      });

      const weatherEventsByWeek = filterEvents({
        events: weatherEvents,
        weekStart,
        weekEnd,
        dateFieldName: 'date',
        removeTimeFromDate: true,
      });

      let device = devices.find((device) => device.deviceId === deviceId);
      let deviceLocation = (device && device.location) || 'n/a';
      let deviceName = (device && device.deviceName) || 'n/a';
      let siteName = (device && device.siteName) || 'n/a';
      let stickerName = (device && device.stickerName) || 'n/a';

      // console.log(`processing ${deviceId} weekEvents count = ${weekEvents.length}`);
      if (weekEvents.length > 0) {
        let row = {
          [fieldNames.projectId]: projectId,
          [fieldNames.deviceId]: deviceId,
          [fieldNames.deviceName]: deviceName,
          [fieldNames.weekNumber]: weekCount,
          [fieldNames.weekText]: format(weekStart, 'dd-MMM') + ' to ' + format(weekEnd, 'dd-MMM'),
          [fieldNames.temp_hi]: getHigh({ events: weekEvents, fieldName: 'temperature' }),
          [fieldNames.temp_low]: getLow({ events: weekEvents, fieldName: 'temperature' }),
          [fieldNames.humidity_hi]: getHigh({ events: weekEvents, fieldName: 'humidity' }),
          [fieldNames.humidity_low]: getLow({ events: weekEvents, fieldName: 'humidity' }),
          [fieldNames.bee_hi]: getHigh({ events: weekEvents, fieldName: 'beeActivity' }),
          [fieldNames.bee_low]: getLow({ events: weekEvents, fieldName: 'beeActivity' }),
          [fieldNames.device_location]: deviceLocation,
          [fieldNames.site_name]: siteName,
          [fieldNames.sticker_name]: stickerName,
          [fieldNames.light_hi]: getHigh({ events: weekEvents, fieldName: 'light' }),
          [fieldNames.light_low]: getLow({ events: weekEvents, fieldName: 'light' }),
        };

        if (includeMetrics) {
          row[fieldNames.temp_mean] = getAverage({ events: weekEvents, fieldName: 'temperature' });
          row[fieldNames.temp_median] = getMedian({ events: weekEvents, fieldName: 'temperature' });
          row[fieldNames.humidity_mean] = getAverage({ events: weekEvents, fieldName: 'humidity' });
          row[fieldNames.humidity_median] = getMedian({
            events: weekEvents,
            fieldName: 'humidity',
          });
          row[fieldNames.bee_mean] = getAverage({ events: weekEvents, fieldName: 'beeActivity' });
          row[fieldNames.bee_median] = getMedian({ events: weekEvents, fieldName: 'beeActivity' });
          row[fieldNames.api_temp_mean] = getAverage({
            events: weatherEventsByWeek,
            fieldName: 'temp',
          });
          row[fieldNames.light_mean] = getAverage({ events: weekEvents, fieldName: 'light' });
          row[fieldNames.light_median] = getMedian({ events: weekEvents, fieldName: 'light' });
          // row.fraction = row.bee_mean && row.bee_mean === 0 ? 0 : row.bee_mean / 100;
          // row.estimated_bees = getEstimatedBeesPerSquareMetre(row.bee_hi);
        }

        if (isEquinix) {
          const hubCode = device.hubCode || projectHubCode || 'n/a';
          let habitat = (device && device.habitat) || 'n/a';
          let quadrant = (device && device.quadrant) || 'n/a';
          let equinixDeviceNumber = (device && device.equinixDeviceNumber) || 'n/a';

          row[fieldNames.hub_code] = hubCode;
          row[fieldNames.habitat] = habitat;
          row[fieldNames.quadrant] = quadrant;
          row[fieldNames.equinix_device_number] = equinixDeviceNumber;
        }

        weeksData.push(row);
      }
    }

    weekStart = weekEnd;
    weekCount++;
    weekEnd = addDays(weekStart, 7);
  }
  // const reportFileName = `${projectId}_${format(reportStart, 'dd-MMM-yyyy')}_to_${format(
  //   reportEnd,
  //   'dd-MMM-yyyy'
  // )}.csv`;
  // await toCsv(weeksData, `${exportPath}/${reportFileName}`, opts);
  // console.log(`${exportPath}/${reportFileName} exported`);

  // console.log('weeksData', weeksData);
  return weeksData;
}

async function getAllDeviceLocations(projectId) {
  const snapshot = await db
    .collection(`devices`)
    .where('projectIds', 'array-contains-any', [projectId])
    .get();
  const devices = getDocsFromSnapshot(snapshot);
  return devices.map((device) => {
    let location = 'n/a';
    if (device.location && device.location.lat && device.location.lng) {
      location = `${device.location.lat},${device.location.lng}`;
    }
    return {
      deviceId: device.deviceId,
      deviceName: device.deviceName,
      location,
      ...device,
    };
  });
}

async function getWeatherEvents(projectId) {
  const project = await getProjectByProjectId(projectId);
  //The Agrisound UI is currently set up to only set a single weather location so grab the first on the array
  const weatherLocationId = project.weatherLocations && project.weatherLocations[0];
  if (!weatherLocationId) {
    return [];
  }

  const weatherDocs = await getWeatherDocs(projectId);
  let allWeatherEvents = [];
  // console.log('weatherDocs.len', weatherDocs.length);
  for (let doc of weatherDocs) {
    allWeatherEvents = allWeatherEvents.concat(doc.weatherEvents);
  }

  //filter for the single weatherLocationId. Not necessarily needed right now but in future
  //if there are multiple locations the logic might be like this:
  // return weatherEvents.filter(event => event.docId.includes(weatherLocationId));
  // console.log('allWeatherEvents.len', allWeatherEvents.length);
  return allWeatherEvents;
}

async function getProjectByProjectId(projectId) {
  const projectRef = await db.doc(`projects/${projectId}`).get();
  if (projectRef.exists) {
    let project = projectRef.data();
    project.docId = projectRef.id;
    return project;
  }

  throw new Error(`${projectId} does not exist`);
}

async function getWeatherDocs(projectId) {
  const snapshot = await db.collection(`projects/${projectId}/weather`).get();
  return getDocsFromSnapshot(snapshot);
}

async function toCsv(jsonData, fileName, opts) {
  try {
    const parser = new Parser(opts);
    for (let item of jsonData) {
      item.dateCreated = new Date(item.dateCreated);
    }
    const csv = parser.parse(jsonData);
    // console.log(csv);
    // await fs.writeFileSync(fileName, csv);
  } catch (err) {
    console.error(err);
  }
}

function getLow({ events, fieldName }) {
  let filtered = filterForNumbers({ events, fieldName });
  const values = filtered.map((e) => e[fieldName]);
  // return min(values) || 'n/a';
  const minValue = min(values);
  return minValue === 0 ? 0 : minValue || 'n/a';
}

function getHigh({ events, fieldName }) {
  let filtered = filterForNumbers({ events, fieldName });
  const values = filtered.map((e) => e[fieldName]);
  // console.log('getHigh', fieldName, values);
  const maxValue = max(values);
  return maxValue === 0 ? 0 : maxValue || 'n/a';

  // const result = maxBy(events, fieldName);
  // console.log(result);
  // if(isNaN(result[fieldName])) {
  //   return 'n/a';
  // } else {
  //   return result[fieldName];
  // }
}

function filterForNumbers({ events, fieldName }) {
  return events.filter((e) => !isNaN(e[fieldName]));
}

function getAverage({ events, fieldName }) {
  let filtered = filterForNumbers({ events, fieldName });
  const values = filtered.map((e) => e[fieldName]);
  if (filtered.length === 0) {
    return 'n/a';
  }
  return mean(values);
}

/**
  @function filterEvents
  @description Return a filtered list of 'events' from an array of events determined by the weekStart and the numberOfDays

  @param {array} events - the events to filter on
  @param {date} weekStart - the date to start from
  @param {string} [deviceId] - the deviceId to filter for if provided
  @param {number} numberOfDays=7 - the number of days to get from the weekStart
 **/
function filterEvents({ events, weekStart, deviceId, numberOfDays = 7 }) {
  let filtered = events.filter(
    (e) =>
      differenceInCalendarDays(e.dateCreated, weekStart) >= 0 &&
      differenceInCalendarDays(e.dateCreated, weekStart) < numberOfDays
  );

  if (deviceId) {
    return filtered.filter((e) => e.deviceId === deviceId);
  }

  return filtered;
}

function getMedian({ events, fieldName, values }) {
  if (!values) {
    values = orderBy(events.map((event) => event[fieldName]));
  }
  if (isOdd(values.length)) {
    const pos = (values.length - 1) / 2;
    return values[pos];
  } else {
    const val1 = values[values.length / 2 - 1];
    const val2 = values[values.length / 2];
    return (val1 + val2) / 2;
  }
}

function isOdd(num) {
  return num % 2;
}

function explodeEvents(events, deviceId) {
  let explodedEvents = [];
  for (let event of events) {
    if (event.dateCreated) {
      try {
        event.dateCreated = event.dateCreated.toDate();
        event.deviceId = deviceId;
        explodedEvents.push(event);
      } catch (err) {
        console.log('err', deviceId, event);
      }
    }
  }
  return explodedEvents;
}

async function getEventsByProjectId({ projectId }) {
  if (!projectId) {
    throw new Error('projectId not provided');
  }

  const snapshot = await db
    .collection('events')
    .where('projectIds', 'array-contains-any', [projectId])
    .get();
  return getDocsFromSnapshot(snapshot);
}

async function getEventsByDeviceId({ deviceId, collectionName = 'events' }) {
  const snapshot = await db.collection(collectionName).where('deviceId', '==', deviceId).get();
  return getDocsFromSnapshot(snapshot);
}

// module.exports = {
//   exportDataStudioWeekly,
//   getMedian,
//   filterEvents,
//   getLow,
//   getHigh,
//   getAverage
// };
