import React from 'react';
import Grid from '@material-ui/core/Grid';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import IconButton from '@material-ui/core/IconButton';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { format } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import { deleteDeviceLog } from '../../../api/updateDevice';
import { DeviceLogForm2 } from './DeviceLogForm2';

const logs = [
  {
    dateCreated: '8 Apr 2022 10:00am',
    logDate: new Date('8 Apr 2022'),
    description: 'flashed with latest firmware and re-deployed in field',
    userId: '1124',
    userEmail: 'dan@agrisound.io',
  },
  {
    dateCreated: '4 Apr 2022 10:00am',
    logDate: new Date('4 Apr 2022'),
    description: 'battery replaced',
    userId: '1123',
    userEmail: 'calum@agrisound.io',
  },
  {
    dateCreated: '1 Apr 2022 10:00am',
    logDate: new Date('1 Apr 2022'),
    description: 'Device not working, recalled',
    userId: '1123',
    userEmail: 'dan@agrisound.io',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    // textAlign: 'center',
    color: theme.palette.text.secondary,
    width: '100%',
  },
  timelineDeleteRow: {
    width: '100px'
  }
}));

//https://v4.mui.com/components/timeline/

export default function DeviceLogs({ device }) {
  // console.log('DeviceLogs', device);
  const classes = useStyles();
  const logs = device.logs || [];

  // const deleteLogAction = async (logDate, logDescription) => {
  //   let newLogs = [...device.logs] || [];
  //   newLogs = newLogs.filter(log => log.logDescription === logDescription && log.logDate.toDate().getTime() === logDate.getTime())
  //   await deleteDeviceLog({ deviceId: device.deviceId, newLogs, logDescription });
  // };

  return (
    <Paper className={classes.paper}>
      <Grid style={{ width: '100%' }} container spacing={2}>
        <Grid item xs={12}>
          <Typography style={{ textAlign: 'center' }} color="textPrimary" variant="h6">
            Device Logs
          </Typography>
        </Grid>
        {logs.length === 0 && (
          <Grid item xs={12}>
            <Typography style={{ textAlign: 'center' }} color="grey" variant="subtitle1">
              Nothing to display
            </Typography>
          </Grid>
        )}
        {logs.length > 0 && (
          <Timeline>
            {logs.map((log, index) => (
              <Grid item xs={12}>
                <TimelineEvent
                  date={format(log.logDate.toDate(), 'dd MMM yy')}
                  description={log.logDescription}
                  userEmail={log.userEmail}
                  includeLine={index !== logs.length - 1}
                  deleteLogAction={() => {
                    let newLogs = [...device.logs] || [];
                    const logDescription = log.logDescription;
                    const logDate = log.logDate.toDate();
                    newLogs = newLogs.filter(log => log.logDescription !== logDescription && log.logDate.toDate().getTime() !== logDate.getTime())
                    deleteDeviceLog({ deviceId: device.deviceId, newLogs, logDescription });
                  }}
                />
              </Grid>
            ))}
          </Timeline>
        )}
        <Grid item xs={12}>
          <DeviceLogForm2 device={device} />
        </Grid>
      </Grid>
    </Paper>
  );
}

function TimelineEvent({ date, description, userEmail, includeLine, deleteLogAction }) {
  // console.log('TimelineEvent', { date, description, userEmail, includeLine, index });
  return (
    <TimelineItem>
      <TimelineOppositeContent>
        <Box
          sx={{
            // width: '200px',
            display: 'flex',
            flexDirection: 'row-reverse',
            // p: 1,
            // m: 1,
            bgcolor: 'background.paper',
            borderRadius: 1,
          }}
        >
          <Typography color="textSecondary">{date}</Typography>
          <IconButton component="span" style={{ marginTop: -12 }}>
            <DeleteForeverIcon width={10} onClick={deleteLogAction}/>
          </IconButton>
        </Box>
        <Typography color="secondary" variant={'subtitle2'}>
          {userEmail}
        </Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot />
        {includeLine && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        <Typography>{description}</Typography>
      </TimelineContent>
    </TimelineItem>
  );
}
