import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { GoogleMap, HeatmapLayer, Marker, InfoWindow, Circle } from '@react-google-maps/api';

const onClick = (...args) => {
  console.log('onClick args: ', args);
};

export default function PollyHeatmap({
  devices,
  showMarkers = false,
  currentView,
  slider,
  height = '400px',
  width = '100%',
  zoom = 20,
  showTimeline = true,
  centralDeviceId = 'PNET_474',
  circeMultiplier = 1
}) {
  const mapContainerStyle = {
    height,
    width,
  };
  let data = getHeatmapData(devices, circeMultiplier);
  // console.log('PollyHeatmap.data', data);
  const center = devices.find((device) => device.deviceId === centralDeviceId).location;

  const markers = devices.map((device) => {
    return {
      // title: device.deviceId,
      // content: `Pollination Activity: ${device.pollinationActivity}`,
      // content: `${device.pollinationActivity}, ${device.weight}`,
      // content: `${device.deviceId.substring(5, 9)}: ${device.pollinationActivity}`,
      deviceId: device.deviceId.substring(5, 9),
      pollinationActivity: device.pollinationActivity,
      // content: `${device.deviceId.substring(5, 9)}`,
      // content: `${device.deviceId.substring(5, 9)}, PA: ${device.pollinationActivity}`,
      position: {
        lat: device.location.lat,
        lng: device.location.lng,
      },
    };
  });

  const circles = getCircles({ devices: data, days: 1 });

  return (
    <Grid container spacing={2}>
      {showTimeline && (
        <Grid item xs={12} sm={12} md={3} lg={3}>
          {currentView}
        </Grid>
      )}
      {showTimeline && (
        <Grid item xs={12} sm={12} md={9} lg={9}>
          {slider}
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <div className="map">
          <div className="map-container">
            <GoogleMap
              id="heatmap-example"
              streetViewControl={false}
              isFractionalZoomEnabled={false}
              mapContainerStyle={mapContainerStyle}
              zoom={zoom}
              mapTypeId={'satellite'}
              center={center}
              onClick={onClick}
              // maxIntensity={15}
            >
              {/*{circles.map((circle) => circle)}*/}
              <HeatmapLayer data={data} />
              {showMarkers &&
                markers.map((marker) => (
                  <Marker {...marker}>
                    <InfoWindow>
                      <div><span style={{ fontWeight: 'bold'}}>{marker.deviceId}</span>:{marker.pollinationActivity}</div>
                      {/*<span>{marker.pollinationActivity}</span>*/}
                      {/*<span>{marker.content}</span>*/}
                    </InfoWindow>
                  </Marker>
                ))}
              }
            </GoogleMap>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

function getCircles({ devices, days = 1 }) {
  const circles = devices.map((device, index) => {
    const options = {
      strokeColor: '#FF0000',
      // strokeColor: device.weight > 10 ? 'red' : '#00ffff',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#ff5900',
      // fillColor: '#00ffb7',
      // fillColor: device.weight > 10 ? 'red' : '#00ffb7',
      fillOpacity: 0.35,
      clickable: false,
      draggable: false,
      editable: false,
      visible: true,
      radius: Math.sqrt(device.weight / days),
      zIndex: 1,
    };
    return <Circle key={index} center={device.coords} options={options} />;
  });

  return circles;
}
function getHeatmapData(devices, circeMultiplier) {
  let data = [];
  for (let device of devices) {
    let coords = { lat: device.location.lat, lng: device.location.lng };
    let latLng = new window.google.maps.LatLng(device.location.lat, device.location.lng);
    data.push({
      weight: device.weight * circeMultiplier || 1,
      pollinationActivity: device.pollinationActivity || '110%',
      location: latLng,
      coords,
    });
  }

  return data;
}
