import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { updateDevice } from '../../../api/updateDevice';
import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import SaveIcon from '@material-ui/icons/Save';
import IconButton from '@material-ui/core/IconButton';

export default function PollyInterventionTextField({ classes, device, setSnackbar }) {
  let initialValue = (device && device.tags && device.tags.intervention) || '';
  const [value, setValue] = useState(initialValue);
  const deviceId = device.deviceId;
  return (
    <FormControl className={classes.formControl}>
      <FormHelperText>Intervention</FormHelperText>
      <Box flexDirection="row">
        <TextField
          multiline
          style={{ backgroundColor: '#fed8b1', marginTop: '5px' }}
          rows={1}
          required
          id="intervention"
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
        <IconButton
          onClick={(e) => {
            const updatedObj = { tags: { intervention: value } };
            updateDevice({ deviceId, updatedObj });
            setSnackbar && setSnackbar({ message: `'intervention' tag saved for ${deviceId}`, open: true, severity: 'success' });
          }}
        >
          <SaveIcon />
        </IconButton>
      </Box>
    </FormControl>
  );
}
