import { getMenuConfig } from '../config/menu-config';
import { cloneDeep } from 'lodash';
import { beekeeperUserRoles } from '../config/userRoles';

function getAdminFilteredMenuItems(menuItems, isAgrisoundAdmin) {
  const copy = cloneDeep(menuItems);
  if (isAgrisoundAdmin) {
    return copy;
  }

  return copy.filter((menuItem) => menuItem.isAdmin === false);
}

export function getMenuItems({ accountType, userRoles, isAgrisoundAdmin, isCanterbury }) {
  if (isCanterbury) {
    return [
      {
        name: 'Canterbury',
        key: 'canterbury',
        to: '/canterbury',
        divide: true,
        isAdmin: true,
      },
    ];
  }
  //TODO: define the other accountType menus and default menu
  switch (accountType) {
    case 'beekeeper': {
      // console.log('getMenuConfig', { accountType, userRoles });
      return getNewMenuConfig( { accountType, isAgrisoundAdmin, userRoles })
      // return getMenuConfig({ accountType, userRoles: newUserRoles });
    }
    case 'polly': {
      return getMenuConfig({ accountType, userRoles });
    }
    case 'none': {
      return [];
    }
    default:
      return getNewMenuConfig( { accountType, isAgrisoundAdmin, userRoles })
  }
}

function getNewMenuConfig({ accountType, isAgrisoundAdmin, userRoles }) {
  let newUserRoles = cloneDeep(userRoles);
  if(isAgrisoundAdmin) {
    newUserRoles.push(beekeeperUserRoles.agriSoundAdmin);
  }

  return getMenuConfig({ accountType, userRoles: newUserRoles });
}


