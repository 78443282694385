import React from 'react';
import DevicesMap from './DevicesMap';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { getProjectIds } from '../../../config/env';
import { getDevicesQuery, getDevicesStoreAs } from '../../../api/queryConfig';
import { uniqObject } from '../Filter/filterUtil';

export default function PollyMapPage() {
  // const classes = useStyles();
  const userData = useSelector((state) => state.appConfig.userData);
  let projectIds = getProjectIds(userData);
  // const projectId = projectIds[0];
  const storeAs = getDevicesStoreAs();
  const devicesQuery = getDevicesQuery({ projectIds, storeAs });
  const devicesData = useSelector(({ firestore: { data } }) => data[storeAs]);

  useFirestoreConnect([devicesQuery]);

  if (!isLoaded(devicesData)) {
    return 'Loading...';
  }

  const devicesRaw = (devicesData && Object.values(devicesData)) || [];
  // let filtered = devicesRaw.filter((device) => device.deviceId.includes('PNET_INNOCENT'));
  let filtered = devicesRaw.filter(device => device.location);
  // const devices = devicesRaw;
  // setDevices(devicesRaw);
  console.log('PollyMapPage', filtered);
  const farms = uniqObject(filtered, 'farm');
  const groups = uniqObject(filtered, 'group');
  const sites = uniqObject(filtered, 'site');
  return <DevicesMap initialDevicesData={filtered} farms={farms} groups={groups} sites={sites} />;
}

