import React from 'react';
import { log } from '../../../util/log-util';
import useOpenWeather from './useOpenWeather';
import Forecast from './Forecast';
import { subDays } from 'date-fns';
import { getDateNoTime } from '../../../util/date-util';
import PollyForecast from './PollyForecast';
const weatherApiKey = '71db987946eb9f72df358cf914827a02';

export default function Weather({ lat, lng, dataOverride }) {
  const date = subDays(getDateNoTime(new Date()), 1);
  const unix = Math.round(+date / 1000);
  const historic = false;
  let { data, isLoading, errorMessage } = useOpenWeather({
    key: weatherApiKey,
    lat: lat,
    lon: lng,
    lang: 'en',
    unit: 'metric', // values are (metric, standard, imperial)
    historic,
    dt: unix, //required if historic = true
  });
  if(dataOverride) data = dataOverride;
  // console.log('Weather', data && data.forecast);
  // return (
  //   <ReactWeather
  //     isLoading={isLoading}
  //     errorMessage={errorMessage}
  //     data={data}
  //     lang="en"
  //     locationLabel="Munich"
  //     unitsLabels={{ temperature: 'C', windSpeed: 'Km/h' }}
  //     showForecast
  //   />
  // );
  if (isLoading) {
    return <></>;
  }
  if (historic) {
    return (
      <Forecast
        unitsLabels={{ temperature: 'C', windSpeed: 'Km/h' }}
        // forecast={(data && [data.current]) || []}
        current={data && data.current}
        lang="en"
        // theme={theme}
      />
    );
  }
  if(dataOverride) {
    return (
      <PollyForecast
        unitsLabels={{ temperature: 'C', windSpeed: 'Km/h' }}
        forecast={(data && data.forecast) || []}
        lang="en"
        // theme={theme}
      />
    );
  }
  return (
    <Forecast
      unitsLabels={{ temperature: 'C', windSpeed: 'Km/h' }}
      forecast={(data && data.forecast) || []}
      lang="en"
      // theme={theme}
    />
  );
}
