import React, { useEffect, useRef, useState } from 'react';
import { log } from '../../../util/log-util';
// import Highcharts from 'highcharts';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { getHiveChartData } from './chart-data-util';
import ChartCommentInput from './CommentInput';
import ChartDateRange, { rangeOptions } from './ChartDateRange';
import ChartDeviceToggle from './ChartDeviceToggle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import {
  getAnnotationsData,
  getAudioEventTableData,
  getEventTableData,
  getFlagsData,
} from '../../../data/data-util';
import { uniq } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { useFirestore } from 'react-redux-firebase';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import { deleteComment } from '../../../api/deleteComment';
import DeleteIcon from '@material-ui/icons/Delete';
import Divider from '@material-ui/core/Divider';
import { cloneDeep } from 'lodash';
import { subDays, differenceInDays } from 'date-fns';
import { defaultHighChartOptions } from './highChartsConfig';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
// import { sampleData } from './highchartsSample';
import { getIsAgrisoundAdmin } from '../../../util/user-util';
import DownloadFile from '../Download/DownloadFile';
// import Box from '@material-ui/core/Box';

/*

  Highcharts reference: https://api.highcharts.com/highcharts/xAxis.min

  Multi-axis examples: https://www.highcharts.com/demo/combo-multi-axes

  Annotations:
  https://www.highcharts.com/docs/advanced-chart-features/annotations-module
  https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/highcharts/demo/annotations/

  examples:

  https://stackblitz.com/edit/react-4ded5d?file=components%2FContainer.jsx
  https://codesandbox.io/s/volumechart-o8nrg?file=/src/index.js

 */

const labelFormatMap = {
  temperature: '{value}°C',
  temperatureImperial: '{value}°F',
  humidity: '{value}%',
  weight: '{value} Kg',
  weightImperial: '{value} lbs',
};

const useStyles = makeStyles((theme) => ({
  dateRange: {
    marginBottom: 20,
  },
  comments: {
    width: '100%',
  },
}));

export default function HighCharts({
  chartData,
  accountType,
  eventsRows,
  devicesData,
  weatherData,
  title,
  deviceId = undefined,
  projectId,
  initialToggleState = {
    weatherTemp: true,
    sensorTemp: true,
    gatewayTemp: true,
    humidity: true,
    weight: true,
    voltage: true,
    battery: true,
    acoustics: false,
    peakFlight: false,
    peakFanning: false,
    hiveActivity: false,
    beeActivity: false,
  },
  showGatewayOnly,
  showToggleGroup = true,
  showChartLineToggles = true,
}) {
  // console.log('HighCharts', eventsRows);
  return (
    <Chart
      chartData={chartData}
      projectId={projectId}
      accountType={accountType}
      eventsRows={eventsRows}
      weatherData={weatherData}
      devicesData={devicesData}
      title={title}
      deviceId={deviceId}
      initialToggleState={initialToggleState}
      showGatewayOnly={showGatewayOnly}
      showToggleGroup={showToggleGroup}
      showChartLineToggles={showChartLineToggles}
    />
  );
}

function Chart({
  chartData,
  projectId,
  accountType,
  eventsRows,
  weatherData,
  devicesData,
  title,
  deviceId,
  initialToggleState,
  showGatewayOnly,
  showToggleGroup,
  showChartLineToggles,
}) {
  // console.log('dan', eventsRows.filter(row => row.deviceId === '84:2e:14:4e:3f:bd'));
  const classes = useStyles();
  const chartRef = useRef(null);
  let devices = Object.values(devicesData) || [];
  devices = devices.filter((device) => device.active);
  const device = devices.find((device) => device.deviceId === deviceId);
  /*
    If the scale device is associated with a hive map the scaleDeviceId to the (IHS) Hive Name
   */
  const devicesWithScales = devices.filter((device) => device.hasOwnProperty('scaleDeviceId'));
  let scaleDeviceIdNameMap = devicesWithScales.reduce((obj, device) => {
    obj[device.scaleDeviceId] = device.deviceName;
    return obj;
  }, {});

  let deviceIdNameMap = devices.reduce((obj, device) => {
    obj[device.deviceId] = device.deviceName;
    if (scaleDeviceIdNameMap[device.deviceId]) {
      obj[device.deviceId] = scaleDeviceIdNameMap[device.deviceId];
    }
    return obj;
  }, {});
  const [showComment, setShowComment] = useState(false);
  const [hasBeeCounterData, setHasBeeCounterData] = useState(false);
  // const [names, setNames] = useState({});
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  defaultHighChartOptions.plotOptions.series.point.events.click = (pointerEvent) => {
    // console.log('pointerEvent', pointerEvent);
    setX(pointerEvent.point.x);
    setY(pointerEvent.point.y);
    setShowComment(true);
  };
  defaultHighChartOptions.time.getTimezoneOffset = (timestamp) => {
    const zone = 'Europe/London';
    const timezoneOffset = -moment.tz(timestamp, zone).utcOffset();
    return timezoneOffset;
  };
  const [options, setOptions] = useState(defaultHighChartOptions);
  const [annotations, setAnnotations] = useState([]);
  // const [rangeSelected, setRangeSelected] = useState(rangeOptions.max);
  const [maxDate, setMaxDate] = useState(new Date());
  const [minDate, setMinDate] = useState();
  const [minDate2, setMinDate2] = useState(new Date());
  const [toggleState, setToggleState] = useState(initialToggleState);
  const userData = useSelector((state) => state.appConfig.userData);
  const currentProject = useSelector((state) => state.appConfig.currentProject);
  const isMetric = currentProject && currentProject.hasOwnProperty('metric') ? currentProject.metric : true;
  const isAgrisoundAdmin = getIsAgrisoundAdmin(userData);
  // let showAudio = userData.showAudioInCharts || false;
  let showAudio = true;

  let deviceIdsToDisplay = devices.map((device) => device.deviceId);
  let allTableData = getEventTableData(eventsRows, deviceIdsToDisplay, accountType);
  let allDeviceIds = uniq(allTableData.map((entry) => entry.deviceId));
  if (device && device.hasOwnProperty('scaleDeviceId')) {
    allDeviceIds = allDeviceIds.filter((deviceId) => deviceId !== device.scaleDeviceId);
  }
  let selectedId = deviceId ? [deviceId] : allDeviceIds;
  if (showGatewayOnly && device) selectedId = [device.gatewayDeviceId];
  const [selectedDeviceIds, setSelectedDeviceIds] = useState(selectedId);
  // const [deviceIds, setDeviceIds] = useState(allDeviceIds);

  log('HighCharts', { options, deviceId, allDeviceIds, deviceIdNameMap });

  useEffect(() => {
    const { current } = chartRef;
    if (current && current.chart) {
      current.chart.reflow();
    }
  }, [chartRef]);

  useEffect(() => {
    // console.log('useEffect.minDate', minDate);
    setTheChartData2({
      chartData,
      eventsRows,
      weatherData,
      device,
      devices,
      deviceIds: selectedDeviceIds,
      toggleState,
      title,
      options,
      setOptions,
      minDate,
      setMinDate2,
      setMaxDate,
      deviceIdNameMap,
      setAnnotations,
      isAgrisoundAdmin,
      showAudio,
      isMetric,
      setHasBeeCounterData,
    });
    // console.log('HighCharts.chartRef', chartRef, chartRef.current.container.current.hasOwnProperty('reflow'));
    // if (chartRef.current.chart) {
    //   // console.log(chartRef.current.chart.hasOwnProperty('reflow'))
    //   chartRef.current.chart.reflow();
    // }
  }, [minDate, selectedDeviceIds, toggleState]);

  useEffect(() => {
    setTheChartData2({
      chartData,
      eventsRows,
      weatherData,
      device,
      devices,
      deviceIds: selectedDeviceIds,
      toggleState: initialToggleState,
      title,
      options,
      setOptions,
      minDate,
      setMinDate2,
      setMaxDate,
      deviceIdNameMap,
      setAnnotations,
      showAudio,
      isMetric,
      setHasBeeCounterData,
    });
  }, [eventsRows]);

  // useEffect(() => {
  //   console.log('options', options);
  // }, [options]);

  const handleToggleChange = (event) => {
    let dataType = event.target.name;
    let checked = event.target.checked;
    const newToggleState = { ...toggleState, [dataType]: checked };

    setToggleState(newToggleState);
  };

  // const onRangeChange = (rangeSelected) => {
  //   setRangeSelected(rangeSelected);
  //   const newMinDate = getMinDate({ rangeSelected, maxDate, minDate2 });
  //   // console.log('onRangeChange.newMinDate', { rangeSelected, maxDate, minDate2, newMinDate });
  //   setMinDate(newMinDate);
  // };

  // const onDeviceToggleChange = (deviceId) => {
  //   let newDeviceIds = [...selectedDeviceIds];
  //   if (!selectedDeviceIds.includes(deviceId)) {
  //     newDeviceIds.push(deviceId);
  //   } else {
  //     newDeviceIds = newDeviceIds.filter((id) => id !== deviceId);
  //   }
  //
  //   setSelectedDeviceIds(newDeviceIds);
  // };

  const onDeviceToggleChange = (deviceIds) => {
    let newDeviceIds = [...selectedDeviceIds];
    for (let deviceId of deviceIds) {
      if (!selectedDeviceIds.includes(deviceId)) {
        newDeviceIds.push(deviceId);
      } else {
        newDeviceIds = newDeviceIds.filter((id) => id !== deviceId);
      }
    }

    setSelectedDeviceIds(newDeviceIds);
  };

  const gridSize = annotations && annotations.length > 0 ? 10 : 12;
  const demo = false;
  /*
    https://stackblitz.com/edit/highcharts-responsive-size-solution?file=chart.jsx
    https://codesandbox.io/s/9f7ly?file=/src/HighChartsReflow.js
  */

  if (demo) {
    return (
      <HighchartsReact
        ref={chartRef}
        highcharts={Highcharts}
        options={options}
        // containerProps={{ style: { width: '100%' } }}
      />
    );
  }

  const fileName = projectId + '-chart-data.csv';

  const downloadButton = (
    <DownloadFile
      eventsRows={eventsRows}
      isMetric={isMetric}
      fileName={fileName}
      deviceIds={selectedDeviceIds}
      devices={devices}
    />
  );
  return (
    <Grid container spacing={2}>
      {deviceId && (
        <Grid item xs={4} lg={4}>
          <ChartCommentInput
            visible={showComment}
            setVisible={setShowComment}
            deviceId={deviceId}
            x={x}
            y={y}
          />
        </Grid>
      )}
      {showToggleGroup && (
        <Grid item xs={12}>
          <ChartDeviceToggle
            deviceIdNameMap={deviceIdNameMap}
            selectedDeviceIds={selectedDeviceIds}
            onDeviceToggleChange={onDeviceToggleChange}
            downloadButton={downloadButton}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        {/*<HighchartsReact highcharts={Highcharts} options={options} constructorType={'stockChart'} />*/}
        <div>
          <HighchartsReact
            ref={chartRef}
            highcharts={Highcharts}
            options={options}
            containerProps={{ className: 'chartContainer' }}
          />
        </div>
        {/*<HighchartsReact highcharts={Highcharts} options={options} height={'600px'} />*/}
      </Grid>
      {showChartLineToggles && (
        <Grid item xs={12}>
          <ChartLineToggles
            toggleState={toggleState}
            handleToggleChange={handleToggleChange}
            showAudio={showAudio}
            hasBeeCounterData={hasBeeCounterData}
          />
        </Grid>
      )}
      {gridSize === 10 && (
        <Grid item xs={8} sm={6} md={6} lg={6} xl={6}>
          <RemoveAnnotations annotations={annotations} classes={classes} deviceId={deviceId} />
        </Grid>
      )}
    </Grid>
  );
}

function ChartLineToggles({ toggleState, handleToggleChange, showAudio, hasBeeCounterData = true }) {
  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <FormControlLabel
          control={<Switch checked={toggleState.humidity} onChange={handleToggleChange} name="humidity" />}
          label="Show Humidity"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <FormControlLabel
          control={
            <Switch checked={toggleState.sensorTemp} onChange={handleToggleChange} name="sensorTemp" />
          }
          label="Show Sensor Temp"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <FormControlLabel
          control={
            <Switch checked={toggleState.weatherTemp} onChange={handleToggleChange} name="weatherTemp" />
          }
          label="Show Weather API Temp"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <FormControlLabel
          control={<Switch checked={toggleState.weight} onChange={handleToggleChange} name="weight" />}
          label="Show Weight"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <FormControlLabel
          control={<Switch checked={toggleState.battery} onChange={handleToggleChange} name="battery" />}
          label="Show Battery Level"
        />
      </Grid>
      {showAudio && (
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <FormControlLabel
            control={
              <Switch checked={toggleState.peakFlight} onChange={handleToggleChange} name="peakFlight" />
            }
            label="Show Peak Flight Noise"
          />
        </Grid>
      )}
      {showAudio && (
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <FormControlLabel
            control={
              <Switch checked={toggleState.peakFanning} onChange={handleToggleChange} name="peakFanning" />
            }
            label="Show Peak Fanning"
          />
        </Grid>
      )}
      {showAudio && (
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <FormControlLabel
            control={
              <Switch checked={toggleState.hiveActivity} onChange={handleToggleChange} name="hiveActivity" />
            }
            label="Show Hive Activity"
          />
        </Grid>
      )}
      {hasBeeCounterData && (
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <FormControlLabel
            control={<Switch checked={toggleState.inCount} onChange={handleToggleChange} name="inCount" />}
            label="Show In Count"
          />
        </Grid>
      )}
      {hasBeeCounterData && (
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <FormControlLabel
            control={<Switch checked={toggleState.outCount} onChange={handleToggleChange} name="outCount" />}
            label="Show Out Count"
          />
        </Grid>
      )}
    </Grid>
  );
}

function getMinDate({ rangeSelected, maxDate, minDate2 }) {
  let days = 7;
  if (rangeSelected === rangeOptions._1day) {
    days = 1;
  } else if (rangeSelected === rangeOptions._1month) {
    days = 30;
  } else if (rangeSelected === rangeOptions._3months) {
    days = 90;
  } else if (rangeSelected === rangeOptions.max) {
    return minDate2;
  }
  return subDays(maxDate, days);
}

function setTheChartData2({
  chartData,
  eventsRows,
  weatherData,
  deviceIds,
  devices,
  toggleState,
  options,
  setOptions,
  minDate,
  setMinDate2,
  setMaxDate,
  deviceIdNameMap,
  setAnnotations,
  isAgrisoundAdmin = false,
  showAudio = false,
  isMetric,
  setHasBeeCounterData,
}) {
  for (let device of devices) {
    if (device && device.hasOwnProperty('scaleDeviceId')) {
      for (let row of eventsRows) {
        if (row.deviceId === device.scaleDeviceId) {
          row.deviceId = device.deviceId;
          row.scaleDeviceId = device.scaleDeviceId; //A hack so we can get the calibrationOffset value in the chart-data-util
        }
      }
    }
  }
  // log('setTheChartData3', uniq(eventsRows.map(event => event.deviceId)));
  let tableData = getEventTableData(eventsRows, deviceIds);
  // console.log('tableData', uniq(tableData.map(entry => entry.deviceType)));
  let audioEvents = [];
  if (showAudio) {
    audioEvents = getAudioEventTableData(eventsRows, deviceIds);
  }

  // log('setTheChartData4', uniq(tableData.map(event => event.deviceId)), tableData);

  let annotations = []; //don't show comments on a multi device chart
  let comments = []; //don't show comments on a multi device chart
  comments = getFlagsData(eventsRows, deviceIds);
  if (deviceIds.length === 1) {
    annotations = getAnnotationsData(eventsRows);
    // log('comments', comments);
    // log('annotations', annotations);
    annotations = annotations.filter((event) => deviceIds.includes(event.deviceId));
    annotations = { labels: annotations };
  }
  let [maxDate, minDate2, stroke, series] = getHiveChartData({
    eventData: tableData,
    audioEvents,
    weatherData,
    deviceIdNameMap,
    // includeDeviceNameInLabels: deviceIds.length > 1,
    includeDeviceNameInLabels: true,
    isMetric,
    devices,
  });

  const inCountData = series.find((entry) => entry.dataType === 'inCount');
  const outCountData = series.find((entry) => entry.dataType === 'outCount');
  const hasBeeCounterData =
    (inCountData && inCountData.data.length > 0) || (outCountData && outCountData.data.length > 0) || false;
  // console.log('inCountDiffEvents', series.find(entry => entry.dataType === "inCount").data.length > 0);
  setHasBeeCounterData(hasBeeCounterData);
  // let minDate2 = chartData.minDate2;
  // let maxDate = chartData.maxDate;
  // let series = chartData.series.filter(s => deviceIds.includes(s.deviceId));
  setMinDate2 && setMinDate2(minDate2);
  setMaxDate && setMaxDate(maxDate);

  let seriesData = getSeriesData({ series, toggleState, isMetric });
  // console.log('seriesData', seriesData, series);
  // let onSeries = seriesData.length > 0 && seriesData[0].id;
  let flagsSeries = {
    data: comments,
    type: 'flags',
    // onSeries: 'sensorTemp',
    shape: 'circlepin',
    width: 30,
    name: 'comments',
  };
  if (seriesData.length > 0) {
    seriesData.unshift(flagsSeries);
  }

  const yAxis = getYAxis({ series, toggleState });
  log('setTheChartData2', new Date(), { seriesData, yAxis, deviceIds });

  let newOptions = {
    chart: {
      height: '45%',
      zoomType: isAgrisoundAdmin ? 'xy' : 'x',
    },
    // plotOptions: {
    //   flags: {
    //     visible: false
    //   }
    // },
    series: seriesData,
    yAxis,
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        // don't display the dummy year
        month: '%e. %b',
        year: '%b',
      },
      title: {
        text: 'Date',
      },
      // min: minDate && minDate.getTime(),
      min: minDate ? minDate.getTime() : minDate2.getTime(),
      max: maxDate && maxDate.getTime(),
    },
  };

  // if(!isAgrisoundAdmin) {
  //   newOptions.annotations = annotations;
  // }
  log('setTheChartData2', { ...options, ...newOptions });
  setAnnotations(annotations.labels);
  setOptions({ ...options, ...newOptions });
}

function RemoveAnnotations({ annotations, classes, deviceId }) {
  log('RemoveAnnotations', annotations);
  const firestore = useFirestore();
  if (!annotations || annotations.length === 0) {
    return <></>;
  }
  return (
    <List
      className={classes.comments}
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Comments
        </ListSubheader>
      }
    >
      {annotations.map((annotation, i) => {
        return (
          <div key={i}>
            <ListItem alignItems="flex-start">
              <ListItemText primary={annotation.text} />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={(event) => {
                    deleteComment({
                      firestore,
                      commentText: annotation.text,
                      deviceId,
                      x: annotation.point.x,
                      y: annotation.point.y,
                    }).catch((err) => {
                      log('deleteComment.err', err);
                    });
                    // console.log('event', annotation.label.text, new Date(annotation.x));
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider variant="fullWidth" component="li" />
          </div>
        );
      })}
    </List>
  );
}

function getSeriesData({ series, toggleState, isMetric }) {
  let seriesCopy = [...series];
  // console.log('getSeriesData', seriesCopy);
  let counter = 0;
  const newSeriesData = seriesCopy.reduce(function (newObj, entry) {
    const include = true; //toggleState[entry.dataType]
    if (include) {
      let series = {
        data: toggleState[entry.dataType] ? entry.data : [],
        name: entry.name,
        // stroke: entry.stroke,
        dataType: entry.dataType,
        tooltip: {
          valueSuffix: labelFormatMap[entry.dataGroup],
        },
        color: entry.color,
        type: entry.type,
      };
      if (entry.id) series.id = entry.id;
      if (counter !== 0) {
        series.yAxis = counter;
        // series.yAxis = counter;
        // series.yAxis.min = entry.min;
        // series.yAxis.max = entry.max;
      }
      if (series.data.length > 0) {
        counter++;
      }
      newObj.push(series);
    }
    return newObj;
  }, []);
  return newSeriesData.filter((series) => series.data.length > 0);
}

function getOnClickEvent({ setDate, setShowComment }) {
  const onChartClick = (event, chartContext, { seriesIndex, dataPointIndex, config }) => {
    const line = config.series[seriesIndex];
    // console.log('onChartClick', { event, chartContext });
    if (line) {
      let time = line.data[dataPointIndex][0];
      setDate(new Date(time));
    }
    setShowComment(true);
  };

  return onChartClick;
}

function getYAxis({ series, toggleState }) {
  let seriesToShow = series.filter((entry) => toggleState[entry.dataType] === true && entry.data.length > 0);
  // console.log('getYAxis.seriesToShow', series, seriesToShow);
  let axisList = uniq(seriesToShow.map((entry) => entry.dataGroup));
  let axisMinMax = axisList.reduce((obj, axis) => {
    obj[axis] = {
      min: Math.min(...seriesToShow.filter((entry) => entry.dataGroup === axis).map((entry) => entry.min)),
      max: Math.max(...seriesToShow.filter((entry) => entry.dataGroup === axis).map((entry) => entry.max)),
    };
    return obj;
  }, {});
  let yaxis = [];
  let opposite = false;
  for (let entry of seriesToShow) {
    const axis = {
      labels: {
        format: labelFormatMap[entry.dataGroup],
        style: {
          colors: entry.color,
        },
      },
      min: axisMinMax[entry.dataGroup].min,
      max: axisMinMax[entry.dataGroup].max,
      visible: axisList.includes(entry.dataGroup),
      opposite: cloneDeep(opposite),
      crosshair: true,
      title: {
        text: entry.axisLabel,
        style: {
          color: entry.color,
        },
      },
      // startOnTick: true,
      // endOnTick: true,
      // alignTicks: true
    };
    yaxis.push(axis);
    //All other axis go on the right hand side
    if (!opposite) opposite = true;
    //remove the axis from the list as it has been set to be visible for one of the series
    axisList = axisList.filter((group) => group !== entry.dataGroup);
  }
  // console.log('getYAxis', { yaxis, axisMinMax });
  return yaxis;
}
