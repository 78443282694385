import React from 'react';
import HeatMap from 'react-heatmap-grid';
import { getHeatMapColour, getDeviceStats, getDiffInDays, pollyHeatMapColours } from '../../beekeeper/Widgets/stats-util';
import Box from '@material-ui/core/Box';
import Paper from '@mui/material/Paper';

export default function PollyDevicesHeatmap({ devices }) {
  // const xLabels2 = new Array(12).fill(0).map((_, i) => `${i}`);
  // const yLabels2 = ['', '', ''];
  // const data2 = new Array(yLabels2.length)
  //   .fill(0)
  //   .map(() => new Array(xLabels2.length).fill(0).map(() => Math.floor(Math.random() * 100)));

  const maxGridWidth = devices.length < 12 ? 6 : 12;
  let stats = getDeviceStats(devices);
  let devicesMatrix = getData(devices, maxGridWidth);
  const data = devicesMatrix;
  const gridWidth = devices.length > maxGridWidth ? maxGridWidth : devices.length;
  const gridHeight = Math.ceil(devices.length / maxGridWidth);

  const xLabels = new Array(gridWidth).fill(0).map((val) => 'D');
  const yLabels = new Array(gridHeight).fill(0).map((val) => '');

  // const xLabelsVisibility = new Array(12)
  //   .fill(0)
  //   .map(value => false);

  const xLabelsVisibility = new Array(gridWidth).fill(0).map((val) => false);

  const hoverTitle = (value, unit) => {
    if (!value) {
      return '';
    }

    const sdCardPercent = value && value.latestEvent && value.latestEvent.sdCardPercent;
    const numOfReboots =
      (value && value.rebootMetrics && value.rebootMetrics._1dayCount + value.rebootMetrics._3dayCount) ||
      'n/a';

    let loggedInDaysAgo = 'n/a';
    if (value && value.latestEvent && value.latestEvent.dateCreated) {
      const date = value.latestEvent.dateCreated.toDate();
      loggedInDaysAgo = getDiffInDays(new Date(), date);
    }

    return `deviceId: ${value.deviceId} \r\n reboots: ${numOfReboots} \r\n SD Card: ${sdCardPercent}% full \r\n loggedInDaysAgo: ${loggedInDaysAgo}`;
  };

  let colourKey = (
    <Paper style={{ width: '50%' }}>
      <div>
        <Box display="flex" flexDirection="row">
          <div
            style={{
              backgroundColor: pollyHeatMapColours.noLatestEventColour,
              width: 50,
              height: 20,
              marginRight: 10,
            }}
          ></div>
          <div>No latestEvent</div>
        </Box>
        <Box display="flex" flexDirection="row">
          <div
            style={{
              backgroundColor: pollyHeatMapColours._1DayOldColour,
              width: 50,
              height: 20,
              marginRight: 10,
            }}
          ></div>
          <div>Logged in yesterday</div>
        </Box>
        <Box display="flex" flexDirection="row">
          <div
            style={{
              backgroundColor: pollyHeatMapColours._2DaysOldColour,
              width: 50,
              height: 20,
              marginRight: 10,
            }}
          ></div>
          <div>Logged in 2 days ago</div>
        </Box>
        <Box display="flex" flexDirection="row">
          <div
            style={{
              backgroundColor: pollyHeatMapColours.deviceStoppedColour,
              width: 50,
              height: 20,
              marginRight: 10,
            }}
          ></div>
          <div>Not logged in for over 3 days and SD card not full</div>
        </Box>
        <Box display="flex" flexDirection="row">
          <div
            style={{
              backgroundColor: pollyHeatMapColours.sdCardFullColour,
              width: 50,
              height: 20,
              marginRight: 10,
            }}
          ></div>
          <div>SD card full</div>
        </Box>
        <Box display="flex" flexDirection="row">
          <div
            style={{
              backgroundColor: pollyHeatMapColours.rebootColour,
              width: 50,
              height: 20,
              marginRight: 10,
            }}
          ></div>
          <div>Rebooted in last 3 days</div>
        </Box>
        <Box display="flex" flexDirection="row">
          <div
            style={{
              backgroundColor: pollyHeatMapColours.rebootColourDeviceFine,
              width: 50,
              height: 20,
              marginRight: 10,
            }}
          ></div>
          <div>Device is functioning well (some reboots)</div>
        </Box>
        <Box display="flex" flexDirection="row">
          <div
            style={{
              backgroundColor: pollyHeatMapColours.deviceGoodColour,
              width: 50,
              height: 20,
              marginRight: 10,
            }}
          ></div>
          <div>Device is functioning well</div>
        </Box>
      </div>
    </Paper>
  );

  return (
    <>
      {colourKey}
      <HeatMap
        xLabels={xLabels}
        yLabels={yLabels}
        xLabelsVisibility={xLabelsVisibility}
        yLabelWidth={0}
        data={data}
        background={'red'}
        cellStyle={(background, value, min, max, data, x, y) => ({
          // background: `rgba(66, 86, 244, ${1 - (max - value) / (max - min)})`,
          // background: backgroundColor(value),
          background: getHeatMapColour({ device: value, allStats: stats }),
          fontSize: '11px',
        })}
        cellRender={(value) => value && `${value.deviceId}`}
        title={(value, unit) => hoverTitle(value)}
      />
    </>
  );
}

function getData(devices, maxGridWidth) {
  const devicesCopy = [...devices];
  const devicesTotal = devices.length;
  let devicesCount = devicesTotal;
  let loopCount = 0;
  const devicesMatrix = [];
  while (devicesCount > 0) {
    const start = loopCount * maxGridWidth;
    const end = (loopCount + 1) * maxGridWidth;
    devicesMatrix.push(devicesCopy.slice(start, end));
    // console.log('devicesMatrix', { devicesMatrix, start, end });
    loopCount = loopCount + 1;
    devicesCount = devicesCount - maxGridWidth;
  }

  return devicesMatrix;
}
