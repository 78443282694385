import { auth } from '../firebase';
import { getYearMonth } from '../util/date-util';

export async function deleteComment({ firestore, deviceId, x, y, commentText }) {
  // console.log('deleteComment', { deviceId, x, y, commentText });
  const date = new Date(x);
  const month = getYearMonth(date);
  const snapshot = await firestore
    .collection(`events`)
    .where('month', '==', month)
    .where('deviceId', '==', deviceId)
    .get();
  let events = [];
  snapshot.docs.map((doc) => events.push({ ...doc.data(), ...{ id: doc.id } }));
  // console.log('deleteComment', events);
  if (events.length === 1) {
    const event = events[0];
    const docId = event.id;
    let comments = event.comments || [];
    //remove the comment from the array
    comments = comments.filter((comment) => comment.text !== commentText);
    await firestore.doc(`events/${docId}`).update({
      comments,
      dateUpdated: new Date(),
      updatedComment: `deleted comment with text ${commentText}`,
    });
    // console.log('deleteComment.comments', comments);
  }
}

function addAudit(obj) {
  const uid = auth.currentUser && auth.currentUser.uid;
  obj.updatedBy = uid;
  obj.dateUpdated = new Date();
}
