import List from '@material-ui/core/List';
import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import RouterLink from '../Nav/RouterLink';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '100%',
    // maxWidth: '48ch',
    backgroundColor: theme.palette.background.paper,
  },
  titleWide: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      textOverflow: 'ellipsis',
      // overflow: 'hidden',
      // whiteSpace: 'nowrap',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      textOverflow: 'ellipsis',
      // overflow: 'hidden',
      // whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
      textOverflow: 'ellipsis',
      // overflow: 'hidden',
      // whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('lg')]: {
      width: '100%',
      textOverflow: 'ellipsis',
      // overflow: 'hidden',
      // whiteSpace: 'nowrap',
    },
  },
  subtitleWide: {
    [theme.breakpoints.down('xs')]: {
      width: '150px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.down('sm')]: {
      width: '350px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('md')]: {
      width: '600px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('lg')]: {
      width: '800px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      width: '300px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
      width: '400px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('md')]: {
      width: '500px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('lg')]: {
      width: '200px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  paper: {
    width: '100%',
    // maxWidth: '48ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  button: {
    textTransform: 'none',
    paddingTop: '28px',
    paddingLeft: '80px',
  },
  link: {
    marginLeft: '28px',
  },
  linkNoSubtitle: {
    fontSize: '14px',
    marginLeft: '28px',
  },
  listItemSecondary: {
    width: '85%',
    fontWeight: 'normal',
    color: '#7a7a7a',
    overflow: 'hidden',
  },
}));

const showLink = false;
const isComponentWide = true;

export default function UserTips({
  userTips = [],
  // routePath,
  // linkWidth,
  // showLink,
  showSubtitle,
  // isComponentWide,
}) {
  // console.log('articles', articles[0].subtitle);
  const classes = useStyles();
  if (userTips.length === 0) {
    return <></>;
  }
  return (
    <List className={classes.root}>
      {userTips.map((userTip, index) => {
        const title = userTip.title;
        // const dateCreated = format(userTip.dateCreated, 'MMM-dd hh:mm');
        // const subtitle = `${userTip.alertName}, created @ ${dateCreated}`;
        return (
          <React.Fragment key={index}>
            <ListItem key={index} alignItems="flex-start">
              <UserTipHeadline
                // routePath={routePath}
                // isComponentWide={isComponentWide}
                title={title}
                // subtitle={subtitle}
                showSubtitle={showSubtitle}
                classes={classes}
                // linkWidth={linkWidth}
                // showLink={showLink}
              />
            </ListItem>
            {userTips.length - 1 !== index && <Divider />}
          </React.Fragment>
        );
      })}
    </List>
  );
}

function UserTipHeadline({ title, subtitle, classes, showSubtitle = false }) {
  const titleWidth = 11;

  const primary = (
    <Grid container>
      <Grid item xs={titleWidth} sm={titleWidth} md={titleWidth} lg={titleWidth}>
        <Typography className={isComponentWide ? classes.titleWide : classes.title}>{title}</Typography>
      </Grid>
    </Grid>
  );
  if (!showSubtitle) {
    return <ListItemText primary={primary} />;
  }

  return (
    <ListItemText
      // style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
      primary={primary}
      secondary={<div className={classes.subtitleWide}>{subtitle}</div>}
    />
  );
}
