import { get } from 'lodash';
import { differenceInCalendarDays } from 'date-fns';

export const statNames = {
  hasRebooted: 'hasRebooted',
  highestTemp: 'highestTemp',
  highestBeeActivity: 'highestBeeActivity',
  lowBattery: 'lowBattery',
  lowRssi: 'lowRssi',
  sdCard80Percent: 'sdCard80Percent',
  sdCard50To80Percent: 'sdCard50To80Percent',
  sdCard20To50Percent: 'sdCard20To50Percent',
  sdCardBelow20: 'sdCardBelow20',
  noData: 'noData',
  noLatestEvent: 'noLatestEvent',
  dataIsCurrent: 'dataIsCurrent',
  _1DayOld: '_1DayOld',
  _2DaysOld: '_2DaysOld',
  _3To7DaysOld: '_3To7DaysOld',
  _8To14DaysOld: '_8To14DaysOld',
  _Over14DaysOld: '_Over14DaysOld',
};

export const statsConfig = {
  hasRebooted: {
    label: 'Rebooted',
    colour: 'red',
  },
  highestTemp: {
    label: 'Highest Temp',
    colour: 'aquamarine',
  },
  highestBeeActivity: {
    label: 'Highest Activity',
    colour: 'aquamarine',
  },
  sdCard80Percent: {
    label: 'SD Card is 80% full or above',
    colour: 'lightsalmon',
  },
  sdCard50To80Percent: {
    label: 'SD Card is 50% to 80% full',
    colour: 'lightsalmon',
  },
  sdCard20To50Percent: {
    label: 'SD Card is 20% to 50% full',
    colour: 'lightsalmon',
  },
  sdCardBelow20: {
    label: 'SD Card below is 20% full',
    colour: 'lightsalmon',
  },
  lowBattery: {
    label: 'Low Battery',
    colour: 'lightsalmon',
  },
  lowRssi: {
    label: 'Weak 2G Signal',
    colour: 'lightsalmon',
  },
  noData: {
    label: 'No data yet',
    colour: 'white',
  },
  dataIsCurrent: {
    label: 'Today',
    colour: 'aquamarine',
    type: 'lastUpdated',
  },
  _1DayOld: {
    label: '1 day ago',
    colour: '#ffe6e6',
    type: 'lastUpdated',
  },
  _2DaysOld: {
    label: '2 days ago',
    colour: '#ffcccc',
    type: 'lastUpdated',
  },
  _3To7DaysOld: {
    label: '3 to 7 days ago',
    colour: '#ffb3b3',
    type: 'lastUpdated',
  },
  _8To14DaysOld: {
    label: '8 to 14 days ago',
    colour: '#ff8080',
    type: 'lastUpdated',
  },
  _Over14DaysOld: {
    label: 'Over 2 weeks ago',
    colour: '#ff4d4d',
    type: 'lastUpdated',
  },
};

export function getDeviceIdWithGreatest({ devices, metricName, fieldName }) {
  let deviceId;
  let greatest = 0;
  for (let device of devices) {
    if (device.metrics) {
      let metric = getMetric(device.metrics, metricName);
      let tmpValue = get(metric, fieldName);
      if (tmpValue > greatest) {
        greatest = tmpValue;
        deviceId = device.deviceId;
      }
    }
  }
  return deviceId;
}

export function getDeviceIdWithLowest({ devices, metricName, fieldName, useLatestEvent = false }) {
  let deviceId;
  let lowest = 10000;
  for (let device of devices) {
    if (useLatestEvent) {
      if (device.latestEvent) {
        let tmpValue = get(device.latestEvent, fieldName);
        if (tmpValue < lowest) {
          lowest = tmpValue;
          deviceId = device.deviceId;
        }
      }
    } else {
      if (device.metrics) {
        let metric = getMetric(device.metrics, metricName);
        let tmpValue = get(metric, fieldName);
        if (tmpValue < lowest) {
          lowest = tmpValue;
          deviceId = device.deviceId;
        }
      }
    }
  }
  return deviceId;
}

export function getDiffInDays(date1, date2) {
  return differenceInCalendarDays(date1, date2);
}

export function getStalenessStat(date, deviceId) {
  let stalenessStat = statNames.noData;
  if (!date) {
    return statNames.noData;
  }
  const daysDiff = getDiffInDays(new Date(), date);
  // console.log(deviceId, 'daysDiff', date, daysDiff);
  if (daysDiff <= 0) {
    stalenessStat = statNames.dataIsCurrent;
  } else if (daysDiff === 1) {
    stalenessStat = statNames._1DayOld;
  } else if (daysDiff === 2) {
    stalenessStat = statNames._2DaysOld;
  } else if (daysDiff > 2 && daysDiff < 7) {
    stalenessStat = statNames._3To7DaysOld;
  } else if (daysDiff >= 7 && daysDiff < 14) {
    stalenessStat = statNames._8To14DaysOld;
  } else if (daysDiff >= 14) {
    stalenessStat = statNames._Over14DaysOld;
  }

  return [daysDiff, stalenessStat];
}
const dataPointsToExclude = ['0', 0, 'n/a'];
export function getAverage({ devices, metricName, fieldName }) {
  let sum = 0;
  let count = 0;
  for (let device of devices) {
    if (device.metrics) {
      let metric = getMetric(device.metrics, metricName);
      let tmpValue = get(metric, fieldName);
      if (tmpValue && !dataPointsToExclude.includes(tmpValue)) {
        sum = sum + tmpValue;
        count++;
      }
    }
  }
  return [sum / count, count];
}

const lowBatteryLevel = 3.8;
const lowRssiLevel = 10;
const highSDCardPercentLevel = 80;
const mediumSDCardPercentLevel = 50;
const lowSDCardPercentLevel = 20;
export function getDeviceStats(devices) {
  let stats = devices.map((device) => {
    return {
      deviceId: device.deviceId,
      stats: [],
      daysDiff: 0,
    };
  });
  // console.log('stats', stats);
  if (stats.length === 0) {
    return stats;
  }

  for (let device of devices) {
    let stat = stats.find((stat) => stat.deviceId === device.deviceId);
    const battery = getValueFromDevice(device, 'latestEvent.battery');
    const sdCardPercent = getValueFromDevice(device, 'latestEvent.sdCardPercent');
    const rssi = getValueFromDevice(device, 'latestEvent.rssi');

    if (device.rebootMetrics && device.rebootMetrics._1dayCount > 0) {
      stat.stats.push(statNames.hasRebooted);
    }

    if (battery <= lowBatteryLevel) {
      stat.stats.push(statNames.lowBattery);
    }

    if (rssi < lowRssiLevel) {
      stat.stats.push(statNames.lowRssi);
    }

    if (sdCardPercent >= highSDCardPercentLevel) {
      stat.stats.push(statNames.sdCard80Percent);
    }

    if (sdCardPercent >= mediumSDCardPercentLevel && sdCardPercent < highSDCardPercentLevel) {
      stat.stats.push(statNames.sdCard50To80Percent);
    }
    if (sdCardPercent >= lowSDCardPercentLevel && sdCardPercent < mediumSDCardPercentLevel) {
      stat.stats.push(statNames.sdCard20To50Percent);
    }
    if (sdCardPercent < lowSDCardPercentLevel || sdCardPercent === 'n/a') {
      stat.stats.push(statNames.sdCardBelow20);
    }

    if (device && device.latestEvent && device.latestEvent.dateCreated) {
      const date = device.latestEvent.dateCreated.toDate();
      const [daysDiff, stalenessStat] = getStalenessStat(date, device.deviceId);
      // console.log('stalenessStat', device.deviceId, date, stalenessStat);
      stat.stats.push(stalenessStat);
      stat.daysDiff = daysDiff;
      // if(!statsConfig[stalenessStat]) {
      //   stat.colour = 'grey';
      //   console.log(`${stalenessStat} does not exist in statsConfig`, date);
      // }
      stat.colour = statsConfig[stalenessStat].colour;
    } else {
      stat.stats.push(statNames.noLatestEvent);
    }
  }

  const idHighestTemp = getDeviceIdWithGreatest({
    devices,
    metricName: 'temperature',
    fieldName: '_30dayAvg',
  });
  const idHighestActivity = getDeviceIdWithGreatest({
    devices,
    metricName: 'beeActivity',
    fieldName: '_30dayAvg',
  });

  if (idHighestTemp) {
    let stat = stats.find((stat) => stat.deviceId === idHighestTemp);
    stat.stats.push(statNames.highestTemp);
  }

  if (idHighestActivity) {
    let stat = stats.find((stat) => stat.deviceId === idHighestActivity);
    stat.stats.push(statNames.highestBeeActivity);
  }

  return stats;
}

function getValueFromDevice(device, fieldName) {
  return get(device, fieldName);
}

export function getBatteryTrendText({ batteryLatest, battery7DaysAgo, batteryWillRunOut = 3 }) {
  let batteryTrend;
  if (battery7DaysAgo === '0' || battery7DaysAgo === 'n/a') {
    batteryTrend = 'Battery: not enough data to calc';
  } else if (batteryLatest) {
    const moveIn1Week = batteryLatest - battery7DaysAgo;
    batteryTrend = `Battery: holding charge`;
    if (batteryLatest > batteryWillRunOut && moveIn1Week < 0) {
      const weeksToDie = ((batteryWillRunOut - batteryLatest) / moveIn1Week).toFixed(1);
      batteryTrend = `Battery will die in ${weeksToDie} weeks`;
    }
  }

  return batteryTrend;
}

export function getMetricValue({ metric, fieldName, suffix }) {
  if (!metric) {
    return 'n/a';
  }
  let val;
  try {
    let rawValue = get(metric, fieldName);
    if (rawValue == 0) {
      val = 0;
    } else if (rawValue && rawValue !== 'n/a' && rawValue !== 0) {
      val = `${rawValue.toFixed(1)}${suffix}`;
    } else {
      return 'n/a';
    }
  } catch (err) {
    console.log('getMetricValue', metric, fieldName);
  }

  return val;
}

export function getMetric(metrics = [], name) {
  return metrics.find((m) => m.name === name);
}

export function getLatestFieldValue(device, fieldName) {
  return (device && device.latestEvent && device.latestEvent[fieldName]) || 'n/a';
}

const rebootColourDeviceFine = '#72dbd2';
const rebootColour = '#345987';
const deviceStoppedColour = '#FF6347';
const sdCardFullColour = '#7ae0ff';
const deviceGoodColour = statsConfig[statNames.dataIsCurrent].colour;
const noLatestEventColour = '#e77aff';
const _1DayOldColour = statsConfig[statNames._1DayOld].colour;
const _2DaysOldColour = statsConfig[statNames._2DaysOld].colour;

export const pollyHeatMapColours = {
  rebootColourDeviceFine,
  rebootColour,
  deviceStoppedColour,
  sdCardFullColour,
  deviceGoodColour,
  noLatestEventColour,
  _1DayOldColour,
  _2DaysOldColour
}

const isOver3DaysOld = (deviceStats) => {
  const oldData = [statNames._3To7DaysOld, statNames._8To14DaysOld, statNames._Over14DaysOld];
  return deviceStats.some((stat) => oldData.includes(stat));
};

const sdCardLessThan80Percent = (deviceStats) => {
  const oldData = [statNames.sdCardBelow20, statNames.sdCard20To50Percent, statNames.sdCard50To80Percent];
  return deviceStats.some((stat) => oldData.includes(stat));
};

const sdCardMoreThan80Percent = (deviceStats) => {
  const oldData = [statNames.sdCard80Percent];
  return deviceStats.some((stat) => oldData.includes(stat));
};

export function getHeatMapColour({ device, allStats, deviceStats }) {
  // console.log('heatmap', device && device.deviceId, deviceStats);
  if(!deviceStats) {
    deviceStats = device && allStats.find((stat) => stat.deviceId === device.deviceId);
  }

  const rebootMetrics = (device && device.rebootMetrics) || {
    _1dayCount: 0,
    _3dayCount: 0,
    _7dayCount: 0,
    _30dayCount: 0,
  };

  if (!device) {
    return 'white';
  }

  if (sdCardMoreThan80Percent(deviceStats.stats)) {
    return sdCardFullColour;
  }
  if(deviceStats.stats.includes(statNames.noLatestEvent)) {
    return noLatestEventColour;
  }
  if (isOver3DaysOld(deviceStats.stats) && sdCardLessThan80Percent(deviceStats.stats)) {
    return deviceStoppedColour;
  }
  if (isOver3DaysOld(deviceStats.stats) && (rebootMetrics._1dayCount > 0 || rebootMetrics._3dayCount > 0)) {
    return rebootColour;
  }

  if (deviceStats.stats.includes(statNames.dataIsCurrent) && (rebootMetrics._1dayCount > 0 || rebootMetrics._3dayCount > 0)) {
    return rebootColourDeviceFine;
  }

  if (deviceStats.stats.includes(statNames._1DayOld)) {
    return statsConfig[statNames._1DayOld].colour;
  }

  if (deviceStats.stats.includes(statNames._2DaysOld)) {
    return statsConfig[statNames._2DaysOld].colour;
  }

  if (deviceStats.stats.includes(statNames._8To14DaysOld) || deviceStats.stats.includes(statNames._Over14DaysOld)) {
    return statsConfig[statNames._2DaysOld].colour;
  }

  if (deviceStats.stats.includes(statNames.dataIsCurrent)) {
    return deviceGoodColour;
  }

  if (isOver3DaysOld(deviceStats.stats) && sdCardLessThan80Percent(deviceStats.stats)) {
    return deviceStoppedColour;
  }

  return 'purple';
}
