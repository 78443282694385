import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { uniq } from 'lodash';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import { blueGrey } from '@material-ui/core/colors';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import Paper from '@material-ui/core/Paper';
import {convertFirestoreCollectionToArray} from "../../../data/data-util";
import BreadcrumbNav from "../Nav/BreadcrumbsNav";
import ArticleListWidget from "../Widgets/ArticleListWidget";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '20 20 20 20',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: blueGrey[500],
  },
  hiveGrid: {
    flexGrow: 1,
  },
  hivePaper: {
    // height: 'auto',
    // maxWidth: 600,
    // minWidth: 300,
    // width: 'auto',
  },
}));

const schemaName = 'knowledgeBase';

export default function KnowledgeBaseSummaryPage() {
  const articlesData = useSelector(({ firestore: { data } }) => data[schemaName]);
  const articlesQuery = [
    {
      collection: 'fl_content',
      where: [
        ['_fl_meta_.schema', '==', schemaName],
        // ['accountType', '==', 'beekeeper'],
      ],
      storeAs: schemaName,
    },
  ];

  useFirestoreConnect(articlesQuery);

  if (!isLoaded(articlesData)) {
    return 'Loading...';
  }

  let articles = convertFirestoreCollectionToArray({ collectionData: articlesData });

  return <Sections articles={articles} />;
}

function Sections({ articles }) {
  const classes = useStyles();
  const sectionTitles = uniq(articles.map((entry) => entry.sectionTitle));
  // console.log('sectionTitles', sectionTitles, articles);
  const sections = {};
  for (let sectionTitle of sectionTitles) {
    sections[sectionTitle] = articles.filter((entry) => entry.sectionTitle === sectionTitle);
  }

  return (
    <Grid justify={'flex-start'} container spacing={2}>
      <Grid item xs={12}>
        <Breadcrumbs />
      </Grid>
      {sectionTitles.map((sectionTitle, index) => (
        <Grid key={index} item xs={12}>
          <Paper className={classes.hivePaper}>
            <Section classes={classes} sectionTitle={sectionTitle} articles={sections[sectionTitle]} />
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}

function Breadcrumbs() {
  const breadcrumbs = [
    {
      path: '/',
      text: 'Home',
    },
    {
      path: `/kb`,
      text: 'Knowledge Base',
    },
  ];
  return <BreadcrumbNav breadcrumbs={breadcrumbs} />;
}


function Section({ classes, sectionTitle, articles }) {
  return (
    <Card className={classes.root}>
      <CardHeader title={sectionTitle} />
      <ArticleListWidget
        linkWidth={1}
        routePath={'kb'}
        articles={articles}
        showSubtitle={true}
        isComponentWide={true}
        showLink={true}
      />
    </Card>
  );
}
