import React from 'react';
import { sortBy } from 'lodash';
import { format } from 'date-fns';
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { eventsColumns } from '../../../data/beekeperDataUtil';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { Hidden } from '@material-ui/core';
import { HiveCard } from '../Hive/HiveCard';
import { getProjectIds } from '../../../config/env';
import { getDevicesQuery, getDevicesStoreAs } from '../../../api/queryConfig';
import { getDevicesToDisplay } from './hivesUtil';
import { convertAdcToKg } from '../../../data/data-util';
import { globalElevation } from '../../../config/globalStyle';

const useStyles = makeStyles((theme) => ({
  deltaGrid: {
    maxWidth: '95%',
    '& .cell-small': {
      fontSize: '12px',
    },
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: 'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: '#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
  hiveGrid: {
    flexGrow: 1,
  },
  hivePaper: {
    // height: 500,
    width: 260,
    fontSize: 12,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function Hives({ metric, showDeltaGrid = false }) {
  const classes = useStyles();
  const userData = useSelector((state) => state.appConfig.userData);
  let projectIds = getProjectIds(userData);
  const projectId = projectIds[0];
  const storeAs = getDevicesStoreAs();
  const devicesQuery = getDevicesQuery({ projectIds, storeAs });
  const devicesData = useSelector(({ firestore: { data } }) => data[storeAs]);

  useFirestoreConnect([devicesQuery]);

  if (!isLoaded(devicesData)) {
    return 'Loading...';
  }

  const devicesRaw = (devicesData && Object.values(devicesData)) || [];
  const devices = getDevicesToDisplay(devicesRaw);
  const devicesForDataGrid = devicesRaw; //getDevicesToDisplay(devicesRaw);
  const deltaGridRows = getRows(devicesForDataGrid, metric);
  return (
    <Grid container className={classes.hiveGrid} spacing={2}>
      <Grid item xs={12}>
        <HiveCards
          metric={metric}
          withColour={true}
          classes={classes}
          devices={devices}
          projectId={projectId}
        />
      </Grid>
      <Hidden smDown>
        {showDeltaGrid && deltaGridRows.length > 0 && (
          <Grid item xs={12}>
            <div style={{ height: 800 }}>
              <DataGrid rows={deltaGridRows} columns={eventsColumns} pageSize={50} />
            </div>
          </Grid>
        )}
      </Hidden>
    </Grid>
  );
}

function HiveCards({ metric, withColour, classes, devices }) {
  //sort hives by deviceName to maintain the same hive order every time the page loads
  let sortedHives = sortBy(devices, ['deviceName']);
  return (
    <Grid container spacing={3}>
      {sortedHives.map((device, index) => (
        <Grid key={index} item>
          <Paper elevation={globalElevation} className={classes.hivePaper}>
            <HiveCard
              metric={metric}
              deviceId={device.deviceId}
              gatewayDeviceId={device.gatewayDeviceId}
              scaleDeviceId={device.scaleDeviceId}
              withColour={withColour}
            />
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}

function getRows(devices, metric) {
  let rows = [];
  for (let device of devices) {
    const deviceRows = getDeviceRows(device, metric);
    rows = rows.concat(deviceRows);
  }

  return rows;
}

function getDeviceRows(device, metric) {
  let rows = [];
  // if (device.latestEvent && device.latestEvent.temperature) {
  //   let converter = (value) => getTemperature(value, metric);
  //   let row = getRow({ device, dataType: 'temperature', converter });
  //   rows.push(row);
  // }
  // if (device.latestEvent && device.latestEvent.humidity) {
  //   let converter = (value) => value;
  //   let row = getRow({ device, dataType: 'humidity', converter });
  //   rows.push(row);
  // }

  if (device.latestEvent && device.latestEvent.weight) {
    let converter = (value) => (device.deviceType === 'agsenzeScaleOne' ? value : convertAdcToKg(value));
    let row = getRow({ device, dataType: 'weight', converter });
    rows.push(row);
  }

  return rows;
}

function getRow({ device, dataType, converter }) {
  let row = getEmptyRow(device);

  row.id = device.deviceId + '-' + dataType;
  row.dataType = dataType;
  row.valueToday = converter(device.latestEvent[dataType]);
  row._1dayDiff = getDiff({ row, deltaReport: device.deltaReport, fieldName: '_1day', converter, dataType });
  row._2dayDiff = getDiff({ row, deltaReport: device.deltaReport, fieldName: '_2days', converter, dataType });
  row._3dayDiff = getDiff({ row, deltaReport: device.deltaReport, fieldName: '_3days', converter, dataType });
  row._4dayDiff = getDiff({ row, deltaReport: device.deltaReport, fieldName: '_4days', converter, dataType });
  row._5dayDiff = getDiff({ row, deltaReport: device.deltaReport, fieldName: '_5days', converter, dataType });
  row._6dayDiff = getDiff({ row, deltaReport: device.deltaReport, fieldName: '_6days', converter, dataType });
  row._7dayDiff = getDiff({ row, deltaReport: device.deltaReport, fieldName: '_7days', converter, dataType });
  // prettier-ignore
  row._30dayDiff = getDiff({row, deltaReport: device.deltaReport, fieldName: '_30days', converter, dataType,});
  row.valid = device.latestEvent.dateCreated;

  return row;
}

function getDiff({ row, deltaReport, fieldName, converter, dataType }) {
  return deltaReport && deltaReport[fieldName] && deltaReport[fieldName].dateCreated
    ? row.valueToday - converter(deltaReport[fieldName][dataType])
    : 'n/a';
}

function getEmptyRow(device) {
  return {
    id: 0,
    deviceName: device.deviceName,
    dataType: '',
    valueToday: 0,
    _1dayDiff: 0,
    _7dayDiff: 0,
    _30dayDiff: 0,
  };
}
