import { cloneDeep, isEqual } from 'lodash';
import React from 'react';
const emptyLocation = {};

export const hasNoLocation = (device) =>
  !device.hasOwnProperty('location') || isEqual(device.location, emptyLocation);
export const hasLocation = (device) =>
  device.hasOwnProperty('location') && !isEqual(device.location, emptyLocation);

export function getDeviceMapInfo(device) {
  const dev = cloneDeep(device);
  const temp = (device.latestEvent && device.latestEvent.temperature) || 'n/a';
  const humidity = (device.latestEvent && device.latestEvent.humidity + '%') || 'n/a';
  return (
    <div>
      <div>
        <strong>{dev.deviceName}</strong>
      </div>
      <div>Temp: {temp}</div>
      <div>Humidity: {humidity}</div>
    </div>
  );
}

export const cloneAndInitialise = ({ devicesData }) => {
  let devices = cloneDeep((devicesData && Object.values(devicesData)) || []).filter(
    (device) => device !== null
  );
  for (let device of devices) {
    if (device) {
      device.version = (device.latestEvent && device.latestEvent.version) || 'not_present';
    }
  }
  return devices;
};
