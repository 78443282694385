import React, { useState } from 'react';
import { log } from '../../../util/log-util';
import { makeStyles } from '@material-ui/core/styles';
import { isEqual, merge, cloneDeep } from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import UndoIcon from '@material-ui/icons/Undo';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import { useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import GeoLocation from '../../beekeeper/Location/GeoLocation3';
import { updateDevice } from '../../../api/updateDevice';
import { isEmpty, isDate } from 'lodash';
import { getProjectIds } from '../../../config/env';
import ObjectNotFoundPage from '../../beekeeper/ObjectNotFoundPage';
import BreadcrumbNav from '../../beekeeper/Nav/BreadcrumbsNav';
import Box from '@material-ui/core/Box';
import SnackbarAlert from '../../beekeeper/SnackbarAlert/SnackbarAlert';
import Link from '../../beekeeper/Nav/Link';
import { convertDateWithTimezone } from '../../../util/date-util';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import { auth } from '../../../firebase';
import { useFirestore } from 'react-redux-firebase';
import { deviceTypes, deviceTypesConfig } from '../../../config/deviceTypes';
import { diff } from 'deep-object-diff';
import { getIsAgrisoundAdmin } from '../../../util/user-util';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import ReactJson from 'react-json-view';
import MuiTreeDemo from '../tree/MuiTreeDemo';
// import SortableTreeDemo from './tree/SortableTreeDemo';
// import AddRemoveDemo from './tree/add-remove';
import { MenuItem } from '@material-ui/core';
import { getSiteOptionsFromProject } from '../util/hierarchyUtil';
import DeviceLogs from '../DeviceHistory/DeviceLogs';
import DeviceAuditHistory from '../DeviceHistory/DeviceAuditHistory';
import DeviceNotFoundPage from '../../beekeeper/NotFoundPage/DeviceNotFoundPage';
import PollySim from './PollySim';
import { addRequestToCollection } from '../../../api/adminFunctions';
import { getEquinixDeviceNumber } from './get-equinix-device-number';
import { getPermission } from '../../../util/permission-util';
import { AddImageForm } from './Images/AddImageForm';
import { ImageList, ImageListItem, ImageListItemBar } from '@mui/material';
import { deleteImageFromStorage } from './Images/share';
import { firestore } from '../../../firebase';
import styled from 'styled-components';
import firebase from 'firebase/app';

const snackbarDefault = {
  message: undefined,
  open: false,
  severity: undefined,
};
const showHierarchyTree = false;
const showSimData = false;
const projectIdsWithPhotoUploadAccess = ['polly-james'];

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '75%',
  },
  textField: {
    backgroundColor: '#fed8b1',
    width: '80%',
  },
  select: {
    backgroundColor: '#fed8b1',
    width: '80%',
    height: '30px',
  },
  numberTextField: {
    backgroundColor: '#fed8b1',
    width: '20%',
  },
  card: {
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '100%',
    },
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function PollyDeviceEditPage() {
  const match = useRouteMatch('/polly-devices-edit/:deviceId');
  // const firestore = useFirestore();
  const deviceId = match.params.deviceId;
  // console.log('DevicePage.deviceId', deviceId);
  const classes = useStyles();
  const userData = useSelector((state) => state.appConfig && state.appConfig.userData);
  const project = useSelector((state) => state.appConfig && state.appConfig.currentProject);
  const user = useSelector((state) => state.appConfig && state.appConfig.user);
  const userId = (user && user.uid) || '123';
  log('DevicePage', deviceId, userData, project);
  const storeAsDevice = `devices/${deviceId}`;
  const storeAsConfig = `polly-device-config`;
  const storeAsEquinixConfig = `equinix-config`;
  const storeAsUser = `project-user-${userId}`;
  const deviceData = useSelector(({ firestore: { data } }) => data[storeAsDevice]);
  const projectUser = useSelector(({ firestore: { data } }) => data[storeAsUser]);
  const defaultPollyConfig = useSelector(({ firestore: { data } }) => data[storeAsConfig]);
  const equinixConfig = useSelector(({ firestore: { data } }) => data[storeAsEquinixConfig]);
  let projectIds = getProjectIds(userData);
  const projectId = projectIds[0];
  const isAgrisoundAdmin = getIsAgrisoundAdmin(userData);
  // const notes =
  //   deviceData &&
  //   (deviceData.photos || {}).map((item) => {
  //     return {
  //       name: item.name,
  //       note: item.note,
  //     };
  //   });
  const [photoNotes, setPhotoNotes] = useState({});
  // console.log('notes', notes)

  const queries = [
    {
      collection: 'devices',
      doc: deviceId,
      storeAs: storeAsDevice,
    },
    {
      collection: 'config',
      doc: 'pollyDeviceConfig',
      storeAs: storeAsConfig,
    },
    {
      collection: 'config',
      doc: 'equinix',
      storeAs: storeAsEquinixConfig,
    },
    {
      collection: 'projects',
      doc: projectId,
      subcollections: [{ collection: 'users', doc: userId }],
      storeAs: storeAsUser,
    },
  ];

  useFirestoreConnect(queries);

  if (!isLoaded(deviceData, projectUser, equinixConfig)) {
    log(deviceId, 'loading');
    return 'Loading...';
  }

  let canEdit = getPermission({
    component: 'PollyDeviceEditPage',
    permission: 'edit',
    userData,
    currentProjectId: project.projectId,
  });
  // console.log('PollyDeviceEditPage.canEdit', canEdit);

  if (deviceData === null || deviceData === undefined) {
    return DeviceNotFoundPage({ deviceId });
  }
  // console.log('PollyDeviceEditPage', deviceData);
  const siteOptions = getSiteOptionsFromProject(project);
  let deviceObj = cloneDeep(deviceData);
  convertDatesDevice(deviceObj);
  const isAdmin = projectUser.userRoles.includes('admin');

  if (isEmpty(deviceData)) {
    return <ObjectNotFoundPage objectName={'Device'} />;
  }

  const permissions = {
    canEdit,
  };

  const deleteImage = ({ item }) => {
    const path = `deviceImages/${deviceId}/${item.name}`;

    deleteImageFromStorage(path)
      .then((res) => {
        // console.log('deleteImage.SUCCESS', path);
        firestore.doc(`devices/${deviceId}`).update({
          [`photos.${item.id}`]: firebase.firestore.FieldValue.delete(),
        });
      })
      .catch((err) => console.log('deleteImage.ERR', path, item, err));
  };

  const savePhotoNote = async ({ item, note }) => {
    await firestore.doc(`devices/${deviceId}`).update({ [`photos.${item.id}.note`]: note });
  };

  return (
    <Box justifyContent="flex-start">
      <Grid container justify={'center'} spacing={2}>
        <Grid item xs={12}>
          <DeviceBreadcrumbs deviceId={deviceId} />
        </Grid>
        <Grid item xs={12}>
          <DeviceCard
            device={deviceData}
            projectId={projectId}
            isAdmin={isAdmin}
            isAgrisoundAdmin={isAgrisoundAdmin}
            project={project}
            initialDeviceConfig={defaultPollyConfig}
            siteOptions={siteOptions}
            equinixConfig={equinixConfig}
            permissions={permissions}
          />
        </Grid>
        {showSimData && (
          <Grid item xs={12}>
            <PollySim device={deviceData} />
          </Grid>
        )}
        {projectIdsWithPhotoUploadAccess.includes(projectId) && (
          <Grid item xs={12}>
            <Typography
              style={{ textAlign: 'center', fontWeight: 'bold' }}
              color="textPrimary"
              variant="h6"
            >
              Device Photos
            </Typography>
          </Grid>
        )}
        {projectIdsWithPhotoUploadAccess.includes(projectId) && (
          <Grid item xs={12}>
            <Card className={classes.card}>
              <CardContent>
                <AddImageForm deviceId={deviceId} />
                <Grid item xs={12}>
                  <ImageList
                    variant="masonry"
                    // rowHeight={160}
                    // style={{ width: '100%', height: devicePhotosHeight }}
                    cols={3}
                    gap={8}
                  >
                    {!deviceData.photos && <div style={{ marginLeft: '25px' }}>No photos</div>}
                    {deviceData.photos &&
                      Object.values(deviceData.photos).map((item, idx) => (
                        <ImageListItem key={idx} cols={item.cols || 1}>
                          <img
                            src={item.url}
                            alt={item.name}
                            style={{ maxWidth: '100%', border: '1px dotted grey' }}
                            loading="lazy"
                          />
                          <ImageListItemBar
                            title={<div style={{ marginTop: '8px' }}>{item.name}</div>}
                            actionIcon={
                              <IconButton
                                onClick={() => {
                                  deleteImage({ item });
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            }
                            position={'below'}
                          />
                          <PhotoNoteContainer>
                            <TextField
                              fullWidth
                              multiline
                              style={{
                                // backgroundColor: '#fed8b1',
                                marginTop: '5px',
                                maxWidth: '100%',
                                border: '1px dotted grey',
                              }}
                              rows={2}
                              required
                              id="intervention"
                              placeholder="Add a note..."
                              // value={photoNotes && photoNotes.find(_item => _item.name === item.name)}
                              value={item.note}
                              onChange={(e) => {
                                setPhotoNotes({ ...photoNotes, [item.id]: e.target.value });
                              }}
                            />
                            <Button
                              color="primary"
                              variant="contained"
                              style={{ height: '30px', marginTop: '10px' }}
                              onClick={async () => {
                                await savePhotoNote({ item, note: photoNotes[item.id] });
                              }}
                            >
                              Save
                            </Button>
                          </PhotoNoteContainer>
                        </ImageListItem>
                      ))}
                  </ImageList>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )}

        {isAgrisoundAdmin && (
          <Grid item xs={12}>
            <DeviceLogs device={deviceData} />
          </Grid>
        )}
        {isAgrisoundAdmin && (
          <Grid item xs={12}>
            <DeviceAuditHistory device={deviceData} />
          </Grid>
        )}
        {isAgrisoundAdmin && showHierarchyTree && (
          <Grid item xs={12}>
            <MuiTreeDemo />
            {/*<SortableTreeDemo />*/}
            {/*<AddRemoveDemo />*/}
          </Grid>
        )}
        {isAgrisoundAdmin && showHierarchyTree && (
          <Grid item xs={12}>
            <Typography variant="h6">JSON Object view</Typography>
          </Grid>
        )}
        {isAgrisoundAdmin && showHierarchyTree && (
          <Grid item xs={12}>
            <ReactJson src={deviceObj} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

const PhotoNoteContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
`;

function convertDatesDevice(device) {
  convertDates(device);
  for (let metric of device.metrics || []) {
    convertDates(metric);
  }
  convertDates(device.latestEvent || {});
  // if(deviceObj.beeActivity) deviceObj.dateCreated = deviceObj.dateCreated.toDate();
}

function convertDates(obj) {
  if (obj.dateUpdated) obj.dateUpdated = obj.dateUpdated.toDate();
  if (obj.dateCreated) obj.dateCreated = obj.dateCreated.toDate();
  if (obj.dateCreatedDb) obj.dateCreatedDb = obj.dateCreatedDb.toDate();
  if (obj.messageTime) obj.messageTime = obj.messageTime.toDate();
}

function DeviceCard({
  device,
  projectId,
  isAdmin,
  isAgrisoundAdmin,
  project,
  initialDeviceConfig,
  siteOptions,
  equinixConfig,
  permissions,
}) {
  // console.log('PollyDeviceCard', isAdmin, isAgrisoundAdmin);
  const classes = useStyles();
  const firestore = useFirestore();
  const [expanded, setExpanded] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState(snackbarDefault);
  const [deviceName, setDeviceName] = useState(device.deviceName);
  const [siteName, setSiteName] = useState(device.siteName);
  const [simId, setSimId] = useState(device.simId);
  const [stickerName, setStickerName] = useState(device.stickerName);
  const [description, setDescription] = useState(device.description);
  const [deviceConfig, setDeviceConfig] = useState(device.deviceConfig || initialDeviceConfig);
  const [gatewayMac, setGatewayMac] = useState(device.gatewayDeviceId);
  const [scaleDeviceId, setScaleDeviceId] = useState(device.scaleDeviceId);
  const [lat, setLat] = useState(device.location && device.location.lat);
  const [lng, setLng] = useState(device.location && device.location.lng);
  const [what3WordsLocation, setWhat3WordsLocation] = useState(
    device.location && device.location.what3words
  );
  const [message, setMessage] = useState();
  const [valid, setValid] = useState(true);
  const [open, setOpen] = useState(false);
  const [updateAll, setUpdateAll] = useState(false);
  const [hubCode, setHubcode] = useState(device.hubCode || project.hubCode);
  const [quadrant, setQuadrant] = useState(device.quadrant);
  const [habitat, setHabitat] = useState(device.habitat);
  const [equinixDeviceNumber, setEquinixDeviceNumber] = useState(device.equinixDeviceNumber);

  const collectionName = 'removeDeviceFromProject';
  const showRemoveDeviceButton = false;
  const fieldPermissions = {
    stickerName: [deviceTypes.beeHeroOne, deviceTypes.pollyResearch],
    simId: [deviceTypes.pollyResearch],
  };

  const hasEquinixDataChanges = () => {
    return (
      device.hubCode !== hubCode ||
      device.quadrant !== quadrant ||
      device.equinixDeviceNumber !== equinixDeviceNumber
    );
  };
  const hasChanges = () => {
    // console.log('hasChanges', { initialDeviceConfig, deviceConfig });

    return (
      device.deviceName !== deviceName ||
      device.siteName !== siteName ||
      device.simId !== simId ||
      device.stickerName !== stickerName ||
      (!device.location && lng) ||
      (!device.location && lat) ||
      (device.location && device.location.lng !== lng) ||
      (device.location && device.location.lat !== lat) ||
      device.description !== description ||
      device.gatewayDeviceId !== gatewayMac ||
      device.scaleDeviceId !== scaleDeviceId ||
      device.habitat !== habitat ||
      !isEqual(device.deviceConfig, deviceConfig) ||
      (project.isEquinix && hasEquinixDataChanges())
    );
  };

  const removeDeviceAction = () => {
    // setLoading(true);
    const uid = auth.currentUser && auth.currentUser.uid;
    const removeDeviceRequest = {
      dateCreated: new Date(),
      updatedBy: uid,
    };

    firestore
      .collection(collectionName)
      .add(removeDeviceRequest)
      .then((res) => {
        const docId = res.id;
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleConfirmOpen = () => {
    setOpen(true);
  };

  const handleConfirmClose = () => {
    setOpen(false);
  };

  const handleConfirmYes = () => {
    removeDeviceAction();
    setOpen(false);
  };
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  let lastActivity = device && device.latestEvent && device.latestEvent.dateCreated.toDate();
  if (isDate(lastActivity))
    lastActivity = convertDateWithTimezone(lastActivity, project.timezone).format(
      'DD MMMM yyyy hh:mm'
    );
  const location =
    device && device.location ? (
      <>
        Lat: {device.location.lat} Long: {device.location.lng}
      </>
    ) : (
      'To be added'
    );

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar(snackbarDefault);
  };

  const showUpdateAllConfig = false;

  return (
    <Card className={classes.card}>
      <SnackbarAlert snackbar={snackbar} handleClose={handleClose} />
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            R
          </Avatar>
        }
        title={`Device ID: ${device.deviceId}`}
        subheader={`Last Activity: ${lastActivity}`}
      />
      <CardContent>
        <Grid container justify={'center'} spacing={0} style={{ paddingTop: '20px' }}>
          <Grid item xs={6}>
            <Typography paragraph>Device Id:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography paragraph>{device.deviceId}</Typography>
          </Grid>
          {device.deviceType === 'beeHeroOne' && (
            <>
              <Grid item xs={6}>
                <Typography paragraph>Gateway Id:</Typography>
              </Grid>
              <Grid item xs={6}>
                {!editMode && (
                  <Typography paragraph>
                    <Link path={`/devices/${gatewayMac}`} text={gatewayMac} bold={true} />
                  </Typography>
                )}
                {editMode && isAgrisoundAdmin && (
                  <GatewayMac
                    gatewayMac={gatewayMac}
                    setGatewayMac={setGatewayMac}
                    classes={classes}
                  />
                )}
                {editMode && !isAgrisoundAdmin && (
                  <Typography paragraph>
                    <Link
                      path={`/devices/${device.gatewayDeviceId}`}
                      text={device.gatewayDeviceId}
                      bold={true}
                    />
                  </Typography>
                )}
              </Grid>
            </>
          )}
          {device.deviceType === 'beeHeroOne' && (
            <>
              <Grid item xs={6}>
                <Typography paragraph>Barscale Id:</Typography>
              </Grid>
              <Grid item xs={6}>
                {!editMode && (
                  <Typography paragraph>
                    <Link path={`/devices/${scaleDeviceId}`} text={scaleDeviceId} bold={true} />
                  </Typography>
                )}
                {editMode && isAgrisoundAdmin && (
                  <ScaleDeviceId
                    scaleDeviceId={scaleDeviceId}
                    setScaleDeviceId={setScaleDeviceId}
                    classes={classes}
                  />
                )}
                {editMode && !isAgrisoundAdmin && (
                  <Typography paragraph>
                    <Link
                      path={`/devices/${device.scaleDeviceId}`}
                      text={device.scaleDeviceId}
                      bold={true}
                    />
                  </Typography>
                )}
              </Grid>
            </>
          )}
          <Grid item xs={6}>
            <Typography paragraph>Device Name:</Typography>
          </Grid>
          <Grid item xs={6}>
            {!editMode && <Typography paragraph>{device.deviceName}</Typography>}
            {editMode && (
              <DeviceName deviceName={deviceName} setDeviceName={setDeviceName} classes={classes} />
            )}
          </Grid>
          {project.isEquinix && (
            <>
              <Grid item xs={6}>
                <Typography paragraph>Hub code:</Typography>
              </Grid>
              <Grid item xs={6}>
                {!editMode && <Typography paragraph>{hubCode}</Typography>}
                {editMode && (
                  <Dropdown
                    value={hubCode}
                    setValue={setHubcode}
                    classes={classes}
                    placeholder={'Hubcode'}
                    options={equinixConfig.hubCodes}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                <Typography paragraph>Quadrant:</Typography>
              </Grid>
              <Grid item xs={6}>
                {!editMode && <Typography paragraph>{quadrant}</Typography>}
                {editMode && (
                  <Dropdown
                    value={quadrant}
                    setValue={setQuadrant}
                    classes={classes}
                    placeholder={'Quadrant'}
                    options={equinixConfig.quadrants}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                <Typography paragraph>Habitat:</Typography>
              </Grid>
              <Grid item xs={6}>
                {!editMode && <Typography paragraph>{habitat}</Typography>}
                {editMode && (
                  <Dropdown
                    value={habitat}
                    setValue={setHabitat}
                    classes={classes}
                    placeholder={'Habitat'}
                    options={equinixConfig.habitats}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                <Typography paragraph>Equinix Device Number:</Typography>
              </Grid>
              <Grid item xs={6}>
                {!editMode && <Typography paragraph>{equinixDeviceNumber}</Typography>}
                {editMode && (
                  <Dropdown
                    value={equinixDeviceNumber}
                    setValue={setEquinixDeviceNumber}
                    classes={classes}
                    placeholder={'Device Number'}
                    options={[1, 2, 3]}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                <Typography paragraph>Equinix ID:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography paragraph>
                  {getEquinixDeviceNumber({
                    projectHubCode: project.hubCode,
                    device,
                  })}
                </Typography>
              </Grid>
            </>
          )}
          {!project.isEquinix && (
            <>
              <Grid item xs={6}>
                <Typography paragraph>Habitat:</Typography>
              </Grid>
              <Grid item xs={6}>
                {!editMode && <Typography paragraph>{habitat}</Typography>}
                {editMode && (
                  <Habitat habitat={habitat} setHabitat={setHabitat} classes={classes} />
                )}
              </Grid>
            </>
          )}
          {isAgrisoundAdmin && (
            <Grid item xs={6}>
              <Typography paragraph>Site Name:</Typography>
            </Grid>
          )}
          {isAgrisoundAdmin && (
            <Grid item xs={6}>
              {!editMode && <Typography paragraph>{device.siteName}</Typography>}
              {editMode && (
                <SiteName
                  siteName={siteName}
                  setSiteName={setSiteName}
                  classes={classes}
                  siteOptions={siteOptions}
                />
              )}
            </Grid>
          )}
          {fieldPermissions.stickerName.includes(device.deviceType) && isAgrisoundAdmin && (
            <>
              <Grid item xs={6}>
                <Typography paragraph>Sticker Name:</Typography>
              </Grid>
              {!editMode && (
                <Grid item xs={6}>
                  <Typography paragraph>{device.stickerName}</Typography>
                </Grid>
              )}
              {editMode && (
                <Grid item xs={6}>
                  <StickerName
                    stickerName={stickerName}
                    setStickerName={setStickerName}
                    classes={classes}
                  />
                </Grid>
              )}
            </>
          )}
          {fieldPermissions.simId.includes(device.deviceType) && isAgrisoundAdmin && (
            <>
              <Grid item xs={6}>
                <Typography paragraph>Sim ID:</Typography>
              </Grid>
              {!editMode && (
                <Grid item xs={6}>
                  <Typography paragraph>{device.simId}</Typography>
                </Grid>
              )}
              {editMode && (
                <Grid item xs={6}>
                  <SimId simId={simId} setSimId={setSimId} classes={classes} />
                </Grid>
              )}
            </>
          )}
          {device.deviceType === deviceTypes.beeHeroGatewayOne && (
            <>
              <Grid item xs={6}>
                <Typography paragraph>Barcode:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography paragraph>{device.barcode}</Typography>
              </Grid>
            </>
          )}
          <Grid item xs={6}>
            <Typography paragraph>Device Type:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography paragraph>{deviceTypesConfig[device.deviceType]}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography paragraph>Description:</Typography>
          </Grid>
          <Grid item xs={6}>
            {!editMode && <Typography paragraph>{device.description}</Typography>}
            {editMode && (
              <Description
                description={description}
                setDescription={setDescription}
                classes={classes}
              />
            )}
          </Grid>
          {message && (
            <>
              <Grid item xs={6}>
                <Typography paragraph></Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant={'caption'} style={{ color: 'red ' }}>
                  {message}
                </Typography>
              </Grid>
            </>
          )}
          <Grid item xs={6}>
            <Typography paragraph>Location:</Typography>
          </Grid>
          <Grid item xs={6}>
            {!editMode && <Typography paragraph>{location}</Typography>}
            {editMode && (
              <>
                <Latitude lat={lat} setLat={setLat} setMessage={setMessage} setValid={setValid} />
                <Longitude lng={lng} setLng={setLng} setMessage={setMessage} setValid={setValid} />
              </>
            )}
          </Grid>
          <Grid item xs={6}>
            <Typography paragraph>What3Words:</Typography>
          </Grid>
          <Grid item xs={6}>
            {!editMode && <Typography paragraph>{what3WordsLocation}</Typography>}
            {editMode && (
              <What3Words
                what3wordsLocation={what3WordsLocation}
                setWhat3WordsLocation={setWhat3WordsLocation}
                classes={classes}
              />
            )}
          </Grid>
          {permissions.canEdit && (
            <>
              <Grid item xs={6}>
                <Typography paragraph>
                  Use GPS (using the mobile browser gives the most accurate GPS location)
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <GeoLocation
                  setEditMode={setEditMode}
                  setLat={setLat}
                  setLng={setLng}
                  setWhat3WordsLocation={setWhat3WordsLocation}
                />
              </Grid>
            </>
          )}
          {isAgrisoundAdmin && (
            <Grid item xs={6}>
              <Typography paragraph>Algo Threshold</Typography>
            </Grid>
          )}
          {!editMode && isAgrisoundAdmin && (
            <Grid item xs={6}>
              <Typography paragraph>{deviceConfig.algoThreshold}</Typography>
            </Grid>
          )}
          {editMode && isAgrisoundAdmin && (
            <Grid item xs={6}>
              <AlgoThreshold
                classes={classes}
                deviceConfig={deviceConfig}
                setDeviceConfig={setDeviceConfig}
              />
            </Grid>
          )}
          {isAgrisoundAdmin && (
            <Grid item xs={6}>
              <Typography paragraph>Hour From</Typography>
            </Grid>
          )}
          {!editMode && isAgrisoundAdmin && (
            <Grid item xs={6}>
              <Typography paragraph>{deviceConfig.audioWriteConfig.fromHour}</Typography>
            </Grid>
          )}
          {editMode && isAgrisoundAdmin && (
            <Grid item xs={6}>
              <FromHour
                classes={classes}
                deviceConfig={deviceConfig}
                setDeviceConfig={setDeviceConfig}
              />
            </Grid>
          )}
          {isAgrisoundAdmin && (
            <Grid item xs={6}>
              <Typography paragraph>Hour To</Typography>
            </Grid>
          )}
          {!editMode && isAgrisoundAdmin && (
            <Grid item xs={6}>
              <Typography paragraph>{deviceConfig.audioWriteConfig.toHour}</Typography>
            </Grid>
          )}
          {editMode && isAgrisoundAdmin && (
            <Grid item xs={6}>
              <ToHour
                classes={classes}
                deviceConfig={deviceConfig}
                setDeviceConfig={setDeviceConfig}
              />
            </Grid>
          )}
          {isAgrisoundAdmin && (
            <Grid item xs={6}>
              <Typography paragraph>Collect Sensor Reading Every (minutes)</Typography>
            </Grid>
          )}
          {!editMode && isAgrisoundAdmin && (
            <Grid item xs={6}>
              <Typography paragraph>{deviceConfig.sensorConfig.collectEvery}</Typography>
            </Grid>
          )}
          {editMode && isAgrisoundAdmin && (
            <Grid item xs={6}>
              <CollectEvery
                classes={classes}
                deviceConfig={deviceConfig}
                setDeviceConfig={setDeviceConfig}
              />
            </Grid>
          )}
          {isAgrisoundAdmin && (
            <Grid item xs={6}>
              <Box display="flex">
                <Typography paragraph>Publish Every</Typography>
                <Tooltip title="A multiple of the collect every value. E.g. If 'Collect Every' is set to 10 minutes and 'Publish Every' is 2 then messages will be published every 20 minutes">
                  <InfoIcon style={{ width: '15px', marginLeft: '5px' }} />
                </Tooltip>
              </Box>
            </Grid>
          )}
          {!editMode && isAgrisoundAdmin && (
            <Grid item xs={6}>
              <Typography paragraph>{deviceConfig.sensorConfig.publishEvery}</Typography>
            </Grid>
          )}
          {editMode && isAgrisoundAdmin && (
            <Grid item xs={6}>
              <PublishEvery
                classes={classes}
                deviceConfig={deviceConfig}
                setDeviceConfig={setDeviceConfig}
              />
            </Grid>
          )}
          {showUpdateAllConfig && editMode && isAgrisoundAdmin && (
            <Grid item xs={6}>
              <Box display="flex">
                <Typography paragraph>Update All Devices with this run config</Typography>
                <Tooltip title="If this checkbox is ticked ALL devices in this project will get updated with the same run config (Algo Threshold, Collect Every etc)">
                  <InfoIcon style={{ width: '15px', marginLeft: '5px' }} />
                </Tooltip>
              </Box>
            </Grid>
          )}
          {editMode && isAgrisoundAdmin && (
            <Grid item xs={6}>
              <UpdateAllDevices updateAll={updateAll} setUpdateAll={setUpdateAll} />
            </Grid>
          )}
          {editMode && showRemoveDeviceButton && isAgrisoundAdmin && (
            <Grid item xs={6}>
              <Typography paragraph>Remove device from Project (Admin function only)</Typography>
            </Grid>
          )}
          {editMode && isAgrisoundAdmin && showRemoveDeviceButton && (
            <Grid item xs={6}>
              <div>
                <Button variant="contained" color="primary" onClick={handleConfirmOpen}>
                  Remove
                </Button>
                <Dialog
                  // fullScreen={fullScreen}
                  open={open}
                  onClose={handleConfirmClose}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {"Use Google's location service?"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Are you really sure you want to remove this device from the project?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button autoFocus onClick={handleConfirmClose} color="primary">
                      Disagree
                    </Button>
                    <Button onClick={handleConfirmClose} color="primary" autoFocus>
                      Agree
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </Grid>
          )}
        </Grid>
      </CardContent>
      <CardActions disableSpacing>
        {isAdmin && (
          <>
            <IconButton
              aria-label="Edit"
              onClick={async () => {
                if (editMode && hasChanges() && valid) {
                  setLoading(true);
                  let updatedObj = {};
                  updatedObj.description = !description ? '' : description; //description can be set back to empty
                  if (deviceName) updatedObj.deviceName = deviceName;
                  if (siteName) updatedObj.siteName = siteName;
                  if (simId) updatedObj.simId = simId;
                  if (!simId) updatedObj.simId = '';
                  if (stickerName) updatedObj.stickerName = stickerName;
                  updatedObj.location = {};
                  if (lat) updatedObj.location.lat = Number.parseFloat(lat);
                  if (lng) updatedObj.location.lng = Number.parseFloat(lng);
                  if (what3WordsLocation) updatedObj.location.what3words = what3WordsLocation;
                  if ((gatewayMac === '' || gatewayMac) && isAgrisoundAdmin) {
                    updatedObj.gatewayDeviceId = gatewayMac;
                  }
                  if ((scaleDeviceId === '' || scaleDeviceId) && isAgrisoundAdmin) {
                    updatedObj.scaleDeviceId = scaleDeviceId;
                  }
                  if (deviceConfig) updatedObj.deviceConfig = deviceConfig;
                  if (project.isEquinix) {
                    if ((device.hubCode || project.hubCode) !== hubCode) {
                      updatedObj.hubCode = hubCode;
                    }
                    if (device.quadrant !== quadrant) {
                      updatedObj.quadrant = quadrant;
                    }
                    if (device.equinixDeviceNumber !== equinixDeviceNumber) {
                      updatedObj.equinixDeviceNumber = equinixDeviceNumber;
                    }
                  }
                  if (device.habitat !== habitat) {
                    updatedObj.habitat = habitat;
                  }
                  try {
                    const diffs = diff(device, updatedObj);
                    // console.log(`Device changes diffs: ${JSON.stringify(diffs)}`);
                    const auditDescription = `Device changes: ${JSON.stringify(diffs)}`;
                    await updateDevice({
                      deviceId: device.deviceId,
                      updatedObj,
                      auditDescription,
                    });
                    setTimeout(() => {
                      if (!updateAll) {
                        setLoading(false);
                      }
                      setSnackbar({
                        message: 'Saved Device',
                        open: true,
                        severity: 'success',
                      });
                    }, 500);
                    if (updateAll) {
                      const uid = auth.currentUser && auth.currentUser.uid;
                      const userEmail = auth.currentUser && auth.currentUser.email;
                      const request = {
                        type: 'updateDeviceConfig',
                        projectId,
                        dateCreated: new Date(),
                        updatedBy: uid,
                        userEmail,
                        algoThreshold: deviceConfig.algoThreshold,
                        collectEvery: deviceConfig.sensorConfig.collectEvery,
                        publishEvery: deviceConfig.sensorConfig.publishEvery,
                        fromHour: deviceConfig.audioWriteConfig.fromHour,
                        toHour: deviceConfig.audioWriteConfig.toHour,
                      };
                      setLoading(true);

                      addRequestToCollection({
                        request,
                        collectionName: 'uiRequests',
                        firestore,
                        setMessage,
                        setLoading,
                      });
                    }
                  } catch (err) {
                    setLoading(false);
                    setSnackbar({
                      message: `Error: ${err.message}`,
                      open: true,
                      severity: 'error',
                    });
                    log('DeviceUpdate.err', err);
                  }
                } else {
                  // setSnackbar({ message: 'No changes to save', open: true, severity: 'info' });
                  log(`Device has no changes`);
                }
                setEditMode(!editMode);
              }}
            >
              {!editMode && permissions.canEdit && <EditIcon />}
              {editMode && permissions.canEdit && <SaveIcon />}
            </IconButton>
            {editMode && permissions.canEdit && (
              <IconButton onClick={() => setEditMode(false)}>
                <UndoIcon />
              </IconButton>
            )}
          </>
        )}
        {loading && <CircularProgress />}
        {/*<IconButton*/}
        {/*  className={clsx(classes.expand, {*/}
        {/*    [classes.expandOpen]: expanded,*/}
        {/*  })}*/}
        {/*  onClick={handleExpandClick}*/}
        {/*  aria-expanded={expanded}*/}
        {/*  aria-label="show more"*/}
        {/*>*/}
        {/*  <ExpandMoreIcon />*/}
        {/*</IconButton>*/}
      </CardActions>
      {/*<CardCollapse expanded={expanded} />*/}
    </Card>
  );
}

function DeviceBreadcrumbs({ deviceId }) {
  const breadcrumbs = [
    {
      path: '/',
      text: 'Home',
    },
    {
      path: '/polly-devices',
      text: 'Devices',
    },
    {
      path: `/users/${deviceId}`,
      text: 'Device',
    },
  ];
  return <BreadcrumbNav breadcrumbs={breadcrumbs} />;
}

function AlgoThreshold({ classes, deviceConfig, setDeviceConfig }) {
  return (
    <TextField
      // style={{ backgroundColor: '#fed8b1' }}
      className={classes.numberTextField}
      required
      id="hour-from"
      value={deviceConfig.algoThreshold}
      type="number"
      InputProps={{ inputProps: { min: 0, max: 50 } }}
      onChange={(e) => {
        const update = {
          algoThreshold: Number.parseFloat(e.target.value),
        };
        const newDeviceConfig = cloneDeep(deviceConfig);
        merge(newDeviceConfig, update);
        setDeviceConfig(newDeviceConfig);
      }}
    />
  );
}

function FromHour({ classes, deviceConfig, setDeviceConfig }) {
  return (
    <TextField
      // style={{ backgroundColor: '#fed8b1' }}
      className={classes.numberTextField}
      required
      id="hour-from"
      value={deviceConfig.audioWriteConfig.fromHour}
      type="number"
      InputProps={{ inputProps: { min: 0, max: 23 } }}
      onChange={(e) => {
        const update = {
          audioWriteConfig: {
            fromHour: Number.parseFloat(e.target.value),
          },
        };
        const newDeviceConfig = cloneDeep(deviceConfig);
        merge(newDeviceConfig, update);
        setDeviceConfig(newDeviceConfig);
      }}
    />
  );
}

function ToHour({ classes, deviceConfig, setDeviceConfig }) {
  return (
    <TextField
      // style={{ backgroundColor: '#fed8b1' }}
      className={classes.numberTextField}
      required
      id="hour-to"
      value={deviceConfig.audioWriteConfig.toHour}
      type="number"
      InputProps={{ inputProps: { min: 0, max: 23 } }}
      onChange={(e) => {
        const update = {
          audioWriteConfig: {
            toHour: Number.parseFloat(e.target.value),
          },
        };
        const newDeviceConfig = cloneDeep(deviceConfig);
        merge(newDeviceConfig, update);
        setDeviceConfig(newDeviceConfig);
      }}
    />
  );
}

function CollectEvery({ classes, deviceConfig, setDeviceConfig }) {
  return (
    <TextField
      // style={{ backgroundColor: '#fed8b1' }}
      className={classes.numberTextField}
      required
      id="collect-every"
      value={deviceConfig.sensorConfig.collectEvery}
      type="number"
      InputProps={{ inputProps: { min: 2, max: 60 } }}
      onChange={(e) => {
        const update = {
          sensorConfig: {
            collectEvery: Number.parseFloat(e.target.value),
          },
        };
        const newDeviceConfig = cloneDeep(deviceConfig);
        merge(newDeviceConfig, update);
        setDeviceConfig(newDeviceConfig);
      }}
    />
  );
}

function UpdateAllDevices({ classes, updateAll, setUpdateAll }) {
  return (
    <Checkbox
      checked={updateAll}
      onChange={(e) => {
        // const value = e.target.checked;
        // if (value) {
        //   setProjectId(undefined);
        // }
        // setIsNewProject(!isNewProject);
        // validate();
        setUpdateAll(!updateAll);
      }}
      name="updateAllDevices"
    />
  );
}

function PublishEvery({ classes, deviceConfig, setDeviceConfig }) {
  return (
    <TextField
      // style={{ backgroundColor: '#fed8b1' }}
      className={classes.numberTextField}
      required
      id="publish-every"
      value={deviceConfig.sensorConfig.publishEvery}
      type="number"
      InputProps={{ inputProps: { min: 2, max: 60 } }}
      onChange={(e) => {
        const update = {
          sensorConfig: {
            publishEvery: Number.parseFloat(e.target.value),
          },
        };
        const newDeviceConfig = cloneDeep(deviceConfig);
        merge(newDeviceConfig, update);
        setDeviceConfig(newDeviceConfig);
      }}
    />
  );
}

function SiteName({ siteName, setSiteName, classes }) {
  return (
    <TextField
      // style={{ backgroundColor: '#fed8b1' }}
      className={classes.textField}
      required
      id="standard-required"
      value={siteName}
      onChange={(e) => setSiteName(e.target.value)}
    />
  );
}

function Habitat({ habitat, setHabitat, classes }) {
  return (
    <TextField
      // style={{ backgroundColor: '#fed8b1' }}
      className={classes.textField}
      required
      id="standard-required"
      value={habitat}
      onChange={(e) => setHabitat(e.target.value)}
    />
  );
}

function Dropdown({ value, setValue, classes, options, placeholder }) {
  return (
    <Select
      className={classes.select}
      disabled={false}
      placeholder={placeholder}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      variant="outlined"
    >
      {options.map((option) => {
        return (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        );
      })}
    </Select>
  );
}

function DeviceName({ deviceName, setDeviceName, classes }) {
  return (
    <TextField
      // style={{ backgroundColor: '#fed8b1' }}
      className={classes.textField}
      required
      id="standard-required"
      value={deviceName}
      onChange={(e) => setDeviceName(e.target.value)}
    />
  );
}

function Description({ description, setDescription, classes }) {
  return (
    <TextField
      // style={{ backgroundColor: '#fed8b1' }}
      className={classes.textField}
      required
      id="standard-required"
      value={description}
      onChange={(e) => setDescription(e.target.value)}
    />
  );
}

function StickerName({ stickerName, setStickerName, classes }) {
  return (
    <TextField
      // style={{ backgroundColor: '#fed8b1' }}
      className={classes.textField}
      required
      id="standard-required"
      value={stickerName}
      onChange={(e) => setStickerName(e.target.value)}
    />
  );
}

function SimId({ simId, setSimId, classes }) {
  return (
    <TextField
      // style={{ backgroundColor: '#fed8b1' }}
      className={classes.textField}
      required
      id="standard-required"
      value={simId}
      onChange={(e) => setSimId(e.target.value)}
    />
  );
}

function CalibrationOffset({ calibrationOffset, setCalibrationOffset, classes }) {
  return (
    <TextField
      className={classes.textField}
      type="number"
      required
      id="standard-required"
      value={calibrationOffset}
      onChange={(e) => setCalibrationOffset(Number.parseFloat(e.target.value))}
    />
  );
}

function CalibrationCoefficient({ calibrationCoefficient, setCalibrationCoefficient, classes }) {
  return (
    <TextField
      className={classes.textField}
      type="number"
      required
      id="standard-required"
      value={calibrationCoefficient}
      onChange={(e) => setCalibrationCoefficient(Number.parseFloat(e.target.value))}
    />
  );
}

function GatewayMac({ gatewayMac, setGatewayMac, classes }) {
  return (
    <TextField
      className={classes.textField}
      required
      id="standard-required"
      value={gatewayMac}
      onChange={(e) => {
        let value = e.target.value;
        setGatewayMac(value);
      }}
    />
  );
}

function ScaleDeviceId({ scaleDeviceId, setScaleDeviceId, classes }) {
  return (
    <TextField
      className={classes.textField}
      // style={{ backgroundColor: '#fed8b1' }}
      required
      id="standard-required"
      value={scaleDeviceId}
      onChange={(e) => {
        let value = e.target.value;
        setScaleDeviceId(value);
      }}
    />
  );
}

function What3Words({ what3wordsLocation, setWhat3WordsLocation, classes }) {
  return (
    <TextField
      className={classes.textField}
      // style={{ backgroundColor: '#fed8b1' }}
      required
      id="standard-required"
      value={what3wordsLocation}
      onChange={(e) => {
        let value = e.target.value;
        setWhat3WordsLocation(value);
      }}
    />
  );
}

function Latitude({ lat, setLat, setMessage, setValid }) {
  return (
    <TextField
      type="string"
      label="Lat"
      style={{ backgroundColor: '#fed8b1' }}
      required
      id="standard-required"
      value={lat}
      onChange={(e) => {
        const value = e.target.value;
        setLat(value);
        if (value === '') {
          setMessage && setMessage(undefined);
          setValid && setValid(true);
          return;
        }
        let lat = parseFloat(value);
        if (isNaN(lat)) {
          setMessage && setMessage('lat is not a number');
          setValid && setValid(false);
        } else {
          setMessage && setMessage(undefined);
          setValid && setValid(true);
          // setLat(lat);
        }
      }}
    />
  );
}

function Longitude({ lng, setLng, setMessage, setValid }) {
  return (
    <TextField
      type="string"
      label="Long"
      style={{ backgroundColor: '#fed8b1' }}
      required
      id="standard-required"
      value={lng}
      onChange={(e) => {
        const value = e.target.value;
        setLng(value);
        if (value === '') {
          setMessage && setMessage(undefined);
          setValid && setValid(true);
          return;
        }
        let lat = parseFloat(value);
        if (isNaN(lat)) {
          setMessage && setMessage('lng is not a number');
          setValid && setValid(false);
        } else {
          setMessage && setMessage(undefined);
          setValid && setValid(true);
          // setLng(lng);
        }
      }}
    />
  );
}
