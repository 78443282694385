import { getDocsFromSnapshot } from './db-util';
import { cloneDeep } from 'lodash';
import { format } from 'date-fns';
import { firestore as db } from '../../../../firebase';

export function getPollyDailyValuesFileName({ projectId }) {
  return projectId + '-daily-values.csv';
}

export async function downloadPollyDailyReport({ projectId }) {
  const eventsData = await getEventsByProjectId({ projectId });
  const eventsRows = Object.values((eventsData && cloneDeep(eventsData)) || {});
  const deviceIdLocationMap = {};
  const devices = await getDevicesByProjectId({ projectId });
  for (let device of devices) {
    if (!deviceIdLocationMap[device.deviceId] && device.location) {
      deviceIdLocationMap[[device.deviceId]] = device.location;
    }
  }
  // console.log(deviceIdLocationMap);
  try {
    return await getPollyCsvData({
      eventsRows,
      deviceIdLocationMap,
    });
  } catch (err) {
    console.log(err);
  }
}

function getPollyCsvData({ eventsRows, deviceIdLocationMap = {} }) {
  let csv = [];
  let headers = [
    'deviceId',
    'deviceName',
    'dateCreated',
    'temperature',
    'humidity',
    'beeActivity',
    'estimatedBeesPerSqMetre',
    'lat',
    'lng',
    'what3words',
  ];

  for (let row of eventsRows) {
    for (let event of row.events) {
      if (event.hasOwnProperty('dateCreated')) {
        try {
          let dateCreated = format(event.dateCreated.toDate(), 'dd MMM yyyy HH:mm:ss');
          const beeActivity = event.hasOwnProperty('beeActivity') && event.beeActivity;
          const location = deviceIdLocationMap[row.deviceId];
          let dataPoint = {
            deviceId: row.deviceId,
            deviceName: row.deviceName,
            dateCreated,
            temperature: (event.hasOwnProperty('temperature') && event.temperature) || 'n/a',
            humidity: (event.hasOwnProperty('humidity') && event.humidity) || 'n/a',
            beeActivity: beeActivity || 'n/a',
            estimatedBeesPerSqMetre:
              (beeActivity && getEstimatedBeesPerSquareMetre(beeActivity)) || 'n/a',
            lat: (location && location.lat) || 'n/a',
            lng: (location && location.lng) || 'n/a',
            what3words: (location && location.what3words) || 'n/a',
          };

          csv.push(dataPoint);
        } catch (err) {
          // console.log('dateCreated.format.err', event.dateCreated, row.docId, err.message);
        }
      }
    }
  }

  csv = csv.sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated));

  return [csv, headers];
}

function getEstimatedBeesPerSquareMetre(beeActivity) {
  if (beeActivity === 0) {
    return 0;
  }
  return (beeActivity / 100) * 3600 * 2;
}

async function getEventsByProjectId({ projectId }) {
  if (!projectId) {
    throw new Error('projectId not provided');
  }

  const snapshot = await db
    .collection('events')
    .where('projectIds', 'array-contains-any', [projectId])
    .where('month', '==', '2023-7')
    .where('deviceId', '==', 'PNET_FE39CDD31C56')
    .get();
  return getDocsFromSnapshot(snapshot);
}

async function getDevicesByProjectId({ projectId }) {
  if (!projectId) {
    throw new Error('projectId not provided');
  }

  const snapshot = await db
    .collection('devices')
    .where('projectIds', 'array-contains-any', [projectId])
    .get();
  return getDocsFromSnapshot(snapshot);
}
