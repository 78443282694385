import React, { useState } from 'react';
import { format } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import { green, blueGrey } from '@material-ui/core/colors';
import { DataGrid } from '@material-ui/data-grid';
import {
  getBatteryTrendText,
  getDeviceStats, getHeatMapColour,
  getLatestFieldValue,
  getMetric,
  getMetricValue,
  statNames,
  statsConfig
} from '../../beekeeper/Widgets/stats-util';
import { getDateFromTimeStamp } from '../Notes/yieldTableData';
import { differenceInDays } from 'date-fns';
import Highlight from '../../beekeeper/Widgets/Highlight';
import DownloadFile from '../../beekeeper/Download/DownloadFile';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: blueGrey[500],
  },
}));

export default function PollyDevicesHealthTable({ devices, projectId }) {
  const classes = useStyles();
  // const userData = useSelector((state) => state.appConfig.userData);
  // const isAdmin = isProjectAdmin(userData);
  // let projectIds = getProjectIds(userData);
  // const projectId = projectIds[0];
  // let storeAs = `project-${projectId}`;
  // const projectData = useSelector(({ firestore: { data } }) => data[storeAs]);
  //
  // const projectQuery = [
  //   {
  //     collection: 'projects',
  //     doc: projectIds[0],
  //     storeAs,
  //   },
  // ];

  return <HealthTable devices={devices} projectId={projectId} />;
}

function HealthTable({ devices, projectId }) {
  const classes = useStyles();
  const tableData = getTableData(devices);
  const columns = getHealthColumns();
  //'Reboots (1/7/30)'
  const csvHeaders = columns.map(column => column.field).concat(['lastUpdatedDate', 'url']);
  const csvFieldNames = columns.map(column => column.headerName).concat(['lastUpdatedDate', 'url']);

  const headers =  [
    'id',
    'deviceId',
    'lastUpdated',
    'lastUpdatedDate',
    'url',
    // status: 'Good',
    // actions: 'none',
    'battery',
    'sdCardPercent',
    'rssi',
    'reboots',
    'rebootsToday',
    // rebootsLast7Days,
    // rebootsLast30Days,
    // rebootsDayCount: '4 / 7',
    'batteryTrend',
    'batteryAction',
    'backgroundColor',
    'deviceName',
    'fwver',
  ];

  const reportDate = format(new Date(), 'yyyyMMdd_hhmm');
  const fileName = `${projectId}-device-status-report-${reportDate}.csv`;
  // const reportDate = '';//format(new Date(), '');
  // const fileName = `${projectId}-device-status-report-${reportDate}.csv`;
  const downloadButton = (
    <DownloadFile
      fileName={fileName}
      csvData={tableData}
      csvHeaders={headers}
      // csvFieldNames={csvFieldNames}
    />
  );
  return (
    <div style={{ height: 600, width: '150%' }}>
      {downloadButton}
      <DataGrid rows={tableData} columns={columns} pageSize={50} style={{ fontSize: '8px' }} />
    </div>
  );
}

function getTableData(devices) {
  let devicesStats = getDeviceStats(devices);
  return devices.map((device, index) => {
    const deviceId = device.deviceId;
    let deviceStats = devicesStats.find((stat) => stat.deviceId === deviceId);
    // const lastUpdatedStat = deviceStats.stats.find((stat) => statsConfig[stat].type === 'lastUpdated');
    // const lastUpdated = deviceStats.daysDiff + ' ' + statsConfig[lastUpdatedStat].label;
    const lastUpdatedDate = (device.latestEvent && format(device.latestEvent.dateCreated.toDate(), 'yyyy-MM-dd HH:mm:ss')) || 'No latestEvent';
    // if(deviceId === 'PNET_D42') {
    //   console.log('PNET_D42', lastUpdatedDate);
    // }
    const url = `https://us-central1-agrisound-7474e.cloudfunctions.net/getPollyEventsPlanB?apiKey=123&deviceId=${deviceId}&subDays=0`;
    const lastUpdated = deviceStats.daysDiff + ' days ago';
    const rebootsToday = getValue(device, 'rebootsToday');
    const rebootsLast7Days =getValue(device, 'rebootsLast7Days');
    const rebootsLast30Days = getValue(device, 'rebootsLast30Days');
    const reboots = `${rebootsToday || '0'} / ${rebootsLast7Days || '0'} / ${rebootsLast30Days || '0'}`;
    // console.log(device.deviceId, statsConfig[lastUpdatedStat].label);
    return {
      id: index,
      deviceId: device.deviceId,
      lastUpdated,
      lastUpdatedDate,
      url,
      // status: 'Good',
      // actions: 'none',
      battery: getValue(device, 'battery'),
      sdCardPercent: getValue(device, 'sdCardPercent'),
      rssi: getValue(device, 'rssi'),
      reboots,
      rebootsToday,
      // rebootsLast7Days,
      // rebootsLast30Days,
      // rebootsDayCount: '4 / 7',
      batteryTrend: getValue(device, 'batteryTrend'),
      batteryAction: 'none',
      backgroundColor: getHeatMapColour({ device, deviceStats }),
      deviceName: device.deviceName,
      fwver: getValue(device, 'fwver'),
    };
  });
}

function getValue(device, fieldName) {
  const latestEventFields = ['battery', 'sdCardPercent', 'rssi', 'fwver'];
  if (latestEventFields.includes(fieldName)) {
    const value = device?.latestEvent?.[fieldName];
    if (!value) {
      return 'not present';
    }

    return value;
  }

  if (fieldName === 'rebootsToday') {
    return device?.rebootMetrics?._1dayCount;
  } else if (fieldName === 'rebootsLast7Days') {
    return device?.rebootMetrics?._7dayCount;
  } else if (fieldName === 'rebootsLast30Days') {
    return device?.rebootMetrics?._30dayCount;
  }

  if (fieldName === 'batteryTrend') {
    const metrics = device.metrics;
    const batteryMetric = getMetric(metrics, 'battery');
    const batteryLatest = getLatestFieldValue(device, 'battery');
    const battery7DaysAgo = getMetricValue({ metric: batteryMetric, fieldName: '_7dayValue', suffix: '' });
    // console.log(device.deviceId, batteryMetric, battery7DaysAgo);
    return getBatteryTrendText({ batteryLatest, battery7DaysAgo });
  }

  return 'TBD';
}

function getHealthColumns() {
  const columns = [
    {
      field: 'deviceId',
      headerName: 'deviceId',
      width: 150,
      renderCell: (params) => {
        const backgroundColor = params.row.backgroundColor || 'yellow';
        return <Highlight text={params.row.deviceId} color={'black'} backgroundColor={backgroundColor} />;
      },
    },
    {
      field: 'lastUpdated',
      headerName: 'lastUpdated',
      width: 150,
    },
    // {
    //   field: 'status',
    //   headerName: 'Status',
    //   width: 150,
    // },
    // {
    //   field: 'actions',
    //   headerName: 'Actions',
    //   width: 150,
    // },
    {
      field: 'battery',
      headerName: 'Battery',
      type: 'number',
      width: 100,
    },
    {
      field: 'sdCardPercent',
      headerName: 'SD Card %',
      type: 'number',
      width: 150,
    },
    {
      field: 'rssi',
      headerName: 'RSSI',
      type: 'number',
      width: 100,
    },
    {
      field: 'reboots',
      headerName: 'Reboots (1/7/30)',
      width: 250,
    },
    // {
    //   field: 'reboots',
    //   headerName: 'Reboots (1/7/30)',
    //   width: 250,
    //   valueGetter: (params) =>
    //     `${params.getValue('rebootsToday') || '0'} / ${params.getValue('rebootsLast7Days') || '0'} / ${
    //       params.getValue('rebootsLast30Days') || '0'
    //     }`,
    // },
    // {
    //   field: 'rebootsDayCount',
    //   headerName: 'Reboots Day Count',
    //   width: 150,
    // },
    // {
    //   field: 'batteryTrend',
    //   headerName: 'Battery Trend',
    //   width: 200,
    // },
    {
      field: 'fwver',
      headerName: 'fwver',
      width: 300,
    },
    // {
    //   field: 'batteryAction',
    //   headerName: 'Battery Action',
    //   width: 150,
    // },
  ];

  return columns;
}
