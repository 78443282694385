import React, { useEffect, useState } from 'react';
import { cloneDeep, sumBy, min, max, uniq } from 'lodash';
import { differenceInMinutes, parse, format } from 'date-fns';
import { DataGrid } from '@material-ui/data-grid';
import PlayArrow from '@material-ui/icons/PlayArrow';
import Tooltip from '@material-ui/core/Tooltip';
import { log } from '../../../util/log-util';
import canterburyData from './canterburyData.json';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Grid from '@material-ui/core/Grid';
import PollySummaryTable from './PollySummaryTable';
import Typography from '@material-ui/core/Typography';
import PollyHeatmap from '../../beekeeper/FieldOverviewPage/PollyHeatmap';
import { InputAdornment, Slider, Switch } from '@material-ui/core';
import Input from '@material-ui/core/Input';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import PollyStackedBarChart, { defaultStackedBarChartOptions } from '../Charts/PollyStackedBarChart';
import PollyTemperatureComparison from './PollyTemperatureComparison';
import Weather from '../../beekeeper/Weather/Weather';
import Paper from '@material-ui/core/Paper';
import { globalElevation } from '../../../config/globalStyle';
import TotalCustomersWidget from '../../beekeeper/Dashboard/TotalCustomersWidget';
import PollyActivityWidget from '../../beekeeper/Widgets/PollyActivityWidget';
import PollyDeviceHighLowWidget from '../../beekeeper/Widgets/PollyDeviceHighLowWidget';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
    // textAlign: 'center',
    // color: theme.palette.text.secondary,
  },
}));

const columns = [
  // { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'beeCount',
    headerName: 'Bee Count',
    type: 'number',
    width: 150,
    // editable: true,
  },
  {
    field: 'minTemp',
    headerName: 'Min Temp',
    type: 'number',
    width: 150,
    // editable: true,
  },
  {
    field: 'maxTemp',
    headerName: 'Max Temp',
    type: 'number',
    width: 150,
    // editable: true,
  },
  {
    field: 'minHumidity',
    headerName: 'Min Humidity',
    type: 'number',
    width: 130,
    // editable: true,
  },
  {
    field: 'maxHumidity',
    headerName: 'Max Humidity',
    type: 'number',
    width: 130,
    // editable: true,
  },
];

const options2 = {
  chart: {
    zoomType: 'xy',
    height: 320,
  },
  title: {
    text: 'Average Monthly Temperature and Rainfall in Tokyo',
  },
  // subtitle: {
  //   text: 'Source: WorldClimate.com',
  // },
  xAxis: [
    {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      crosshair: true,
    },
  ],
  yAxis: [
    {
      // Primary yAxis
      labels: {
        format: '{value}°C',
        style: {
          color: Highcharts.getOptions().colors[1],
        },
      },
      title: {
        text: 'Temperature',
        style: {
          color: Highcharts.getOptions().colors[1],
        },
      },
    },
    {
      // Secondary yAxis
      title: {
        text: 'Bee count',
        style: {
          color: Highcharts.getOptions().colors[0],
        },
      },
      labels: {
        format: '{value} count',
        style: {
          color: Highcharts.getOptions().colors[0],
        },
      },
      opposite: true,
    },
  ],
  tooltip: {
    shared: true,
  },
  // legend: {
  //   layout: 'horizontal',
  //   align: 'left',
  //   x: 0,
  //   verticalAlign: 'top',
  //   y: -10,
  //   floating: true,
  //   backgroundColor:
  //     Highcharts.defaultOptions.legend.backgroundColor || // theme
  //     'rgba(255,255,255,0.25)',
  // },
  series: [
    {
      name: 'Bee Count',
      type: 'column',
      yAxis: 1,
      data: [49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4],
      tooltip: {
        valueSuffix: ' count',
      },
    },
    {
      name: 'Temperature',
      type: 'spline',
      data: [7.0, 6.9, 9.5, 14.5, 18.2, 21.5, 25.2, 26.5, 23.3, 18.3, 13.9, 9.6],
      tooltip: {
        valueSuffix: '°C',
      },
    },
  ],
};

const dayMap = {
  2: '20210802',
  3: '20210803',
  4: '20210804',
  5: '20210805',
  6: '20210806',
};

export let defaultDevices = [
  {
    deviceId: 'PNET_3A0',
    location: { lat: 51.266373, lng: 1.138508 },
    weight: 1,
    pollinationActivity: '100%',
  },
  {
    deviceId: 'PNET_111',
    location: { lat: 51.266427, lng: 1.138594 },
    weight: 1,
    pollinationActivity: '100%',
  },
  {
    deviceId: 'PNET_6E1',
    location: { lat: 51.266454, lng: 1.138681 },
    weight: 1,
    pollinationActivity: '100%',
  },
  {
    deviceId: 'PNET_D80',
    location: { lat: 51.266481, lng: 1.13881 },
    weight: 1,
    pollinationActivity: '100%',
  },
  {
    deviceId: 'PNET_AE6',
    location: { lat: 51.266427, lng: 1.13881 },
    weight: 1,
    pollinationActivity: '100%',
  },
  {
    deviceId: 'PNET_F80',
    location: { lat: 51.2664, lng: 1.138724 },
    weight: 1,
    pollinationActivity: '100%',
  },
  {
    deviceId: 'PNET_474',
    location: { lat: 51.266319, lng: 1.138508 },
    weight: 1,
    pollinationActivity: '100%',
  },
  {
    deviceId: 'PNET_C7F',
    location: { lat: 51.266346, lng: 1.138638 },
    weight: 1,
    pollinationActivity: '100%',
  },
];

// export let defaultDevices = [
//   {
//     deviceId: 'PNET_3A0',
//     location: { lat: 57.775644, lng: -5.605355 }, //F1
//     weight: 1,
//     pollinationActivity: '100%',
//   },
//   {
//     deviceId: 'PNET_111',
//     location: { lat: 57.775563, lng: -5.599692 }, //F5
//     weight: 1,
//     pollinationActivity: '100%',
//   },
//   {
//     deviceId: 'PNET_6E1',
//     location: { lat: 57.775995, lng: -5.601259 }, //F10
//     weight: 1,
//     pollinationActivity: '100%',
//   },
//   {
//     deviceId: 'PNET_D80',
//     location: { lat: 57.775563, lng: -5.599186 }, //F3
//     weight: 1,
//     pollinationActivity: '100%',
//   },
//   {
//     deviceId: 'PNET_AE6',
//     location: { lat: 57.775833, lng: -5.605658 }, //F4
//     weight: 1,
//     pollinationActivity: '100%',
//   },
//   {
//     deviceId: 'PNET_F80',
//     location: { lat: 57.775590, lng: -5.598579 }, //F7
//     weight: 1,
//     pollinationActivity: '100%',
//   },
//   {
//     deviceId: 'PNET_474',
//     location: { lat: 57.776587, lng: -5.601512 },//F2
//     weight: 1,
//     pollinationActivity: '100%',
//   },
//   {
//     deviceId: 'PNET_C7F',
//     location: { lat: 57.776318, lng: -5.601815 }, //F6
//     weight: 1,
//     pollinationActivity: '100%',
//   },
// ];

// export const mapSampleData = [
//   {deviceId: 1,deviceName: 'device1', siteId: 1,siteName: 'South Point', farmId: 1, farmName: 'Bardsley South', groupId: 1,groupName: 'Strawberries', location: { lat: 51.3160912, lng: 1.1566061 },},
//   {deviceId: 2,deviceName: 'device2', siteId: 1,siteName: 'South Point', farmId: 1, farmName: 'Bardsley South', groupId: 1,groupName: 'Strawberries', location: { lat: 51.3168556, lng: 1.1739922 },},
//   {deviceId: 3,deviceName: 'device3', siteId: 1,siteName: 'South Point', farmId: 1, farmName: 'Bardsley South', groupId: 1,groupName: 'Strawberries', location: { lat: 51.3159537, lng: 1.1712939 },},
//   {deviceId: 4,deviceName: 'device4', siteId: 1,siteName: 'South Point', farmId: 1, farmName: 'Bardsley South', groupId: 1,groupName: 'Strawberries', location: { lat: 51.3156285, lng: 1.1651141 },},
//   {deviceId: 5,deviceName: 'device5', siteId: 1,siteName: 'South Point', farmId: 1, farmName: 'Bardsley South', groupId: 1,groupName: 'Strawberries', location: { lat: 51.3164667, lng: 1.1599749 },},
//   {deviceId: 6,deviceName: 'device6', siteId: 2,siteName: 'East Park', farmId: 1, farmName: 'Bardsley South', groupId: 2,groupName: 'Cherries', location: { lat: 51.266373, lng: 1.138508 },},
//   {deviceId: 7,deviceName: 'device7', siteId: 2,siteName: 'East Park', farmId: 1, farmName: 'Bardsley South', groupId: 2,groupName: 'Cherries', location: { lat: 51.266427, lng: 1.138594 },},
//   {deviceId: 8,deviceName: 'device8', siteId: 2,siteName: 'East Park', farmId: 1, farmName: 'Bardsley South', groupId: 2,groupName: 'Cherries', location: { lat: 51.266454, lng: 1.138681 },},
//   {deviceId: 9,deviceName: 'device9', siteId: 2,siteName: 'East Park', farmId: 1, farmName: 'Bardsley South', groupId: 2,groupName: 'Cherries', location: { lat: 51.266481, lng: 1.13881 },},
//   {deviceId: 10,deviceName: 'device10', siteId: 2,siteName: 'East Park', farmId: 1, farmName: 'Bardsley South', groupId: 2,groupName: 'Cherries', location: { lat: 51.266427, lng: 1.13881 },},
//   {deviceId: 11,deviceName: 'device11', siteId: 2,siteName: 'East Park', farmId: 1, farmName: 'Bardsley South', groupId: 2,groupName: 'Cherries', location: { lat: 51.2664, lng: 1.138724 },},
//   {deviceId: 12,deviceName: 'device12', siteId: 2,siteName: 'East Park', farmId: 1, farmName: 'Bardsley South', groupId: 2,groupName: 'Cherries', location: { lat: 51.266319, lng: 1.138508 }},
//   {deviceId: 13,deviceName: 'device13', siteId: 2,siteName: 'East Park', farmId: 1, farmName: 'Bardsley South', groupId: 2,groupName: 'Cherries', location: { lat: 51.266346, lng: 1.138638 },},
//   {deviceId: 14,deviceName: 'device14', siteId: 3,siteName: 'West End', farmId: 1, farmName: 'Bardsley South', groupId: 2,groupName: 'Cherries', location: { lat: 51.2627074, lng: 0.9631669 },},
//   {deviceId: 15,deviceName: 'device15', siteId: 3,siteName: 'West End', farmId: 1, farmName: 'Bardsley South', groupId: 2,groupName: 'Cherries', location: { lat: 51.2629071, lng: 0.9607015 },},
//   {deviceId: 16,deviceName: 'device16', siteId: 4,siteName: 'North Pole', farmId: 2, farmName: 'Bardsley North', groupId: 1,groupName: 'Strawberries', location: { lat: 57.775644, lng: -5.605355 },},
//   {deviceId: 17,deviceName: 'device17', siteId: 4,siteName: 'North Pole', farmId: 2, farmName: 'Bardsley North', groupId: 1,groupName: 'Strawberries', location: { lat: 57.775563, lng: -5.599692 },},
//   {deviceId: 18,deviceName: 'device18', siteId: 4,siteName: 'North Pole', farmId: 2, farmName: 'Bardsley North', groupId: 1,groupName: 'Strawberries', location: { lat: 57.775995, lng: -5.601259 },},
//   {deviceId: 19,deviceName: 'device19', siteId: 4,siteName: 'North Pole', farmId: 2, farmName: 'Bardsley North', groupId: 1,groupName: 'Strawberries', location: { lat: 57.775563, lng: -5.599186 },},
//   {deviceId: 20,deviceName: 'device20', siteId: 4,siteName: 'North Pole', farmId: 2, farmName: 'Bardsley North', groupId: 1,groupName: 'Strawberries', location: { lat: 57.775833, lng: -5.605658 },},
//   {deviceId: 21,deviceName: 'device21', siteId: 4,siteName: 'North Pole', farmId: 2, farmName: 'Bardsley North', groupId: 1,groupName: 'Strawberries', location: { lat: 57.775590, lng: -5.598579 },},
//   {deviceId: 22,deviceName: 'device22', siteId: 4,siteName: 'North Pole', farmId: 2, farmName: 'Bardsley North', groupId: 1,groupName: 'Strawberries', location: { lat: 57.776587, lng: -5.601512 },},
//   {deviceId: 23,deviceName: 'device23', siteId: 4,siteName: 'North Pole', farmId: 2, farmName: 'Bardsley North', groupId: 1,groupName: 'Strawberries', location: { lat: 57.776318, lng: -5.601815 },},
//   {deviceId: 24,deviceName: 'device24', siteId: 5,siteName: 'Aubeyterre-sur-dronne', farmId: 3, farmName: 'Bardsley France', groupId: 2,groupName: 'Cherries', location: { lat: 45.2635887, lng: 0.1488879 },},
//   {deviceId: 25,deviceName: 'device25', siteId: 5,siteName: 'Aubeyterre-sur-dronne', farmId: 3, farmName: 'Bardsley France', groupId: 2,groupName: 'Cherries', location: { lat: 45.2649521, lng: 0.1469942 },},
//   {deviceId: 26,deviceName: 'device26', siteId: 5,siteName: 'Aubeyterre-sur-dronne', farmId: 3, farmName: 'Bardsley France', groupId: 2,groupName: 'Cherries', location: { lat: 45.2633182, lng: 0.150587 },},
// ];
//
// const defaultDevices = mapSampleData.map(entry => {
//   entry.weight = 1;
//   entry.pollinationActivity = '100%';
//   entry.deviceId = entry.deviceId + '';
//   return entry;
// });

export default function PollyDashboard() {
  const classes = useStyles();
  const deviceIds = defaultDevices.map((device) => device.deviceId.substring(5, 8)); //['C7F', '474', 'F80', 'AE6', '3A0', '111', '6E1', 'D80'];
  // const deviceIds = defaultDevices.map((device) => device.deviceId);
  const defaultDay = undefined;
  const sunrise = new Date('2021 Aug 03 05:25');
  const sunset = new Date('2021 Aug 03 20:37');
  const weatherData = [
    {
      sunrise: new Date('2021 Aug 02 05:21'),
      sunset: new Date('2021 Aug 02 20:40'),
      date: '02 Aug',
      description: 'clear sky',
      // humidity: 53
      icon:
        'M0 15.375q0-0.609 0.422-1.031 0.438-0.406 1-0.406h3.406q0.578 0 0.961 0.422t0.383 1.016-0.383 1.008-0.961 0.414h-3.406q-0.578 0-1-0.422t-0.422-1zM4.766 26.922q0-0.578 0.391-1.016l2.453-2.375q0.375-0.391 0.984-0.391 0.594 0 0.992 0.375t0.398 0.953q0 0.609-0.406 1.063l-2.375 2.375q-1.016 0.797-2.047 0-0.391-0.422-0.391-0.984zM4.766 3.844q0-0.578 0.391-1.016 0.484-0.406 1.063-0.406 0.547 0 0.984 0.406l2.375 2.453q0.406 0.375 0.406 0.984 0 0.594-0.398 0.992t-0.992 0.398q-0.609 0-0.984-0.406l-2.453-2.375q-0.391-0.422-0.391-1.031zM9.016 15.375q0-2.328 1.172-4.336t3.18-3.18 4.336-1.172q1.75 0 3.359 0.695t2.773 1.859 1.852 2.773 0.688 3.359q0 2.344-1.164 4.344t-3.164 3.164-4.344 1.164-4.344-1.164-3.172-3.164-1.172-4.344zM11.844 15.375q0 2.438 1.711 4.164t4.148 1.727 4.164-1.727 1.727-4.164q0-2.406-1.727-4.109t-4.164-1.703q-2.422 0-4.141 1.703t-1.719 4.109zM16.281 28.328q0-0.594 0.414-1t1.008-0.406q0.609 0 1.016 0.406t0.406 1v3.313q0 0.609-0.414 1.031t-1.008 0.422-1.008-0.422-0.414-1.031v-3.313zM16.281 2.5v-3.406q0-0.578 0.422-1t1-0.422 1 0.422 0.422 1v3.406q0 0.578-0.414 0.961t-1.008 0.383-1.008-0.383-0.414-0.961zM25.484 24.469q0-0.578 0.375-0.938 0.375-0.391 0.938-0.391 0.609 0 1 0.391l2.438 2.375q0.406 0.438 0.406 1.016t-0.406 0.984q-1 0.781-2 0l-2.375-2.375q-0.375-0.422-0.375-1.063zM25.484 6.266q0-0.625 0.375-0.984l2.375-2.453q0.438-0.406 0.984-0.406 0.594 0 1.008 0.422t0.414 1q0 0.625-0.406 1.031l-2.438 2.375q-0.453 0.406-1 0.406-0.563 0-0.938-0.398t-0.375-0.992zM29.25 15.375q0-0.594 0.406-1.031 0.406-0.406 0.953-0.406h3.375q0.578 0 1.008 0.43t0.43 1.008-0.43 1-1.008 0.422h-3.375q-0.578 0-0.969-0.414t-0.391-1.008z',
      temperature: { min: '20', max: '29' },
      // wind: "14"
    },
    {
      sunrise: new Date('2021 Aug 03 05:23'),
      sunset: new Date('2021 Aug 03 20:39'),
      date: '03 Aug',
      description: 'clear sky',
      // humidity: 53
      icon:
        'M0 15.375q0-0.609 0.422-1.031 0.438-0.406 1-0.406h3.406q0.578 0 0.961 0.422t0.383 1.016-0.383 1.008-0.961 0.414h-3.406q-0.578 0-1-0.422t-0.422-1zM4.766 26.922q0-0.578 0.391-1.016l2.453-2.375q0.375-0.391 0.984-0.391 0.594 0 0.992 0.375t0.398 0.953q0 0.609-0.406 1.063l-2.375 2.375q-1.016 0.797-2.047 0-0.391-0.422-0.391-0.984zM4.766 3.844q0-0.578 0.391-1.016 0.484-0.406 1.063-0.406 0.547 0 0.984 0.406l2.375 2.453q0.406 0.375 0.406 0.984 0 0.594-0.398 0.992t-0.992 0.398q-0.609 0-0.984-0.406l-2.453-2.375q-0.391-0.422-0.391-1.031zM9.016 15.375q0-2.328 1.172-4.336t3.18-3.18 4.336-1.172q1.75 0 3.359 0.695t2.773 1.859 1.852 2.773 0.688 3.359q0 2.344-1.164 4.344t-3.164 3.164-4.344 1.164-4.344-1.164-3.172-3.164-1.172-4.344zM11.844 15.375q0 2.438 1.711 4.164t4.148 1.727 4.164-1.727 1.727-4.164q0-2.406-1.727-4.109t-4.164-1.703q-2.422 0-4.141 1.703t-1.719 4.109zM16.281 28.328q0-0.594 0.414-1t1.008-0.406q0.609 0 1.016 0.406t0.406 1v3.313q0 0.609-0.414 1.031t-1.008 0.422-1.008-0.422-0.414-1.031v-3.313zM16.281 2.5v-3.406q0-0.578 0.422-1t1-0.422 1 0.422 0.422 1v3.406q0 0.578-0.414 0.961t-1.008 0.383-1.008-0.383-0.414-0.961zM25.484 24.469q0-0.578 0.375-0.938 0.375-0.391 0.938-0.391 0.609 0 1 0.391l2.438 2.375q0.406 0.438 0.406 1.016t-0.406 0.984q-1 0.781-2 0l-2.375-2.375q-0.375-0.422-0.375-1.063zM25.484 6.266q0-0.625 0.375-0.984l2.375-2.453q0.438-0.406 0.984-0.406 0.594 0 1.008 0.422t0.414 1q0 0.625-0.406 1.031l-2.438 2.375q-0.453 0.406-1 0.406-0.563 0-0.938-0.398t-0.375-0.992zM29.25 15.375q0-0.594 0.406-1.031 0.406-0.406 0.953-0.406h3.375q0.578 0 1.008 0.43t0.43 1.008-0.43 1-1.008 0.422h-3.375q-0.578 0-0.969-0.414t-0.391-1.008z',
      temperature: { min: '15', max: '29' },
    },
    {
      sunrise: new Date('2021 Aug 04 05:25'),
      sunset: new Date('2021 Aug 04 20:37'),
      date: '04 Aug',
      description: 'clear sky',
      // humidity: 53
      icon:
        'M0 15.375q0-0.609 0.422-1.031 0.438-0.406 1-0.406h3.406q0.578 0 0.961 0.422t0.383 1.016-0.383 1.008-0.961 0.414h-3.406q-0.578 0-1-0.422t-0.422-1zM4.766 26.922q0-0.578 0.391-1.016l2.453-2.375q0.375-0.391 0.984-0.391 0.594 0 0.992 0.375t0.398 0.953q0 0.609-0.406 1.063l-2.375 2.375q-1.016 0.797-2.047 0-0.391-0.422-0.391-0.984zM4.766 3.844q0-0.578 0.391-1.016 0.484-0.406 1.063-0.406 0.547 0 0.984 0.406l2.375 2.453q0.406 0.375 0.406 0.984 0 0.594-0.398 0.992t-0.992 0.398q-0.609 0-0.984-0.406l-2.453-2.375q-0.391-0.422-0.391-1.031zM9.016 15.375q0-2.328 1.172-4.336t3.18-3.18 4.336-1.172q1.75 0 3.359 0.695t2.773 1.859 1.852 2.773 0.688 3.359q0 2.344-1.164 4.344t-3.164 3.164-4.344 1.164-4.344-1.164-3.172-3.164-1.172-4.344zM11.844 15.375q0 2.438 1.711 4.164t4.148 1.727 4.164-1.727 1.727-4.164q0-2.406-1.727-4.109t-4.164-1.703q-2.422 0-4.141 1.703t-1.719 4.109zM16.281 28.328q0-0.594 0.414-1t1.008-0.406q0.609 0 1.016 0.406t0.406 1v3.313q0 0.609-0.414 1.031t-1.008 0.422-1.008-0.422-0.414-1.031v-3.313zM16.281 2.5v-3.406q0-0.578 0.422-1t1-0.422 1 0.422 0.422 1v3.406q0 0.578-0.414 0.961t-1.008 0.383-1.008-0.383-0.414-0.961zM25.484 24.469q0-0.578 0.375-0.938 0.375-0.391 0.938-0.391 0.609 0 1 0.391l2.438 2.375q0.406 0.438 0.406 1.016t-0.406 0.984q-1 0.781-2 0l-2.375-2.375q-0.375-0.422-0.375-1.063zM25.484 6.266q0-0.625 0.375-0.984l2.375-2.453q0.438-0.406 0.984-0.406 0.594 0 1.008 0.422t0.414 1q0 0.625-0.406 1.031l-2.438 2.375q-0.453 0.406-1 0.406-0.563 0-0.938-0.398t-0.375-0.992zM29.25 15.375q0-0.594 0.406-1.031 0.406-0.406 0.953-0.406h3.375q0.578 0 1.008 0.43t0.43 1.008-0.43 1-1.008 0.422h-3.375q-0.578 0-0.969-0.414t-0.391-1.008z',
      temperature: { min: '16', max: '29' },
    },
    {
      sunrise: new Date('2021 Aug 05 05:26'),
      sunset: new Date('2021 Aug 05 20:35'),
      date: '05 Aug',
      description: 'clear sky',
      // humidity: 53
      icon:
        'M0 15.375q0-0.609 0.422-1.031 0.438-0.406 1-0.406h3.406q0.578 0 0.961 0.422t0.383 1.016-0.383 1.008-0.961 0.414h-3.406q-0.578 0-1-0.422t-0.422-1zM4.766 26.922q0-0.578 0.391-1.016l2.453-2.375q0.375-0.391 0.984-0.391 0.594 0 0.992 0.375t0.398 0.953q0 0.609-0.406 1.063l-2.375 2.375q-1.016 0.797-2.047 0-0.391-0.422-0.391-0.984zM4.766 3.844q0-0.578 0.391-1.016 0.484-0.406 1.063-0.406 0.547 0 0.984 0.406l2.375 2.453q0.406 0.375 0.406 0.984 0 0.594-0.398 0.992t-0.992 0.398q-0.609 0-0.984-0.406l-2.453-2.375q-0.391-0.422-0.391-1.031zM9.016 15.375q0-2.328 1.172-4.336t3.18-3.18 4.336-1.172q1.75 0 3.359 0.695t2.773 1.859 1.852 2.773 0.688 3.359q0 2.344-1.164 4.344t-3.164 3.164-4.344 1.164-4.344-1.164-3.172-3.164-1.172-4.344zM11.844 15.375q0 2.438 1.711 4.164t4.148 1.727 4.164-1.727 1.727-4.164q0-2.406-1.727-4.109t-4.164-1.703q-2.422 0-4.141 1.703t-1.719 4.109zM16.281 28.328q0-0.594 0.414-1t1.008-0.406q0.609 0 1.016 0.406t0.406 1v3.313q0 0.609-0.414 1.031t-1.008 0.422-1.008-0.422-0.414-1.031v-3.313zM16.281 2.5v-3.406q0-0.578 0.422-1t1-0.422 1 0.422 0.422 1v3.406q0 0.578-0.414 0.961t-1.008 0.383-1.008-0.383-0.414-0.961zM25.484 24.469q0-0.578 0.375-0.938 0.375-0.391 0.938-0.391 0.609 0 1 0.391l2.438 2.375q0.406 0.438 0.406 1.016t-0.406 0.984q-1 0.781-2 0l-2.375-2.375q-0.375-0.422-0.375-1.063zM25.484 6.266q0-0.625 0.375-0.984l2.375-2.453q0.438-0.406 0.984-0.406 0.594 0 1.008 0.422t0.414 1q0 0.625-0.406 1.031l-2.438 2.375q-0.453 0.406-1 0.406-0.563 0-0.938-0.398t-0.375-0.992zM29.25 15.375q0-0.594 0.406-1.031 0.406-0.406 0.953-0.406h3.375q0.578 0 1.008 0.43t0.43 1.008-0.43 1-1.008 0.422h-3.375q-0.578 0-0.969-0.414t-0.391-1.008z',
      temperature: { min: '16', max: '26' },
    },
    {
      sunrise: new Date('2021 Aug 06 05:28'),
      sunset: new Date('2021 Aug 06 20:34'),
      date: '06 Aug',
      description: 'clear sky',
      // humidity: 53
      icon:
        'M0 15.375q0-0.609 0.422-1.031 0.438-0.406 1-0.406h3.406q0.578 0 0.961 0.422t0.383 1.016-0.383 1.008-0.961 0.414h-3.406q-0.578 0-1-0.422t-0.422-1zM4.766 26.922q0-0.578 0.391-1.016l2.453-2.375q0.375-0.391 0.984-0.391 0.594 0 0.992 0.375t0.398 0.953q0 0.609-0.406 1.063l-2.375 2.375q-1.016 0.797-2.047 0-0.391-0.422-0.391-0.984zM4.766 3.844q0-0.578 0.391-1.016 0.484-0.406 1.063-0.406 0.547 0 0.984 0.406l2.375 2.453q0.406 0.375 0.406 0.984 0 0.594-0.398 0.992t-0.992 0.398q-0.609 0-0.984-0.406l-2.453-2.375q-0.391-0.422-0.391-1.031zM9.016 15.375q0-2.328 1.172-4.336t3.18-3.18 4.336-1.172q1.75 0 3.359 0.695t2.773 1.859 1.852 2.773 0.688 3.359q0 2.344-1.164 4.344t-3.164 3.164-4.344 1.164-4.344-1.164-3.172-3.164-1.172-4.344zM11.844 15.375q0 2.438 1.711 4.164t4.148 1.727 4.164-1.727 1.727-4.164q0-2.406-1.727-4.109t-4.164-1.703q-2.422 0-4.141 1.703t-1.719 4.109zM16.281 28.328q0-0.594 0.414-1t1.008-0.406q0.609 0 1.016 0.406t0.406 1v3.313q0 0.609-0.414 1.031t-1.008 0.422-1.008-0.422-0.414-1.031v-3.313zM16.281 2.5v-3.406q0-0.578 0.422-1t1-0.422 1 0.422 0.422 1v3.406q0 0.578-0.414 0.961t-1.008 0.383-1.008-0.383-0.414-0.961zM25.484 24.469q0-0.578 0.375-0.938 0.375-0.391 0.938-0.391 0.609 0 1 0.391l2.438 2.375q0.406 0.438 0.406 1.016t-0.406 0.984q-1 0.781-2 0l-2.375-2.375q-0.375-0.422-0.375-1.063zM25.484 6.266q0-0.625 0.375-0.984l2.375-2.453q0.438-0.406 0.984-0.406 0.594 0 1.008 0.422t0.414 1q0 0.625-0.406 1.031l-2.438 2.375q-0.453 0.406-1 0.406-0.563 0-0.938-0.398t-0.375-0.992zM29.25 15.375q0-0.594 0.406-1.031 0.406-0.406 0.953-0.406h3.375q0.578 0 1.008 0.43t0.43 1.008-0.43 1-1.008 0.422h-3.375q-0.578 0-0.969-0.414t-0.391-1.008z',
      temperature: { min: '16', max: '28' },
    },
  ];
  const dayLightMinutes = differenceInMinutes(sunset, sunrise);
  const [devices, setDevices] = useState(defaultDevices);
  const [sliderValue, setSliderValue] = useState(defaultDay);
  const [day, setDay] = useState('all');
  const [showMarkers, setShowMarkers] = useState(true);
  // const days = uniq(
  //   canterburyData.filter((entry) => entry.period.length > 8).map((entry) => entry.period.substring(0, 8))
  // );

  const getPollinationLevel = ({ pollinationActivity }) => {
    if (pollinationActivity < 0.1) {
      return '< 0.1%';
    } else {
      return pollinationActivity + '%';
    }
  };

  const getAverage = (device, dayCount) => {
    return Number.parseFloat(((device.beeCount / 6 / dayCount / dayLightMinutes) * 100).toFixed(1));
  };

  const getDevicesData = ({ devices, rows, dayCount }) => {
    let newDevices = cloneDeep(devices);
    console.log('newDevices', newDevices, rows);

    for (let row of rows) {
      let deviceId = 'PNET_' + row.deviceId;
      // let deviceId = row.deviceId;
      // let weight = row.beeCount / dayCount / 10;
      let pollinationActivity = getAverage(row, dayCount);
      let device = newDevices.find((device) => device.deviceId === deviceId);
      device.weight = pollinationActivity;
      // console.log('pollinationActivity', pollinationActivity);
      device.pollinationActivity = getPollinationLevel({ pollinationActivity });
      device.beeCount = row.beeCount;
      device.dayLightMinutes = dayLightMinutes;
    }

    return newDevices;
  };

  useEffect(() => {
    let dayCount = 1;
    // if (!sliderValue) {
    //   dayCount = Object.keys(dayMap).length;
    // }
    const rows = getSummaryDataByDay({
      deviceIds,
      day: dayMap[sliderValue],
    });
    const newDevices = getDevicesData({ devices, rows, dayCount });
    setDevices(newDevices);
  }, [sliderValue]);

  const sliderOnChange = (e, value) => {
    setSliderValue(value);
    setDay(dayMap[value]);
    const rows = getSummaryDataByDay({
      deviceIds,
      day,
    });
    const newDevices = getDevicesData({ devices, rows, dayCount: 1 });
    setDevices(newDevices);
  };

  const date = sliderValue === undefined ? undefined : parse(dayMap[sliderValue], 'yyyyMMdd', new Date());
  const currentView = (
    <Typography variant="subtitle1">
      Current View: {sliderValue === undefined ? 'Total Pollination' : format(date, 'd MMM')}
    </Typography>
  );

  const rows = getSummaryData({ deviceIds: ['C7F', '474', 'F80', 'AE6', '3A0', '111', '6E1', 'D80'] });

  const getMinMax = (row) => {
    return [Number.parseFloat(row.minTemp), Number.parseFloat(row.maxTemp)];
  };

  let deviceIdsMinMax = {
    '3A0': getMinMax(rows.find((row) => row.deviceId === '3A0')),
    111: getMinMax(rows.find((row) => row.deviceId === '111')),
    '6E1': getMinMax(rows.find((row) => row.deviceId === '6E1')),
    D80: getMinMax(rows.find((row) => row.deviceId === 'D80')),
    AE6: getMinMax(rows.find((row) => row.deviceId === 'AE6')),
    F80: getMinMax(rows.find((row) => row.deviceId === 'F80')),
    474: getMinMax(rows.find((row) => row.deviceId === '474')),
    C7F: getMinMax(rows.find((row) => row.deviceId === 'C7F')),
  };

  const deviceIdsMinMaxMap = new Map();
  const deviceIds2 = ['3A0', '111', '6E1', 'D80', 'AE6', 'F80', '474', 'C7F'];
  for (let deviceId of deviceIds2) {
    deviceIdsMinMaxMap.set(deviceId, getMinMax(rows.find((row) => row.deviceId === deviceId)));
  }

  const dayCount = Object.keys(dayMap).length;
  // console.log('devices', devices);
  const pollinationActivitySeries = [
    // {
    //   name: `Total PA % (${dayCount} days)`,
    //   data: devices.map((device) => getAverage(device, 1)), //each bee count is counted as 10 seconds
    // },
    {
      name: 'Average PA % per day',
      data: devices.map((device) => getAverage(device, dayCount)), //each bee count is counted as 10 seconds
    },
    // {
    //   name: 'Daylight hours',
    //   data: devices.map((device) => device.dayLightMinutes / 60),
    // },
  ];

  const pollinationActivityCategories = devices.map((device) => device.deviceId.substring(5, 9));
  // const pollinationActivityCategories = devices.map((device) => device.deviceId);

  let options = cloneDeep(defaultStackedBarChartOptions);
  options.series = pollinationActivitySeries;
  options.xAxis.categories = pollinationActivityCategories;
  options.yAxis.title.text = 'Percentage';

  const lat = 39.1642631;
  const lng = 119.7726193;
  const weatherDataOverride = {
    forecast: weatherData,
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {/*<PollyTrialDescription />*/}
        {/*<Header title={'Canterbury Strawberry Trials Overview'} subtitle={'(2nd Aug to 6th Aug)'} />*/}
        <Header title={'Canterbury Trials Overview'} subtitle={'(2nd Aug to 6th Aug)'} />
        <Divider variant="middle" />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Paper elevation={globalElevation}>
          {<Weather lat={lat} lng={lng} dataOverride={weatherDataOverride} />}
        </Paper>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Widgets />
      </Grid>
      {/*<Grid item xs={12}>*/}
      {/*  <Header content={navigation} showContent={true} />*/}
      {/*</Grid>*/}
      <Grid item xs={12}>
        <Header
          content={
            <PollyHeatmap
              // centralDeviceId={'1'}
              centralDeviceId={'PNET_F80'}
              devices={devices}
              showMarkers={showMarkers}
              currentView={currentView}
              slider={<DaySlider onChange={sliderOnChange} sliderValue={sliderValue} />}
              height={'350px'}
              width={'100%'}
              // zoom={5}
              zoom={20}
              showTimxeline={false}
              showTotalPollinationActivity={true}
              center
            />
          }
          showContent={true}
        />
      </Grid>
      <Grid item xs={6}>
        <PollyStackedBarChart
          series={pollinationActivitySeries}
          categories={pollinationActivityCategories}
          initialOptions={options}
        />
      </Grid>
      <Grid item xs={6}>
        <PollyTemperatureComparison
          deviceIdsMinMax={deviceIdsMinMax}
          deviceIdsMinMaxMap={deviceIdsMinMaxMap}
        />
      </Grid>
      <Grid item xs={6} sm={6} m d={6} lg={6}>
        <PollyTempComparison
          dataName="temp"
          title={'Temperature Comparison'}
          height={640}
          min={0}
          max={40}
          labelSymbol={'°C'}
          devices={devices}
        />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <PollyTempComparison
          dataName="humidity"
          title={'Humidity Comparison'}
          height={640}
          min={40}
          max={100}
          labelSymbol={'%'}
        />
      </Grid>
      {/*<Grid item xs={12} sm={12} md={12} lg={12}>*/}
      {/*  <div style={{ fontWeight: 'bold' }}>Overview of entire trial</div>*/}
      {/*</Grid>*/}
      {/*<Grid item xs={12} sm={12} md={12} lg={12}>*/}
      {/*  <PollyOverviewTableGrid />*/}
      {/*</Grid>*/}
    </Grid>
  );
}

function Widgets() {
  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <PollyActivityWidget />
      </Grid>
      <Grid item xs={6}>
        <PollyDeviceHighLowWidget />
      </Grid>
    </Grid>
  );
}

function DaySlider({ onChange, sliderValue }) {
  const marks = [
    {
      value: 2,
      label: '2nd',
    },
    {
      value: 3,
      label: '3rd',
    },
    {
      value: 4,
      label: '4th',
    },
    {
      value: 5,
      label: '5th',
    },
    {
      value: 6,
      label: '6th',
    },
  ];

  const valuetext = (value) => {
    return `${value}`;
  };

  const valueLabelFormat = (value) => {
    return marks.findIndex((mark) => mark.value === value) + 1;
  };

  return (
    <Slider
      sx={{
        '& input[type="range"]': {
          WebkitAppearance: 'slider-vertical',
        },
      }}
      // orientation="vertical"
      aria-label="Restricted values"
      defaultValue={2}
      // valueLabelDisplay="on"
      valueLabelFormat={valueLabelFormat}
      getAriaValueText={valuetext}
      step={1}
      // valueLabelDisplay="auto"
      min={2}
      max={6}
      marks={marks}
      value={sliderValue}
      // onChange={(e, value) => console.log('slider', value)}
      onChange={onChange}
    />
  );
}

function PollyTrialDescription() {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="h5" style={{ marginBottom: '10px' }}>
          Canterbury Strawberry Trials (2nd Aug to 6th Aug)
        </Typography>
      </Grid>
    </Grid>
  );
}

function Header({ title, subtitle, content, showContent = false }) {
  return (
    <Card elevation={globalElevation}>
      {title && <CardHeader title={title} subheader={subtitle} />}
      {showContent && <CardContent>{content}</CardContent>}
    </Card>
  );
}

function PollyOverviewGrid() {
  return (
    <Grid container spacing={1}>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <PollyOverview deviceId={'C7F'} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <PollyOverview deviceId={'3A0'} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <PollyOverview deviceId={'474'} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <PollyOverview deviceId={'111'} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <PollyOverview deviceId={'F80'} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <PollyOverview deviceId={'6E1'} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <PollyOverview deviceId={'AE6'} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <PollyOverview deviceId={'D80'} />
      </Grid>
    </Grid>
  );
}

function PollyOverviewTableGrid() {
  const rows1 = getSummaryData({ deviceIds: ['C7F', '474', 'F80', 'AE6'] });
  const rows2 = getSummaryData({ deviceIds: ['3A0', '111', '6E1', 'D80'] });
  return (
    <Grid container spacing={1}>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <PollySummaryTable rows={rows1} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <PollySummaryTable rows={rows2} />
      </Grid>
    </Grid>
  );
}

function PollyDayOverviewTableGrid() {
  const dayFilters = ['20210802', '20210803', '20210804', '20210805', '20210806'];
  let rows1 = [];
  let rows2 = [];
  let rows3 = [];
  let rows4 = [];
  let rows5 = [];
  let rows6 = [];
  let rows7 = [];
  let rows8 = [];
  for (let dayFilter of dayFilters) {
    const rows1Day = getSummaryDataByDay({ deviceIds: ['C7F'], dayFilter });
    const rows2Day = getSummaryDataByDay({ deviceIds: ['3A0'], dayFilter });
    const rows3Day = getSummaryDataByDay({ deviceIds: ['474'], dayFilter });
    const rows4Day = getSummaryDataByDay({ deviceIds: ['111'], dayFilter });
    const rows5Day = getSummaryDataByDay({ deviceIds: ['F80'], dayFilter });
    const rows6Day = getSummaryDataByDay({ deviceIds: ['6E1'], dayFilter });
    const rows7Day = getSummaryDataByDay({ deviceIds: ['AE6'], dayFilter });
    const rows8Day = getSummaryDataByDay({ deviceIds: ['D80'], dayFilter });
    rows1 = rows1.concat(rows1Day);
    rows2 = rows2.concat(rows2Day);
    rows3 = rows3.concat(rows3Day);
    rows4 = rows4.concat(rows4Day);
    rows5 = rows5.concat(rows5Day);
    rows6 = rows6.concat(rows6Day);
    rows7 = rows7.concat(rows7Day);
    rows8 = rows8.concat(rows8Day);
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <PollySummaryTable rows={rows1} showDay={true} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <PollySummaryTable rows={rows2} showDay={true} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <PollySummaryTable rows={rows3} showDay={true} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <PollySummaryTable rows={rows4} showDay={true} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <PollySummaryTable rows={rows5} showDay={true} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <PollySummaryTable rows={rows6} showDay={true} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <PollySummaryTable rows={rows7} showDay={true} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <PollySummaryTable rows={rows8} showDay={true} />
      </Grid>
    </Grid>
  );
}

function getSummaryData({ deviceIds }) {
  let data = [];
  for (let deviceId of deviceIds) {
    const deviceData = getSummaryDataByDeviceId({ deviceId });
    data.push(deviceData);
  }
  return data;
}

function getSummaryDataByDay({ deviceIds, day }) {
  let data = [];
  for (let deviceId of deviceIds) {
    let deviceData = [];
    if (day === 'all' || !day) {
      deviceData = getSummaryDataByDeviceId({ deviceId });
    } else {
      deviceData = getSummaryDataByDeviceId({ deviceId, dayFilter: day });
    }

    data.push(deviceData);
  }
  return data;
}

function getSummaryDataByDeviceId({ deviceId, dayFilter = undefined }) {
  let pollyData = cloneDeep(canterburyData);
  if (dayFilter) {
    pollyData = pollyData.filter(
      (entry) =>
        entry.deviceId === deviceId &&
        entry.period !== '-' &&
        entry.period.substring(0, 4) !== '1990' &&
        entry.period.substring(0, 8) === dayFilter
    );
  } else {
    pollyData = pollyData.filter(
      (entry) =>
        entry.deviceId === deviceId && entry.period !== '-' && entry.period.substring(0, 4) !== '1990'
    );
  }

  pollyData = pollyData.sort((a, b) => b.period - a.period);
  const tempData = pollyData.filter((entry) => entry.temp && !isNaN(entry.temp)).map((entry) => entry.temp);
  const humidityData = pollyData
    .filter((entry) => entry.humidity && !isNaN(entry.humidity))
    .map((entry) => entry.humidity);
  const beeCount = sumBy(pollyData, 'count');
  const minTemp = min(tempData);
  const maxTemp = max(tempData);
  const minHumidity = min(humidityData);
  const maxHumidity = max(humidityData);
  return {
    id: deviceId,
    deviceId,
    day: dayFilter,
    beeCount,
    minTemp: !isNaN(minTemp) && minTemp.toFixed(1),
    maxTemp: !isNaN(maxTemp) && maxTemp.toFixed(1),
    minHumidity: !isNaN(minHumidity) && minHumidity.toFixed(1),
    maxHumidity: !isNaN(maxHumidity) && maxHumidity.toFixed(1),
  };
}

function PollyOverviewTable({ rows = [] }) {
  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        hideFooter={true}
        // pageSize={5}
        // checkboxSelection
        // disableSelectionOnClick
      />
    </div>
  );
}

function PollyOverview({ deviceId = '111' }) {
  let singlePollyData = cloneDeep(canterburyData);
  // let options = cloneDeep(options2);
  singlePollyData = singlePollyData.filter(
    (entry) => entry.deviceId === deviceId && entry.period !== '-' && entry.period.substring(0, 4) !== '1990'
  );
  singlePollyData = singlePollyData.sort((a, b) => b.period - a.period);
  const categories = singlePollyData.map((entry) => entry.period.replace('202108', 'Aug'));
  const beeCount = singlePollyData.map((entry) => entry.count);
  const temp = singlePollyData.map((entry) => entry.temp);
  const totalBeeCount = sumBy(singlePollyData, 'count');
  return (
    <div>
      {deviceId}: {totalBeeCount}
    </div>
  );
}

function PollyTempComparison({ title, dataName, height, min, max, labelSymbol, devices }) {
  // console.log('PollyTempComparison', devices);
  let singlePollyData = cloneDeep(canterburyData);
  let options = cloneDeep(options2);
  singlePollyData = singlePollyData.filter(
    (entry) => entry.deviceId === '111' && entry.period !== '-' && entry.period.substring(0, 4) !== '1990'
  );
  singlePollyData = singlePollyData.sort((a, b) => b.period - a.period);
  const categories = singlePollyData.map((entry) => entry.period.replace('202108', 'Aug'));
  const xAxis = [
    {
      categories,
      crosshair: true,
    },
  ];

  let series = [];
  for (let device of defaultDevices) {
    const deviceId = device.deviceId.substring(5, 8);
    const seriesData = getSeriesData({ deviceId, dataName });
    const seriesEntry = {
      name: deviceId,
      type: 'line',
      data: seriesData,
      tooltip: {
        valueSuffix: labelSymbol,
      },
      marker: {
        enabled: false,
      },
    };
    series.push(seriesEntry);
  }

  const yAxis = [
    {
      // Primary yAxis
      max,
      min,
      labels: {
        format: `{value}${labelSymbol}`,
        // style: {
        //   color: Highcharts.getOptions().colors[1],
        // },
      },
      title: {
        text: title,
        style: {
          color: Highcharts.getOptions().colors[1],
        },
      },
    },
  ];

  options.yAxis = yAxis;
  options.xAxis = xAxis;
  options.series = series;
  options.title.text = title;
  options.chart.height = height;

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}

function getSeriesData({ deviceId, dataName }) {
  let singlePollyData = cloneDeep(canterburyData);
  // let options = cloneDeep(options2);
  singlePollyData = singlePollyData.filter(
    (entry) => entry.deviceId === deviceId && entry.period !== '-' && entry.period.substring(0, 4) !== '1990'
  );
  singlePollyData = singlePollyData.sort((a, b) => b.period - a.period);
  // const categories = singlePollyData.map((entry) => entry.period.replace('202108', 'Aug'));
  // const beeCount = singlePollyData.map((entry) => entry.count);
  const temp = singlePollyData.map((entry) => entry[dataName]);
  return temp;
}

function PollyChartLayoutGrid() {
  return (
    <Grid container spacing={1}>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <SinglePollyChart deviceId={'C7F'} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <SinglePollyChart deviceId={'3A0'} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <SinglePollyChart deviceId={'474'} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <SinglePollyChart deviceId={'111'} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <SinglePollyChart deviceId={'F80'} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <SinglePollyChart deviceId={'6E1'} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <SinglePollyChart deviceId={'AE6'} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <SinglePollyChart deviceId={'D80'} />
      </Grid>
    </Grid>
  );
}

function SinglePollyChart({ deviceId }) {
  let singlePollyData = cloneDeep(canterburyData);
  let options = cloneDeep(options2);
  singlePollyData = singlePollyData.filter(
    (entry) => entry.deviceId === deviceId && entry.period !== '-' && entry.period.substring(0, 4) !== '1990'
  );
  singlePollyData = singlePollyData.sort((a, b) => b.period - a.period);
  const categories = singlePollyData.map((entry) => entry.period.replace('202108', 'Aug'));
  const beeCount = singlePollyData.map((entry) => entry.count);
  const temp = singlePollyData.map((entry) => entry.temp);
  // console.log('PollyCanterbury.data', singlePollyData);
  const xAxis = [
    {
      // categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      categories,
      crosshair: true,
    },
  ];

  const series = [
    {
      name: 'Bee Count',
      type: 'column',
      yAxis: 1,
      data: beeCount,
      tooltip: {
        valueSuffix: ' bee count',
      },
    },
    {
      name: 'Temperature',
      type: 'spline',
      data: temp,
      tooltip: {
        valueSuffix: '°C',
      },
    },
  ];

  const yAxis = [
    {
      // Primary yAxis
      max: 40,
      min: 0,
      labels: {
        format: '{value}°C',
        style: {
          color: Highcharts.getOptions().colors[1],
        },
      },
      title: {
        text: 'Temperature',
        style: {
          color: Highcharts.getOptions().colors[1],
        },
      },
    },
    {
      // Secondary yAxis
      title: {
        text: 'Bee count',
        style: {
          color: Highcharts.getOptions().colors[0],
        },
      },
      labels: {
        format: '{value}',
        style: {
          color: Highcharts.getOptions().colors[0],
        },
      },
      opposite: true,
    },
  ];

  options.yAxis = yAxis;
  options.xAxis = xAxis;
  options.series = series;
  options.title.text = deviceId;

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}
