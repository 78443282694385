import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';

export let defaultStackedBarChartOptions = {
  chart: {
    type: 'bar',
    style: {
      fontFamily: 'Helvetica, sans-serif',
      fontSize: '12px',
    },
  },
  colors: [
    '#ff9933',
    '#3399ff',
  ],
  title: {
    text: 'Pollinator Activity (PA)',
    align: 'left'
  },
  xAxis: {
    // categories: ['Apples', 'Oranges', 'Pears', 'Grapes', 'Bananas'],
    categories: ['3A0', '111', '6E1', 'D80', 'AE6', 'F80', '474', 'C7F'],
  },
  yAxis: {
    min: 0,
    title: {
      text: 'Hours',
    },
    stackLabels: {
      enabled: true,
      style: {
        fontWeight: 'bold',
        color:
          // theme
          (Highcharts.defaultOptions.title.style && Highcharts.defaultOptions.title.style.color) || 'gray',
      },
    },
  },
  legend: {
    align: 'right',
    x: 0,
    verticalAlign: 'top',
    y: -5,
    floating: true,
    backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || 'white',
    borderColor: '#CCC',
    borderWidth: 1,
    shadow: false,
  },
  tooltip: {
    headerFormat: '<b>{point.x}</b><br/>',
    pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
  },
  plotOptions: {
    column: {
      stacking: 'percent',
    },
  },
  series: [
    {
      name: 'Pollination Activity',
      // data: [5, 3, 4, 7, 2],
      data: [
        5.36,
        12.18,
        1.42,
        10.9,
        1.96,
        2.82,
        16.93,
        7.22,
      ],
    },
    {
      name: 'Daylight hours',
      // data: [2, 2, 3, 2, 1],
      data: [15.2, 15.2, 15.2, 15.2, 15.2, 15.2, 15.2, 15.2],
    },
    // {
    //   name: 'Joe',
    //   data: [3, 4, 4, 2, 5],
    // },
  ],
};

export default function PollyStackedBarChart({ series, categories, initialOptions }) {
  const [options, setOptions] = useState(initialOptions);
  // const chartRef = useRef(null);
  // options.series[0].data = series[0].data;
  // options.xAxis.categories = categories;
  // console.log('options', initialOptions);

  // useEffect(() => {
  //   let newOptions = cloneDeep(options);
  //   newOptions.series = series;
  //   newOptions.categories = categories;
  //   // setOptions({ ...newOptions, ...options });
  //   // setOptions(newOptions);
  // }, []);

  return (
    <HighchartsReact
      // ref={chartRef}
      highcharts={Highcharts}
      options={initialOptions}
      // containerProps={{ style: { width: '100%' } }}
    />
  );
}
