import React from 'react';
import { log } from '../../../util/log-util';
import { DataGrid } from '@material-ui/data-grid';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { addIdToTableData, getTableData } from '../../../data/data-util';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { getProjectIds, isProjectAdmin } from '../../../config/env';
import { getAllDevicesQuery } from '../../../api/queryConfig';
import { convertDateWithTimezone } from '../../../util/date-util';
import { deviceTypesConfig } from '../../../config/deviceTypes';
import { accountTypes } from '../../../config/accountTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));
export default function DeviceManagement({ tableData }) {
  const classes = useStyles();
  // const limit = useSelector((state) => state.appConfig.limit);
  const userData = useSelector((state) => state.appConfig.userData);
  const project = useSelector((state) => state.appConfig.currentProject);
  const isAdmin = isProjectAdmin(userData);
  let projectIds = getProjectIds(userData);
  const projectId = projectIds[0];
  const storeAs = `${projectId}-devices`;
  const devices = useSelector(({ firestore: { data } }) => data[storeAs]);
  const devicesQuery = getAllDevicesQuery({ storeAs, projectIds });
  useFirestoreConnect(devicesQuery);

  if (!isLoaded(devices)) {
    return 'Loading...';
  }

  let devicesData = (devices && getTableData(devices)) || [];
  log('DeviceManagement', { projectIds, devices, devicesData });

  return (
    <Grid justify={'flex-start'} container spacing={2}>
      <Grid item xs={12}>
        <DevicesTable tableData={devicesData} project={project} />
      </Grid>
    </Grid>
  );
}

function DevicesTable({ tableData, project }) {
  const classes = useStyles();
  const theme = useTheme();
  const isPollyProject = project.accountType === accountTypes.polly;
  // const screenExtraLarge = useMediaQuery(theme.breakpoints.only('xl'));
  // const screenLarge = useMediaQuery(theme.breakpoints.only('lg'));
  // const screenMedium = useMediaQuery(theme.breakpoints.only('md'));
  // const screenSmall = useMediaQuery(theme.breakpoints.only('sm'));
  // const screenExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));
  // const screenNarrow = useMediaQuery('(max-width:340px)');
  const screenSmallOrLess = useMediaQuery(theme.breakpoints.down('sm'));
  addIdToTableData(tableData);
  // const limit = useSelector((state) => state.appConfig.limit);
  // const dispatch = useDispatch();
  // // console.log('EventsTable', data);
  // let tableData = (data && getTableData(data)) || [];
  // console.log('EventsTable', tableData);
  return (
    <div style={{ height: 1000, width: '100%' }}>
      <form className={classes.root} noValidate autoComplete="off"></form>
      <DataGrid
        rows={tableData}
        columns={getColumns({ downValue: screenSmallOrLess, timezone: project.timezone, isPollyProject })}
        pageSize={50}
      />
    </div>
  );
}

function getColumns({ downValue, timezone = 'Europe/London', isPollyProject = false }) {
  return [
    {
      field: 'deviceType',
      headerName: 'Device Type',
      width: 200,
      hide: downValue,
      renderCell: (params) => {
        return deviceTypesConfig[params.row.deviceType];
      },
    },
    {
      field: 'deviceName',
      headerName: 'Device Name',
      width: 200,
      // hide: !screenLarge,
      // flex: 2,
      // renderCell: (params) => {
      //   // return <Hidden>{params.getValue('deviceName')}</Hidden>;
      //   return 'View';
      // },
    },
    { field: 'deviceId', headerName: 'Device ID', width: 170, flex: 0, hide: downValue },
    { field: 'ip', headerName: 'IP', width: 150, flex: 0, hide: downValue },
    // { field: 'sensorName', headerName: 'Sensor Name', width: 150 },
    {
      field: 'lastActivity',
      headerName: 'Last Activity',
      width: 150,
      type: 'date',
      flex: 0,
      // hide: downValue,
      renderCell: (params) => {
        const latestEvent = params.row.latestEvent;
        const lastActivityDateCreated =
          latestEvent && latestEvent.dateCreated && latestEvent.dateCreated.toDate();
        let lastActivityFormatted = convertDateWithTimezone(lastActivityDateCreated, timezone).format(
          'DD MMM HH:mm'
        );
        return lastActivityDateCreated && <div>{lastActivityFormatted}</div>;
      },
    },
    {
      field: 'view',
      headerName: 'View',
      width: 100,
      renderCell: (params) => {
        return (
          <RouterLink
            to={
              isPollyProject
                ? `/polly-devices-edit/${params.row.deviceId}`
                : `/devices/${params.row.deviceId}`
            }
          >
            View
          </RouterLink>
        );
      },
    },
  ];
}
