import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import PollyActivityWidget from '../../beekeeper/Widgets/PollyActivityWidget';
import PollyDeviceHighLowWidget from '../../beekeeper/Widgets/PollyDeviceHighLowWidget';
import PollyPageTitle from '../Shared/PollyPageTitle';
import { CustomSelect } from '../Shared/Dropdown';
import { getFilterByPossibleValues, uniqObject } from '../Filter/filterUtil';
import PollyAutocomplete from '../Filter/PollyAutocomplete';
import Box from '@material-ui/core/Box';
import { useSelector } from 'react-redux';
import { getProjectIds, getSelectedSiteName } from '../../../config/env';
import { getDevicesQuery, getDevicesStoreAs, getProjectQuery } from '../../../api/queryConfig';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { DeviceCards } from '../Devices/PollyDevices2';
import { flatten } from '../tree/treeUtil';
import { getAverage, getDeviceStats, statNames, statsConfig } from '../../beekeeper/Widgets/stats-util';
import { uniq, cloneDeep } from 'lodash';
import { Accordion, AccordionDetails, AccordionSummary, Card, Switch } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import MuiAlert from '@material-ui/lab/Alert';
import { CustomMapComponent } from '../Shared/CustomMapComponent';
import { isEqual } from 'lodash';
import ToggleButton from '@material-ui/lab/ToggleButton';
import CardHeader from '@material-ui/core/CardHeader';
import { cloneAndInitialise, getDeviceMapInfo, hasLocation, hasNoLocation } from '../util/devicesUtil';
import MapControl from '../Maps/MapControl';
import PollyMapBasic from '../Maps/PollyMapBasic';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { updateSelectedSiteName } from '../../../api/updateUser';
import { getSiteOptionsFromProject } from '../util/hierarchyUtil';
import PollyDevicesHealthTable from './PollyDevicesHealthTable';
import PollyDevicesHeatmap from '../Maps/PollyDevicesHeatmap';
import DownloadFile from '../../beekeeper/Download/DownloadFile';
const periodOptions = [
  { label: 'Last 7 days', value: '7days' },
  { label: 'Last 30 days', value: '30days' },
  { label: 'Last 90 days', value: '90days' },
  { label: '1 year', value: '1year' },
];

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: '100%',
    // width: '500px'
  },
  hiveGrid: {
    flexGrow: 1,
  },
  hivePaper: {
    // height: 150,
    width: 250,
    fontSize: 12,
  },
  paper: {
    padding: theme.spacing(3),
    // color: theme.palette.text.secondary,
    backgroundColor: 'white',
  },
  test: {
    fontFamily: 'inherit',
  },
  deviceCount: {
    height: '55px',
  },
  stats: {
    height: '100%',
  },
  mapControl: {
    marginTop: '10px',
  },
}));

const dataStatNames = [
  statNames.dataIsCurrent,
  statNames.noData,
  statNames._1DayOld,
  statNames._2DaysOld,
  statNames._3To7DaysOld,
  statNames._8To14DaysOld,
  statNames._Over14DaysOld,
];

const otherStatNames = [
  statNames.hasRebooted,
  statNames.lowRssi,
  statNames.lowBattery,
  statNames.sdCard80Percent,
  statNames.sdCard50To80Percent,
  statNames.sdCard20To50Percent,
  statNames.sdCardBelow20,
];

export default function PollySitesOverview({ businessName = 'Innocent' }) {
  const classes = useStyles();
  const [period, setPeriod] = useState('30days');
  const [filterBy, setFilterBy] = useState();
  // const [siteName, setSiteName] = useState();
  const [filterByOptions, setFilterByOptions] = useState();
  const [versionOptions, setVersionOptions] = useState();
  const [version, setVersion] = useState();
  const [devices, setDevices] = useState([]);
  const [showTrends, setShowTrends] = useState(false);
  const [mapHeight, setMapHeight] = useState('0px');
  const [sites, setSites] = useState([]);
  const userData = useSelector((state) => state.appConfig.userData);
  const user = useSelector((state) => state.appConfig.user);
  const userId = user && user.uid;
  let projectIds = getProjectIds(userData);
  const projectId = projectIds[0];
  // const hierarchyTree = JSON.parse(project.hierarchy);
  const selectedSiteName = getSelectedSiteName(userData);
  const storeAsDefault = `devices-${selectedSiteName}`;//getDevicesStoreAs();
  const [storeAs, setStoreAs] = useState(storeAsDefault);
  const [siteName, setSiteName] = useState(selectedSiteName);
  const isAgriSoundOnlyProject = projectId.includes('polly-test');
  const devicesQuery = getDevicesQuery({ projectIds, storeAs, siteName, isAgriSoundOnlyProject });
  const projectQuery = getProjectQuery({ projectId });
  // const deviceQuery = getDevicesByIdQuery({ deviceId: 'PNET_BED', storeAs });
  const devicesData = useSelector(({ firestore: { data } }) => data[storeAs]);
  const projectData = useSelector(({ firestore: { data } }) => data.projects && data.projects[projectId]);

  const handleSiteNameChangeAutocomplete = (event, value) => {
    const selectedSiteName = value.value;
    // console.log('handleProjectIdChangeAutocomplete', newSelectedProjectId);
    if (selectedSiteName) {
      updateSelectedSiteName({ userId, selectedSiteName })
        .then((success) => {
          setSiteName(selectedSiteName);
          const storeAsDefault = `devices-${selectedSiteName}`;
          setStoreAs(storeAsDefault);
        })
        .catch((err) => console.log('handleSiteNameChangeAutocomplete.err', err));
    }
  };

  const getVersionOptions = (devices) => {
    const versions = uniq(
      devices.filter((device) => device.version !== undefined).map((device) => device.version)
    );
    return versions.map((version) => {
      return { label: version, value: version };
    });
  };

  const getSites = (devices) => {
    return uniqObject(
      devices
        .filter((device) => device.siteName)
        .map((device) => {
          return {
            siteId: device.siteName,
            siteName: device.siteName,
          };
        }),
      'site'
    );
  };

  const filterDevices = ({ devices, version, filterBy }) => {
    return devices.filter((device) => {
      if (version && filterBy) {
        return device.siteName === filterBy && device.version === version;
      } else if (!version && filterBy) {
        return device.siteName === filterBy;
      } else if (version && !filterBy) {
        return device.version === version;
      } else if (!version && !filterBy) {
        return true;
      }
    });
  };

  useEffect(() => {
    let devices = cloneAndInitialise({ devicesData });
    const sites = getSites(devices);
    setSites(sites);
    const filterByOptions = getFilterByPossibleValues({ option: 'siteName', sites });
    setFilterByOptions(filterByOptions);
    let filteredDevices = filterDevices({ devices, version, filterBy });
    if (filterBy) {
      const versionsOptions = getVersionOptions(filteredDevices);
      setVersionOptions(versionsOptions);
    } else {
      setVersionOptions(undefined);
    }

    setDevices(filteredDevices);
  }, [filterBy, version, devicesData]);

  useFirestoreConnect([devicesQuery, projectQuery]);

  if (!isLoaded(devicesData, projectData)) {
    return 'Loading...';
  }

  // const hierarchyTree = JSON.parse(projectData.hierarchy);
  // const siteOptions = flatten(hierarchyTree)
  //   .filter((item) => item.level === 'Site')
  //   .map((item) => item.name);

  const getSites2 = (siteNames) => {
    return siteNames
      .map((siteName) => {
        return {
          label: siteName,
          value: siteName,
        };
      });
  };
  const siteOptions = getSiteOptionsFromProject(projectData);
  const hierarchySiteNameOptions = getSites2(siteOptions);

  // const sites = uniqObject(sitesWeekly, 'site');
  //TODO: set the widgetData from the sitesWeekly.json data and filter when SitesSelect is used
  const periodText = periodOptions.find((p) => p.value === period).label;
  // console.log('filterBy', filterBy);
  let devicesNoLocation = devices.filter((device) => hasNoLocation(device));
  let devicesWithLocation = devices
    .filter((device) => hasLocation(device))
    .map((device) => {
      const dev = cloneDeep(device);
      dev.infoText = getDeviceMapInfo(device);
      return dev;
    });

  if (filterBy) {
    devicesNoLocation = devicesNoLocation.filter((device) => device.siteName === filterBy);
    devicesWithLocation = devicesWithLocation.filter((device) => device.siteName === filterBy);
  }

  const dataLastUpdatedTitle = filterBy ? `Data last updated (${filterBy})` : `Data last updated (All sites)`;
  const otherStatsTitle = filterBy ? `Device stats (${filterBy})` : `Device stats`;

  return (
    <Grid container spacing={1}>
      <Grid item xs={3}>
        <PollyPageTitle title={`Sites Overview`} subtitle={businessName} />
      </Grid>
      {filterBy && devices.length > 0 && (
        <Grid item xs={9}>
          <LocationStatus count={devicesNoLocation.length} devices={devicesNoLocation} />
        </Grid>
      )}
      {/*<Grid item xs={12} sm={12} md={12} lg={12}>*/}
      {/*  <SitesSelect*/}
      {/*    handleSiteNameChangeAutocomplete={handleSiteNameChangeAutocomplete}*/}
      {/*    sites={sites}*/}
      {/*    period={period}*/}
      {/*    setPeriod={setPeriod}*/}
      {/*    filterBy={siteName}*/}
      {/*    filterByOptions={hierarchySiteNameOptions}*/}
      {/*    setFilterBy={setFilterBy}*/}
      {/*    version={version}*/}
      {/*    versionsOptions={versionOptions}*/}
      {/*    setVersion={setVersion}*/}
      {/*    devices={devices}*/}
      {/*    // deviceCount={devices.filter((device) => filterBy === device.siteName).length}*/}
      {/*    deviceCount={devices.length}*/}
      {/*    mapHeight={mapHeight}*/}
      {/*    setMapHeight={setMapHeight}*/}
      {/*    devicesWithLocation={devicesWithLocation}*/}
      {/*    showTrends={showTrends}*/}
      {/*    setShowTrends={setShowTrends}*/}
      {/*  />*/}
      {/*</Grid>*/}
      <Grid item xs={12}>
        <PollyDevicesHeatmap devices={devices} />
      </Grid>
      {devicesWithLocation.length > 0 && (
        <Grid item xs={12}>
          <PollyMapBasic devices={devicesWithLocation} mapHeight={mapHeight} />
        </Grid>
      )}
      <Grid item xs={4}>
        <DeviceActivityOverview
          title={dataLastUpdatedTitle}
          devices={devices}
          statNamesInclude={dataStatNames}
        />
      </Grid>
      <Grid item xs={4}>
        <DeviceActivityOverview title={otherStatsTitle} devices={devices} statNamesInclude={otherStatNames} />
      </Grid>
      <Grid item xs={4}>
        <></>
      </Grid>
      {/*{filterBy && (*/}
      {/*  <Grid item xs={12} sm={6} md={4} lg={3}>*/}
      {/*    <SiteAverage*/}
      {/*      devices={devices}*/}
      {/*      siteName={filterBy}*/}
      {/*      title={`1 day avg`}*/}
      {/*      metricName={'beeActivity'}*/}
      {/*      fieldName={'_7dayAvg'}*/}
      {/*    />*/}
      {/*  </Grid>*/}
      {/*)}*/}
      {/*{filterBy && (*/}
      {/*  <Grid item xs={12} sm={6} md={4} lg={3}>*/}
      {/*    <SiteAverage*/}
      {/*      devices={devices}*/}
      {/*      siteName={filterBy}*/}
      {/*      title={`7 day avg`}*/}
      {/*      metricName={'beeActivity'}*/}
      {/*      fieldName={'_7dayAvg'}*/}
      {/*    />*/}
      {/*  </Grid>*/}
      {/*)}*/}
      {/*{filterBy && (*/}
      {/*  <Grid item xs={12} sm={6} md={4} lg={3}>*/}
      {/*    <SiteAverage*/}
      {/*      devices={devices}*/}
      {/*      siteName={filterBy}*/}
      {/*      title={`30 day avg`}*/}
      {/*      metricName={'beeActivity'}*/}
      {/*      fieldName={'_30dayAvg'}*/}
      {/*    />*/}
      {/*  </Grid>*/}
      {/*)}*/}
      <Grid item xs={12}>
        <HealthTableAccordion devices={devices} projectId={projectId} />
      </Grid>
      <Grid item xs={12}>
        <DeviceCards
          cardType="overview-lite"
          devices={devices}
          metric={projectData.metric}
          withColour={true}
          classes={classes}
          projectId={projectId}
          siteOptions={siteOptions}
          showSite={filterBy === undefined}
          showTrends={showTrends}
          project={projectData}
        />
      </Grid>
    </Grid>
  );
}

function HealthTableAccordion({ devices, projectId }) {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography style={{ fontWeight: 'bold' }}>Device Status Report</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <PollyDevicesHealthTable devices={devices} projectId={projectId}/>
      </AccordionDetails>
    </Accordion>
  );
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function DeviceActivityOverview({ devices, statNamesInclude, title = 'Sample Title' }) {
  let stats = getDeviceStats(devices);
  stats = stats.filter((stat) => stat);
  const classes = useStyles();
  const deviceCount = devices.length;
  let theStats = [];
  for (let statName of Object.values(statNames)) {
    if (statNamesInclude.includes(statName)) {
      const statLabel = statsConfig[statName].label;
      const statCount = stats.filter((stat) => stat.stats.includes(statName)).length;
      const count = `${statCount} / ${deviceCount}`;
      if (statCount > 0) {
        theStats.push({
          label: statLabel,
          value: count,
        });
      }
      if(statName === statNames.hasRebooted && count === 0) {
        theStats.push({
          label: statLabel,
          value: count,
        });
      }
    }
  }

  theStats = theStats.sort((a, b) => b.value - a.value);

  return (
    <Card className={classes.stats}>
      <CardHeader
        // title={title}
        subheader={<Typography variant="h6">{title}</Typography>}
      />
      <CardContent>
        {theStats.map((stat) => {
          return <Stat2 stat={stat} />;
        })}
      </CardContent>
    </Card>
  );
}

function Stat2({ stat }) {
  return (
    <Grid container>
      <Grid item xs={8}>
        {stat.label}
      </Grid>
      <Grid item xs={4}>
        {stat.value}
      </Grid>
    </Grid>
  );
}

function Stat({ statName, stats, deviceCount }) {
  const statLabel = statsConfig[statName].label;
  const statCount = stats.filter((stat) => stat.stats.includes(statName)).length;
  const count = `${statCount} / ${deviceCount}`;
  return (
    <Grid container style={{ width: '100%' }}>
      <Grid item xs={6}>
        {statLabel}
      </Grid>
      <Grid item xs={6}>
        {count}
      </Grid>
    </Grid>
  );
}

function NoLocationAlert({ count, devices }) {
  const text = `${count} devices with no location set ${JSON.stringify(
    devices.map((device) => device.deviceId)
  )}`;
  return <Alert severity="warning">{text}</Alert>;
}

function LocationStatus({ count, devices }) {
  if (count === 0) {
    return <Alert severity="success">All devices have GPS locations </Alert>;
  } else {
    return <NoLocationAlert count={count} devices={devices} />;
  }
}

function SiteAverage({ devices, siteName, title, metricName, fieldName }) {
  const siteDevices = devices.filter((device) => device.siteName === siteName);
  const [average, deviceCount] = getAverage({ devices: siteDevices, metricName, fieldName });

  const newTitle = title + ` (${deviceCount} of ${siteDevices.length})`;
  const currentValue = average && average.toFixed(1);
  return (
    <PollyActivityWidget
      title={newTitle}
      currentValue={currentValue}
      showIcon={false}
      showMovement={false}
      height={120}
      suffix={'%'}
    />
  );
}

function SitesSelect({
  sites,
  period,
  setPeriod,
  filterBy,
  handleSiteNameChangeAutocomplete,
  filterByOptions,
  setFilterBy,
  showPeriodSelect = false,
  devices,
  devicesWithLocation,
  deviceCount,
  version,
  setVersion,
  versionsOptions,
  mapHeight,
  setMapHeight,
  showTrends,
  setShowTrends,
}) {
  const classes = useStyles();
  const initialValues = {
    filterByLevel: 'siteName',
    // filterByPossibleValues: filterByOptions,
    // versionPossibleValues: versionsOptions,
    // filterBy: (sites && sites.length > 0 && [sites[0].siteName]) || undefined,
    filterBy: undefined,
    version: undefined,
    // period: '30days',
  };

  const defaultValues = {
    filterByLevel: initialValues.filterByLevel,
    // filterByPossibleValues: initialValues.filterByPossibleValues,
    // versionPossibleValues: initialValues.versionPossibleValues,
    filterBy: initialValues.filterBy,
    version: initialValues.version,
    // period: initialValues.period,
    groupBy: 'siteName',
    locationData: [],
  };

  // console.log('defaultValues', defaultValues);

  // const selectStyle = { m: 1, width: '300px' };
  // const selectStyle2 = { m: 1, width: '300px' };
  // const selectStyleMulti = { m: 1, minWidth: '416px' };
  // const selectStyleMulti2 = { m: 1, minWidth: '216px' };
  const [filterByLevel, setFilterByLevel] = useState(defaultValues.filterByLevel);
  const [filterByPossibleValues, setFilterByPossibleValues] = useState(filterByOptions);

  const filterLevelOptions = [
    // { label: 'Farm', value: 'farmName' },
    { label: 'Site', value: 'siteName' },
    // { label: 'Tag', value: 'groupName' },
  ];

  const onFilterLevelChange = (e) => {
    const { value } = e.target;
    // console.log('onFilterLevelChange', value);
    setFilterByLevel(value);
    setFilterBy([]);
    setFilterByPossibleValues(getFilterByPossibleValues({ option: value, sites }));
  };

  const onFilterByChange = (event, value) => {
    handleSiteNameChangeAutocomplete(event, value);
    // const filterByValues = value.map((entry) => entry.label);
    // console.log('onFilterByChange', value);
    if (value) {
      setFilterBy(value.value);
    } else {
      setFilterBy(undefined);
    }
  };
  const onVersionChange = (event, value) => {
    // console.log('onVersionChange', event, value);
    // setVersion(event.target.value);
    if (value) {
      setVersion(value.value);
    } else {
      setVersion(undefined);
    }
  };

  const showTrendsAction = (e) => setShowTrends(!showTrends);
  const showTrendsSwitch = (
    <FormControlLabel
      style={{ marginTop: '15px', marginLeft: '5px' }}
      control={<Switch size="small" checked={showTrends} onChange={showTrendsAction} />}
      label="Trends"
    />
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6} md={3} lg={3}>
        <CustomSelect
          options={filterLevelOptions}
          onSelectChange={onFilterLevelChange}
          label={'Filter By'}
          selectedValue={filterByLevel}
          // defaultValue={'siteName'}
          // sx={selectStyle}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={2}>
        <PollyAutocomplete
          options={filterByOptions}
          defaultValue={defaultValues.filterBy}
          disabled={filterByLevel === undefined}
          onChange={onFilterByChange}
          // value={filterBy}
          value={filterBy}
          // sx={selectStyleMulti}
          multiple={false}
          label={'Site'}
          placeholder={'Site'}
        />
      </Grid>
      {versionsOptions && (
        <Grid item xs={12} sm={6} md={2} lg={2}>
          <PollyAutocomplete
            options={versionsOptions}
            // defaultValue={version}
            disabled={filterByLevel === undefined}
            onChange={onVersionChange}
            value={version}
            // sx={selectStyleMulti2}
            multiple={false}
            label={'Version'}
            placeholder={'Version'}
          />
        </Grid>
      )}
      {/*{showPeriodSelect && (*/}
      {/*  <Grid item xs={12} sm={6} md={4} lg={4}>*/}
      {/*    <PeriodSelection selectedValue={period} setSelectedValue={setPeriod} />*/}
      {/*  </Grid>*/}
      {/*)}*/}
      <Grid item xs={12} sm={6} md={2} lg={2}>
        <DeviceCount count={deviceCount} />
      </Grid>
      <Grid item xs={12} sm={6} md={1} lg={1}>
        {showTrendsSwitch}
      </Grid>
      {devicesWithLocation.length > 0 && (
        <Grid item xs={12} sm={6} md={2} lg={2}>
          <MapControl mapHeight={mapHeight} setMapHeight={setMapHeight} classes={classes} />
        </Grid>
      )}
      {devicesWithLocation.length === 0 && devices.length > 0 && (
        <Grid item xs={12} sm={6} md={2} lg={12}>
          <Alert severity="info">Cannot display the map, no locations are set</Alert>
        </Grid>
      )}
    </Grid>
  );
}

// function CannotDisplayMap() {
//   const classes = useStyles();
//   return (
//     <Card className={classes.deviceCount}>
//       <CardContent>
//         <Typography variant={'subtitle2'}>Cannot display map, no locations set</Typography>
//       </CardContent>
//     </Card>
//   );
// }

function DeviceCount({ count }) {
  const classes = useStyles();
  const text = `Device count = ${count}`;
  return (
    <Card className={classes.deviceCount}>
      <CardContent>
        <Typography variant={'subtitle1'}>{text}</Typography>
      </CardContent>
    </Card>
  );
}

function PeriodSelection({ selectedValue, setSelectedValue }) {
  const defaultValue = '30days';
  const selectStyle = { m: 1, width: '200px', marginLeft: '100px' };
  const label = 'Period';
  const onSelectChange = (e) => {
    const { value } = e.target;
    // console.log('onFilterLevelChange', value);
    setSelectedValue(value);
    // setFilterByLevel(value);
    // setFilterBy([]);
    // setFilterByPossibleValues(getFilterByPossibleValues({ option: value, sites }));
  };

  return (
    <CustomSelect
      options={periodOptions}
      onSelectChange={onSelectChange}
      label={label}
      selectedValue={selectedValue}
      defaultValue={defaultValue}
      sx={selectStyle}
    />
  );
}
