import React from 'react';
import Grid from '@material-ui/core/Grid';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { format } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    // textAlign: 'center',
    color: theme.palette.text.secondary,
    width: '100%',
  },
}));

//https://v4.mui.com/components/timeline/

export default function DeviceAuditHistory({ device }) {
  const classes = useStyles();
  const logs = device.auditHistory || [];
  // console.log('DeviceLogs', device);
  return (
    <Paper className={classes.paper}>
      <Grid style={{ width: '100%' }} container spacing={2}>
        <Grid item xs={12}>
          <Typography style={{ textAlign: 'center' }} color="textPrimary" variant="h6">
            Audit History
          </Typography>
        </Grid>
        <Timeline>
          {logs.map((log, index) => (
            <Grid key={index} item xs={12}>
              <TimelineEvent
                date={format(log.dateCreated.toDate(), 'dd MMM yy')}
                description={log.description}
                userEmail={log.userEmail}
                includeLine={index !== logs.length - 1}
                index={index}
              />
            </Grid>
          ))}
        </Timeline>
      </Grid>
    </Paper>
  );
}

function TimelineEvent({ date, description, userEmail, includeLine, index }) {
  // console.log('TimelineEvent', { date, description, userEmail, includeLine, index });
  return (
    <TimelineItem key={index}>
      <TimelineOppositeContent>
        <Typography color="secondary">{date}</Typography>
        <Typography color="secondary" variant={'subtitle2'}>
          {userEmail}
        </Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot />
        {includeLine && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        <Typography>{description}</Typography>
      </TimelineContent>
    </TimelineItem>
  );
}
