import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import UndoIcon from '@material-ui/icons/Undo';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useDispatch, useSelector } from 'react-redux';
import { isLoaded, useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import GeoLocation from '../Location/GeoLocation3';
import {
  updateProject,
  updateProjectMetric,
  updateProjectNote,
  updateProjectTimezone,
} from '../../../api/updateProject';
import { updateSelectedProjectId } from '../../../api/updateUser';
import { getSelectedProjectId } from '../../../config/env';
import BreadcrumbNav from '../Nav/BreadcrumbsNav';
import { getProjectNoteQuery, getProjectQuery } from '../../../api/queryConfig';
import { cloneDeep, isEqual } from 'lodash';
import SnackbarAlert from '../SnackbarAlert/SnackbarAlert';
import { snackbarDefault } from '../SnackbarAlert/SnackbarAlert';
import Switch from '@material-ui/core/Switch';
import DeviceManagement from '../DeviceManagement';
import UserManagement from '../UserManagement/UserManagement';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import { globalElevation } from '../../../config/globalStyle';
import { getIsAgrisoundAdmin, hasRole } from '../../../util/user-util';
import Button from '@material-ui/core/Button';
import { Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import { beekeeperUserRoles, pollyUserRoles } from '../../../config/userRoles';

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: '100%',
    // width: '500px'
  },
  card: {
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '60%',
    },
  },
  metricSwitch: {
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      padding: theme.spacing(3),
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '60%',
      padding: theme.spacing(3),
    },
  },
  userManagement: {
    padding: theme.spacing(3),
  },
  deviceManagement: {
    padding: theme.spacing(3),
  },
  title: {
    marginBottom: '20px',
    // maxWidth: '75%',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 400,
  },
}));

export default function ProjectPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);
  const [snackbar, setSnackbar] = useState(snackbarDefault);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar(snackbarDefault);
  };

  const currentProject = useSelector((state) => state.appConfig.currentProject);
  const userData = useSelector((state) => state.appConfig.userData);
  const user = useSelector((state) => state.appConfig.user);
  const userId = user && user.uid;
  const selectedProjectId = getSelectedProjectId(userData);
  const [projectId, setProjectId] = useState(selectedProjectId);
  const [projectNote, setProjectNote] = useState('');
  const [timezone, setTimezone] = useState(currentProject && currentProject.timezone);
  const [metric, setMetric] = useState(currentProject && currentProject.metric);
  const [dataStudioUrl, setDataStudioUrl] = useState(
    currentProject && currentProject.dataStudioUrl
  );
  const [dataStudioSpreadSheetId, setDataStudioSpreadSheetId] = useState(
    currentProject && currentProject.dataStudioSpreadSheetId
  );
  const [timezoneDisabled, setTimezoneDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const storeAsUser = `project-user-${userId}`;
  // const storeAsProject = `projects-${selectedProjectId}`;
  const storeAsProjectNotes = `project-notes-${selectedProjectId}`;
  const projectQuery = getProjectQuery({
    projectId: selectedProjectId,
    // storeAs: storeAsProject,
  });

  const projectNotesQuery = getProjectNoteQuery({
    projectId: selectedProjectId,
    storeAs: storeAsProjectNotes,
  });

  const queries = [projectNotesQuery];

  const projectNoteData = useSelector(
    ({ firestore: { data } }) => data && data[storeAsProjectNotes]
  );
  useEffect(() => {
    currentProject && setMetric(currentProject.metric);
    currentProject && setTimezone(currentProject.timezone);
    currentProject && setDataStudioUrl(currentProject.dataStudioUrl);
    currentProject && setDataStudioSpreadSheetId(currentProject.dataStudioSpreadSheetId);
  }, [currentProject]);

  useEffect(() => {
    const adminNote =
      (projectNoteData && projectNoteData.admin && projectNoteData.admin.noteText) || '';
    // console.log('projectNoteData', adminNote);
    setProjectNote(adminNote);
  }, [projectNoteData]);

  useFirestoreConnect(queries);

  if (!isLoaded(projectNoteData)) {
    return 'Loading...';
  }

  const onMetricChanged = () => {
    const value = !metric;
    updateProjectMetric({
      projectId: selectedProjectId,
      metric: value,
      dispatch,
    })
      .then((success) => setMetric(value))
      .catch((err) => console.log('updateProjectMetric.err', err));
  };

  const projectUserData =
    userData && userData.projects.find((project) => project.projectId === selectedProjectId);

  const isReadOnly = hasRole({
    userData: projectUserData,
    role: pollyUserRoles.readOnly,
  });
  const isAdmin = hasRole({
    userData: projectUserData,
    role: beekeeperUserRoles.admin,
  });

  const isAgrisoundAdmin = getIsAgrisoundAdmin(userData);
  const alerts =
    (currentProject &&
      currentProject.alertsConfig &&
      currentProject.alertsConfig.filter((alert) => alert.visible === true)) ||
    [];
  const projectIds = userData.projectIdsReadAccess.sort();
  const userHasMultipleProjects = projectIds.length > 1;

  const handleProjectIdChangeAutocomplete = (event, value) => {
    const newSelectedProjectId = value;
    // console.log('handleProjectIdChangeAutocomplete', newSelectedProjectId);
    if (newSelectedProjectId) {
      updateSelectedProjectId({
        userId,
        selectedProjectId: newSelectedProjectId,
      })
        .then((success) => {
          setProjectId(newSelectedProjectId);
        })
        .catch((err) => console.log('handleProjectIdChangeAutocomplete.err', err));
    }
  };
  const defaultProps = {
    options: projectIds,
    getOptionLabel: (option) => option,
  };

  const projectIdsAutocomplete = (
    <FormControl className={classes.formControl}>
      <Autocomplete
        {...defaultProps}
        value={projectId}
        id="projectId"
        debug
        onChange={handleProjectIdChangeAutocomplete}
        renderInput={(params) => <TextField {...params} label="Selected Project" margin="normal" />}
        disabled={!userHasMultipleProjects}
      />
    </FormControl>
  );

  const timezones = [
    'Europe/London',
    'Europe/Paris',
    'Europe/Moscow',
    'America/Los_Angeles',
    'America/New_York',
    'America/Detroit',
    'America/Denver',
    'America/Chicago',
    'America/Phoenix',
    'America/Anchorage',
    'Australia/Sydney',
    'Australia/Perth',
    'Australia/Adelaide',
    'NZ',
  ].sort();

  const handleTimezoneChange = (e) => {
    setTimezoneDisabled(true);
    setLoading(true);
    const newTimezone = e.target.value;
    updateProjectTimezone({ projectId, timezone: newTimezone, dispatch })
      .then((success) => {
        setTimezone(newTimezone);
        setTimezoneDisabled(false);
        setLoading(false);
      })
      .catch((err) => {
        console.log('updateTimezone.err', err);
        setTimezoneDisabled(false);
        setLoading(false);
      });
  };

  const updateProjectNoteAction = (noteText) => {
    setLoading(true);
    updateProjectNote({ projectId, noteText })
      .then((success) => {
        setProjectNote(noteText);
        setLoading(false);
      })
      .catch((err) => {
        console.log('updateProjectNote.err', err);
        setLoading(false);
      });
  };

  const updateDataStudioUrl = (dataStudioUrl) => {
    setLoading(true);
    const updatedObj = { dataStudioUrl };
    updateProject({ projectId, updatedObj, dispatch })
      .then((success) => {
        setDataStudioUrl(dataStudioUrl);
        setLoading(false);
      })
      .catch((err) => {
        console.log('updateDataStudioUrl.err', err);
        setLoading(false);
      });
  };

  const updateDataStudioSpreadSheetId = (dataStudioSpreadSheetId) => {
    setLoading(true);
    const updatedObj = { dataStudioSpreadSheetId };
    updateProject({ projectId, updatedObj, dispatch })
      .then((success) => {
        setDataStudioSpreadSheetId(dataStudioSpreadSheetId);
        setLoading(false);
      })
      .catch((err) => {
        console.log('updateDataStudioSpreadSheetId.err', err);
        setLoading(false);
      });
  };

  const TimeZoneSelect = (
    <Select disabled={timezoneDisabled} value={timezone} onChange={handleTimezoneChange}>
      {timezones.map((timezone, i) => {
        return (
          <MenuItem key={i} value={timezone}>
            {timezone}
          </MenuItem>
        );
      })}
    </Select>
  );

  if (isReadOnly) {
    return (
      <>
        <SnackbarAlert snackbar={snackbar} handleClose={handleClose} />
        <Grid justify={'flex-start'} container spacing={2} style={{ marginTop: -30 }}>
          <Grid item xs={12}>
            {projectIdsAutocomplete}
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <>
      <SnackbarAlert snackbar={snackbar} handleClose={handleClose} />
      <Grid justify={'flex-start'} container spacing={2} style={{ marginTop: -30 }}>
        {userHasMultipleProjects && (
          <Grid item xs={12}>
            {projectIdsAutocomplete}
          </Grid>
        )}
        {isAgrisoundAdmin && (
          <Grid item xs={12}>
            <ProjectNoteEdit
              projectNote={projectNote}
              setProjectNote={setProjectNote}
              updateProjectNoteAction={updateProjectNoteAction}
            />
          </Grid>
        )}
        {isAgrisoundAdmin && (
          <Grid item xs={12}>
            <DataStudioUrlEdit
              dataStudioUrl={dataStudioUrl}
              setDataStudioUrl={setDataStudioUrl}
              updateDataStudioUrl={updateDataStudioUrl}
            />
          </Grid>
        )}
        {isAgrisoundAdmin && (
          <Grid item xs={12}>
            <DataStudioSpreadSheetIdEdit
              dataStudioSpreadSheetId={dataStudioSpreadSheetId}
              setDataStudioSpreadSheetId={setDataStudioSpreadSheetId}
              updateDataStudioSpreadSheetId={updateDataStudioSpreadSheetId}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <ProjectCard
            project={currentProject}
            projectId={selectedProjectId}
            isAdmin={isAdmin}
            alerts={alerts}
            editMode={editMode}
            setEditMode={setEditMode}
            setSnackbar={setSnackbar}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.metricSwitch} elevation={globalElevation}>
            <Grid container alignItems="center">
              <Grid item xs={12}>
                <Box component="p">Timezone</Box>
              </Grid>
              <Grid item xs={12}>
                {TimeZoneSelect}
                {loading && <CircularProgress />}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.metricSwitch} elevation={globalElevation}>
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>Imperial</Grid>
              <Grid item>
                <Switch checked={metric} onChange={onMetricChanged} name="metric" />
              </Grid>
              <Grid item>Metric</Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.userManagement} elevation={globalElevation}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">User Management</Typography>
              </Grid>
              <Grid item xs={12}>
                <UserManagement />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.deviceManagement} elevation={globalElevation}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Device Management</Typography>
              </Grid>
              <Grid item xs={12}>
                <DeviceManagement />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

function DataStudioUrlEdit({ updateDataStudioUrl, dataStudioUrl, setDataStudioUrl }) {
  const classes = useStyles();
  return (
    <Paper className={classes.metricSwitch} elevation={globalElevation}>
      <Grid component="label" container alignItems="center" spacing={1}>
        <Grid item xs={3}>
          <Typography paragraph>Data Studio URL:</Typography>
        </Grid>
        <Grid item xs={7}>
          <DataStudioUrl dataStudioUrl={dataStudioUrl} setDataStudioUrl={setDataStudioUrl} />
        </Grid>
        <Grid item xs={2}>
          <Button
            variant={'contained'}
            onClick={() => {
              updateDataStudioUrl(dataStudioUrl);
            }}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

function DataStudioSpreadSheetIdEdit({
  updateDataStudioSpreadSheetId,
  dataStudioSpreadSheetId,
  setDataStudioSpreadSheetId,
}) {
  const classes = useStyles();
  return (
    <Paper className={classes.metricSwitch} elevation={globalElevation}>
      <Grid component="label" container alignItems="center" spacing={1}>
        <Grid item xs={3}>
          <Typography paragraph>Data Studio Spread Sheet ID:</Typography>
        </Grid>
        <Grid item xs={7}>
          <DataStudioSpreadSheetId
            dataStudioSpreadSheetId={dataStudioSpreadSheetId}
            setDataStudioSpreadSheetId={setDataStudioSpreadSheetId}
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            variant={'contained'}
            onClick={() => {
              updateDataStudioSpreadSheetId(dataStudioSpreadSheetId);
            }}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

function ProjectNoteEdit({ updateProjectNoteAction, projectNote, setProjectNote }) {
  const classes = useStyles();
  return (
    <Paper className={classes.metricSwitch} elevation={globalElevation}>
      <Grid component="label" container alignItems="center" spacing={1}>
        <Grid item xs={3}>
          <Typography paragraph>Admin Note:</Typography>
        </Grid>
        <Grid item xs={7}>
          <ProjectNote projectNote={projectNote} setProjectNote={setProjectNote} />
        </Grid>
        <Grid item xs={2}>
          <Button
            variant={'contained'}
            onClick={() => {
              updateProjectNoteAction(projectNote);
            }}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

function ProjectCard({ project, projectId, isAdmin, editMode, setEditMode, setSnackbar }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [projectName, setProjectName] = useState(project.projectName);
  const [description, setDescription] = useState(project.description);
  const [weatherLocations, setWeatherLocations] = useState(
    project.weatherLocations || [
      {
        lat: 0,
        lng: 0,
        label: undefined,
        id: projectId + '-0',
      },
    ]
  );

  const hasChanges = () => {
    return (
      project.projectName !== projectName ||
      project.description !== description ||
      !isEqual(project.weatherLocations, weatherLocations) ||
      project.description !== description
    );
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const lastActivityDateCreated =
    project && project.latestEvent && project.latestEvent.dateCreated.toDate();

  return (
    <Card className={classes.card} elevation={globalElevation}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            {project.projectName &&
              project.projectName.length > 0 &&
              project.projectName.substring(0, 1).toUpperCase()}
          </Avatar>
        }
        title={`Project ID: ${project.projectId}`}
        // subheader={`Last Activity: ${lastActivityDateCreated}`}
      />
      <CardContent>
        <Grid container justify={'center'} spacing={0} style={{ paddingTop: '20px' }}>
          <Grid item xs={6}>
            <Typography paragraph>Project Name:</Typography>
          </Grid>
          <Grid item xs={6}>
            {!editMode && <Typography paragraph>{project.projectName}</Typography>}
            {editMode && <DeviceName deviceName={projectName} setDeviceName={setProjectName} />}
          </Grid>
          <Grid item xs={6}>
            <Typography paragraph>Project Id:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography paragraph>{project.projectId}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography paragraph>Description:</Typography>
          </Grid>
          <Grid item xs={6}>
            {!editMode && <Typography paragraph>{project.description}</Typography>}
            {editMode && <Description description={description} setDescription={setDescription} />}
          </Grid>
          {/*<Grid item xs={6}>*/}
          {/*  <Typography paragraph>Weather Location</Typography>*/}
          {/*</Grid>*/}
          {/*<Grid item xs={6}>*/}
          {/*  {!editMode && <Typography paragraph>{location}</Typography>}*/}
          {/*  {editMode && (*/}
          {/*    <>*/}
          {/*      <Latitude lat={lat} setLat={setLat} />*/}
          {/*      <Longitude lng={lng} setLng={setLng} />*/}
          {/*    </>*/}
          {/*  )}*/}
          {/*</Grid>*/}
          {/*<Grid item xs={6}>*/}
          {/*  <Typography paragraph>Weather Label:</Typography>*/}
          {/*</Grid>*/}
          {/*<Grid item xs={6}>*/}
          {/*  {!editMode && (*/}
          {/*    <Typography paragraph>{weatherLocations.length > 0 && weatherLocations[0].label}</Typography>*/}
          {/*  )}*/}
          {/*  {editMode && <Description description={description} setDescription={setDescription} />}*/}
          {/*</Grid>*/}
          {/*<Grid item xs={6}>*/}
          {/*  <Typography paragraph>Use GPS</Typography>*/}
          {/*</Grid>*/}
          {/*<Grid item xs={6}>*/}
          {/*  <GeoLocation setEditMode={setEditMode} setLat={setLat} setLng={setLng} />*/}
          {/*</Grid>*/}
          <Grid item xs={12}>
            <Box display="flex">
              <Typography paragraph>Weather API Location</Typography>
              <Tooltip
                title={
                  <h2>
                    Set this location to allow weather data to be sourced from the external source
                    (the https://openweathermap.org/ API)
                  </h2>
                }
              >
                <IconButton aria-label="delete">
                  <InfoIcon
                    style={{
                      width: '15px',
                      marginLeft: '0px',
                      marginTop: '-18px',
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Box>
            <WeatherLocations
              weatherLocations={weatherLocations}
              setWeatherLocations={setWeatherLocations}
              editMode={editMode}
              setEditMode={setEditMode}
              classes={classes}
              setSnackbar={setSnackbar}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions disableSpacing>
        {isAdmin && (
          <>
            <IconButton
              aria-label="Edit"
              onClick={async () => {
                if (editMode && hasChanges()) {
                  setLoading(true);
                  let updatedObj = {};
                  if (description) updatedObj.description = description;
                  if (projectName) updatedObj.projectName = projectName;
                  updatedObj.weatherLocations = weatherLocations;
                  if (weatherLocations[0].label === undefined) {
                    setSnackbar({
                      message: 'The weather label cannot be empty',
                      open: true,
                      severity: 'info',
                    });
                    setLoading(false);
                    return;
                  }
                  try {
                    // console.log(`Device changes: ${JSON.stringify(updatedObj)}`);
                    await updateProject({ projectId, updatedObj, dispatch });
                    setTimeout(() => {
                      setLoading(false);
                      setSnackbar({
                        message: 'Saved',
                        open: true,
                        severity: 'success',
                      });
                    }, 500);
                  } catch (err) {
                    setLoading(false);
                    setSnackbar({
                      message: `Error: ${err.message}`,
                      open: true,
                      severity: 'error',
                    });
                    console.log('updateProject.err', err);
                  }
                } else {
                  console.log(`Device has no changes`);
                }
                setEditMode(!editMode);
              }}
            >
              {!editMode && <EditIcon />}
              {editMode && <SaveIcon />}
            </IconButton>
            {editMode && (
              <IconButton onClick={() => setEditMode(false)}>
                <UndoIcon />
              </IconButton>
            )}
          </>
        )}
        {loading && <CircularProgress />}
        {/*<IconButton*/}
        {/*  className={clsx(classes.expand, {*/}
        {/*    [classes.expandOpen]: expanded,*/}
        {/*  })}*/}
        {/*  onClick={handleExpandClick}*/}
        {/*  aria-expanded={expanded}*/}
        {/*  aria-label="show more"*/}
        {/*>*/}
        {/*  <ExpandMoreIcon />*/}
        {/*</IconButton>*/}
      </CardActions>
      {/*<CardCollapse expanded={expanded} />*/}
    </Card>
  );
}

function ProjectBreadcrumbs({ projectId }) {
  const breadcrumbs = [
    {
      path: '/',
      text: 'Home',
    },
    // {
    //   path: '/device-management',
    //   text: 'Device Management',
    // },
    {
      path: `/projects`,
      text: 'Settings',
    },
  ];
  return <BreadcrumbNav breadcrumbs={breadcrumbs} />;
}

function DataStudioUrl({ dataStudioUrl, setDataStudioUrl }) {
  return (
    <TextField
      multiline
      style={{ backgroundColor: '#fed8b1', width: '100%' }}
      rows={2}
      required
      id="standard-required"
      value={dataStudioUrl}
      onChange={(e) => setDataStudioUrl(e.target.value)}
    />
  );
}

function DataStudioSpreadSheetId({ dataStudioSpreadSheetId, setDataStudioSpreadSheetId }) {
  return (
    <TextField
      multiline
      style={{ backgroundColor: '#fed8b1', width: '100%' }}
      rows={2}
      required
      id="standard-required"
      value={dataStudioSpreadSheetId}
      onChange={(e) => setDataStudioSpreadSheetId(e.target.value)}
    />
  );
}

function ProjectNote({ projectNote, setProjectNote }) {
  return (
    <TextField
      multiline
      style={{ backgroundColor: '#fed8b1', width: '100%' }}
      rows={4}
      required
      id="standard-required"
      value={projectNote}
      onChange={(e) => setProjectNote(e.target.value)}
    />
  );
}

function DeviceName({ deviceName, setDeviceName }) {
  return (
    <TextField
      style={{ backgroundColor: '#fed8b1' }}
      required
      id="standard-required"
      value={deviceName}
      onChange={(e) => setDeviceName(e.target.value)}
    />
  );
}

function Description({ description, setDescription }) {
  return (
    <TextField
      style={{ backgroundColor: '#fed8b1' }}
      required
      id="standard-required"
      value={description}
      onChange={(e) => setDescription(e.target.value)}
    />
  );
}

function Latitude({ lat, setLat, editMode }) {
  return (
    <TextField
      disabled={!editMode}
      type="number"
      label="Lat"
      style={{ backgroundColor: editMode ? '#fed8b1' : 'white' }}
      required
      id="standard-required"
      value={lat}
      onChange={(e) => {
        const lat = parseFloat(e.target.value);
        setLat(lat);
      }}
    />
  );
}

function Longitude({ lng, setLng, editMode }) {
  return (
    <TextField
      disabled={!editMode}
      type="number"
      label="Long"
      style={{ backgroundColor: editMode ? '#fed8b1' : 'white' }}
      required
      id="standard-required"
      value={lng}
      onChange={(e) => {
        const lng = parseFloat(e.target.value);
        setLng(lng);
      }}
    />
  );
}

function WeatherLabel({ label, setLabel, editMode }) {
  return (
    <TextField
      disabled={!editMode}
      // type="number"
      label="Label"
      style={{ backgroundColor: editMode ? '#fed8b1' : 'white' }}
      required
      id="standard-required"
      value={label}
      onChange={(e) => {
        setLabel(e.target.value);
      }}
    />
  );
}

function WeatherLocations({
  weatherLocations,
  setWeatherLocations,
  editMode,
  setEditMode,
  classes,
  setSnackbar,
}) {
  // console.log('RemoveAnnotations', annotations);
  const firestore = useFirestore();
  const showActions = false;
  return (
    <WeatherLocation
      setWeatherLocations={setWeatherLocations}
      weatherLocations={weatherLocations}
      editMode={editMode}
      setEditMode={setEditMode}
      setSnackbar={setSnackbar}
    />
  );
  // return (
  //   <List className={classes.root}>
  //     {weatherLocations.map((weatherLocation) => {
  //       return (
  //         <>
  //           <ListItem alignItems="flex-start">
  //             <WeatherLocation weatherLocation={weatherLocation} editMode={editMode} />
  //             {showActions && (
  //               <ListItemSecondaryAction>
  //                 <IconButton
  //                   edge="end"
  //                   aria-label="delete"
  //                   onClick={(event) => {
  //                     deleteComment({
  //                       firestore,
  //                       commentText: weatherLocation.label.text,
  //                       deviceId: '',
  //                       date: weatherLocation.x,
  //                     }).catch((err) => {
  //                       console.log('deleteComment.err', err);
  //                     });
  //                     // console.log('event', annotation.label.text, new Date(annotation.x));
  //                   }}
  //                 >
  //                   <DeleteIcon />
  //                 </IconButton>
  //               </ListItemSecondaryAction>
  //             )}
  //           </ListItem>
  //           <Divider variant="fullWidth" component="li" />
  //         </>
  //       );
  //     })}
  //   </List>
  // );
}

function WeatherLocation({
  weatherLocations,
  setWeatherLocations,
  editMode,
  setEditMode,
  classes,
  setSnackbar,
}) {
  let weatherLocation = weatherLocations[0];
  const [lat, setLat] = useState(weatherLocation && weatherLocation.lat);
  const [lng, setLng] = useState(weatherLocation && weatherLocation.lng);
  const [label, setLabel] = useState(weatherLocation && weatherLocation.label);
  useEffect(() => {
    const newWeatherLocations = cloneDeep(weatherLocations);
    newWeatherLocations[0] = {
      id: weatherLocation.id,
      lat,
      lng,
      label,
    };
    setWeatherLocations(newWeatherLocations);
  }, [lat, lng, label]);
  return (
    <Box flexDirection="row">
      {/*{!editMode && <Typography paragraph>{location}</Typography>}*/}
      {/*{editMode && (*/}

      <Latitude lat={lat} setLat={setLat} editMode={editMode} />
      <Longitude lng={lng} setLng={setLng} editMode={editMode} />
      <WeatherLabel label={label} setLabel={setLabel} editMode={editMode} />
      <GeoLocation
        editMode={editMode}
        setEditMode={setEditMode}
        setLat={setLat}
        setLng={setLng}
        setSnackbar={setSnackbar}
      />
    </Box>
  );
}
