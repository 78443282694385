import { objectDiff } from '../data/data-util';
import { auth } from '../firebase';

export async function updateAlerts({ firestore, projectId, alerts, updatedAlerts }) {
  console.log('updateAlerts', { projectId, alerts, diffs: objectDiff(alerts, updatedAlerts) });
  let updatedObj = { alertsConfig: updatedAlerts };
  addAudit(updatedObj);
  await firestore.doc(`projects/${projectId}`).update(updatedObj);
}

function addAudit(obj) {
  const uid = auth.currentUser && auth.currentUser.uid;
  obj.updatedBy = uid;
  obj.dateUpdated = new Date();
  obj.updateComment = `Updated the Alerts config`;
}
