import React from 'react';
import Typography from '@material-ui/core/Typography';

export default function ContactPage() {
  return (
    <Typography>
      For any support queries of feedback please email us at{' '}
      <a href="mailto:support@agrisound.io">support@agrisound.io</a>
    </Typography>
  );
}
