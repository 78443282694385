import React, { useEffect, useState } from 'react';
import { diff } from 'deep-object-diff';
import { log } from '../../../util/log-util';
import { DataGrid } from '@material-ui/data-grid';
import { format } from 'date-fns';
import { deviceTypes, deviceTypesConfig } from '../../../config/deviceTypes';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getTableDataDevices } from '../../../data/data-util';
import Grid from '@material-ui/core/Grid';
import { Typography, useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { isLoaded, useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import { isProjectAdmin } from '../../../config/env';
import { getAllDevicesQuery } from '../../../api/queryConfig';
import BreadcrumbNav from '../Nav/BreadcrumbsNav';
import { projectIdNotAssigned } from '../../../config/projectConfig';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { updateDevice } from '../../../api/updateDevice';
import { cloneDeep } from 'lodash';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import UndoIcon from '@mui/icons-material/Replay';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { auth } from '../../../firebase';
import { addRequestToCollection } from '../../../api/adminFunctions';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    // minWidth: 800,
  },
  resize: {
    fontSize: 12,
    minWidth: 300,
  },
  dataGrid: {
    width: '100%',
    // height: '1000px'
  },
}));

export default function DeviceManagementNotAssigned() {
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [updates, setUpdates] = useState([]);
  const [devicesData, setDevicesData] = useState([]);
  const [searchDeviceId, setSearchDeviceId] = useState();
  const userData = useSelector((state) => state.appConfig.userData);
  const project = useSelector((state) => state.appConfig.currentProject);
  const isAdmin = isProjectAdmin(userData);
  const projectId = projectIdNotAssigned;
  const projectIds = [projectIdNotAssigned];
  const projectIdsSelect = projectIds.concat(userData.projectIdsReadAccess.sort());
  const storeAs = `${projectId}-devices`;
  const devices = useSelector(({ firestore: { data } }) => data[storeAs]);
  const devicesQuery = getAllDevicesQuery({ storeAs, projectIds });
  useFirestoreConnect(devicesQuery);

  useEffect(() => {
    let devicesData = (devices && getTableDataDevices(Object.values(devices))) || [];
    const sortedByDate = devicesData.sort((a, b) => (b.dateUpdated || 'n/a') - (a.dateUpdated || 'n/a'));
    setUpdates(sortedByDate);
    setDevicesData(sortedByDate);
  }, [devices]);
  useEffect(() => {
    if (searchDeviceId && searchDeviceId.length > 0) {
      let newUpdates = cloneDeep(updates).filter((device) => device.deviceId.includes(searchDeviceId));
      const sortedByDate = newUpdates.sort((a, b) => b.dateUpdated - a.dateUpdated);
      setUpdates(sortedByDate);
    } else {
      setUpdates(devicesData);
    }
  }, [searchDeviceId]);

  if (!isLoaded(devices)) {
    return 'Loading...';
  }

  const saveAction = async ({ deviceId, deviceUpdates, setLoading, diffs }) => {
    setLoading(true);
    let updatedObj = cloneDeep(deviceUpdates);
    try {
      if (Object.keys(updatedObj).length > 0) {
        const auditDescription = `Updated: ${JSON.stringify(diffs)}`;
        // console.log(`update the deviceId ${deviceId} with ${JSON.stringify(updatedObj)}`);
        await updateDevice({ deviceId, updatedObj, auditDescription });
      }
    } catch (err) {
      console.log(`saveAction.updateDevice.err2`, err);
    }
    setLoading(false);
  };

  const getDeviceUpdates = ({ deviceId, diffs }) => {
    const deviceUpdates = {};
    const device = updates.find((device) => device.deviceId === deviceId);
    for (let key of Object.keys(diffs)) {
      deviceUpdates[key] = device[key];
    }

    return deviceUpdates;
  };

  const saveButton = (
    <Button
      disabled={Object.keys(diff(devicesData, updates)).length === 0}
      variant="contained"
      color="primary"
      onClick={async () => {
        const allDiffs = diff(devicesData, updates);
        // console.log('diffs', allDiffs);
        for (let key of Object.keys(allDiffs)) {
          const position = Number.parseFloat(key);
          if (updates[position]) {
            const deviceId = updates[position].deviceId;
            const d1 = devicesData.find((d) => d.deviceId === deviceId);
            const d2 = updates.find((d) => d.deviceId === deviceId);
            const diffs = diff(d1, d2);
            const deviceUpdates = getDeviceUpdates({ deviceId, diffs });
            // console.log('diffs.deviceUpdates', deviceId, deviceUpdates);
            await saveAction({ deviceId, deviceUpdates, setLoading, diffs });
          }
        }
      }}
      style={{ marginRight: '10px' }}
    >
      Save
    </Button>
  );

  const undoButton = (
    <Button
      disabled={Object.keys(diff(devicesData, updates)).length === 0}
      variant="contained"
      color="primary"
      onClick={() => {
        setUpdates(devicesData);
      }}
    >
      Undo
      <UndoIcon style={{ marginLeft: '5px' }} />
    </Button>
  );

  const buttons = (
    <Box display="flex">
      {loading && <CircularProgress style={{ marginLeft: '-70px', fontSize: 50, color: 'orange' }} />}
      <ButtonGroup style={{ marginRight: '15px' }}>
        {saveButton}
        {undoButton}
      </ButtonGroup>
    </Box>
  );

  const searchOnChange = (event) => {
    setSearchDeviceId(event.target.value);
  };
  const search = (
    <Box display="flex" justifyContent="center">
      <TextField value={searchDeviceId} onChange={searchOnChange} placeholder="DeviceId to search" />
      <ClearIcon onClick={() => setSearchDeviceId('')} />
    </Box>
  );

  return (
    <Grid container spacing={2}>
      {message && (
        <Grid item xs={12}>
          Message: {message}
        </Grid>
      )}
      <Grid item xs={4}>
        <Typography variant="h6">Devices where project = 'not-assigned'</Typography>
      </Grid>
      <Grid item xs={2}>
        {buttons}
      </Grid>
      <Grid item xs={6}>
        {search}
      </Grid>
      <Grid item xs={12}>
        <DevicesTable
          tableData={updates}
          project={project}
          projectIdsSelect={projectIdsSelect}
          updates={updates}
          setUpdates={setUpdates}
          setLoading={setLoading}
        />
      </Grid>
    </Grid>
  );
}

function DevicesTable({
  tableData,
  project,
  projectIdsSelect,
  updates,
  setUpdates,
  setLoading,
}) {
  const classes = useStyles();
  const firestore = useFirestore();
  const theme = useTheme();
  const screenSmallOrLess = useMediaQuery(theme.breakpoints.down('sm'));
  // addIdToTableData(tableData);

  // return <div>Hello you</div>;
  // console.log('window', window.innerWidth, (window.innerWidth - 50) + 'px' );
  const width = window.innerWidth - 150 + 'px';

  return (
    // <div style={{ height: 1000 }}>
    <div style={{ height: '600px', width }}>
      <DataGrid
        // autoHeight={true}
        // disableExtendRowFullWidth={true}
        className={classes.dataGrid}
        rows={tableData}
        columns={getColumns({
          downValue: screenSmallOrLess,
          timezone: project.timezone,
          projectIdsSelect,
          classes,
          updates,
          setUpdates,
          firestore,
          setLoading,
        })}
        pageSize={75}
      />
    </div>
  );
}

function getColumns({
  downValue,
  timezone = 'Europe/London',
  projectIdsSelect,
  classes,
  updates,
  setUpdates,
  firestore,
  setLoading,
}) {
  const defaultProps = {
    options: projectIdsSelect,
    getOptionLabel: (option) => option,
  };

  const disabledProjectIds = [projectIdNotAssigned];

  const handleChangeTextField = ({ event, deviceId, fieldName }) => {
    let value = event.target.value && event.target.value.trim();
    const calibrationFields = ['calibrationOffsetRaw', 'calibrationCoefficientRaw'];
    if (calibrationFields.includes(fieldName)) {
      value = Number.parseFloat(value);
    }
    let newUpdates = cloneDeep(updates);
    newUpdates.find((device) => device.deviceId === deviceId)[fieldName] = value;
    setUpdates(newUpdates);
  };
  const handleChangeMultiple = (event, value, deviceId) => {
    let newUpdates = cloneDeep(updates);
    const fieldName = 'projectIds';
    newUpdates.find((device) => device.deviceId === deviceId)[fieldName] = value;
    // console.log('handleChangeMultiple', value, deviceId, newUpdates);
    setUpdates(newUpdates);
  };

  const removeWidth = true;
  const columns = [
    // {
    //   field: 'deviceType',
    //   headerName: 'Device Type',
    //   flex: 0.3,
    //   width: 200,
    //   hide: downValue,
    //   renderCell: (params) => {
    //     return deviceTypesConfig[params.row.deviceType];
    //   },
    // },
    {
      field: '',
      headerName: '',
      width: 20,
      flex: 0.3,
      renderCell: (params) => {
        const collectionName = 'removeDeviceFromProject';
        const deviceId = params.row.deviceId;
        const projectIds = params.row.projectIds;
        const removeDeviceAction = () => {
          setLoading(true);
          const performAction = true;
          // setOpenConfirm(false);
          if (!performAction) {
            // setDevices([]);
            // setMessage(`${deviceId} successfully removed`);
            // setLoading(false);
            setTimeout(() => {
              window.location.reload();
            }, 2000);
            return;
          }

          const uid = auth.currentUser && auth.currentUser.uid;
          const request = { projectId: projectIds[0], deviceId, dateCreated: new Date(), updatedBy: uid };

          addRequestToCollection({ request, collectionName, firestore, setLoading });
        };
        return <DeleteForeverIcon onClick={removeDeviceAction} />;
      },
    },
    {
      field: 'dateCreated',
      headerName: 'Created',
      flex: 0.7,
      width: 100,
      hide: downValue,
      renderCell: (params) => {
        const dateFormat = 'dd-MMM hh:ss';
        let dateCreated =
          (params.row.dateCreated && format(params.row.dateCreated, dateFormat)) || 'n/a';
        if (dateCreated === 'n/a' && params.row.dateUpdated) {
          dateCreated =
            (params.row.dateUpdated && format(params.row.dateUpdated, dateFormat)) || 'n/a';
        }
        // let dateCreated = params.row.dateCreated || params.row.dateUpdated || 'n/a';
        return <div>{dateCreated}</div>;
      },
    },
    {
      field: 'deviceId',
      headerName: 'Device ID',
      width: 100,
      flex: 1,
      hide: downValue,
    },
    {
      field: 'deviceName',
      headerName: 'Device Name',
      flex: 1.1,
      width: 100,
      hide: downValue,
      renderCell: (params) => {
        const fieldName = 'deviceName';
        const deviceId = params.row.deviceId;
        return (
          <TextField
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            name="deviceName"
            onChange={(event) =>
              handleChangeTextField({
                event,
                deviceId,
                fieldName,
              })
            }
            value={params.row[fieldName]}
          />
        );
      },
    },
    {
      field: 'projectIds',
      headerName: 'ProjectIds',
      width: 300,
      flex: 2,
      renderCell: (params) => {
        return (
          <FormControl className={classes.formControl}>
            <Autocomplete
              {...defaultProps}
              value={params.row.projectIds}
              multiple
              id="projectId"
              debug
              onChange={(event, value) => {
                handleChangeMultiple(event, value, params.row.deviceId);
              }}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    style={{ fontSize: '11px' }}
                    label={option}
                    {...getTagProps({ index })}
                    // disabled={disabledProjectIds.indexOf(option) !== -1}
                    disabled={params.row.projectIds.length === 1}
                  />
                ))
              }
              renderInput={(params) => <TextField {...params} margin="normal" />}
            />
          </FormControl>
        );
      },
    },
    {
      field: 'stickerName',
      headerName: 'Sticker Name',
      flex: 0.5,
      width: 150,
      hide: downValue,
      renderCell: (params) => {
        const fieldName = 'stickerName';
        const deviceId = params.row.deviceId;
        return (
          <TextField
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
            name="stickerName"
            onChange={(event) =>
              handleChangeTextField({
                event,
                deviceId,
                fieldName,
              })
            }
            value={params.row[fieldName]}
          />
        );
      },
    },
    // {
    //   field: 'gatewayDeviceId',
    //   headerName: 'Gateway ID',
    //   width: 170,
    //   flex: 0.5,
    //   hide: downValue,
    //   renderCell: (params) => {
    //     const fieldName = 'gatewayDeviceId';
    //     const deviceId = params.row.deviceId;
    //     if (params.row.deviceType === deviceTypes.beeHeroOne) {
    //       return (
    //         <TextField
    //           value={params.row.gatewayDeviceId}
    //           onChange={(event) => handleChangeTextField({ event, deviceId, fieldName })}
    //         />
    //       );
    //     }
    //
    //     return <></>;
    //   },
    // },
    // {
    //   field: 'ihsDeviceId',
    //   headerName: 'IHS ID',
    //   width: 170,
    //   flex: 0.5,
    //   hide: downValue,
    //   renderCell: (params) => {
    //     const fieldName = 'ihsDeviceId';
    //     const deviceId = params.row.deviceId;
    //     if (params.row.deviceType === deviceTypes.agsenzeScaleOne) {
    //       return (
    //         <TextField
    //           value={params.row[fieldName]}
    //           onChange={(event) => handleChangeTextField({ event, deviceId, fieldName })}
    //         />
    //       );
    //     }
    //
    //     return <></>;
    //   },
    // },
    // {
    //   field: 'scaleDeviceId',
    //   headerName: 'Scale ID',
    //   width: 170,
    //   flex: 0.5,
    //   hide: downValue,
    //   renderCell: (params) => {
    //     const fieldName = 'scaleDeviceId';
    //     const deviceId = params.row.deviceId;
    //     if (params.row.deviceType === deviceTypes.beeHeroOne) {
    //       return (
    //         <TextField
    //           value={params.row.scaleDeviceId}
    //           onChange={(event) => handleChangeTextField({ event, deviceId, fieldName })}
    //         />
    //       );
    //     }
    //
    //     return <></>;
    //   },
    // },
    {
      field: 'calibrationOffsetRaw',
      headerName: 'Offset',
      width: 120,
      flex: 0.8,
      renderCell: (params) => {
        if (params.row.deviceType === deviceTypes.agsenzeScaleOne) {
          const fieldName = 'calibrationOffsetRaw';
          const deviceId = params.row.deviceId;
          return (
            <TextField
              InputProps={{
                classes: {
                  input: classes.resize,
                },
              }}
              type="number"
              name="calibrationOffsetRaw"
              onChange={(event) =>
                handleChangeTextField({
                  event,
                  deviceId,
                  fieldName,
                })
              }
              value={params.row[fieldName]}
            />
          );
        }

        return <></>;
      },
    },
    {
      field: 'calibrationCoefficientRaw',
      headerName: 'Coefficient',
      width: 120,
      flex: 0.8,
      renderCell: (params) => {
        const fieldName = 'calibrationCoefficientRaw';
        const deviceId = params.row.deviceId;
        if (params.row.deviceType === deviceTypes.agsenzeScaleOne) {
          return (
            <TextField
              InputProps={{
                classes: {
                  input: classes.resize,
                },
              }}
              type="number"
              name="calibrationCoefficientRaw"
              onChange={(event) =>
                handleChangeTextField({
                  event,
                  deviceId,
                  fieldName,
                })
              }
              value={params.row[fieldName]}
            />
          );
        }

        return <></>;
      },
    },
  ];

  const filtered = columns.map((col) => {
    let newCol = cloneDeep(col);
    if (removeWidth) {
      delete newCol.width;
    }
    // newCol.flex = 1;
    return newCol;
  });

  // console.log('cols', filtered);
  return filtered;
}

function Breadcrumbs() {
  const breadcrumbs = [
    {
      path: '/',
      text: 'Home',
    },
    {
      path: `/device-management`,
      text: 'Device Management',
    },
  ];
  return <BreadcrumbNav breadcrumbs={breadcrumbs} />;
}
