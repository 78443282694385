import React, { useEffect, useState } from 'react';
import { cloneDeep, sumBy, min, max, uniq } from 'lodash';
import { differenceInMinutes, parse, format } from 'date-fns';
import { DataGrid } from '@material-ui/data-grid';
import PlayArrow from '@material-ui/icons/PlayArrow';
import Tooltip from '@material-ui/core/Tooltip';
import { log } from '../../../util/log-util';
import canterburyData from './canterburyData.json';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Grid from '@material-ui/core/Grid';
import PollySummaryTable from './PollySummaryTable';
import Typography from '@material-ui/core/Typography';
import PollyHeatmap from '../../beekeeper/FieldOverviewPage/PollyHeatmap';
import { InputAdornment, Slider, Switch } from '@material-ui/core';
import Input from '@material-ui/core/Input';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { defaultDevices } from './PollyDashboard';

const columns = [
  // { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'beeCount',
    headerName: 'Bee Count',
    type: 'number',
    width: 150,
    // editable: true,
  },
  {
    field: 'minTemp',
    headerName: 'Min Temp',
    type: 'number',
    width: 150,
    // editable: true,
  },
  {
    field: 'maxTemp',
    headerName: 'Max Temp',
    type: 'number',
    width: 150,
    // editable: true,
  },
  {
    field: 'minHumidity',
    headerName: 'Min Humidity',
    type: 'number',
    width: 130,
    // editable: true,
  },
  {
    field: 'maxHumidity',
    headerName: 'Max Humidity',
    type: 'number',
    width: 130,
    // editable: true,
  },
];

const options2 = {
  chart: {
    zoomType: 'xy',
    height: 320,
  },
  title: {
    text: 'Average Monthly Temperature and Rainfall in Tokyo',
  },
  // subtitle: {
  //   text: 'Source: WorldClimate.com',
  // },
  xAxis: [
    {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      crosshair: true,
    },
  ],
  yAxis: [
    {
      // Primary yAxis
      labels: {
        format: '{value}°C',
        style: {
          color: Highcharts.getOptions().colors[1],
        },
      },
      title: {
        text: 'Temperature',
        style: {
          color: Highcharts.getOptions().colors[1],
        },
      },
    },
    {
      // Secondary yAxis
      title: {
        text: 'Bee count',
        style: {
          color: Highcharts.getOptions().colors[0],
        },
      },
      labels: {
        format: '{value} count',
        style: {
          color: Highcharts.getOptions().colors[0],
        },
      },
      opposite: true,
    },
  ],
  tooltip: {
    shared: true,
  },
  // legend: {
  //   layout: 'horizontal',
  //   align: 'left',
  //   x: 0,
  //   verticalAlign: 'top',
  //   y: -10,
  //   floating: true,
  //   backgroundColor:
  //     Highcharts.defaultOptions.legend.backgroundColor || // theme
  //     'rgba(255,255,255,0.25)',
  // },
  series: [
    {
      name: 'Bee Count',
      type: 'column',
      yAxis: 1,
      data: [49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4],
      tooltip: {
        valueSuffix: ' count',
      },
    },
    {
      name: 'Temperature',
      type: 'spline',
      data: [7.0, 6.9, 9.5, 14.5, 18.2, 21.5, 25.2, 26.5, 23.3, 18.3, 13.9, 9.6],
      tooltip: {
        valueSuffix: '°C',
      },
    },
  ],
};

const dayMap = {
  2: '20210802',
  3: '20210803',
  4: '20210804',
  5: '20210805',
  6: '20210806',
};

// let defaultDevices = [
//   {
//     deviceId: 'PNET_3A0',
//     location: { lat: 51.266373, lng: 1.138508 },
//     weight: 1,
//     pollinationActivity: '100%',
//   },
//   {
//     deviceId: 'PNET_111',
//     location: { lat: 51.266427, lng: 1.138594 },
//     weight: 1,
//     pollinationActivity: '100%',
//   },
//   {
//     deviceId: 'PNET_6E1',
//     location: { lat: 51.266454, lng: 1.138681 },
//     weight: 1,
//     pollinationActivity: '100%',
//   },
//   {
//     deviceId: 'PNET_D80',
//     location: { lat: 51.266481, lng: 1.13881 },
//     weight: 1,
//     pollinationActivity: '100%',
//   },
//   {
//     deviceId: 'PNET_AE6',
//     location: { lat: 51.266427, lng: 1.13881 },
//     weight: 1,
//     pollinationActivity: '100%',
//   },
//   {
//     deviceId: 'PNET_F80',
//     location: { lat: 51.2664, lng: 1.138724 },
//     weight: 1,
//     pollinationActivity: '100%',
//   },
//   {
//     deviceId: 'PNET_474',
//     location: { lat: 51.266319, lng: 1.138508 },
//     weight: 1,
//     pollinationActivity: '100%',
//   },
//   {
//     deviceId: 'PNET_C7F',
//     location: { lat: 51.266346, lng: 1.138638 },
//     weight: 1,
//     pollinationActivity: '100%',
//   },
// ];

export default function PollyCanterbury() {
  // const deviceIds = defaultDevices.map((device) => device.deviceId.substring(5, 8)); //['C7F', '474', 'F80', 'AE6', '3A0', '111', '6E1', 'D80'];
  const deviceIds = defaultDevices.map((device) => device.deviceId); //['C7F', '474', 'F80', 'AE6', '3A0', '111', '6E1', 'D80'];
  const defaultDay = 6;
  const defaultIntervalValue = 2;
  const sunrise = new Date('2021 Aug 03 05:25');
  const sunset = new Date('2021 Aug 03 20:37');
  const dayLightMinutes = differenceInMinutes(sunset, sunrise);
  const [devices, setDevices] = useState(defaultDevices);
  const [sliderValue, setSliderValue] = useState(defaultDay);
  const [day, setDay] = useState('all');
  const [intervalValue, setIntervalValue] = useState(defaultIntervalValue);
  const [playButtonDisabled, setPlayButtonDisabled] = useState(false);
  const [showMarkers, setShowMarkers] = useState(true);
  // const days = uniq(
  //   canterburyData.filter((entry) => entry.period.length > 8).map((entry) => entry.period.substring(0, 8))
  // );

  const getPollinationLevel = ({ pollinationActivity }) => {
    if (pollinationActivity < 0.1) {
      return '< 0.1%';
    } else {
      return pollinationActivity.toFixed(1) + '%';
    }
  };

  const getDevicesData = ({ devices, rows, dayCount }) => {
    let newDevices = cloneDeep(devices);

    for (let row of rows) {
      // let deviceId = 'PNET_' + row.deviceId;
      let deviceId = row.deviceId;
      // let weight = row.beeCount / dayCount / 10;
      let pollinationActivity = (row.beeCount / dayLightMinutes / dayCount) * 100;
      let device = newDevices.find((device) => device.deviceId === deviceId);
      device.weight = pollinationActivity;
      device.pollinationActivity = getPollinationLevel({ pollinationActivity });
    }

    return newDevices;
  };

  useEffect(() => {
    let dayCount = 1;
    if (!sliderValue) {
      dayCount = Object.keys(dayMap).length;
    }
    const rows = getSummaryDataByDay({
      deviceIds,
      day: dayMap[sliderValue],
    });
    const newDevices = getDevicesData({ devices, rows, dayCount });
    setDevices(newDevices);
  }, [sliderValue]);

  const sliderOnChange = (e, value) => {
    setSliderValue(value);
    setDay(dayMap[value]);
    const rows = getSummaryDataByDay({
      deviceIds,
      day,
    });
    const newDevices = getDevicesData({ devices, rows, dayCount: 1 });
    setDevices(newDevices);
  };

  const playAction = (e) => {
    setPlayButtonDisabled(true);
    let counter = 2;
    let days = Object.values(dayMap);
    const changeSlider = () => {
      let day = days[counter];
      setDay(day);
      setSliderValue(counter);
      counter++;
      if (counter > 6) {
        clearInterval(intervalId);
        setPlayButtonDisabled(false);
      }
    };

    changeSlider();
    let intervalId = setInterval(() => {
      // console.log('setInterval.started', new Date());
      changeSlider();
    }, intervalValue * 1000);
  };

  const intervalChange = (event) => {
    const newInterval = event.target.value;
    // console.log(event.target.value);
    // if (newInterval) {
      setIntervalValue(newInterval);
    // }
  };

  const interval = (
    <FormControl style={{ width: '180px' }} variant="outlined">
      <Input
        id="outlined-adornment-weight"
        value={intervalValue}
        onChange={intervalChange}
        endAdornment={<InputAdornment position="end">second interval</InputAdornment>}
        aria-describedby="outlined-weight-helper-text"
        inputProps={{
          'aria-label': 'weight',
        }}
      />
      {/*<FormHelperText id="outlined-weight-helper-text">Interval</FormHelperText>*/}
    </FormControl>
  );

  const showMarkersAction = (e) => setShowMarkers(!showMarkers);
  const showMarkersSwitch = (
    <FormControlLabel
      control={<Switch size="small" checked={showMarkers} onChange={showMarkersAction} />}
      label="Show markers"
    />
  );

  const showTotalAction = (e) => setSliderValue(undefined);
  const showTotalButton = (
    <Tooltip title="Calculation = bee minutes / day light minutes">
      <Button
        size={'small'}
        variant={'outlined'}
        color={'secondary'}
        onClick={showTotalAction}
        disabled={playButtonDisabled}
      >
        Total pollinator activity
      </Button>
    </Tooltip>
  );

  const navigation = (
    <Grid container spacing={3}>
      <Grid item xs={12} s={6} md={6} lg={3}>
        <Button
          size={'small'}
          color={'secondary'}
          variant={'outlined'}
          onClick={playAction}
          disabled={playButtonDisabled}
        >
          Pollinator activity by day
          <PlayArrow />
        </Button>
      </Grid>
      <Grid item xs={12} s={6} md={6} lg={3}>
        {showTotalButton}
      </Grid>
      <Grid item xs={12} s={6} md={6} lg={3}>
        {interval}
      </Grid>
      <Grid item xs={12} s={6} md={6} lg={3}>
        {showMarkersSwitch}
      </Grid>
    </Grid>
  );

  const date = (sliderValue=== undefined ? undefined : parse(dayMap[sliderValue], 'yyyyMMdd', new Date()));
  const currentView = (
    <Typography variant="subtitle1">
      Current View: {sliderValue === undefined ? 'Total Pollination' : format(date, 'd MMM')}
    </Typography>
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {/*<PollyTrialDescription />*/}
        <Header title={'Canterbury Trials Overview'} subtitle={'(2nd Aug to 6th Aug)'} />
        <Divider variant="middle" />
      </Grid>
      <Grid item xs={12}>
        <Header content={navigation} showContent={true} />
      </Grid>
      <Grid item xs={12}>
        <Header
          content={
            <PollyHeatmap
              devices={devices}
              showMarkers={showMarkers}
              currentView={currentView}
              slider={<DaySlider onChange={sliderOnChange} sliderValue={sliderValue} />}
              height={'350px'}
              width={'100%'}
              zoom={20}
              showTimeline={true}
              showTotalPollinationActivity={true}
              center
              circeMultiplier={50}
              // height={'350px'}
            />
          }
          showContent={true}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <div style={{ fontWeight: 'bold' }}>Overview of entire trial</div>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <PollyOverviewTableGrid />
      </Grid>
      <Grid item xs={6} sm={6} m d={6} lg={6}>
        <PollyTempComparison
          dataName="temp"
          title={'Temperature Comparison'}
          height={640}
          min={0}
          max={40}
          labelSymbol={'°C'}
          devices={devices}
        />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <PollyTempComparison
          dataName="humidity"
          title={'Humidity Comparison'}
          height={640}
          min={40}
          max={100}
          labelSymbol={'%'}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <PollyChartLayoutGrid />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <div style={{ fontWeight: 'bold' }}>Overview by Day</div>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <PollyDayOverviewTableGrid />
      </Grid>
    </Grid>
  );
}

function DaySlider({ onChange, sliderValue }) {
  const marks = [
    {
      value: 2,
      label: '2nd',
    },
    {
      value: 3,
      label: '3rd',
    },
    {
      value: 4,
      label: '4th',
    },
    {
      value: 5,
      label: '5th',
    },
    {
      value: 6,
      label: '6th',
    },
  ];

  const valuetext = (value) => {
    return `${value}`;
  };

  const valueLabelFormat = (value) => {
    return marks.findIndex((mark) => mark.value === value) + 1;
  };

  return (
    <Slider
      sx={{
        '& input[type="range"]': {
          WebkitAppearance: 'slider-vertical',
        },
      }}
      // orientation="vertical"
      aria-label="Restricted values"
      defaultValue={2}
      // valueLabelDisplay="on"
      valueLabelFormat={valueLabelFormat}
      getAriaValueText={valuetext}
      step={1}
      // valueLabelDisplay="auto"
      min={2}
      max={6}
      marks={marks}
      value={sliderValue}
      // onChange={(e, value) => console.log('slider', value)}
      onChange={onChange}
    />
  );
}

function PollyTrialDescription() {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="h5" style={{ marginBottom: '10px' }}>
          Canterbury Strawberry Trials (2nd Aug to 6th Aug)
        </Typography>
      </Grid>
    </Grid>
  );
}

function Header({ title, subtitle, content, showContent = false }) {
  return (
    <Card>
      {title && <CardHeader title={title} subheader={subtitle} />}
      {showContent && <CardContent>{content}</CardContent>}
    </Card>
  );
}

function PollyOverviewGrid() {
  return (
    <Grid container spacing={1}>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <PollyOverview deviceId={'C7F'} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <PollyOverview deviceId={'3A0'} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <PollyOverview deviceId={'474'} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <PollyOverview deviceId={'111'} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <PollyOverview deviceId={'F80'} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <PollyOverview deviceId={'6E1'} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <PollyOverview deviceId={'AE6'} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <PollyOverview deviceId={'D80'} />
      </Grid>
    </Grid>
  );
}

function PollyOverviewTableGrid() {
  const rows1 = getSummaryData({ deviceIds: ['C7F', '474', 'F80', 'AE6'] });
  const rows2 = getSummaryData({ deviceIds: ['3A0', '111', '6E1', 'D80'] });
  return (
    <Grid container spacing={1}>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <PollySummaryTable rows={rows1} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <PollySummaryTable rows={rows2} />
      </Grid>
    </Grid>
  );
}

function PollyDayOverviewTableGrid() {
  const dayFilters = ['20210802', '20210803', '20210804', '20210805', '20210806'];
  let rows1 = [];
  let rows2 = [];
  let rows3 = [];
  let rows4 = [];
  let rows5 = [];
  let rows6 = [];
  let rows7 = [];
  let rows8 = [];
  for (let dayFilter of dayFilters) {
    const rows1Day = getSummaryDataByDay({ deviceIds: ['C7F'], dayFilter });
    const rows2Day = getSummaryDataByDay({ deviceIds: ['3A0'], dayFilter });
    const rows3Day = getSummaryDataByDay({ deviceIds: ['474'], dayFilter });
    const rows4Day = getSummaryDataByDay({ deviceIds: ['111'], dayFilter });
    const rows5Day = getSummaryDataByDay({ deviceIds: ['F80'], dayFilter });
    const rows6Day = getSummaryDataByDay({ deviceIds: ['6E1'], dayFilter });
    const rows7Day = getSummaryDataByDay({ deviceIds: ['AE6'], dayFilter });
    const rows8Day = getSummaryDataByDay({ deviceIds: ['D80'], dayFilter });
    rows1 = rows1.concat(rows1Day);
    rows2 = rows2.concat(rows2Day);
    rows3 = rows3.concat(rows3Day);
    rows4 = rows4.concat(rows4Day);
    rows5 = rows5.concat(rows5Day);
    rows6 = rows6.concat(rows6Day);
    rows7 = rows7.concat(rows7Day);
    rows8 = rows8.concat(rows8Day);
  }

  console.log('rows1', rows1);

  return (
    <Grid container spacing={1}>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <PollySummaryTable rows={rows1} showDay={true} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <PollySummaryTable rows={rows2} showDay={true} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <PollySummaryTable rows={rows3} showDay={true} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <PollySummaryTable rows={rows4} showDay={true} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <PollySummaryTable rows={rows5} showDay={true} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <PollySummaryTable rows={rows6} showDay={true} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <PollySummaryTable rows={rows7} showDay={true} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <PollySummaryTable rows={rows8} showDay={true} />
      </Grid>
    </Grid>
  );
}

function getSummaryData({ deviceIds }) {
  let data = [];
  for (let deviceId of deviceIds) {
    const deviceData = getSummaryDataByDeviceId({ deviceId });
    data.push(deviceData);
  }
  return data;
}

function getSummaryDataByDay({ deviceIds, day }) {
  let data = [];
  for (let deviceId of deviceIds) {
    let deviceData = [];
    if (day === 'all' || !day) {
      deviceData = getSummaryDataByDeviceId({ deviceId });
    } else {
      deviceData = getSummaryDataByDeviceId({ deviceId, dayFilter: day });
    }

    data.push(deviceData);
  }
  return data;
}

function getSummaryDataByDeviceId({ deviceId, dayFilter = undefined }) {

  let pollyData = cloneDeep(canterburyData);
  // console.log('getSummaryDataByDeviceId', { deviceId, dayFilter , pollyData});
  if (dayFilter) {
    pollyData = pollyData.filter(
      (entry) =>
        entry.deviceId === deviceId &&
        entry.period !== '-' &&
        entry.period.substring(0, 4) !== '1990' &&
        entry.period.substring(0, 8) === dayFilter
    );
  } else {
    pollyData = pollyData.filter(
      (entry) =>
        entry.deviceId === deviceId && entry.period !== '-' && entry.period.substring(0, 4) !== '1990'
    );
  }

  pollyData = pollyData.sort((a, b) => b.period - a.period);
  const tempData = pollyData.filter((entry) => entry.temp && !isNaN(entry.temp)).map((entry) => entry.temp);
  const humidityData = pollyData
    .filter((entry) => entry.humidity && !isNaN(entry.humidity))
    .map((entry) => entry.humidity);
  const beeCount = sumBy(pollyData, 'count');
  const minTemp = min(tempData);
  const maxTemp = max(tempData);
  const minHumidity = min(humidityData);
  const maxHumidity = max(humidityData);
  return {
    id: deviceId,
    deviceId,
    day: dayFilter,
    beeCount,
    minTemp: !isNaN(minTemp) && minTemp.toFixed(1),
    maxTemp: !isNaN(maxTemp) && maxTemp.toFixed(1),
    minHumidity: !isNaN(minHumidity) && minHumidity.toFixed(1),
    maxHumidity: !isNaN(maxHumidity) && maxHumidity.toFixed(1),
  };
}

function PollyOverviewTable({ rows = [] }) {
  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        hideFooter={true}
        // pageSize={5}
        // checkboxSelection
        // disableSelectionOnClick
      />
    </div>
  );
}

function PollyOverview({ deviceId = '111' }) {
  let singlePollyData = cloneDeep(canterburyData);
  // let options = cloneDeep(options2);
  singlePollyData = singlePollyData.filter(
    (entry) => entry.deviceId === deviceId && entry.period !== '-' && entry.period.substring(0, 4) !== '1990'
  );
  singlePollyData = singlePollyData.sort((a, b) => b.period - a.period);
  const categories = singlePollyData.map((entry) => entry.period.replace('202108', 'Aug'));
  const beeCount = singlePollyData.map((entry) => entry.count);
  const temp = singlePollyData.map((entry) => entry.temp);
  const totalBeeCount = sumBy(singlePollyData, 'count');
  return (
    <div>
      {deviceId}: {totalBeeCount}
    </div>
  );
}

function PollyTempComparison({ title, dataName, height, min, max, labelSymbol, devices }) {
  // console.log('PollyTempComparison', devices);
  let singlePollyData = cloneDeep(canterburyData);
  let options = cloneDeep(options2);
  singlePollyData = singlePollyData.filter(
    (entry) => entry.deviceId === '111' && entry.period !== '-' && entry.period.substring(0, 4) !== '1990'
  );
  singlePollyData = singlePollyData.sort((a, b) => b.period - a.period);
  const categories = singlePollyData.map((entry) => entry.period.replace('202108', 'Aug'));
  const xAxis = [
    {
      categories,
      crosshair: true,
    },
  ];

  let series = [];
  for (let device of defaultDevices) {
    const deviceId = device.deviceId.substring(5, 8);
    const seriesData = getSeriesData({ deviceId, dataName });
    const seriesEntry = {
      name: deviceId,
      type: 'line',
      data: seriesData,
      tooltip: {
        valueSuffix: labelSymbol,
      },
      marker: {
        enabled: false,
      },
    };
    series.push(seriesEntry);
  }

  const yAxis = [
    {
      // Primary yAxis
      max,
      min,
      labels: {
        format: `{value}${labelSymbol}`,
        // style: {
        //   color: Highcharts.getOptions().colors[1],
        // },
      },
      title: {
        text: title,
        style: {
          color: Highcharts.getOptions().colors[1],
        },
      },
    },
  ];

  options.yAxis = yAxis;
  options.xAxis = xAxis;
  options.series = series;
  options.title.text = title;
  options.chart.height = height;

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}

function getSeriesData({ deviceId, dataName }) {
  let singlePollyData = cloneDeep(canterburyData);
  // let options = cloneDeep(options2);
  singlePollyData = singlePollyData.filter(
    (entry) => entry.deviceId === deviceId && entry.period !== '-' && entry.period.substring(0, 4) !== '1990'
  );
  singlePollyData = singlePollyData.sort((a, b) => b.period - a.period);
  // const categories = singlePollyData.map((entry) => entry.period.replace('202108', 'Aug'));
  // const beeCount = singlePollyData.map((entry) => entry.count);
  const temp = singlePollyData.map((entry) => entry[dataName]);
  return temp;
}

function PollyChartLayoutGrid() {
  return (
    <Grid container spacing={1}>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <SinglePollyChart deviceId={'C7F'} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <SinglePollyChart deviceId={'3A0'} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <SinglePollyChart deviceId={'474'} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <SinglePollyChart deviceId={'111'} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <SinglePollyChart deviceId={'F80'} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <SinglePollyChart deviceId={'6E1'} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <SinglePollyChart deviceId={'AE6'} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <SinglePollyChart deviceId={'D80'} />
      </Grid>
    </Grid>
  );
}

function SinglePollyChart({ deviceId }) {
  let singlePollyData = cloneDeep(canterburyData);
  let options = cloneDeep(options2);
  singlePollyData = singlePollyData.filter(
    (entry) => entry.deviceId === deviceId && entry.period !== '-' && entry.period.substring(0, 4) !== '1990'
  );
  singlePollyData = singlePollyData.sort((a, b) => b.period - a.period);
  const categories = singlePollyData.map((entry) => entry.period.replace('202108', 'Aug'));
  const beeCount = singlePollyData.map((entry) => entry.count);
  const temp = singlePollyData.map((entry) => entry.temp);
  // console.log('PollyCanterbury.data', singlePollyData);
  const xAxis = [
    {
      // categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      categories,
      crosshair: true,
    },
  ];

  const series = [
    {
      name: 'Bee Count',
      type: 'column',
      yAxis: 1,
      data: beeCount,
      tooltip: {
        valueSuffix: ' bee count',
      },
    },
    {
      name: 'Temperature',
      type: 'spline',
      data: temp,
      tooltip: {
        valueSuffix: '°C',
      },
    },
  ];

  const yAxis = [
    {
      // Primary yAxis
      max: 40,
      min: 0,
      labels: {
        format: '{value}°C',
        style: {
          color: Highcharts.getOptions().colors[1],
        },
      },
      title: {
        text: 'Temperature',
        style: {
          color: Highcharts.getOptions().colors[1],
        },
      },
    },
    {
      // Secondary yAxis
      title: {
        text: 'Bee count',
        style: {
          color: Highcharts.getOptions().colors[0],
        },
      },
      labels: {
        format: '{value}',
        style: {
          color: Highcharts.getOptions().colors[0],
        },
      },
      opposite: true,
    },
  ];

  options.yAxis = yAxis;
  options.xAxis = xAxis;
  options.series = series;
  options.title.text = deviceId;

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}
