import React, { useState } from 'react';
import { useFirestore } from 'react-redux-firebase';
import { CustomSelect } from '../Shared/Dropdown';
import { DateRangeFilter } from '../Shared/DateRange';
import { TextareaAutosize, Button, Grid, CircularProgress } from '@mui/material';
import { farmOptions, siteOptions } from './sampleData';
import FileInput from '../Shared/CustomFileInput';
import { putImagetoStorage } from '../Shared/ahsanUtil';
import { useSelector } from 'react-redux';
import { getProjectIds, isProjectAdmin } from '../../../config/env';

export default function AddNoteForm() {
  const userData = useSelector((state) => state.appConfig.userData);
  const isAdmin = isProjectAdmin(userData);
  let projectIds = getProjectIds(userData);
  const projectId = projectIds[0];
  const firestore = useFirestore();
  const defaultFormFilters = {
    selectedFilter: 'Site',
    selectedValue: '',
    text: '',
    noteDateFrom: '',
    noteDateTo: '',
  };
  const [noteFormFilters, setNoteFormFilters] = useState(defaultFormFilters);
  const [error, setError] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialDateRangeValues, setInitialDateRangeValues] = useState(null);

  const onGroupLevelChange = (e) => {
    const { value } = e.target;
    setNoteFormFilters({ ...noteFormFilters, selectedValue: value });
  };

  const onFilterChange = (e) => {
    const { value } = e.target;
    setNoteFormFilters({ ...noteFormFilters, selectedFilter: value });
  };

  const onTextAreaChange = (e) => {
    const { value } = e.target;
    setNoteFormFilters({ ...noteFormFilters, text: value });
  };

  const onDateFilterChange = ({ startDate, endDate }) => {
    setNoteFormFilters({ ...noteFormFilters, noteDateFrom: startDate, noteDateTo: endDate });
    setInitialDateRangeValues('');
  };

  const handlePhotoChange = (files) => {
    const file = files;
    setPhotos([...photos, ...file]);
  };

  const clearNoteForm = () => {
    setNoteFormFilters(defaultFormFilters);
    setInitialDateRangeValues([null, null]);
    setPhotos([]);
  };

  const addNotesData = (e) => {
    e.preventDefault();
    const imgPath = 'noteImages/';
    setLoading(true);
    if (photos.length <= 5) {
      try {
        Promise.all(photos.map((img) => putImagetoStorage({ img, imgPath }))).then((url) => {
          firestore.collection('notes').add({
            type: 'note',
            projectId,
            dateCreated: new Date(),
            noteDateFrom: noteFormFilters.noteDateFrom,
            noteDateTo: noteFormFilters.noteDateTo,
            groupingLevel: noteFormFilters.selectedFilter,
            groupingLevelValue: noteFormFilters.selectedValue,
            text: noteFormFilters.text,
            photos: url,
          });
          clearNoteForm();
          setLoading(false);
          setError(false);
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      setError(true);
      setLoading(false);
      setPhotos([]);
    }
  };

  return (
    <form style={{ padding: '20px' }} onSubmit={addNotesData}>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <CustomSelect
            options={[
              { label: 'Farm', value: 'Farm' },
              { label: 'Site', value: 'Site' },
            ]}
            onSelectChange={onFilterChange}
            label={'Group Level'}
            selectedValue={noteFormFilters.selectedFilter}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <CustomSelect
            options={noteFormFilters.selectedFilter === 'Site' ? siteOptions : farmOptions}
            onSelectChange={onGroupLevelChange}
            label={'Group Level Value'}
            selectedValue={noteFormFilters.selectedValue}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <DateRangeFilter
            resetDateRangeValues={initialDateRangeValues}
            onFilterChanged={onDateFilterChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextareaAutosize
            aria-label="minimum height"
            value={noteFormFilters.text}
            minRows={3}
            placeholder="Note..."
            style={{ width: '100%', margin: '0px' }}
            onChange={onTextAreaChange}
            data-testid="note-text"
          />
        </Grid>
        <Grid item xs={12}>
          <FileInput
            accept="image/*"
            id="imgs"
            onChange={handlePhotoChange}
            name="upload-photo"
            type="file"
            multiple
            // ref={imgRef}
            value={photos}
            data-testid="photos"
          />
          {error && <span style={{ color: 'red' }}>Images Must Be Less than or Equal to 5</span>}
        </Grid>
        <Grid item xs={12}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Button type={'submit'} variant={'contained'}>
              Submit Note
            </Button>
          )}
          <Button style={{ marginLeft: '4px' }} onClick={clearNoteForm}>
            Reset Form
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
