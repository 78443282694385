import React from 'react';
import { Avatar, Box, Card, CardContent, Grid, Typography } from '@mui/material';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
  orange,
  green,
  blue,
  yellow,
  red,
  indigo,
  deepOrange,
  deepPurple,
  purple,
  blueGrey,
} from '@mui/material/colors';
import { globalElevation } from '../../../config/globalStyle';
import PhoneLinkRingIcon from '@mui/icons-material/PhonelinkRing';
import OpacityIcon from '@material-ui/icons/Opacity';
import BatteryAlertIcon from '@material-ui/icons/BatteryAlert';
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';
import LowSignal from '@material-ui/icons/SignalCellularConnectedNoInternet1BarRounded';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { Icon } from '@iconify/react';
import {
  getBatteryTrendText,
  getHeatMapColour,
  getLatestFieldValue,
  getMetric,
  getMetricValue,
  statNames,
} from './stats-util';
import { isEqual } from 'lodash';
import Highlight from './Highlight';
import { get } from 'lodash';

//https://icon-sets.iconify.design/mdi/
const iconNames = {
  // beeActivity: <Icon icon="mdi:bee" height="40" color="orange" />,
  phoneLink: <PhoneLinkRingIcon />,
  // temperature: <Icon icon="mdi:thermometer" height="36" color="green" />,
  // temperature: <Icon icon="mdi:thermometer" height="36" color="blueGrey" />,
  temperature: <Icon icon="mdi:thermometer" height="36" color="white" />,
  beeActivity: <Icon icon="mdi:bee" height="36" color="white" />,
  humidity: <OpacityIcon height="30" />,
  light: <WbIncandescentIcon />,
  battery: <BatteryAlertIcon />,
  noLocation: <NotListedLocationIcon />,
  lowRssi: <LowSignal height="20" style={{ color: 'blue' }} />,
  // battery: <Icon icon="akar-icons:battery-low" color="red" height="36" rotate={3} />,
  // humidity: <Icon icon="ic:outline-opacity" color="blue" height="40" />,
  // light: <Icon icon="akar-icons:light-bulb" color="orange" height="36" />,
};

const iconColours = {
  orange,
  green,
  blue,
  yellow,
  red,
  indigo,
  deepOrange,
  deepPurple,
  purple,
  blueGrey,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    fontSize: 'medium',
  },
  subheader: {
    fontSize: 'medium',
  },
  card: {
    borderRadius: '10px',
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    boxShadow: 'none',
  },
  paper: {
    padding: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    // backgroundColor: 'white',
  },
}));

const emptyLocation = {};

export default function PollyDeviceOverviewLiteWidget({
  title = 'Total Pollinator Activity',
  device,
  deviceStats,
  showSite = false,
  showTrends = false,
}) {
  // const backgroundColor = deviceStats ? deviceStats.colour : '#F0F0F0';
  const backgroundColor = getHeatMapColour({ device, deviceStats });
  return (
    <Card elevation={globalElevation} style={{ borderRadius: '5px' }}>
      <CardContent style={{ backgroundColor: '#F0F0F0' }}>
        <Grid container spacing={0}>
          <Grid item xs={11}>
            <Left
              device={device}
              title={title}
              showSite={showSite}
              showTrends={showTrends}
              backgroundColor={backgroundColor}
            />
          </Grid>
          <Grid item xs={1}>
            <Right device={device} deviceStats={deviceStats} />
          </Grid>
          {showTrends && (
            <Grid item xs={12} style={{ marginTop: '5px' }}>
              <Trends device={device} />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}

function Right({ device, deviceStats }) {
  const height = 27;
  let hasNoLocation = !device.location || isEqual(device.location, emptyLocation);
  const showBatteryIcon =
    (deviceStats && deviceStats.stats && deviceStats.stats.includes(statNames.lowBattery)) || false;

  const showLowRssi =
    (deviceStats && deviceStats.stats && deviceStats.stats.includes(statNames.lowRssi)) || false;
  return (
    <Grid container spacing={0}>
      {showBatteryIcon && (
        <Grid item xs={12}>
          <Avatar
            sx={{
              backgroundColor: iconColours.red[600],
              height: height,
              width: height,
              marginRight: 5,
              marginBottom: 1,
            }}
          >
            {iconNames.battery}
          </Avatar>
        </Grid>
      )}
      {hasNoLocation && (
        <Grid item xs={12}>
          <Avatar
            sx={{
              backgroundColor: iconColours.green[600],
              height: height,
              width: height,
            }}
          >
            {iconNames.noLocation}
          </Avatar>
        </Grid>
      )}
      {showLowRssi && (
        <Grid item xs={12}>
          {iconNames.lowRssi}
        </Grid>
      )}
    </Grid>
  );
}

function Left({ device, title, showSite, showTrends = true, backgroundColor }) {
  let version = (device && device.latestEvent && device.latestEvent.version) || 'n/a';
  let rssi = (device && device.latestEvent && device.latestEvent.rssi) || 'n/a';
  let sdCardPercent = (device && device.latestEvent && device.latestEvent.sdCardPercent) || 'n/a';
  let lastUpdated = (device && device.latestEvent && device.latestEvent.dateCreated.toDate()) || 'n/a';
  if (lastUpdated instanceof Date) lastUpdated = format(lastUpdated, 'dd MMM HH:mm:ss');
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <div>
          <Typography variant="subtitle1">
            <Highlight text={device.deviceId} color={'black'} backgroundColor={backgroundColor} />
          </Typography>
          <Typography variant="caption">Name: {device.deviceName}</Typography>
        </div>
        {showSite && (
          <div>
            <Typography variant="caption">Site: {device.siteName}</Typography>
          </div>
        )}
        <Typography variant="subtitle2">
          <div>{lastUpdated}</div>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <DeviceData device={device} />
      </Grid>
      <Grid item xs={6}>
        <Typography variant="caption" style={{ fontStyle: 'normal' }}>
          RSSI: {rssi}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="caption" style={{ fontStyle: 'normal' }}>
          SD: {sdCardPercent}%
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="caption"
          style={{ fontStyle: 'normal', fontSize: '11px', marginTop: '5px', marginBottom: '5px' }}
        >
          Version: {version}
        </Typography>
      </Grid>
    </Grid>
  );
}

const batteryWillRunOut = 3;
const sdCardMax = 95;
function Trends({ device }) {
  const classes = useStyles();
  const metrics = device.metrics;
  const batteryMetric = getMetric(metrics, 'battery');
  const battery7DaysAgo = getMetricValue({ metric: batteryMetric, fieldName: '_7dayValue', suffix: '' });
  const batteryLatest = getLatestFieldValue(device, 'battery');
  const sdCardPercentMetric = getMetric(metrics, 'sdCardPercent');
  const sdCardPercent7DaysAgo = getMetricValue({
    metric: sdCardPercentMetric,
    fieldName: '_7dayValue',
    suffix: '',
  });
  const sdCardPercentLatest = getLatestFieldValue(device, 'sdCardPercent');
  const beeActivityMetric = getMetric(metrics, 'beeActivity');

  let batteryTrend = getBatteryTrendText({ batteryLatest, battery7DaysAgo, batteryWillRunOut });
  let sdCardTrend = getSdCardTrendText({ sdCardPercentLatest, sdCardPercent7DaysAgo });
  let beeActivityVariance = getBeeActivityVariance(beeActivityMetric);

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={0} style={{ marginTop: 5, marginBottom: 5 }}>
        <Grid item xs={12}>
          <Typography variant="caption" style={{ fontStyle: 'bold', fontSize: '13px', marginTop: '5px' }}>
            {batteryTrend}
          </Typography>
        </Grid>
        {device.rebootMetrics && (
          <Grid item xs={12}>
            <Typography variant="caption" style={{ fontStyle: 'bold', fontSize: '13px', marginTop: '5px' }}>
              Reboots today: {device.rebootMetrics._1dayCount}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="caption" style={{ fontStyle: 'bold', fontSize: '13px', marginTop: '5px' }}>
            {sdCardTrend}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption" style={{ fontStyle: 'bold', fontSize: '13px', marginTop: '5px' }}>
            {beeActivityVariance}
          </Typography>
        </Grid>
        {/*<Grid item xs={12}>*/}
        {/*  <Typography variant="caption" style={{ fontStyle: 'bold', fontSize: '13px', marginTop: '5px' }}>*/}
        {/*    {JSON.stringify(metrics)}*/}
        {/*  </Typography>*/}
        {/*</Grid>*/}
      </Grid>
    </Paper>
  );
}

function getBeeActivityVariance(metric) {
  const labelWidth = 3;
  const varianceWidth = 12 - labelWidth;
  const marginTop = '0px';
  let fields = [
    '_30dayHigh.avg',
    '_30dayLow.avg',
    '_30dayAvg',
    '_7dayHigh.avg',
    '_7dayLow.avg',
    '_7dayAvg',
    '_1dayHigh.avg',
    '_1dayLow.avg',
    '_1dayAvg',
  ];
  let fieldValues = {};
  for (let fieldName of fields) {
    fieldValues[fieldName] = getMetricValue({ metric: metric, fieldName, suffix: '' });
  }

  return (
    <Grid container spacing={0} style={{ marginTop: 5, marginBottom: 5 }}>
      <Grid item xs={labelWidth} style={{ fontStyle: 'bold', fontSize: '12px', marginTop }}>
        <Typography variant="caption" style={{ fontStyle: 'bold', fontSize: '12px', marginTop }}>
          Bee 1D
        </Typography>
      </Grid>
      <Grid item xs={varianceWidth}>
        <VarianceBasic
          low={fieldValues['_1dayLow.avg']}
          avg={fieldValues['_1dayAvg']}
          hi={fieldValues['_1dayHigh.avg']}
        />
      </Grid>
      {/*<Grid item xs={12}>*/}
      {/*  <Variance low={fieldValues['_1dayLow.avg']} avg={fieldValues['_1dayAvg']} hi={fieldValues['_1dayHigh.avg']} />*/}
      {/*</Grid>*/}
      <Grid item xs={labelWidth}>
        <Typography variant="caption" style={{ fontStyle: 'bold', fontSize: '12px', marginTop }}>
          Bee 7D
        </Typography>
      </Grid>
      <Grid item xs={varianceWidth}>
        <VarianceBasic
          low={fieldValues['_7dayLow.avg']}
          avg={fieldValues['_7dayAvg']}
          hi={fieldValues['_7dayHigh.avg']}
        />
      </Grid>
      {/*<Grid item xs={12}>*/}
      {/*  <Variance low={fieldValues['_7dayLow.avg']} avg={fieldValues['_7dayAvg']} hi={fieldValues['_7dayHigh.avg']} />*/}
      {/*</Grid>*/}
      <Grid item xs={labelWidth}>
        <Typography variant="caption" style={{ fontStyle: 'bold', fontSize: '12px', marginTop }}>
          Bee 30D
        </Typography>
      </Grid>
      <Grid item xs={varianceWidth}>
        <VarianceBasic
          low={fieldValues['_30dayLow.avg']}
          avg={fieldValues['_30dayAvg']}
          hi={fieldValues['_30dayHigh.avg']}
        />
      </Grid>
      {/*<Grid item xs={12}>*/}
      {/*  <Variance low={fieldValues['_30dayLow.avg']} avg={fieldValues['_30dayAvg']} hi={fieldValues['_30dayHigh.avg']} />*/}
      {/*</Grid>*/}
    </Grid>
  );
}

function VarianceBasic({ low, hi: high, avg, suffix = '%' }) {
  // return <div>low: {low} hi: {hi} avg: {avg}</div>;
  let variance = 'n/a';
  if (low !== 'n/a' && high !== 'n/a') {
    variance = (high - low).toFixed(1);
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        // p: 1,
        // m: 1,
        bgcolor: 'background.paper',
        maxWidth: 250,
        borderRadius: 1,
      }}
    >
      <div style={{ marginTop: '14px', marginRight: '2px', fontSize: '10px', color: 'red' }}>{low}</div>
      <div style={{ marginTop: '0px', fontSize: '14px', color: 'black' }}>{avg}</div>
      <div style={{ marginTop: '-6px', fontSize: '10px', color: 'green' }}>{high}</div>
      {variance !== 'n/a' && (
        <div style={{ marginTop: '4px', marginLeft: '5px', fontSize: '12px', color: 'blue' }}>
          ({variance})
        </div>
      )}
    </Box>
  );
}
function Variance({ low, hi, avg, suffix = '%' }) {
  let totalWidth = 1000;
  let width1 = (totalWidth * low) / 100;
  let width2 = (totalWidth * (avg - low)) / 100;
  let width3 = (totalWidth * (hi - avg)) / 100;
  let width4 = (totalWidth * (100 - hi)) / 100;
  let height = 15;
  let values = [low, hi, avg];
  if (values.includes('n/a') || values.some((val) => val > 100)) {
    return <></>;
  }
  return (
    <div style={{ marginBottom: '20px' }}>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          // p: 1,
          // m: 1,
          bgcolor: 'background.paper',
          maxWidth: 250,
          borderRadius: 1,
        }}
      >
        {/*<div style={{ width: '40px', marginRight: '5px'}}>{title}</div>*/}
        <div
          style={{ width: width1, height, borderTop: 'solid', borderBottom: 'solid', borderLeft: 'solid' }}
        ></div>
        <div
          style={{
            width: width2,
            height,
            borderTop: 'solid',
            borderBottom: 'solid',
            borderLeft: 'solid',
            backgroundColor: 'lightblue',
          }}
        ></div>
        <div
          style={{
            width: width3,
            height,
            borderTop: 'solid',
            borderBottom: 'solid',
            borderLeft: 'solid blue 3px',
            backgroundColor: 'lightblue',
          }}
        ></div>
        <div
          style={{
            width: width4,
            height,
            borderTop: 'solid',
            borderBottom: 'solid',
            borderRight: 'solid',
            borderLeft: 'solid',
          }}
        ></div>
      </Box>
      {/*<Box*/}
      {/*  sx={{*/}
      {/*    display: 'flex',*/}
      {/*    flexWrap: 'nowrap',*/}
      {/*    // p: 1,*/}
      {/*    m: 1,*/}
      {/*    marginTop: 0,*/}
      {/*    marginLeft: -0.9,*/}
      {/*    bgcolor: 'background.paper',*/}
      {/*    maxWidth: 300,*/}
      {/*    borderRadius: 1,*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <div style={{ width: width1, height: 10 }}></div>*/}
      {/*  <div style={{ width: width2, height: 10 }}></div>*/}
      {/*  <div style={{ width: width3, height: 10, fontSize: '10px' }}>*/}
      {/*    {avg}*/}
      {/*    {suffix}*/}
      {/*  </div>*/}
      {/*  <div style={{ width: width4, height: 10 }}></div>*/}
      {/*</Box>*/}
    </div>
    // <div style={{ marginTop: 35, paddingLeft: 0 }}>{low}</div>
  );
}

function getSdCardTrendText({ sdCardPercentLatest, sdCardPercent7DaysAgo }) {
  let badData = ['0', 0, 'n/a'];
  let sdCardTrend = 'SD Card: not enough data to calc';
  if (!badData.includes(sdCardPercent7DaysAgo) && !badData.includes(sdCardPercentLatest)) {
    const moveIn1Week = sdCardPercentLatest - sdCardPercent7DaysAgo;
    if (moveIn1Week < 0) {
      sdCardTrend = `SD Card has reduced by ${moveIn1Week.toFixed(1)}%`;
    } else if (moveIn1Week > 0) {
      const weeksToFillUp = ((sdCardMax - sdCardPercentLatest) / moveIn1Week).toFixed(1);
      sdCardTrend = `SD Card will fill up in ${weeksToFillUp} weeks`;
    }
  }

  return sdCardTrend;
}

function BasicTable() {
  const classes = useStyles();
  const rows = [{}];

  return (
    // <TableContainer component={Paper}>
    <Table className={classes.table} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>Value</TableCell>
          <TableCell align="right">Today</TableCell>
          <TableCell align="right">7 days</TableCell>
          <TableCell align="right">Move</TableCell>
          <TableCell align="right">Protein&nbsp;(g)</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <TableRow key={row.name}>
            <TableCell component="th" scope="row">
              {row.name}
            </TableCell>
            <TableCell align="right">{row.calories}</TableCell>
            <TableCell align="right">{row.fat}</TableCell>
            <TableCell align="right">{row.carbs}</TableCell>
            <TableCell align="right">{row.protein}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    // </TableContainer>
  );
}

function DeviceData({ device, color }) {
  const metrics = device.metrics;
  let style = color ? { color } : {};
  const beeActivity = getMetric(metrics, 'beeActivity');
  const temperature = getMetric(metrics, 'temperature');
  const humidity = getMetric(metrics, 'humidity');
  const temp = getMetricValue({ metric: temperature, fieldName: '_1dayAvg', suffix: '' });
  const hum = getMetricValue({ metric: humidity, fieldName: '_1dayAvg', suffix: '%' });
  const activity = getMetricValue({ metric: beeActivity, fieldName: '_1dayAvg', suffix: '%' });
  const battery = getLatestFieldValue(device, 'battery');

  return (
    <Grid container spacing={0} style={{ marginTop: 15, marginBottom: 15 }}>
      <Grid item xs={6}>
        <Metric value={`Temp: ${temp}`} style={style} />
      </Grid>
      <Grid item xs={6}>
        <Metric value={`Hum: ${hum}`} style={style} />
      </Grid>
      <Grid item xs={6}>
        <Metric value={`Activity: ${activity}`} style={style} />
      </Grid>
      <Grid item xs={6}>
        <Metric value={`Battery: ${battery}`} style={style} />
      </Grid>
    </Grid>
  );
}

function Metric({ value, style }) {
  return (
    <Box color="textSecondary" gutterBottom variant="subtitle1" fontWeight="fontWeightBold" style={style}>
      {value}
    </Box>
  );
}

function getIcon(iconName) {
  return iconNames[iconName];
}

function getIconColour(movementDirection) {
  return movementDirection === 'up' ? iconColours['green'] : iconColours['orange'];
}

function getActivity(currentValue) {
  return currentValue + '%';
}
