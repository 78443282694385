import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import BreadcrumbNav from '../Nav/BreadcrumbsNav';
import ArticleListWidget from '../Widgets/ArticleListWidget';

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '100%',
    // maxWidth: '48ch',
    backgroundColor: theme.palette.background.paper,
  },
  titleWide: {
    [theme.breakpoints.down('sm')]: {
      width: '300px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('md')]: {
      width: '700px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('lg')]: {
      width: '700px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      width: '300px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('md')]: {
      width: '500px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('lg')]: {
      width: '200px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  paper: {
    width: '100%',
    // maxWidth: '48ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  button: {
    textTransform: 'none',
    paddingTop: '28px',
    paddingLeft: '80px',
  },
  link: {
    marginLeft: '28px',
  },
  linkNoSubtitle: {
    fontSize: '14px',
    marginLeft: '28px',
  },
  listItemSecondary: {
    width: '85%',
    fontWeight: 'normal',
    color: '#7a7a7a',
    overflow: 'hidden',
  },
}));

const linkWidth = 1;
const showSubtitle = false;
const isComponentWide = true;
const schemaName = 'newsAndEvents';
const routePath = 'news';
const showLink = true;

export default function NewsListPage() {
  // console.log('NewsListPage');
  const classes = useStyles();
  const articlesData = useSelector(({ firestore: { data } }) => data[schemaName]);
  const articlesQuery = [
    {
      collection: 'fl_content',
      where: [
        ['_fl_meta_.schema', '==', schemaName],
        // ['accountType', '==', 'beekeeper'],
      ],
      storeAs: schemaName,
    },
  ];

  useFirestoreConnect(articlesQuery);

  if (!isLoaded(articlesData)) {
    return 'Loading...';
  }

  let articles = Object.values(articlesData || []);

  return (
    <Grid justify={'flex-start'} container spacing={2}>
      <Grid item xs={12}>
        <Breadcrumbs />
      </Grid>
      <Grid item xs={12}>
        <ArticleListWidget
          overrideClasses={classes}
          articles={articles}
          routePath={routePath}
          linkWidth={linkWidth}
          showLink={showLink}
          showSubtitle={showSubtitle}
          isComponentWide={isComponentWide}
        />
      </Grid>
    </Grid>
  );
}

function Breadcrumbs() {
  const breadcrumbs = [
    {
      path: '/',
      text: 'Home',
    },
    {
      path: `/news`,
      text: 'News & Events',
    },
  ];
  return <BreadcrumbNav breadcrumbs={breadcrumbs} />;
}
