import { Autocomplete, FormControl, TextField } from '@mui/material';
import React from 'react';

export default function PollyAutocomplete({
  options,
  defaultValue,
  disabled,
  onChange,
  value,
  sx = { m: 1, width: '100%', margin: 0 },
  multiple = true,
  label,
  placeholder,
}) {
  return (
    <FormControl sx={sx}>
      <Autocomplete
        disabled={disabled}
        onChange={onChange}
        value={value}
        multiple={multiple}
        id="tags-outlined"
        options={options}
        // getOptionLabel={(option) => option}
        defaultValue={defaultValue}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label={label} placeholder={placeholder} />
        )}
      />
    </FormControl>
  );
}
