const alertFieldNames = {
  humidity: 'humidity',
  temperature: 'temperature',
  weight: 'weight',
  battery: 'battery',
  temp: 'temp',
  rain: 'rain',
};

const alertsConfig = {
  theftAlert: {
    alertId: 'theftAlert',
    alertName: 'Theft Alert',
  },
  movement: {
    alertId: 'movementAlert',
    alertName: 'Movement Alert',
  },
  locationChange: 'locationChange'
}

const defaultAlertsConfig = [
  {
    alertId: 'humidity_range',
    alertName: 'Humidity Min/Max',
    alertLabel: 'Humidity',
    eventFieldName: alertFieldNames.humidity,
    min: 35,
    max: 70,
    label1: 'Min %',
    label2: 'Max %',
    deviceIds: [], //if empty array or undefined then check all devices
    active: true,
    visible: true,
  },
  {
    alertId: 'humidity_change',
    alertName: 'Change in Humidity',
    alertLabel: 'Change in Humidity',
    eventFieldName: alertFieldNames.humidity,
    loss: 5,
    gain: 5,
    label1: 'Loss %',
    label2: 'Gain %',
    deviceIds: [], //if empty array or undefined then check all devices
    active: false,
    visible: true,
  },
  {
    alertId: 'hive_temp_range',
    alertName: 'Brood Temperature Min/Max',
    alertLabel: 'Brood Temperature',
    eventFieldName: alertFieldNames.temperature,
    min: 25,
    max: 36,
    label1: 'Min °C',
    label2: 'Max °C',
    deviceIds: [], //if empty array or undefined then check all devices
    active: false,
    visible: false,
  },
  {
    alertId: 'hive_temp_change',
    alertName: 'Change in Brood Temp.',
    alertLabel: 'Change in Brood Temp.',
    eventFieldName: alertFieldNames.temperature,
    loss: 5,
    gain: 5,
    label1: 'Loss °C',
    label2: 'Gain °C',
    deviceIds: [], //if empty array or undefined then check all devices
    active: false,
    visible: false,
  },
  {
    alertId: 'hive_weight_range',
    alertName: 'Weight',
    alertLabel: 'Weight',
    eventFieldName: alertFieldNames.weight,
    min: 15,
    max: 80,
    label1: 'Min Kg',
    label2: 'Max Kg',
    deviceIds: [], //if empty array or undefined then check all devices
    active: true,
    visible: true,
  },
  {
    alertId: 'hive_weight_change',
    alertName: 'Change in Weight',
    alertLabel: 'Change in Weight',
    eventFieldName: alertFieldNames.weight,
    loss: 1,
    gain: 1,
    label1: 'Loss Kg',
    label2: 'Gain Kg',
    deviceIds: [], //if empty array or undefined then check all devices
    active: true,
    visible: true,
  },
  {
    alertId: 'scale_battery_change',
    alertName: 'Change in Scale Battery',
    alertLabel: 'Change in Scale Battery',
    eventFieldName: alertFieldNames.battery,
    min: 2,
    max: 5,
    label1: 'Min',
    label2: 'Max',
    deviceIds: [], //if empty array or undefined then check all devices
    active: true,
    visible: true,
  },
  {
    alertId: 'gateway_temp_range',
    alertName: 'Ambient Temp',
    alertLabel: 'Ambient Temp',
    eventFieldName: alertFieldNames.temp,
    collection: 'weather',
    label1: 'Min °C',
    label2: 'Max °C',
    min: 0,
    max: 40,
    deviceIds: [], //if empty array or undefined then check all devices
    active: false,
    visible: false,
  },
  {
    alertId: 'api_rain',
    alertName: 'Rain',
    alertLabel: 'Rain',
    eventFieldName: alertFieldNames.rain,
    collection: 'weather',
    deviceIds: [], //if empty array or undefined then check all devices
    active: false,
    visible: false,
  },
  {
    alertId: alertsConfig.theftAlert.alertId,
    alertName: alertsConfig.theftAlert.alertName,
    alertLabel: alertsConfig.theftAlert.alertName,
    // eventFieldName: alertFieldNames.rain,
    // collection: 'weather',
    deviceIds: [], //if empty array or undefined then check all devices
    active: true,
    visible: true,
    min: 100,
    label1: 'Min (metres)',
  },
];

module.exports = {
  defaultAlerts: defaultAlertsConfig,
  alertFieldNames,
  alertsConfig
};
