import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { auth } from '../../../firebase';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import { updateDevice } from '../../../api/updateDevice';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '75%',
  },
  textField: {
    backgroundColor: '#fed8b1',
    width: '80%',
  },
  select: {
    backgroundColor: '#fed8b1',
    width: '80%',
    height: '30px',
  },
  numberTextField: {
    backgroundColor: '#fed8b1',
    width: '20%',
  },
  card: {
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '80%',
    },
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  formPaper: {
    padding: 20,
  },
  formField: {
    width: '350px',
    marginBottom: '20px'
  },
  formButton: {
    width: '150px',
    marginBottom: '20px'
  },
}));

export function DeviceLogForm2({ device }) {
  const classes = useStyles();
  const [logDate, setLogDate] = useState(new Date());
  const [logDescription, setLogDescription] = useState('');

  const addLogAction = async () => {
    // const auditDescription = `Device changes: ${JSON.stringify(diffs)}`;
    const updatedObj = {};
    const userId = auth.currentUser && auth.currentUser.uid;
    const userEmail = auth.currentUser && auth.currentUser.email;

    const log = {
      dateCreated: new Date(),
      logDate,
      logDescription,
      userId,
      userEmail,
    };
    await updateDevice({ deviceId: device.deviceId, updatedObj, log });
    setLogDate(new Date());
    setLogDescription('');
  };

  return (
    <form>
      <Grid container spacing={0} style={{ paddingTop: '20px' }}>
        <Grid item xs={12} lg={12}>
          <FormControl>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                className={classes.formField}
                label={'Log date'}
                value={logDate}
                onChange={(newValue) => {
                  setLogDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={12}>
          <FormControl>
            <TextField
              className={classes.formField}
              rows={2}
              required
              id="standard-required"
              value={logDescription}
              label="Log description"
              onChange={(e) => setLogDescription(e.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Button
            className={classes.formButton}
            color="primary"
            variant="contained"
            disabled={logDescription.length === 0}
            onClick={addLogAction}
          >
            Add log
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
