import React from 'react';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';

const noop = () => {};

export default function FileInput({ value, onChange = noop, ...rest }) {
  return (
    <div>
      {Boolean(value.length) && <div>Selected files: {value.map((f) => f.name).join(', ')}</div>}
      <label>
        <AddAPhotoIcon />
        <input
          {...rest}
          style={{ display: 'none' }}
          type="file"
          onChange={(e) => {
            onChange([...e.target.files]);
          }}
        />
      </label>
    </div>
  );
}
