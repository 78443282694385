import { beekeeperUserRoles, pollyUserRoles } from '../config/userRoles';
import { accountTypes } from '../config/accountTypes';

export const canterburyEmails = [
  'alana.deacon@bardsley-england.com',
  'adam.slate@bardsley-england.com',
];

export function getIsAgrisoundAdmin(userData) {
  return userData && userData.email.includes('agrisound.io');
}

export function getIsBardsleyUser(userData) {
  return userData && canterburyEmails.includes(userData.email);
}

export function hasRole({ userData, role }) {
  return userData && userData.userRoles.includes(role);
}

export function hasRequiredRole({ userRoles, rolesRequired }) {
  return userRoles.some((role) => rolesRequired.includes(role));
}

export function getRoleOptions({ accountType, isAgrisoundAdmin }) {
  if (accountType === accountTypes.beekeeper) {
    return ['admin', 'read'];
  }

  let roleOptions = [
    pollyUserRoles.projectAdmin,
    pollyUserRoles.deviceInstaller,
    pollyUserRoles.readOnly,
  ];

  if (accountType === accountTypes.polly && isAgrisoundAdmin) {
    roleOptions.push(pollyUserRoles.agriSoundAdmin);
  }

  return roleOptions;
}

export function getProjectRole({ userData, accountType }) {
  const projectUserRoles = userData.userRoles;
  if (accountType === accountTypes.beekeeper) {
    return projectUserRoles[0];
  } else if (accountType === accountTypes.polly) {
    const excludedRoles = [beekeeperUserRoles.read, beekeeperUserRoles.admin];
    return projectUserRoles.filter((role) => !excludedRoles.includes(role))[0];
  }
}
