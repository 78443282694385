import React from 'react';
import { compose, withProps, lifecycle } from 'recompose';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from 'react-google-maps';
import Proptypes from 'prop-types';

export const CustomMapComponent = compose(
  // withProps({
  //   googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
  //   loadingElement: <div style={{ height: `100%` }} />,
  //   containerElement: <div style={{ height: `200px` }} />,
  //   mapElement: <div style={{ height: `100%` }} />,
  // }),
  lifecycle({
    componentDidMount() {
      // console.log('map1');
      this.setState({
        zoomToMarkers: (map) => {
          // console.log('Zoom to markers');
          const bounds = new window.google.maps.LatLngBounds();
          map &&
            map.props.children.forEach((child) => {
              if (child.type === Marker) {
                bounds.extend(
                  new window.google.maps.LatLng(child.props.position.lat, child.props.position.lng)
                );
              }
            });
          map && map.fitBounds(bounds);
        },
      });
    },

    componentWillReceiveProps(nextProps) {
      // console.log('map2', nextProps.markers);
      if (this.props.markers !== nextProps.markers) {
        this.setState({
          zoomToMarkers: (map) => {
            // console.log('Update to markers');
            const bounds = new window.google.maps.LatLngBounds();
            // console.log('map.props.children', map.props.children);
            map &&
              map.props.children.forEach((child) => {
                if (child.type === Marker) {
                  bounds.extend(
                    new window.google.maps.LatLng(child.props.position.lat, child.props.position.lng)
                  );
                }
              });
            map && map.fitBounds(bounds);
          },
        });
      }
    },
  }),
  withScriptjs,
  withGoogleMap
)(({ zoomToMarkers, zoom, markers, label }) => {
  // console.log('map3', markers);
  return (
    <>
      <GoogleMap defaultZoom={10} ref={zoomToMarkers} defaultCenter={{ lat: -34.397, lng: 150.644 }}>
        {markers?.map((marker, i) => {
          // console.log(`marker${marker.deviceName}`, marker.location);
          const infoWindow = (
            <InfoWindow
              // position={label === 'deviceName' ? marker.location : marker.siteLocation}
              // open={show}
              pixelOffset={new window.google.maps.Size(20, 20)}
              onClick={() => alert('dan!')}
            >
              <div>
                {marker.infoText}
              </div>
            </InfoWindow>
          );
          return (
            <Marker
              key={i}
              position={label === 'deviceName' ? marker.location : marker.siteLocation}
              // title={marker.deviceId}
            >
              {/*<InfoWindow>*/}
              {/*  <div>*/}
              {/*    <span style={{ fontWeight: 'bold' }}>{marker.deviceId}</span>:{marker.pollinationActivity}*/}
              {/*  </div>*/}
              {/*</InfoWindow>*/}
              {infoWindow}
            </Marker>
          );
        })}
      </GoogleMap>
    </>
  );
});

CustomMapComponent.propTypes = {
  lat: Proptypes.string,
  lng: Proptypes.string,
  zoom: Proptypes.number,
  markers: Proptypes.array,
};
