import React, { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { getSelectedProject, getSelectedProjectId } from '../../../config/env';
import { useSelector } from 'react-redux';
import { getProjectQuery } from '../../../api/queryConfig';
import { isLoaded, useFirestoreConnect, useFirestore } from 'react-redux-firebase';
import Grid from '@material-ui/core/Grid';
import { updateAlerts } from '../../../api/updateAlerts';
import SnackbarAlert from '../SnackbarAlert/SnackbarAlert';
import { snackbarDefault } from '../SnackbarAlert/SnackbarAlert';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardActions from '@material-ui/core/CardActions';

const labelControlWidth = '200px';
const inputStyle = { backgroundColor: '#E0E0E0', width: '70px' };

//TODO: use formik
//https://codesandbox.io/s/github/formik/formik/tree/master/examples/with-material-ui?from-embed

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  formControlLabel: {
    fontSize: 12,
  },
  container: {
    margin: '20px',
  },
  submitButton: {
    marginTop: '20px',
    marginBottom: '20px',
  },
}));

export default function AlertsForm({ projectId, alerts }) {
  const classes = useStyles();
  const firestore = useFirestore();
  const [alertsState, setAlertsState] = useState(alerts);
  const [hasChanges, setHasChanges] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState(snackbarDefault);

  const handleSubmit = ({ firestore, projectId, alerts, updatedAlerts }) => {
    setLoading(true);
    updateAlerts({ firestore, projectId, alerts, updatedAlerts })
      .then((res) => {
        // console.log('updateAlerts.success', err);
        if (setSnackbar) setSnackbar({ message: 'Saved', open: true, severity: 'success' });
        setTimeout(() => {
          setHasChanges(false);
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        // console.log('updateAlerts.err', err);
        //TOOD: log error to rollbar
        if (setSnackbar) setSnackbar({ message: `Error: ${err.message}`, open: true, severity: 'error' });
        setLoading(false);
      });
  };
  const handleChange = (alertName, fieldName, value) => {
    const updatedState = alertsState.map((alert) => {
      if (alert.alertName === alertName) {
        const newValue = fieldName === 'active' ? !alert[fieldName] : value; //parseFloat(value);
        return { ...alert, [fieldName]: newValue };
      }
      return alert;
    });
    // console.log('handleChange', updatedState);
    setAlertsState(updatedState);
    setHasChanges(!isEqual(alerts, updatedState));
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar(snackbarDefault);
  };

  return (
    <div className={classes.root}>
      <SnackbarAlert snackbar={snackbar} handleClose={handleClose} />
      <form>
        <Grid container spacing={0}>
          {alertsState.map((alert, index) => {
            return (
              <Grid key={index} container item xs={12}>
                <FormRow classes={classes} alert={alert} handleChange={handleChange} />
              </Grid>
            );
          })}
          <Grid container item xs={2}>
            <Button
              className={classes.submitButton}
              color="primary"
              variant="contained"
              disabled={!hasChanges}
              onClick={() => {
                let messages = [];
                for (let alert of alertsState) {
                  messages = messages.concat(getAlertValidationMessages(alert));
                }
                // console.log('alerts', alertsState, messages);
                if (messages.length === 0) {
                  handleSubmit({ firestore, projectId, alerts, updatedAlerts: alertsState });
                } else {
                  if (setSnackbar) setSnackbar({ message: messages, open: true, severity: 'error' });
                  setAlertsState(alerts);
                }
              }}
            >
              Save
            </Button>
          </Grid>
          <Grid container item xs={2}>
            {loading && <CircularProgress className={classes.submitButton} />}
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

function getAlertValidationMessages(alert) {
  let messages = [];
  if (alert.hasOwnProperty('min') && isNaN(alert.min)) {
    messages.push(`Please provide a valid 'min' number`);
  }
  if (alert.hasOwnProperty('max') && isNaN(alert.max)) {
    messages.push(`Please provide a valid 'min' number`);
  }
  if (alert.hasOwnProperty('loss') && isNaN(alert.loss)) {
    messages.push(`Please provide a valid 'loss' number`);
  }
  if (alert.hasOwnProperty('gain') && isNaN(alert.gain)) {
    messages.push(`Please provide a valid 'gain' number`);
  }

  return messages;
}

function FormRow({ alert, handleChange, classes }) {
  const register = () => {};
  const errors = {};
  const isSubmitting = false;
  const useBox = true;
  if (useBox) {
    return (
      <Box flexDirection="row">
        <FormControlLabel
          style={{ width: '350px' }}
          control={
            <Checkbox
              checked={alert.active}
              value={alert.active}
              onChange={(e) => handleChange(alert.alertName, 'active', parseFloat(e.target.value))}
            />
          }
          // label={alert.alertName}
          label={
            <div style={{ whiteSpace: 'nowrap', width: '400px', paddingRight: '10px' }}>
              {alert.alertName}
            </div>
          }
          labelPlacement="end"
        />
        {alert.hasOwnProperty('min') && (
          <FormControlLabel
            style={{ width: labelControlWidth }}
            control={
              <TextField
                type="number"
                name="min"
                placeholder="min"
                // InputProps={{ inputProps: { min: 0, max: 10 } }}
                // InputProps={{
                //   inputProps: {
                //     max: 11,
                //     min: 10,
                //   },
                // }}
                style={inputStyle}
                onChange={(e) => handleChange(alert.alertName, 'min', parseFloat(e.target.value))}
                inputRef={register({
                  required: true,
                  minLength: 2,
                  // validate: validateEmail,
                })}
                error={!!errors.email}
                helperText={errors.email && 'Email must be valid'}
                value={alert.min}
              />
            }
            label={<Box style={{ paddingRight: '10px' }}>{alert.label1}</Box>}
            labelPlacement="start"
          />
        )}
        {alert.hasOwnProperty('loss') && (
          <FormControlLabel
            style={{ width: labelControlWidth }}
            control={
              <TextField
                type="number"
                name="loss"
                placeholder="loss"
                style={inputStyle}
                onChange={(e) => handleChange(alert.alertName, 'loss', parseFloat(e.target.value))}
                inputRef={register({
                  required: true,
                  minLength: 2,
                  // validate: validateEmail,
                })}
                error={!!errors.email}
                helperText={errors.email && 'Email must be valid'}
                value={alert.loss}
              />
            }
            label={<Box style={{ paddingRight: '10px' }}>{alert.label1}</Box>}
            labelPlacement="start"
          />
        )}
        {alert.hasOwnProperty('max') && (
          <FormControlLabel
            style={{ width: labelControlWidth }}
            control={
              <TextField
                type="number"
                name="max"
                placeholder="max"
                style={inputStyle}
                onChange={(e) => handleChange(alert.alertName, 'max', parseFloat(e.target.value))}
                inputRef={register({
                  required: true,
                  minLength: 2,
                  // validate: validateEmail,
                })}
                error={!!errors.email}
                helperText={errors.email && 'Email must be valid'}
                value={alert.max}
              />
            }
            label={<Box style={{ paddingRight: '10px' }}>{alert.label2}</Box>}
            labelPlacement="start"
          />
        )}
        {alert.hasOwnProperty('gain') && (
          <FormControlLabel
            style={{ width: labelControlWidth }}
            control={
              <TextField
                type="number"
                name="gain"
                placeholder="gain"
                style={inputStyle}
                onChange={(e) => handleChange(alert.alertName, 'gain', parseFloat(e.target.value))}
                inputRef={register({
                  required: true,
                  minLength: 2,
                  // validate: validateEmail,
                })}
                error={!!errors.email}
                helperText={errors.email && 'Email must be valid'}
                value={alert.gain}
              />
            }
            label={<Box style={{ paddingRight: '10px' }}>{alert.label2}</Box>}
            labelPlacement="start"
          />
        )}
      </Box>
    );
  }

  return (
    <>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <FormControlLabel
          style={{ width: labelControlWidth }}
          control={
            <Checkbox
              checked={alert.active}
              value={alert.active}
              onChange={(e) => handleChange(alert.alertName, 'active', e.target.value)}
            />
          }
          label={
            <div style={{ whiteSpace: 'nowrap', width: '250px', paddingRight: '10px' }}>
              {alert.alertName}
            </div>
          }
          labelPlacement="end"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        {alert.hasOwnProperty('min') && (
          <FormControlLabel
            style={{ width: labelControlWidth }}
            control={
              <TextField
                type="number"
                name="min"
                placeholder="min"
                style={inputStyle}
                onChange={(e) => handleChange(alert.alertName, 'min', e.target.value)}
                // autoComplete="email"
                // margin="normal"
                // fullWidth
                inputRef={register({
                  required: true,
                  minLength: 2,
                  // validate: validateEmail,
                })}
                error={!!errors.email}
                helperText={errors.email && 'Email must be valid'}
                value={alert.min}
              />
            }
            label={<Box style={{ paddingRight: '10px' }}>{alert.label1}</Box>}
            labelPlacement="start"
          />
        )}
        {alert.hasOwnProperty('loss') && (
          <FormControlLabel
            style={{ width: labelControlWidth }}
            control={
              <TextField
                type="number"
                name="changeDown"
                placeholder="loss"
                style={inputStyle}
                onChange={(e) => handleChange(alert.alertName, 'loss', e.target.value)}
                inputRef={register({
                  required: true,
                  minLength: 2,
                  // validate: validateEmail,
                })}
                error={!!errors.email}
                helperText={errors.email && 'Email must be valid'}
                value={alert.loss}
              />
            }
            label={<Box style={{ paddingRight: '10px' }}>{alert.label1}</Box>}
            labelPlacement="start"
          />
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        {/*<Paper className={classes.paper}>item</Paper>*/}
        {alert.hasOwnProperty('max') && (
          <FormControlLabel
            style={{ width: labelControlWidth }}
            control={
              <TextField
                type="number"
                name="max"
                placeholder="max"
                style={inputStyle}
                onChange={(e) => handleChange(alert.alertName, 'max', e.target.value)}
                inputRef={register({
                  required: true,
                  minLength: 2,
                  // validate: validateEmail,
                })}
                error={!!errors.email}
                helperText={errors.email && 'Email must be valid'}
                value={alert.max}
              />
            }
            label={<Box style={{ paddingRight: '10px' }}>{alert.label2}</Box>}
            labelPlacement="start"
          />
        )}
        {alert.hasOwnProperty('gain') && (
          <FormControlLabel
            style={{ width: labelControlWidth }}
            control={
              <TextField
                type="number"
                name="gain"
                placeholder="gain"
                style={inputStyle}
                onChange={(e) => handleChange(alert.alertName, 'gain', e.target.value)}
                inputRef={register({
                  required: true,
                  minLength: 2,
                  // validate: validateEmail,
                })}
                error={!!errors.email}
                helperText={errors.email && 'Email must be valid'}
                value={alert.gain}
              />
            }
            label={<Box style={{ paddingRight: '10px' }}>{alert.label2}</Box>}
            labelPlacement="start"
          />
        )}
      </Grid>
    </>
  );
}
