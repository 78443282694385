import React, { useEffect, useState } from 'react';
import { useFirestore } from 'react-redux-firebase';
import { deviceTypes, deviceTypesConfig } from '../../../config/deviceTypes';
import { auth } from '../../../firebase';
import { addRequestToCollection } from '../../../api/adminFunctions';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function DeleteDetails({ classes }) {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const [deviceId, setDeviceId] = useState();
  const [devices, setDevices] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [validationMessages, setValidationMessages] = useState(['placeholder']);
  const firestore = useFirestore();
  const collectionName = 'uiRequests';
  const actionType = 'archiveDevice';

  const validate = () => {
    const messages = [];

    if (!deviceId) {
      messages.push('Please select a deviceId');
    }

    setValidationMessages(messages);
  };

  useEffect(() => {
    validate();
    firestore
      .doc(`cache/devices2`)
      .get()
      .then((snapshot) => {
        let allDevices = snapshot.data().devices;
        setDevices(allDevices);
      });
  }, []);

  useEffect(() => {
    validate();
    if (!deviceId) {
      return;
    }
    const device = devices.find((device) => device.deviceId === deviceId);
    if (device && device.deviceType === deviceTypes.beeHeroGatewayOne) {
      const deviceUsingGateway = devices.find((device) => device.gatewayDeviceId === deviceId);
      if (deviceUsingGateway) {
        const messages = [];
        messages.push(
          `This gateway is being used by ${deviceUsingGateway.deviceId}. Please update the gatewayId before removing this gateway`
        );
        setValidationMessages(messages);
      }
    } else if (device && device.deviceType === deviceTypes.agsenzeScaleOne) {
      const deviceUsingScale = devices.find((device) => device.scaleDeviceId === deviceId);
      if (deviceUsingScale) {
        const messages = [];
        messages.push(
          `This barscale is being used by ${deviceUsingScale.deviceId}. Please update the scaleDeviceId before removing this barscale`
        );
        setValidationMessages(messages);
      }
    }
  }, [deviceId]);

  const deleteDeviceAction = () => {
    const performAction = true;
    setOpenConfirm(false);
    if (!performAction) {
      setDevices([]);
      setMessage(`${deviceId} successfully deleted`);
      setLoading(false);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      return;
    }
    if (!deviceId || deviceId.trim() === '') {
      setMessage(`Please select a device Id`);
      return;
    }

    const uid = auth.currentUser && auth.currentUser.uid;
    let request = {
      deviceId,
      type: actionType,
      userId: uid,
      dateCreated: new Date(),
    };

    setLoading(true);

    addRequestToCollection({ request, collectionName, firestore, setMessage, setLoading, setOpenConfirm });
  };

  const defaultDeviceSelectProps = {
    options: devices,
    getOptionLabel: (device) =>
      `${device.deviceId} (${deviceTypesConfig[device.deviceType]}) (name: ${device.deviceName})`,
  };

  return (
    <Grid container spacing={0}>
      <Grid container item xs={12}>
        <FormControl className={classes.formControl2}>
          <Autocomplete
            {...defaultDeviceSelectProps}
            id="deviceId"
            renderInput={(params) => <TextField {...params} label="Device ID" margin="normal" />}
            onChange={(e, value) => {
              if (value) {
                setDeviceId(value.deviceId);
              } else {
                setDeviceId(undefined);
              }
            }}
          />
        </FormControl>
      </Grid>
      {validationMessages.length > 0 && (
        <Grid container item xs={12}>
          <FormControl className={classes.formControl}>
            <div>
              {validationMessages.map((message) => {
                return (
                  <div key={message} style={{ color: 'red' }}>
                    {message}
                  </div>
                );
              })}
            </div>
          </FormControl>
        </Grid>
      )}
      <Grid container item xs={12}>
        <FormControl className={classes.formControl}>
          <Grid container spacing={0}>
            <Grid container item xs={6}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => setOpenConfirm(true)}
                disabled={validationMessages.length > 0}
              >
                Delete device
              </Button>
              <Dialog
                open={openConfirm}
                onClose={() => setOpenConfirm(false)}
                aria-labelledby="responsive-dialog-title"
              >
                <DialogTitle id="responsive-dialog-title">{'Delete action'}</DialogTitle>
                <DialogContent dividers>
                  <Typography gutterBottom>
                    Are you sure you want delete the device {deviceId}?
                  </Typography>
                  <br />
                  <Typography gutterBottom>DeviceId: {deviceId}</Typography>
                </DialogContent>
                <DialogActions>
                  <Button autoFocus onClick={() => setOpenConfirm(false)} color="primary">
                    No
                  </Button>
                  <Button onClick={deleteDeviceAction} color="primary" autoFocus>
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
            <Grid container item xs={6}>
              {loading && <CircularProgress className={classes.submitButton} />}
              {message && (
                <div style={{ color: 'blue', fontSize: '16px', fontWeight: 'bold' }}>{message}</div>
              )}
            </Grid>
          </Grid>
        </FormControl>
      </Grid>
    </Grid>
  );
}
