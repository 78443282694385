import * as React from 'react';
import { GoogleMap } from '@react-google-maps/api';
import { useSelector } from 'react-redux';
import { getCircleComponents } from '../../../data/data-util';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { mapContainerStyle } from './EventsHeatmap';

const onClick = (...args) => {
  console.log('onClick args: ', args);
};

function EventsCircleMap({ styles, events, projectId }) {
  const storeAs = `${projectId}-devices`;
  const devices = useSelector(({ firestore: { data } }) => data[storeAs]);

  const devicesQuery = [
    {
      collection: 'projects',
      doc: projectId,
      subcollections: [{ collection: 'devices' }],
      storeAs: storeAs,
    },
  ];

  useFirestoreConnect(devicesQuery);

  if (!isLoaded(devices)) {
    return 'Loading...';
  }

  const [center, circles] = getCircleComponents({ events, devices, days: 30 });
  console.log('EventsCircleMap', { center, circles });

  // return <div>DDD</div>
  return (
    <div className="map">
      <div className="map-container">
        <GoogleMap id="circle-example" mapContainerStyle={mapContainerStyle} zoom={16} center={center}>
          {circles.map((circle) => circle)}
        </GoogleMap>
      </div>
    </div>
  );
}

export default React.memo(EventsCircleMap);
