import React, { useEffect, useState } from 'react';
import { uniq, cloneDeep } from 'lodash';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { updateProject } from '../../../api/updateProject';
import { useDispatch, useSelector } from 'react-redux';
import { flatten } from './treeUtil';
import { getDefaultHierarchy } from '../util/hierarchyUtil';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 400,
  },
}));

const data = {
  id: 'root',
  name: 'Innocent',
  level: 'Business',
  children: [
    {
      id: 'soriaFarmManager1',
      name: 'Soria Farm Manager',
      level: 'FarmManager',
      children: [
        {
          id: 'soriaFarm1',
          name: 'Soria Farm',
          level: 'Farm',
          children: [
            {
              id: 'soriaSite1',
              name: 'Soria Site 1',
              level: 'Site',
            },
            {
              id: 'soriaSite2',
              name: 'Soria Site 2',
              level: 'Site',
            },
            {
              id: 'soriaSite3',
              name: 'Soria Site 3',
              level: 'Site',
            },
          ],
        },
      ],
    },
  ],
};

export default function MuiTreeDemo() {
  const project = useSelector((state) => state.appConfig && state.appConfig.currentProject);
  const projectId = project.projectId;
  const hierarchy = JSON.parse(project.hierarchy || getDefaultHierarchy(project));
  const [treeData, setTreeData] = useState(hierarchy);
  const [nodeIds, setNodeIds] = useState([]);

  useEffect(() => {
    const nodeIds = flatten(treeData).map((item) => item.id);
    setNodeIds(nodeIds);
  }, []);

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <HierarchyTree
          treeData={treeData}
          setTreeData={setTreeData}
          nodeIds={nodeIds}
          setNodeIds={setNodeIds}
          projectId={projectId}
        />
      </Grid>
      <Grid item xs={6}>
        <AddNodeForm treeData={treeData} setTreeData={setTreeData} projectId={projectId} />
      </Grid>
    </Grid>
  );
}

function AddNodeForm({ treeData, setTreeData, projectId }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  // console.log('project', project);
  const [level, setLevel] = useState('Site');
  const [name, setName] = useState('');
  const [parentOptions, setParentOptions] = useState([]);
  const [parent, setParent] = useState('');
  const levelOptions = ['FarmManager', 'Farm', 'Site'];
  const levelsWithParents = ['Farm', 'Site'];
  const levelToParent = {
    Farm: 'FarmManager',
    Site: 'Farm',
  };

  useEffect(() => {
    const flattened = flatten(treeData);
    const parent = levelToParent[level];
    if (parent) {
      const newOptions = uniq(flattened.filter((item) => item.level === parent).map((item) => item.name));
      setParentOptions(newOptions);
    }
  }, [level]);

  return (
    <Grid container spacing={1} style={{ width: '60%' }}>
      <Grid item xs={12} lg={12}>
        <Typography variant="h6">Add new</Typography>
      </Grid>
      <Grid item xs={12} lg={12}>
        <FormControl className={classes.formControl}>
          <Select
            disabled={false}
            placeholder={'Level'}
            value={level}
            onChange={(e) => {
              setLevel(e.target.value);
            }}
          >
            {levelOptions.map((option) => {
              return (
                // <MenuItem key={deviceType.id} value={deviceType.id}>
                //   {deviceType.label}
                // </MenuItem>
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>Level</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12} lg={12}>
        <FormControl className={classes.formControl}>
          <TextField
            value={name}
            placeholder={`Enter name of ${level}`}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          {/*<FormHelperText>New {level}</FormHelperText>*/}
        </FormControl>
      </Grid>
      {levelsWithParents.includes(level) && (
        <Grid item xs={12} lg={12}>
          <Typography variant="h6">To</Typography>
        </Grid>
      )}
      {levelsWithParents.includes(level) && (
        <Grid item xs={12} lg={12}>
          <FormControl className={classes.formControl}>
            <Select
              disabled={false}
              // placeholder={'Parent'}
              value={parent}
              onChange={(e) => {
                setParent(e.target.value);
              }}
            >
              {parentOptions.map((option) => {
                return (
                  // <MenuItem key={deviceType.id} value={deviceType.id}>
                  //   {deviceType.label}
                  // </MenuItem>
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText>Parent</FormHelperText>
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12}>
        <FormControl className={classes.formControl}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              const node = {
                name,
                parent,
                level,
              };
              addNodeToTree({ node, treeData, setTreeData, projectId, dispatch });
            }}
            // disabled={validationMessages.length > 0}
          >
            Add
          </Button>
        </FormControl>
      </Grid>
    </Grid>
  );
}

function HierarchyTree({ treeData, setTreeData, nodeIds, setNodeIds, projectId }) {
  const dispatch = useDispatch();
  const handleToggle = (event, nodeIds) => {
    setNodeIds(nodeIds);
  };

  const TreeLabel = ({ node }) => {
    const [name, setName] = useState(node.name);
    return (
      <Box display="flex" flexDirection="row">
        {/*<Typography variant={'subtitle1'}>{node.name}</Typography>*/}
        <TextField
          value={name}
          // placeholder={`Enter name of ${level}`}
          onChange={(e) => {
            // setName(e.target.value);
            const nodeUpdate = {
              id: node.id,
              name: e.target.value,
            };
            updateTree({ nodeUpdate, treeData, setTreeData, projectId, dispatch });
          }}
        />
        <Typography variant={'subtitle1'} style={{ fontStyle: 'italic', color: 'grey' }}>
          ({node.level})
        </Typography>
      </Box>
    );
  };

  const renderTree = (node) => (
    <TreeItem key={node.id} nodeId={node.id} label={<TreeLabel node={node} />}>
      {Array.isArray(node.children) ? node.children.map((node) => renderTree(node)) : null}
    </TreeItem>
  );

  return (
    <TreeView
      // onNodeToggle={handleToggle}
      expanded={nodeIds}
      aria-label="rich object"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpanded={nodeIds}
      defaultExpandIcon={<ChevronRightIcon />}
      sx={{ height: '100%', flexGrow: 1, maxWidth: 600, overflowY: 'auto' }}
    >
      {renderTree(treeData)}
    </TreeView>
  );
}

function removeNode({ node, treeData, setTreeData, projectId, dispatch }) {
  const { id, name } = node;
  const newTree = cloneDeep(treeData);

  for (let farmManager of newTree.children) {
    if (farmManager.id === id) {
      // console.log('updateTree.farmManager');
      farmManager.name = name;
      updateProjectHierarchy({ setTreeData, newTree, projectId, dispatch });
      return;
    }
    if (farmManager.children) {
      for (let farm of farmManager.children) {
        if (farm.id === id) {
          // console.log('updateTree.farm');
          farm.name = name;
          updateProjectHierarchy({ setTreeData, newTree, projectId });
          return;
        }
        if (farm.children) {
          for (let site of farm.children) {
            if (site.id === id) {
              // console.log('updateTree.site');
              site.name = name;
              updateProjectHierarchy({ setTreeData, newTree, projectId });
              return;
            }
          }
        }
      }
    }
  }
}
function updateTree({ nodeUpdate, treeData, setTreeData, projectId, dispatch }) {
  // console.log('updateTree', { nodeUpdate, treeData, setTreeData });
  const { id, name } = nodeUpdate;
  const newTree = cloneDeep(treeData);

  for (let farmManager of newTree.children) {
    if (farmManager.id === id) {
      // console.log('updateTree.farmManager');
      farmManager.name = name;
      updateProjectHierarchy({ setTreeData, newTree, projectId, dispatch });
      return;
    }
    if (farmManager.children) {
      for (let farm of farmManager.children) {
        if (farm.id === id) {
          // console.log('updateTree.farm');
          farm.name = name;
          updateProjectHierarchy({ setTreeData, newTree, projectId, dispatch });
          return;
        }
        if (farm.children) {
          for (let site of farm.children) {
            if (site.id === id) {
              // console.log('updateTree.site');
              site.name = name;
              updateProjectHierarchy({ setTreeData, newTree, projectId, dispatch });
              return;
            }
          }
        }
      }
    }
  }
}

function addNodeToTree({ node, treeData, setTreeData, projectId, dispatch }) {
  const parent = node.parent;
  const newNode = {
    id: node.name.replace(/\s/g, ''),
    name: node.name,
    level: node.level,
    children: [],
  };
  const newTree = cloneDeep(treeData);

  if (node.level === 'FarmManager') {
    newTree.children = newTree.children || [];
    newTree.children.push(newNode);
    updateProjectHierarchy({ setTreeData, newTree, projectId, dispatch });
    return;
  }

  for (let farmManager of newTree.children) {
    if (farmManager.name === parent) {
      if (!farmManager.children) farmManager.children = [];
      farmManager.children.push(newNode);
    }
    if (farmManager.children) {
      for (let farm of farmManager.children) {
        if (farm.name === parent) {
          if (!farm.children) farm.children = [];
          farm.children.push(newNode);
        }
      }
    }
  }

  updateProjectHierarchy({ setTreeData, newTree, projectId, dispatch });
}

function updateProjectHierarchy({ setTreeData, newTree, projectId, dispatch }) {
  setTreeData && setTreeData(newTree);
  const jsonStr = JSON.stringify(newTree);
  const updatedObj = {
    hierarchy: jsonStr,
  };
  // console.log('jsonStr', jsonStr);
  updateProject({ projectId, updatedObj, dispatch }).catch((err) =>
    console.log('updateProjectHierarchy.err', err)
  );
}
