import React from 'react';
import { format, subHours } from 'date-fns';
import { useLocation } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import PollyChartWithData from '../Charts/PollyChartWithData';
import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import { useRouteMatch } from 'react-router';
import BreadcrumbNav from '../../beekeeper/Nav/BreadcrumbsNav';
import { useSelector } from 'react-redux';
import { getSelectedProjectId } from '../../../config/env';
import PollyDeviceTitle from './PollyDeviceTitle';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { DataGrid } from '@mui/x-data-grid';
import { cloneDeep } from 'lodash';
import { convertDates } from '../../../util/date-util';
import DownloadFile from '../../beekeeper/Download/DownloadFile';
import { getEstimatedBeesPerSquareMetre } from '../util/polly-calculation-util';

const useStyles = makeStyles((theme) => ({
  hiveGrid: {
    flexGrow: 1,
  },
  hivePaper: {
    height: 450,
    width: 235,
    fontSize: 12,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function PollyDeviceOverviewPage({
  routesConfig = {
    root: {
      path: 'polly-devices',
      label: 'Devices',
    },
    edit: {
      path: 'polly-devices-edit',
      label: 'Device Config',
    },
    overview: {
      path: 'polly-devices-overview',
      label: 'Overview',
    },
  },
}) {
  const classes = useStyles();
  const location = useLocation();
  const match = useRouteMatch(`/${routesConfig.overview.path}/:deviceId`);
  const currentProject = useSelector((state) => state.appConfig.currentProject);
  const deviceId = match.params.deviceId;
  const userData = useSelector((state) => state.appConfig.userData);
  const globalConfig = useSelector((state) => state.appConfig.globalConfig);
  const selectedProjectId = getSelectedProjectId(userData);
  let storeAs = `project-${selectedProjectId}`;
  const storeAsEvents = `events-${deviceId}`;
  const eventsData = useSelector(({ firestore: { data } }) => data[storeAsEvents]);

  const isShowBeeActivity = ({ user, project }) => {
    if (user && user.hasOwnProperty('showBeeActivity')) {
      return user.showBeeActivity;
    }

    if (project && project.hasOwnProperty('showBeeActivity')) {
      return project.showBeeActivity;
    }

    return globalConfig && globalConfig.hasOwnProperty('showBeeActivity')
      ? globalConfig.showBeeActivity
      : false;
  };
  const showBeeActivity = isShowBeeActivity({ user: userData, project: currentProject });

  const projectQuery = {
    collection: 'projects',
    doc: selectedProjectId,
    storeAs,
  };

  const eventsQuery = {
    collection: 'events',
    where: [
      ['deviceId', '==', deviceId],
      ['month', 'not-in', ['1990-0', 'NaN-NaN']],
    ],
    storeAs: storeAsEvents,
  };

  useFirestoreConnect([projectQuery, eventsQuery]);

  if (!isLoaded(eventsData, currentProject)) {
    return 'Loading...';
  }

  let projectData = currentProject;
  let eventsRows = convertEvents(eventsData);

  return (
    <Grid container spacing={2}>
      {/*<Grid item xs={12}>*/}
      {/*  <Breadcrumbs deviceId={deviceId} routesConfig={routesConfig} />*/}
      {/*</Grid>*/}
      <Grid item xs={12}>
        <PollyDeviceTitle
          deviceId={deviceId}
          projectId={selectedProjectId}
          projectName={projectData.projectName}
        />
      </Grid>
      <Grid item xs={12}>
        <DownloadFile
          buttonText={'Download chart data'}
          eventsRows={eventsRows}
          isMetric={true}
          fileName={deviceId + '-chart-data.csv'}
          deviceIds={[deviceId]}
        />
      </Grid>
      <Grid item xs={12}>
        <PollyChartWithData
          eventsRows={eventsRows}
          linesToShow={location.state}
          projectId={selectedProjectId}
          deviceId={deviceId}
          showBeeActivity={showBeeActivity}
        />
      </Grid>
      <Grid item xs={12}>
        <DataGrid2 eventsRows={eventsRows} showBeeActivity={showBeeActivity} />
      </Grid>
    </Grid>
  );
}

function convertEvents(eventsData) {
  if (!eventsData) {
    return [];
  }
  return Object.values((eventsData && cloneDeep(eventsData)) || {});
}

function DataGrid2({ eventsRows, showBeeActivity }) {
  let events = [];
  let filteredEvents = [];
  for (let eventsRow of eventsRows) {
    events = events.concat(eventsRow.events);
  }
  let idCounter = 0;
  for (let event of events) {
    event.id = idCounter;
    convertDates(event);
    if (event.dateCreated) {
      filteredEvents.push(event);
      idCounter++;
    }
  }
  filteredEvents.sort((a, b) => b.dateCreated - a.dateCreated);
  return (
    <div style={{ height: 800 }}>
      <DataGrid
        columns={getDataGridColumns(showBeeActivity)}
        rows={filteredEvents}
        autoHeight={true}
        headerHeight={80}
        disableColumnMenu={true}
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            textOverflow: 'clip',
            whiteSpace: 'break-spaces',
            lineHeight: 2,
          },
        }}
      />
      {/*<DataGrid rows={events} columns={getDataGridColumns(showBeeActivity)} pageSize={50} />*/}
    </div>
  );
}

function getDataGridColumns(showBeeActivity) {
  return [
    // { field: 'deviceType', headerName: 'DataOverview Type', width: 130 },
    // {
    //   field: 'deviceId',
    //   headerName: 'DeviceId',
    //   width: 150,
    //   cellClassName: 'cell-small',
    //   headerClassName: 'cell-small',
    //   flex: 1,
    // },
    {
      field: 'dateCreated',
      headerName: 'Time',
      width: 80,
      cellClassName: 'cell-small',
      headerClassName: 'cell-small',
      flex: 1,
      renderCell: (params) => {
        // console.log('rows', params);
        let dateCreated;
        if (!params.row.dateCreated) {
          return <div>n/a</div>;
        }
        if (params.row.dateCreated instanceof Date) {
          dateCreated = params.row.dateCreated;
        } else {
          try {
            dateCreated = params.row.dateCreated.toDate();
          } catch (e) {
            // console.log('params.row', params.row.deviceId, params.row.dateCreated);
            return <div>n/a</div>;
          }
        }

        dateCreated = subHours(dateCreated, 1);
        return <div>{format(dateCreated, 'dd-MMM HH:mm:ss')}</div>;
      },
    },
    {
      field: 'temperature',
      headerName: 'Temp',
      width: 80,
      cellClassName: 'cell-small',
      headerClassName: 'cell-small',
      flex: 1,
      // renderCell: (params) => {
      //   console.log('rows', params);
      //   return <div>{params.row.dataType}</div>;
      // },
      // valueFormatter: (params) => JSON.stringify(params.getValue('location')),
    },
    {
      field: 'humidity',
      headerName: 'Humidity',
      width: 80,
      cellClassName: 'cell-small',
      headerClassName: 'cell-small',
      flex: 1,
    },
    {
      field: 'light',
      headerName: 'Light',
      width: 100,
      cellClassName: 'cell-small',
      headerClassName: 'cell-small',
      flex: 1,
    },
    {
      field: 'beeActivity',
      headerName: 'Estimated Bees per Sq Metre',
      width: 120,
      hide: !showBeeActivity,
      cellClassName: 'cell-small',
      headerClassName: 'cell-small',
      flex: 1,
      renderCell: (params) => {
        // console.log('rows', params);
        if (!showBeeActivity) {
          return <div>n/a</div>;
        }
        // let beeActivityInSeconds = params.row.beeActivity;
        // let percent = beeActivityInSeconds + '%';
        // return <div>{percent}</div>;

        //Reporting every hour = 60 x 60 = 3600 seconds
        //Assuming device measures in a 0.5m2 area, multiply by 2 to get estimate of average number of bees per m2
        let beesPerSqMetre = getEstimatedBeesPerSquareMetre(params.row.beeActivity);
        return <div>{beesPerSqMetre}</div>;
      },
    },
    {
      field: 'rssi',
      headerName: 'Connectivity Strength',
      width: 120,
      type: 'number',
      cellClassName: 'cell-small',
      headerClassName: 'cell-small',
      flex: 1,
    },
    {
      field: 'battery',
      headerName: 'Battery',
      width: 120,
      type: 'number',
      cellClassName: 'cell-small',
      headerClassName: 'cell-small',
      flex: 1,
    },
  ];
}

function Breadcrumbs({ deviceId, routesConfig }) {
  const breadcrumbs = [
    {
      path: '/',
      text: 'Home',
    },
    {
      path: `/${routesConfig.root.path}`,
      text: routesConfig.root.label,
    },
    {
      path: `/${routesConfig.overview.path}/${deviceId}`,
      text: routesConfig.overview.label,
    },
  ];
  return <BreadcrumbNav breadcrumbs={breadcrumbs} />;
}
