import React, { useEffect, useState } from 'react';
import { cloneDeep, sortBy } from 'lodash';
import { log } from '../../../util/log-util';
import { DataGrid } from '@material-ui/data-grid';
import { Link as RouterLink } from 'react-router-dom';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { getTableData2 } from '../../../data/data-util';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Hidden, Snackbar, useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { isLoaded, useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import { getProjectIds, isProjectAdmin } from '../../../config/env';
import { getProjectUsersQuery } from '../../../api/queryConfig';
import BreadcrumbNav from '../Nav/BreadcrumbsNav';
import { getIsAgrisoundAdmin, getRoleOptions } from '../../../util/user-util';
import { format } from 'date-fns';
import {
  beekeeperUserRoleLabels,
  beekeeperUserRoles,
  pollyUserRoleLabels,
  pollyUserRoles,
} from '../../../config/userRoles';
import { accountTypes } from '../../../config/accountTypes';
import { replaceUserRole } from '../../../api/updateUser';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    height: '35px',
    width: '150px',
  },
}));
export default function UserManagement() {
  const classes = useStyles();
  let showInviteUser = process.env.REACT_APP_SHOW_INVITE_USER === 'YES' || true;
  const userData = useSelector((state) => state.appConfig.userData);
  const project = useSelector((state) => state.appConfig.currentProject);
  const isAdmin = isProjectAdmin(userData);
  let projectIds = getProjectIds(userData);
  const projectId = projectIds[0];
  const storeAs = `${projectId}-users`;
  const projectUsers = useSelector(({ firestore: { data } }) => data[storeAs]);
  const projectUsersQuery = getProjectUsersQuery({ projectId, storeAs });
  const accountType = (project && project.accountType) || 'none';
  const [userIdToUserRoleMap, setUserIdToUserRoleMap] = useState({});
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const isAgrisoundAdmin = getIsAgrisoundAdmin(userData);

  const renderRoleCell = (params) => {
    /*
      I have only implemented this for Beekeeper roles and only to replace the role
      e.g replace 'admin' with 'read'

      polly roles are more complex as a user can have multiple roles and so updating this is
      also more complex

      TODO: refactor for multiple role management
     */
    const userRoles = ['admin', 'read'];
    const userId = params.row.userId;
    const onChangeUserRole = (e) => {
      setLoading(true);
      const newMap = { ...userIdToUserRoleMap };
      const userRole = e.target.value;
      newMap[userId] = userRole;
      setUserIdToUserRoleMap(newMap);

      replaceUserRole({ projectId, userId, userRole })
        .then((r) => {
          setLoading(false);
          console.log(`replaceUserRole: ${userId} updated to have userRole${userRole}`);
        })
        .catch((err) => {
          setLoading(false);
          console.log('replaceUserRole.err', err);
        });
    };
    const dropdown = (
      <Select value={userIdToUserRoleMap[userId]} onChange={onChangeUserRole}>
        {userRoles.map((userRole, i) => {
          return (
            <MenuItem key={i} value={userRole}>
              {userRole}
            </MenuItem>
          );
        })}
      </Select>
    );

    return <div>{dropdown}</div>;
  };

  useEffect(() => {
    let projectUsersData = Object.values((projectUsers && cloneDeep(projectUsers)) || {});
    let userEmails = projectUsersData.map((user) => user.email);
    let invitedUsersNotInUsersCollection = (project.invitedUsers || [])
      .filter((user) => !userEmails.includes(user.email))
      .map((user) => {
        const dateCreated =
          user.dateCreated && format(user.dateCreated.toDate(), 'dd-MMM-yy HH:mm:ss');
        return { ...user, ...{ status: `Invited on ${dateCreated}` } };
      });
    projectUsersData = addFieldToArray({
      arr: projectUsersData,
      fieldName: 'status',
      fieldValue: 'Active',
    });
    let allUsers = projectUsersData.concat(invitedUsersNotInUsersCollection);
    let tableData = getTableData2(allUsers);

    if (!isAgrisoundAdmin) {
      tableData = tableData.filter((user) => !user.email.includes('agrisound.io'));
    }
    setTableData(tableData);
    // console.log('tableData', tableData);
    const userIdToUserRoleMap = {};
    for (let row of tableData) {
      userIdToUserRoleMap[row.userId] = (row.userRoles.length > 0 && row.userRoles[0]) || '';
    }
    // console.log('userIdToUserRoleMap', userIdToUserRoleMap);
    setUserIdToUserRoleMap(userIdToUserRoleMap);
  }, [projectUsers]);

  useFirestoreConnect(projectUsersQuery);

  if (!isLoaded(projectUsers)) {
    return 'Loading...';
  }

  // tableData = sortBy(tableData, ['status']);

  log('UserManagement', { projectIds, tableData });

  return (
    <Grid justify={'flex-start'} container spacing={2}>
      {/*<Grid item xs={12} sm={12} md={6} lg={6}>*/}
      {/*  <Breadcrumbs />*/}
      {/*</Grid>*/}
      {showInviteUser && (
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Toolbar
            classes={classes}
            projectId={projectId}
            project={project}
            accountType={accountType}
            loading={loading}
            isAgrisoundAdmin={isAgrisoundAdmin}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <ProjectUsersTable
          tableData={tableData}
          accountType={accountType}
          isAdmin={isAdmin}
          renderRoleCell={renderRoleCell}
        />
      </Grid>
    </Grid>
  );
}

function addFieldToArray({ arr, fieldName, fieldValue }) {
  return arr.map((user) => {
    return { ...user, ...{ [fieldName]: fieldValue } };
  });
}

function Toolbar({ classes, projectId, project, accountType, loading, isAgrisoundAdmin }) {
  const [access, setAccess] = useState('access');
  const [email, setEmail] = useState();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState();
  const firestore = useFirestore();
  const user = useSelector((state) => state.appConfig.user);

  const roleOptions = getRoleOptions({ accountType, isAgrisoundAdmin });

  const validAccessValues = roleOptions;

  const inviteUserAction = () => {
    const accessArr = [access];
    if (!email || email.trim() === '') {
      setMessage(`Please input a valid email address`);
      setOpenSnackbar(true);
      return;
    }
    if (!validAccessValues.includes(access)) {
      setMessage(`Please select an access level`);
      setOpenSnackbar(true);
      return;
    }

    const inviteUserObject = {
      createdBy: user.uid,
      dateCreated: new Date(),
      email: email && email.toLowerCase().trim(),
      projectId,
      userRoles: accessArr,
    };

    firestore
      .collection('inviteUsers')
      .add(inviteUserObject)
      .then((res) => {
        setMessage('Success');
        setOpenSnackbar(true);
      })
      .catch((err) => {
        setMessage(`Error: ${err.message}`);
        setOpenSnackbar(true);
      });
  };

  return (
    <Box display="flex" justifyContent="flex-end">
      <Snackbar open={openSnackbar} autoHideDuration={3} message={message} />
      <FormControl className={classes.formControl}>
        <TextField placeholder="email" onChange={(e) => setEmail(e.target.value)} />
      </FormControl>
      <FormControl className={classes.formControl}>
        <Select value={access} onChange={(e) => setAccess(e.target.value)}>
          <MenuItem value="access" disabled>
            Choose access
          </MenuItem>
          {roleOptions.map((option, i) => {
            return (
              <MenuItem key={i} value={option}>
                {projectId.startsWith('beekeeper-')
                  ? beekeeperUserRoleLabels[option]
                  : pollyUserRoleLabels[option]}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <Box display="flex">
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={inviteUserAction}
        >
          Invite User
        </Button>
        {loading && (
          <div
            style={{
              marginLeft: '10px',
              marginTop: '5px',
              fontSize: 15,
              color: 'orange',
            }}
          >
            Updating...
          </div>
        )}
        {loading && (
          <CircularProgress style={{ marginLeft: '10px', fontSize: 50, color: 'orange' }} />
        )}
      </Box>
    </Box>
  );
}

function ProjectUsersTable({ tableData, accountType, isAdmin, renderRoleCell }) {
  const classes = useStyles();
  const theme = useTheme();
  // console.log('ProjectUsersTable', tableData);
  // const screenExtraLarge = useMediaQuery(theme.breakpoints.only('xl'));
  // const screenLarge = useMediaQuery(theme.breakpoints.only('lg'));
  // const screenMedium = useMediaQuery(theme.breakpoints.only('md'));
  // const screenSmall = useMediaQuery(theme.breakpoints.only('sm'));
  // const screenExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));
  // const screenNarrow = useMediaQuery('(max-width:340px)');
  const screenSmallOrLess = useMediaQuery(theme.breakpoints.down('sm'));
  addIdToTableData(tableData);
  return (
    <div style={{ height: 400, width: '100%' }}>
      <form className={classes.root} noValidate autoComplete="off"></form>
      <DataGrid
        rows={tableData}
        columns={getColumns({
          downValue: screenSmallOrLess,
          accountType,
          isAdmin,
          renderRoleCell,
        })}
        pageSize={50}
      />
    </div>
  );
}

function getRolesDisplay({ roles = [], accountType }) {
  if (roles.length === 0) {
    return '';
  }
  if (accountType === accountTypes.beekeeper) {
    return roles.map((role) => beekeeperUserRoleLabels[role]).join();
  } else if (accountType === accountTypes.polly) {
    return roles.map((role) => pollyUserRoleLabels[role]).join();
    // return display.substring(0, display.length - 1);
  }
}

function getColumns({ downValue, accountType, isAdmin, renderRoleCell }) {
  let columns = [
    {
      field: 'firstName',
      headerName: 'First name',
      width: 130,
      hide: downValue,
    },
    {
      field: 'lastName',
      headerName: 'Last name',
      width: 130,
      hide: downValue,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 250,
    },
    // {
    //   field: 'fullName',
    //   headerName: 'Full Name',
    //   width: 130,
    //   // hide: downValue,
    //   renderCell: (params) => {
    //     const firstName = params.getValue('firstName');
    //     const lastName = params.getValue('lastName');
    //     let fullName = firstName && lastName && firstName + ' ' + lastName;
    //     if (!fullName) {
    //       if (firstName) {
    //         fullName = firstName;
    //       } else if (lastName) {
    //         fullName = lastName;
    //       }
    //     }
    //     // const fullName = firstName && lastName ? firstName + ' ' + lastName : firstName
    //     return fullName;
    //   },
    // },
    {
      field: 'status',
      headerName: 'Status',
      width: 250,
      hide: downValue,
    },
    // {
    //   field: 'dateCreated',
    //   headerName: 'Date Invited',
    //   width: 200,
    //   hide: downValue,
    //   valueGetter: params => params.row.dateCreated && format(params.row.dateCreated.toDate(), 'dd-MMM-yy HH:mm:ss'),
    // },
    {
      field: 'view',
      headerName: 'View',
      width: 100,
      renderCell: (params) => {
        // console.log('params', params);
        const userId = params.row.userId || 'no_user_id';
        return <RouterLink to={`/users/${userId}`}>View</RouterLink>;
      },
    },
    {
      field: 'role',
      headerName: 'Role',
      width: 250,
      hide: downValue,
      renderCell: (params) => {
        const rolesDisplay = getRolesDisplay({
          roles: params.row.userRoles,
          accountType,
        });
        return <div>{rolesDisplay}</div>;
      },
    },
  ];

  // if (accountType === accountTypes.beekeeper && isAdmin) {
  //   columns.push({
  //     field: 'role',
  //     headerName: 'Role',
  //     width: 250,
  //     hide: downValue,
  //     renderCell: renderRoleCell,
  //   });
  // }

  return columns;
}

function addIdToTableData(tableData) {
  let counter = 0;
  tableData.forEach((entry) => {
    if (!entry.id) {
      entry.id = counter++;
    }
  });
}

function Breadcrumbs() {
  const breadcrumbs = [
    {
      path: '/',
      text: 'Home',
    },
    {
      path: `/user-management`,
      text: 'User Management',
    },
  ];
  return <BreadcrumbNav breadcrumbs={breadcrumbs} />;
}
