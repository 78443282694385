import React, { useState } from 'react';
import { useFirestore } from 'react-redux-firebase';
import {
  TextField,
  TextareaAutosize,
  Button,
  Chip,
  IconButton,
  // Box,
  Grid,
  CircularProgress,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { CustomSelect } from '../Shared/Dropdown';
import { DateRangeFilter } from '../Shared/DateRange';
import { farmOptions, siteOptions } from './sampleData';
import FileInput from '../Shared/CustomFileInput';
import { putImagetoStorage } from '../Shared/ahsanUtil';

export const AddYieldForm = () => {
  const firestore = useFirestore();
  const [yieldNoteFormFilters, setYieldNoteFormFilters] = useState({
    selectedFilter: '',
    selectedValue: '',
    text: '',
    noteDateFrom: '',
    noteDateTo: '',
    yieldAmount: '',
    unit: '',
    crop: '',
    tags: [], //optional
  });
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState('');
  const [initialDateRangeValues, setInitialDateRangeValues] = useState(null);
  const [error, setError] = useState(false);
  const onGroupLevelChange = (e) => {
    const { value } = e.target;
    setYieldNoteFormFilters({ ...yieldNoteFormFilters, selectedValue: value });
  };

  const onFilterChange = (e) => {
    const { value } = e.target;
    setYieldNoteFormFilters({ ...yieldNoteFormFilters, selectedFilter: value });
  };

  const onTextAreaChange = (e) => {
    const { value } = e.target;
    setYieldNoteFormFilters({ ...yieldNoteFormFilters, text: value });
  };

  const onDateFilterChange = ({ startDate, endDate }) => {
    setYieldNoteFormFilters({ ...yieldNoteFormFilters, noteDateFrom: startDate, noteDateTo: endDate });
    setInitialDateRangeValues('');
  };

  const onUnitChange = (e) => {
    const { value } = e.target;
    setYieldNoteFormFilters({ ...yieldNoteFormFilters, unit: value });
  };

  const onCropChange = (e) => {
    const { value } = e.target;
    setYieldNoteFormFilters({ ...yieldNoteFormFilters, crop: value });
  };

  const onAmountChange = (e) => {
    const { value } = e.target;
    setYieldNoteFormFilters({ ...yieldNoteFormFilters, yieldAmount: value });
  };

  const addTag = () => {
    let tag = document.getElementById('tag').value;
    if (tag !== '') {
      setYieldNoteFormFilters({ ...yieldNoteFormFilters, tags: [...yieldNoteFormFilters.tags, tag] });
    }
    document.getElementById('tag').value = '';
  };

  const deleteTag = (comingChip) => {
    const filteredTags = yieldNoteFormFilters.tags.filter((chip) => chip !== comingChip);
    setYieldNoteFormFilters({ ...yieldNoteFormFilters, tags: filteredTags });
  };

  const clearYieldNoteForm = () => {
    document.getElementById('tag').value = '';
    setYieldNoteFormFilters({
      selectedFilter: '',
      selectedValue: '',
      text: '',
      noteDateFrom: '',
      noteDateTo: '',
      yieldAmount: '',
      unit: '',
      crop: '',
      tags: [], //optional
    });
    setPhotos([]);
    setInitialDateRangeValues([null, null]);
  };

  const addYieldNote = (e) => {
    const imgBucketPath = 'yieldNoteImages/';
    e.preventDefault();
    setLoading(true);
    if (photos.length <= 5) {
      try {
        Promise.all(photos.map((img) => putImagetoStorage({ img, imgBucketPath }))).then((url) => {
          firestore.collection('notes').add({
            type: 'yieldNote',
            dateCreated: new Date(),
            noteDateFrom: yieldNoteFormFilters.noteDateFrom,
            noteDateTo: yieldNoteFormFilters.noteDateTo,
            groupingLevel: yieldNoteFormFilters.selectedFilter,
            groupingLevelValue: yieldNoteFormFilters.selectedValue,
            text: yieldNoteFormFilters.text,
            photos: url,
            yields: {
              yieldAmount: yieldNoteFormFilters.yieldAmount,
              unit: yieldNoteFormFilters.unit,
              crop: yieldNoteFormFilters.crop,
              tags: yieldNoteFormFilters.tags, //optional
            },
          });
          clearYieldNoteForm();
          setLoading(false);
          setError(false);
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      setError(true);
      setLoading(false);
      setPhotos([]);
    }
  };

  const handlePhotoChange = (files) => {
    const file = files;
    setPhotos([...photos, ...file]);
  };

  return (
    <form id="yieldNoteForm" style={{ padding: '20px' }} onSubmit={addYieldNote}>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <CustomSelect
            options={[
              { label: 'Farm', value: 'Farm' },
              { label: 'Site', value: 'Site' },
            ]}
            onSelectChange={onFilterChange}
            label={'Group Level'}
            selectedValue={yieldNoteFormFilters.selectedFilter}
            data-testid="groupLevel"
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <CustomSelect
            options={yieldNoteFormFilters.selectedFilter === 'Site' ? siteOptions : farmOptions}
            onSelectChange={onGroupLevelChange}
            label={'Group Level Value'}
            disabled={!yieldNoteFormFilters.selectedFilter}
            selectedValue={yieldNoteFormFilters.selectedValue}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <CustomSelect
            options={[
              { label: 'kg', value: 'kg' },
              { label: ' tonnes per hectare', value: ' tonnes per hectare' },
            ]}
            onSelectChange={onUnitChange}
            selectedValue={yieldNoteFormFilters.unit}
            label={'Unit'}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <DateRangeFilter
            resetDateRangeValues={initialDateRangeValues}
            onFilterChanged={onDateFilterChange}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            value={yieldNoteFormFilters.crop}
            onChange={onCropChange}
            label="Crop"
            variant="outlined"
            style={{ width: '100%' }}
            data-testid="crop"
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            type="number"
            value={yieldNoteFormFilters.yieldAmount}
            onChange={onAmountChange}
            label="Yield Amount"
            variant="outlined"
            style={{ width: '100%' }}
            data-testid="yieldAmount"
          />
        </Grid>

        <Grid item xs={12}>
          <TextareaAutosize
            aria-label="minimum height"
            value={yieldNoteFormFilters.text}
            minRows={3}
            placeholder="Note..."
            style={{ width: '100%', margin: '0px' }}
            onChange={onTextAreaChange}
            data-testid="note-text"
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <div style={{ display: 'flex', alignItems: 'center center', marginBottom: '15px' }}>
            <TextField id="tag" label="Add Tag" variant="outlined" style={{ flexGrow: 1 }} />
            <IconButton color="primary" onClick={addTag}>
              <AddIcon />
            </IconButton>
          </div>
          {yieldNoteFormFilters.tags?.map((data) => (
            <Chip label={data} onDelete={() => deleteTag(data)} variant="filled" />
          ))}
        </Grid>
        <Grid item xs={12}>
          <FileInput
            //innerRef={imgRef}
            accept="image/*"
            onChange={handlePhotoChange}
            name="upload-photo"
            type="file"
            multiple
            value={photos}
            data-testid="photos"
          />
          {error && <span style={{ color: 'red' }}>Images Must Be Less than or Equal to 5</span>}
        </Grid>
        <Grid item xs={12}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Button type={'submit'} variant={'contained'}>
              Submit Yield Note
            </Button>
          )}
          <Button style={{ marginLeft: '4px' }} onClick={clearYieldNoteForm}>
            Reset Form
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
