import { accountTypes } from './accountTypes';
import {
  beekeeperUserRoles as beeRoles,
  pollyUserRoles as pollyRoles,
} from './userRoles';
export const beekeeperMenuItems = [
  {
    name: 'Home',
    key: 'home',
    to: '/',
    divide: true,
    isAdmin: false,
    userRoles: [beeRoles.read, beeRoles.admin, beeRoles.agriSoundAdmin],
  },
  {
    name: 'Hives Overview',
    key: 'hives-overview',
    to: '/hives-overview',
    divide: true,
    isAdmin: false,
    userRoles: [beeRoles.read, beeRoles.admin, beeRoles.agriSoundAdmin],
  },
  {
    name: 'Settings',
    key: 'projects',
    to: '/projects',
    divide: true,
    isAdmin: false,
    userRoles: [beeRoles.read, beeRoles.admin, beeRoles.agriSoundAdmin],
  },
  {
    name: 'Alerts',
    key: 'alerts',
    to: '/alerts',
    divide: true,
    isAdmin: false,
    userRoles: [beeRoles.read, beeRoles.admin, beeRoles.agriSoundAdmin],
  },
  {
    name: 'Blog',
    key: 'blog',
    to: '/blog',
    divide: true,
    isAdmin: false,
    userRoles: [beeRoles.read, beeRoles.admin, beeRoles.agriSoundAdmin],
  },
  {
    name: 'Contact',
    key: 'contact',
    to: '/contact',
    divide: true,
    isAdmin: false,
    userRoles: [beeRoles.read, beeRoles.admin, beeRoles.agriSoundAdmin],
  },
  {
    name: 'Admin',
    key: 'admin',
    to: '/admin',
    divide: true,
    isAdmin: true,
    userRoles: [beeRoles.agriSoundAdmin],
  },
  {
    name: 'Inventory',
    key: 'inventory',
    to: '/inventory',
    divide: true,
    isAdmin: true,
    userRoles: [beeRoles.agriSoundAdmin],
  },
  {
    name: 'Polly Overview',
    key: 'polly-dashboard',
    to: '/polly-dashboard',
    divide: true,
    isAdmin: true,
    userRoles: [beeRoles.agriSoundAdmin],
  },
  {
    name: 'Polly Detail',
    key: 'canterbury',
    to: '/canterbury',
    divide: true,
    isAdmin: true,
    userRoles: [beeRoles.agriSoundAdmin, beeRoles.bardsley],
  },
];

export const pollyMenuItems = [
  {
    name: 'Home',
    key: 'home',
    to: '/',
    divide: true,
    isAdmin: false,
    userRoles: Object.values(pollyRoles),
    icon: 'HomeIcon',
  },
  {
    name: 'Devices',
    key: 'polly-devices',
    to: '/polly-devices',
    divide: true,
    isAdmin: false,
    userRoles: Object.values(pollyRoles),
    icon: 'DevicesIcon',
  },
  {
    name: 'Settings',
    key: 'projects',
    to: '/projects',
    divide: true,
    isAdmin: false,
    userRoles: [
      pollyRoles.agriSoundAdmin,
      pollyRoles.business,
      pollyRoles.projectAdmin,
      pollyRoles.deviceInstaller,
    ],
    icon: 'SettingsIcon',
  },
  {
    name: 'Install Device',
    key: 'device-install',
    to: '/device-install',
    divide: true,
    isAdmin: false,
    userRoles: [pollyRoles.agriSoundAdmin],
  },
  {
    name: 'Project Overview',
    key: 'projectOverview',
    to: '/project-overview',
    divide: true,
    isAdmin: false,
    userRoles: [
      pollyRoles.agriSoundAdmin,
      pollyRoles.projectOverview,
      pollyRoles.projectAdmin,
    ],
    icon: 'AssessmentIcon',
  },
  {
    name: 'Yield Notes',
    key: 'yieldNotes',
    to: '/yield-notes',
    divide: true,
    isAdmin: false,
    userRoles: [pollyRoles.agriSoundAdmin],
    icon: 'AssessmentIcon',
  },
  {
    name: 'Admin',
    key: 'admin',
    to: '/admin',
    divide: true,
    isAdmin: true,
    userRoles: [pollyRoles.agriSoundAdmin],
  },
  {
    name: 'Inventory',
    key: 'inventory',
    to: '/inventory',
    divide: true,
    isAdmin: true,
    userRoles: [pollyRoles.agriSoundAdmin],
    icon: 'MapIcon',
  },
  {
    name: 'Reports',
    key: 'reports',
    to: '/reports',
    divide: true,
    isAdmin: false,
    userRoles: [
      pollyRoles.agriSoundAdmin,
      pollyRoles.business,
      pollyRoles.sponsor,
      pollyRoles.projectAdmin,
    ],
  },
];

export function getMenuConfig({ accountType, userRoles }) {
  if (accountType === accountTypes.polly) {
    const menuItems = pollyMenuItems.filter((menuItem) =>
      Array.isArray(menuItem.userRoles)
    );
    return menuItems.filter((menuItem) =>
      arrayContains(userRoles, menuItem.userRoles)
    );
  } else if (accountType === accountTypes.beekeeper) {
    const menuItems = beekeeperMenuItems.filter((menuItem) =>
      Array.isArray(menuItem.userRoles)
    );
    const items = menuItems.filter((menuItem) =>
      arrayContains(userRoles, menuItem.userRoles)
    );
    // console.log('getMenuConfig', accountType, userRoles, items);
    return items;
  }
}

function arrayContains(arr1, arr2) {
  let result = false;
  for (let entry of arr1) {
    if (arr2.includes(entry)) result = true;
  }

  return result;
}

function rolesWithExclusion(roles, excludedRoles) {
  return Object.values(pollyRoles).filter(
    (role) => !excludedRoles.includes(role)
  );
}
