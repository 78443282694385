import { auth, firestore } from '../firebase';
import { log } from '../util/log-util';

export async function updateSelectedProjectId({ userId, selectedProjectId, selectedProjectAccountType }) {
  let updatedObj = { selectedProjectId };
  if(selectedProjectAccountType) updatedObj.selectedProjectAccountType = selectedProjectAccountType;
  addAudit(updatedObj, `updated selectedProjectId value to ${selectedProjectId}`);
  log(`updated selectedProjectId value to ${selectedProjectId} for userId ${userId}`);
  await firestore.doc(`users/${userId}`).update(updatedObj);
}

export async function updateSelectedSiteName({ userId, selectedSiteName }) {
  let updatedObj = { selectedSiteName };
  addAudit(updatedObj, `updated selectedSiteName value to ${selectedSiteName}`);
  log(`updated selectedSiteName value to ${selectedSiteName} for userId ${userId}`);
  await firestore.doc(`users/${userId}`).update(updatedObj);
}

export async function updateSelectedProjectAccountType({ userId, selectedProjectAccountType }) {
  let updatedObj = { selectedProjectAccountType };
  addAudit(updatedObj, `updated selectedProjectAccountType value to ${selectedProjectAccountType}`);
  log(`updated selectedProjectId value to ${selectedProjectAccountType} for userId ${userId}`);
  await firestore.doc(`users/${userId}`).update(updatedObj);
}

export async function updateSelectedProjectUserRole({ userId, selectedProjectUserRole  }) {
  let updatedObj = { selectedProjectUserRole };
  addAudit(updatedObj, `updated selectedProjectUserRole value to ${selectedProjectUserRole}`);
  log(`updated selectedProjectUserRole value to ${selectedProjectUserRole} for userId ${userId}`);
  await firestore.doc(`users/${userId}`).update(updatedObj);
}

export async function replaceUserRole({ projectId, userId, userRole }) {
  let requestObj = { projectId, userId, userRoles: [userRole] };
  // addAudit(updatedObj, `replaceUserRole to ${userRole}`);
  // await firestore.doc(`users/${userId}`).update(updatedObj);
  await firestore.collection(`updateUserRole`).add(requestObj);
}

function addAudit(obj, comment) {
  const uid = auth.currentUser && auth.currentUser.uid;
  obj.updatedBy = uid;
  if (comment) obj.updateComment = comment;
  obj.dateUpdated = new Date();
}
