import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { getSelectedProject, getSelectedProjectId } from '../../../config/env';
import { useSelector } from 'react-redux';
import { getProjectQuery } from '../../../api/queryConfig';
import { isLoaded, useFirestoreConnect, useFirestore } from 'react-redux-firebase';
import AlertsForm from './AlertsForm';

//TODO: use formik
//https://codesandbox.io/s/github/formik/formik/tree/master/examples/with-material-ui?from-embed

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  formControlLabel: {
    fontSize: 12,
  },
  container: {
    margin: '20px',
  },
  submitButton: {
    marginLeft: '40px',
    marginBottom: '20px',
  },
}));

export default function AlertsConfig() {
  const classes = useStyles();
  const firestore = useFirestore();
  const userData = useSelector((state) => state.appConfig.userData);
  const selectedProjectId = getSelectedProjectId(userData);
  const storeAs = `project-${selectedProjectId}`;
  const projectQuery = getProjectQuery({ projectId: selectedProjectId, storeAs });
  const project = useSelector(({ firestore: { data } }) => data.projects && data.projects[selectedProjectId]);
  useFirestoreConnect([projectQuery]);

  if (!isLoaded(project)) {
    return 'Loading...';
  }

  const alerts =
    (project && project.alertsConfig && project.alertsConfig.filter((alert) => alert.visible === true)) || [];

  return <AlertsForm firestore={firestore} projectId={selectedProjectId} classes={classes} alerts={alerts} />;
}
