import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { accountTypes } from '../../../config/accountTypes';
import BeekeeperBar from './BeekeeperBar';
import PollyBar from './PollyBar';
import HorizontalBar2021 from './HorizontalBar2021';
import { accountTypesNewStyle } from '../../../reducers/app-config';

Bar.defaultProps = {
  performingAction: false,
};

Bar.propTypes = {
  // Properties
  performingAction: PropTypes.bool.isRequired,
  user: PropTypes.object,
  userData: PropTypes.object,

  // Events
  onAboutClick: PropTypes.func.isRequired,
  onSettingsClick: PropTypes.func.isRequired,
  onSignOutClick: PropTypes.func.isRequired,
};

export default function Bar({
  performingAction,
  user,
  userData,
  roles,
  onAboutClick,
  onSettingsClick,
  onSignOutClick,
  onSignUpClick,
  onSignInClick,
  accountType = accountTypes.beekeeper,
  theme
}) {
  // console.log('Bar', userData, { accountType });
  if (accountType === accountTypes.oldBeekeeper) {
    return <HorizontalBar2021
      performingAction={performingAction}
      theme={theme}
      user={user}
      userData={userData}
      roles={roles}
      onSignUpClick={onSignUpClick}
      onSignInClick={onSignInClick}
      onAboutClick={onAboutClick}
      onSettingsClick={onSettingsClick}
      onSignOutClick={onSignOutClick}
    />;
  } else if (accountTypesNewStyle.includes(accountType)) {
    return <PollyBar
      performingAction={performingAction}
      theme={theme}
      user={user}
      userData={userData}
      roles={roles}
      onSignUpClick={onSignUpClick}
      onSignInClick={onSignInClick}
      onAboutClick={onAboutClick}
      onSettingsClick={onSettingsClick}
      onSignOutClick={onSignOutClick}
    />;
  } else {
    // throw new Error(`Bar component not set-up for accountType ${accountType}`)
    // return <BeekeeperBar
    //   performingAction={performingAction}
    //   theme={theme}
    //   user={user}
    //   userData={userData}
    //   roles={roles}
    //   onSignUpClick={onSignUpClick}
    //   onSignInClick={onSignInClick}
    //   onAboutClick={onAboutClick}
    //   onSettingsClick={onSettingsClick}
    //   onSignOutClick={onSignOutClick}
    // />;
    return <PollyBar
      performingAction={performingAction}
      theme={theme}
      user={user}
      userData={userData}
      roles={roles}
      onSignUpClick={onSignUpClick}
      onSignInClick={onSignInClick}
      onAboutClick={onAboutClick}
      onSettingsClick={onSettingsClick}
      onSignOutClick={onSignOutClick}
    />;
  }
}
