import List from '@material-ui/core/List';
import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import RouterLink from '../Nav/RouterLink';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ListItemText from '@material-ui/core/ListItemText';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '100%',
    // maxWidth: '48ch',
    backgroundColor: theme.palette.background.paper,
  },
  titleWide: {
    [theme.breakpoints.down('sm')]: {
      width: '300px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
      width: '400px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('md')]: {
      width: '700px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('lg')]: {
      width: '500px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      width: '300px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
      width: '400px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('md')]: {
      width: '500px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('lg')]: {
      width: '200px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  paper: {
    width: '100%',
    // maxWidth: '48ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  button: {
    textTransform: 'none',
    paddingTop: '28px',
    paddingLeft: '80px',
  },
  link: {
    marginLeft: '28px',
  },
  linkNoSubtitle: {
    fontSize: '14px',
    marginLeft: '28px',
  },
  listItemSecondary: {
    width: '85%',
    fontWeight: 'normal',
    color: '#7a7a7a',
    overflow: 'hidden',
  },
}));

export default function ArticleListWidget({
  overrideClasses,
  articles,
  routePath,
  linkWidth,
  showLink,
  showSubtitle,
  isComponentWide,
}) {
  let classes = useStyles();
  if(overrideClasses) classes = overrideClasses;
  return (
    <ArticleList
      articles={articles}
      routePath={routePath}
      classes={classes}
      linkWidth={linkWidth}
      showLink={showLink}
      showSubtitle={showSubtitle}
      isComponentWide={isComponentWide}
    />
  );
}

function ArticleList({
  articles = [],
  routePath,
  classes,
  linkWidth,
  showLink,
  showSubtitle,
  isComponentWide,
}) {
  return (
    <List className={classes.root}>
      {articles.map((article, index) => {
        return (
          <React.Fragment key={index}>
            <ListItem key={index} alignItems="flex-start">
              <ArticleHeadline
                routePath={routePath}
                articleId={article.id}
                isComponentWide={isComponentWide}
                title={article.title}
                subtitle={article.subtitle}
                showSubtitle={showSubtitle}
                classes={classes}
                linkWidth={linkWidth}
                showLink={showLink}
              />
            </ListItem>
            {articles.length - 1 !== index && <Divider />}
          </React.Fragment>
        );
      })}
    </List>
  );
}

function ArticleHeadline({
  articleId,
  routePath,
  title,
  subtitle,
  classes,
  linkWidth,
  showLink,
  showSubtitle,
  isComponentWide = false,
}) {
  const link = <RouterLink to={`${routePath}/${articleId}`}>View</RouterLink>;
  const titleWidth = 11;
  const defaultLinkWidth = 12 - titleWidth;
  const primary = (
    <Grid container>
      <Grid
        item
        xs={titleWidth}
        sm={titleWidth}
        md={titleWidth}
        lg={isComponentWide ? titleWidth : 12 - linkWidth}
      >
        <Typography className={isComponentWide ? classes.titleWide : classes.title}>{title}</Typography>
      </Grid>
      {showLink && (
        <Grid
          item
          xs={defaultLinkWidth}
          sm={defaultLinkWidth}
          md={defaultLinkWidth}
          lg={isComponentWide ? defaultLinkWidth : linkWidth}
        >
          {link}
        </Grid>
      )}
    </Grid>
  );
  if (!showSubtitle) {
    return <ListItemText primary={primary} />;
  }

  let text =
    'Text Overflow Ellipsis. Text Overflow Ellipsis. Text Overflow Ellipsis. Text Overflow Ellipsis. Text Text Overflow Ellipsis. Text Overflow Ellipsis. Text Overflow Ellipsis. Text Overflow Ellipsis. Text';
  text =
    'The term Homeostasis refers to the mechanisms that allow insects (in this case your honey bees) to maintain conditions within a certain range of values.These conditions may be temperature of the environment, humidity, food stores etc.The bee colony as a group, will regulate these conditions to an optimum state to ensure their survival.';
  // return (
  //   <div style={{ width: '500px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
  //     {subtitle}
  //   </div>
  // )
  return (
    <ListItemText
      // style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
      primary={primary}
      secondary={<div className={isComponentWide ? classes.titleWide : classes.title}>{subtitle}</div>}
    />
  );
  // return (
  //   <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
  //     Text Overflow Ellipsis. Text Overflow Ellipsis. Text Overflow Ellipsis. Text Overflow Ellipsis. Text
  //     Overflow Ellipsis. Text Overflow Ellipsis. Text Overflow Ellipsis. Text Overflow Ellipsis. Text Overflow
  //     Ellipsis
  //   </div>
  // );
}
