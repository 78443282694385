import React from 'react';
import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import RouterLink from '../Nav/RouterLink';
import { getProjectIds, getSelectedProjectId } from '../../../config/env';
import { alertsCollectionName, getProjectQuery, getTheftAlertsTodayQuery } from '../../../api/queryConfig';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { convertFirestoreCollectionToArray } from '../../../data/data-util';
import { log } from '../../../util/log-util';

export default function TheftAlertsBanner() {
  const userData = useSelector((state) => state.appConfig.userData);
  const selectedProjectId = getSelectedProjectId(userData);
  let projectIds = getProjectIds(userData);
  const projectQuery = getProjectQuery({ projectId: selectedProjectId });
  const storeAs = 'theftAlertsTodayQuery';
  const theftAlertsQuery = getTheftAlertsTodayQuery({ projectIds, storeAs });
  const projectData = useSelector(
    ({ firestore: { data } }) => data.projects && data.projects[selectedProjectId]
  );
  const theftAlertsData = useSelector(({ firestore: { data } }) => data[storeAs]);
  useFirestoreConnect([projectQuery, theftAlertsQuery]);

  if (!isLoaded(projectData, theftAlertsData)) {
    return 'Loading...';
  }

  let theftAlerts = convertFirestoreCollectionToArray({
    collectionData: theftAlertsData,
    convertDates: true,
    collectionName: alertsCollectionName,
  });

  // console.log('theftAlertsData', theftAlerts);

  if (theftAlerts === undefined || theftAlerts.length === 0) {
    return <></>;
  }
  // const link = <RouterLink to={`alerts`}>Alerts</RouterLink>;
  // let msg = `<div>There have been ${theftAlerts.length} 'Theft' alerts today. Go to the ${link} section to view in detail</div>`;
  // if (theftAlerts.length === 1) {
  //   msg = `There has been ${theftAlerts.length} 'location change' alert today. Go to the ${link} section to view in detail`;
  // }

  return (
    <>
      <Box
        py={1}
        display="flex"
        justifyContent="center"
        component={'div'}
        style={{ width: '100%', backgroundColor: 'orange' }}
      >
        <Grid container style={{ width: '100%', backgroundColor: 'orange' }}>
          <Grid item xs={11} sm={11} md={11} lg={11}>
            <Box
              display="flex"
              justifyContent="center"
              component={'div'}
              style={{ width: '100%', backgroundColor: 'orange' }}
            >
              <div>
                There have been {theftAlerts.length} 'Theft' alerts today. Go to the{' '}
                <RouterLink style={{ color: 'blue' }} to={`alerts`} text={'Alerts'} /> section to view in
                detail
              </div>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
