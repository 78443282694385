import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React from 'react';
import { chooseGatewayIdText } from '../../../reducers/app-config';

export function GatewayDeviceIdsSelect({ gatewayDeviceId, gatewayDeviceIds, setGatewayDeviceId, validate }) {

  return (
    <Select
      defaultValue={chooseGatewayIdText}
      labelId="demo-multiple-name-label"
      id="demo-multiple-name"
      value={gatewayDeviceId ? gatewayDeviceId : ''}
      onChange={event => {
        const value = event.target.value
        setGatewayDeviceId(value);
        validate();
      }}
    >
      <MenuItem value={chooseGatewayIdText} disabled>
        {chooseGatewayIdText}
      </MenuItem>
      {gatewayDeviceIds.map(value => (
        <MenuItem style={{ display: 'block' }} key={value} value={value}>
          {value}
        </MenuItem>
      ))}
    </Select>
  );
}
