import React from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Title from './Title';
import Grid from '@material-ui/core/Grid';

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
  title: {
    color: 'blue',
  },
});

export default function WidgetWrapper({
  title,
  action = undefined,
  component = <></>,
  isComponentWide = true,
}) {
  const classes = useStyles();
  if (action) {
    return (
      <Grid container className={classes.title}>
        <Grid item xs={11} sm={11} md={11} lg={isComponentWide ? 11 : 9}>
          <Title className={classes.title}>{title}</Title>
        </Grid>
        {action && (
          <Grid item xs={1} sm={1} md={1} lg={isComponentWide ? 1 : 3}>
            {action}
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {component}
        </Grid>
      </Grid>
    );
  }
  return (
    <>
      {title && <Title className={classes.title}>{title}</Title>}
      {component}
    </>
  );
}
