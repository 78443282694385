import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { saveAs } from 'file-saver';
import { toCsv } from '../../beekeeper/Download/DownloadFile';
import { getPollyDailyValuesFileName, downloadPollyDailyReport } from './utils/pollyDailyExport';

export default function ExportPollyDaily({ classes, projectIds }) {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const [projectId, setProjectId] = useState();
  const [validationMessages, setValidationMessages] = useState(['placeholder']);

  const validate = () => {
    const messages = [];
    if (!projectId) {
      messages.push('Please select a projectId');
    }

    setValidationMessages(messages);
  };

  useEffect(() => {
    validate();
  }, [projectId]);

  const exportPollyDailyValuesAction = async () => {
    setLoading(true);
    const fileName = await getPollyDailyValuesFileName({ projectId });
    const [dailyValues, headers] = await downloadPollyDailyReport({ projectId });
    const csv = toCsv({ jsonData: dailyValues, fields: headers });
    const blob = new Blob([csv], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, fileName);
    setLoading(false);
  };

  const defaultProjectSelectProps = {
    options: projectIds,
    getOptionLabel: (option) => option,
  };
  return (
    <Grid container spacing={0}>
      <Grid container item xs={12}>
        <FormControl className={classes.formControl}>
          <Autocomplete
            value={projectId}
            {...defaultProjectSelectProps}
            id="projectId"
            renderInput={(params) => <TextField {...params} label="Project ID" margin="normal" />}
            onChange={(e, value) => {
              if (value) {
                setProjectId(value);
              } else {
                setProjectId(undefined);
              }
              validate();
            }}
          />
        </FormControl>
      </Grid>
      {validationMessages.length > 0 && (
        <Grid container item xs={12}>
          <FormControl className={classes.formControl}>
            <div>
              {validationMessages.map((message) => {
                return (
                  <div key={message} style={{ color: 'red' }}>
                    {message}
                  </div>
                );
              })}
            </div>
          </FormControl>
        </Grid>
      )}
      <Grid container item xs={12}>
        <FormControl className={classes.formControl}>
          <Grid container spacing={0}>
            <Grid container item xs={6}>
              <Button
                color="primary"
                variant="contained"
                onClick={exportPollyDailyValuesAction}
                disabled={validationMessages.length > 0}
              >
                Export
              </Button>
            </Grid>
            <Grid container item xs={6}>
              {loading && <CircularProgress className={classes.submitButton} />}
              {message && (
                <div style={{ color: 'blue', fontSize: '16px', fontWeight: 'bold' }}>{message}</div>
              )}
            </Grid>
          </Grid>
        </FormControl>
      </Grid>
    </Grid>
  );
}
