export function flatten(tree) {
  const rootNode = {
    id: tree.id,
    name: tree.name,
  };
  let allItems = [rootNode];
  let current = tree.children;
  let processNext = [];
  let continueLooping = true;

  while (continueLooping) {
    for (let child of current) {
      allItems.push(child);
      if (child.children) {
        processNext = processNext.concat(child.children);
      }
    }
    if (processNext.length > 0) {
      current = processNext;
      processNext = [];
    } else {
      continueLooping = false;
    }
  }

  return allItems;
}
