import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import WidgetWrapper from './WidgetWrapper';
import CmsWidget from '../Widgets/CmsWidget';
import BeekeeperMap from '../FieldOverviewPage/BeekeeperMap';
import HivesWidget from '../Hives/HivesWidget';
import RouterLink from '../Nav/RouterLink';

import AlertsWidget from '../Widgets/AlertsWidget';
import UserTips from '../UserTips/UserTips';
import { useSelector } from 'react-redux';
import { getProjectQuery } from '../../../api/queryConfig';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { getSelectedProjectId } from '../../../config/env';
import { isWeatherLocationSetUp } from '../../../data/data-util';
import TheftAlertsBanner from '../BannerAlert/TheftAlertsBanner';
import { globalElevation } from '../../../config/globalStyle';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: 240,
    // backgroundColor: '#E8E8E8',
    // border: 'solid'
  },
  paperNoHeight: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    // backgroundColor: '#E8E8E8'
  },
  fixedHeight: {
    height: 240,
  },
  heightRightComponent1: {
    height: 320,
  },
  heightRightComponent2: {
    height: 320,
  },
  fixedHeightSpan: {
    height: 305,
  },
  fixedHeightBottomRow: {
    height: 400,
  },
  grid: {
    margin: theme.spacing(0),
    flexGrow: 0,
    maxWidth: `100%`,
    flexBasis: `100%`,
  },
}));

function weatherLocationNotSetUpMessage() {
  return {
    title: (
      <>
        Display weather data in the charts by configuring your project's weather location by navigating to the{' '}
        <RouterLink to={'/projects'} text={'Settings'} /> tab
      </>
    ),
  };
}
export default function DashboardPolly({ userRoles }) {
  const classes = useStyles();
  const userData = useSelector((state) => state.appConfig.userData);
  const selectedProjectId = getSelectedProjectId(userData);
  const projectQuery = getProjectQuery({ projectId: selectedProjectId });
  const projectData = useSelector(
    ({ firestore: { data } }) => data.projects && data.projects[selectedProjectId]
  );
  useFirestoreConnect([projectQuery]);

  if (!isLoaded(projectData)) {
    return 'Loading...';
  }
  let tips = [];

  const isWeatherSetup = isWeatherLocationSetUp(projectData && projectData.weatherLocations);

  if (!isWeatherSetup) {
    tips.push(weatherLocationNotSetUpMessage());
  }
  // const tips = [{
  //   title: 'Here is a tip for you'
  // }];
  const elevation = globalElevation;
  return (
    <div className={classes.root}>
      <TheftAlertsBanner />
      <Grid container spacing={2}>
        {tips && tips.length > 0 && (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Paper className={classes.paperNoHeight} elevation={elevation}>
              <WidgetWrapper
                title={<>Setup Tips</>}
                // title={<RouterLink to={'#'} text={'User Tips'} />}
                component={<UserTips userTips={tips} />}
              />
            </Paper>
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Paper className={classes.paper} elevation={elevation}>
            <WidgetWrapper
              title={<RouterLink to={'hives-overview'} text={'My Hives'} />}
              component={<HivesWidget />}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Paper className={classes.paper} elevation={elevation}>
            <WidgetWrapper
              component={<BeekeeperMap />}
              title={<RouterLink to={'hives-overview'} text={'My Hives Map'} />}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <Paper className={classes.paperNoHeight} elevation={elevation}>
            <WidgetWrapper
              title={<RouterLink to={'kb'} text={'Knowledge Base'} />}
              component={
                <CmsWidget
                  linkWidth={1}
                  showSubtitle={false}
                  isComponentWide={false}
                  schemaName={'knowledgeBase'}
                  routePath={'kb'}
                />
              }
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <Paper className={classes.paperNoHeight} elevation={elevation}>
            <WidgetWrapper
              title={<RouterLink to={'alerts'} text={'Alerts'} />}
              component={
                <AlertsWidget
                  linkWidth={1}
                  routePath={'alerts'}
                  showSubtitle={true}
                  isComponentWide={true}
                  showLink={true}
                />
              }
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <Paper className={classes.paperNoHeight} elevation={elevation}>
            <WidgetWrapper
              title={<RouterLink to={'news'} text={'News & Events'} />}
              component={
                <CmsWidget
                  linkWidth={1}
                  showSubtitle={false}
                  isComponentWide={false}
                  schemaName={'newsAndEvents'}
                  routePath={'news'}
                />
              }
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
