import React from 'react';
import { log } from '../../../util/log-util';
import Weather from '../../beekeeper/Weather/Weather';
import Project from '../../beekeeper/Project/Project';
import Paper from '@material-ui/core/Paper';
import BreadcrumbNav from '../../beekeeper/Nav/BreadcrumbsNav';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { getSelectedProjectId } from '../../../config/env';
import { getProjectQuery } from '../../../api/queryConfig';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { isWeatherLocationSetUp } from '../../../data/data-util';
import RouterLink from '../../beekeeper/Nav/RouterLink';
import { makeStyles } from '@material-ui/core/styles';
import TheftAlertsBanner from '../../beekeeper/BannerAlert/TheftAlertsBanner';
import { globalElevation } from '../../../config/globalStyle';
import PollyDevices from './PollyDevices2';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
    // textAlign: 'center',
    // color: theme.palette.text.secondary,
  },
}));

export default function PollyDevicesOverview() {
  const classes = useStyles();
  // console.log('XXX.PollyDevicesOverview', new Date());
  const userData = useSelector((state) => state.appConfig.userData);
  const selectedProjectId = getSelectedProjectId(userData);
  const projectQuery = getProjectQuery({ projectId: selectedProjectId });
  const projectData = useSelector(
    ({ firestore: { data } }) => data.projects && data.projects[selectedProjectId]
  );
  useFirestoreConnect([projectQuery]);

  // console.log('PollyDevicesOverview', selectedProjectId);

  if (!isLoaded(projectData)) {
    return 'Loading...';
  }

  const isWeatherSetup = isWeatherLocationSetUp(projectData && projectData.weatherLocations);
  let lat = 0;
  let lng = 0;
  if (isWeatherSetup) {
    const weatherLocation = projectData.weatherLocations[0];
    lat = weatherLocation.lat;
    lng = weatherLocation.lng;
  }

  log('isWeatherLocationSetUp', { isWeatherSetup, lat, lng });
  const elevation = globalElevation;
  return (
    <>
      <TheftAlertsBanner />
      <Grid justify={'flex-start'} container spacing={2}>
        {/*<Grid item xs={4}>*/}
        {/*  <Breadcrumbs />*/}
        {/*</Grid>*/}
        <Grid item xs={12}>
          <Project />
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={elevation}>{isWeatherSetup && <Weather lat={lat} lng={lng} />}</Paper>
          {!isWeatherSetup && (
            <Paper elevation={elevation} className={classes.paper}>
              <div>
                Display forecasted weather here by setting your project's weather location in the{' '}
                <RouterLink to={'/projects'} text={'Settings'} /> tab
              </div>
            </Paper>
          )}
        </Grid>
        {/*<Grid item xs={12}>*/}
        {/*  <Accordion>*/}
        {/*    <AccordionSummary*/}
        {/*      expandIcon={<ExpandMoreIcon />}*/}
        {/*      aria-controls="panel1a-content"*/}
        {/*      id="panel1a-header"*/}
        {/*    >*/}
        {/*      <Typography>Export all Polly device data</Typography>*/}
        {/*    </AccordionSummary>*/}
        {/*    <AccordionDetails>*/}
        {/*      <ExportPollyDataCustomer />*/}
        {/*    </AccordionDetails>*/}
        {/*  </Accordion>*/}
        {/*</Grid>*/}
        <Grid item xs={12}>
          <PollyDevices metric={projectData.metric} showDeltaGrid={true} project={projectData} />
        </Grid>
      </Grid>
    </>
  );
}

function Breadcrumbs() {
  const breadcrumbs = [
    {
      path: '/',
      text: 'Home',
    },
    {
      path: `/devices`,
      text: 'Devices',
    },
  ];
  return <BreadcrumbNav breadcrumbs={breadcrumbs} />;
}
