import React from 'react';
import { Avatar, Box, Card, CardContent, Grid, Typography } from '@mui/material';
import {
  orange,
  green,
  blue,
  yellow,
  red,
  indigo,
  deepOrange,
  deepPurple,
  purple,
  blueGrey,
} from '@mui/material/colors';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import EmojiNatureIcon from '@mui/icons-material/EmojiNature';
import { globalElevation } from '../../../config/globalStyle';
import PhoneLinkRingIcon from '@mui/icons-material/PhonelinkRing';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import OpacityIcon from '@material-ui/icons/Opacity';
import BatteryAlertIcon from '@material-ui/icons/BatteryAlert';
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';
import { makeStyles } from '@material-ui/core/styles';
import WidgetTable from './WidgetTable';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import CardHeader from '@material-ui/core/CardHeader';
import { format } from 'date-fns';
import { Icon } from '@iconify/react';

//https://icon-sets.iconify.design/mdi/
const iconNames = {
  // beeActivity: <Icon icon="mdi:bee" height="40" color="orange" />,
  phoneLink: <PhoneLinkRingIcon />,
  // temperature: <Icon icon="mdi:thermometer" height="36" color="green" />,
  // temperature: <Icon icon="mdi:thermometer" height="36" color="blueGrey" />,
  temperature: <Icon icon="mdi:thermometer" height="36" color="white" />,
  beeActivity: <Icon icon="mdi:bee" height="36" color="white" />,
  humidity: <OpacityIcon height="30" />,
  light: <WbIncandescentIcon />,
  battery: <BatteryAlertIcon color="red" />,
  // battery: <Icon icon="akar-icons:battery-low" color="red" height="36" rotate={3} />,
  // humidity: <Icon icon="ic:outline-opacity" color="blue" height="40" />,
  // light: <Icon icon="akar-icons:light-bulb" color="orange" height="36" />,
};

const iconColours = {
  orange,
  green,
  blue,
  yellow,
  red,
  indigo,
  deepOrange,
  deepPurple,
  purple,
  blueGrey,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    fontSize: 'medium',
  },
  subheader: {
    fontSize: 'medium',
  },
  card: {
    borderRadius: '10px',
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    boxShadow: 'none',
  },
}));

export default function PollyDeviceOverviewWidget({
  title = 'Total Pollinator Activity',
  device,
  lineToHighlight,
  // activity = '24%',
  // movementText = '16%',
  // currentValue = 10,
  // previousValue = 9,
  text,
  movementDirection = 'up',
  durationText = 'Since last month',
  height = 300,
  showIcon = true,
  iconName = 'beeActivity',
  iconColourName = 'orange',
}) {
  const classes = useStyles();
  const icon = getIcon(iconName);
  const metrics = device.metrics;
  // console.log('metrics', metrics);
  // const beeActivity = getMetric(metrics, 'beeActivity');
  // const temperature = getMetric(metrics, 'temperature');
  // const humidity = getMetric(metrics, 'humidity');

  const currentValue = 0;
  const previousValue = 0;
  const activity = getActivity(currentValue);
  const movementText = getMovementText(currentValue, previousValue);
  const movementDirectionText = getMovementDirection(currentValue, previousValue);
  const iconColour = iconColours[iconColourName];
  // const iconColour = getIconColour(movementDirectionText);
  const widgetMainValue = text ? `${text} (${activity})` : activity;
  let lastUpdated = (device && device.latestEvent && device.latestEvent.dateCreated.toDate()) || 'n/a';
  if (lastUpdated instanceof Date) lastUpdated = format(lastUpdated, 'dd MMM HH:mm:ss');
  return (
    <Card elevation={globalElevation} style={{ height, borderRadius: '5px' }}>
      <CardContent style={{ backgroundColor: '#F0F0F0' }}>
        <Grid container spacing={1}>
          <Grid item xs={10}>
            <Typography variant="subtitle1">{title}</Typography>
            <Typography variant="subtitle2">
              <div>{lastUpdated}</div>
            </Typography>
          </Grid>
          {showIcon && (
            <Grid item xs={2}>
              <Avatar
                sx={{
                  backgroundColor: iconColour[600],
                  // backgroundColor: 'white',
                  height: 46,
                  width: 46,
                }}
              >
                {icon}
              </Avatar>
            </Grid>
          )}
          <Grid item xs={12}>
            <DataGrid metrics={metrics} lineToHighlight={lineToHighlight} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

function DataGrid({ metrics, lineToHighlight = 'temperature' }) {
  const size = 2;
  const first = 2;
  const beeActivity = getMetric(metrics, 'beeActivity');
  const temperature = getMetric(metrics, 'temperature');
  const humidity = getMetric(metrics, 'humidity');
  const highlightBeeActivity = lineToHighlight === 'beeActivity';
  const highlightTemperature = lineToHighlight === 'temperature';
  const highlightHumidity = lineToHighlight === 'humidity';
  return (
    <TableContainer component={Paper} style={{ height: 250 }}>
      <Grid container spacing={1} style={{ padding: '5px  ' }}>
        {getHeaderAverage({ size, first })}
        {getHeader({ size, first })}
        {getRow({
          size,
          first,
          title: 'Activity',
          suffix: '%',
          metric: beeActivity,
          highlight: highlightBeeActivity,
        })}
        {getRow({ size, first, title: 'Temp', metric: temperature, highlight: highlightTemperature })}
        {getRow({
          size,
          first,
          title: 'Humidity',
          suffix: '%',
          metric: humidity,
          highlight: highlightHumidity,
        })}
      </Grid>
    </TableContainer>
  );
}

function getHeader({ size, title, first = 4 }) {
  return (
    <>
      <Grid item xs={first}>
        <Typography color="textSecondary" gutterBottom variant="subtitle1"></Typography>
      </Grid>
      <Grid item xs={size}>
        {getHeaderItem({ value: '1d' })}
      </Grid>
      <Grid item xs={size}>
        {getHeaderItem({ value: '7d' })}
      </Grid>
      <Grid item xs={size}>
        {getHeaderItem({ value: '30d' })}
      </Grid>
      <Grid item xs={size}>
        {getHeaderItem({ value: 'Hi' })}
      </Grid>
      <Grid item xs={size}>
        {getHeaderItem({ value: 'Low' })}
      </Grid>
    </>
  );
}

function getHeaderAverage({ size, title, first = 4 }) {
  const left = '<--';
  const right = '-->';
  const grey = '#a6a6a6';
  return (
    <>
      <Grid item xs={first}>
        <Typography color="textSecondary" gutterBottom variant="subtitle1"></Typography>
      </Grid>
      <Grid item xs={size}>
        {getHeaderItem({ value: left, color: grey })}
      </Grid>
      <Grid item xs={size}>
        {getHeaderItem({ value: 'Average', color: grey })}
      </Grid>
      <Grid item xs={size}>
        {getHeaderItem({ value: right, color: grey })}
      </Grid>
      <Grid item xs={size}>
        <Typography color="textSecondary" gutterBottom variant="subtitle1"></Typography>
      </Grid>
      <Grid item xs={size}>
        <Typography color="textSecondary" gutterBottom variant="subtitle1"></Typography>
      </Grid>
    </>
  );
}

function getHeaderItem({ value, color }) {
  let style = color ? { color } : {};
  return (
    <Box color="textSecondary" gutterBottom variant="subtitle1" fontWeight="fontWeightBold" style={style}>
      {value}
    </Box>
  );
}

function getRow({ size, title, first = 4, metric, suffix = '', highlight = false }) {
  if (!metric) {
    return <></>;
  }
  return (
    <>
      <Grid item xs={first}>
        {/*<Box color="textSecondary" gutterBottom variant="subtitle1" fontStyle="italic">*/}
        {/*  {title}*/}
        {/*</Box>*/}
        {getRowItem({ value: title, highlight, fontStyle: 'italic' })}
      </Grid>
      <Grid item xs={size}>
        {getRowItem({ value: getMetricValue({ metric, fieldName: '_1dayAvg'}), highlight })}
      </Grid>
      <Grid item xs={size}>
        {getRowItem({ value: getMetricValue({ metric, fieldName: '_7dayAvg'}), highlight })}
      </Grid>
      <Grid item xs={size}>
        {getRowItem({ value: getMetricValue({ metric, fieldName: '_30ayAvg'}), highlight })}
      </Grid>
      <Grid item xs={size}>
        {getRowItem({
          value: `${metric._30dayHigh && metric._30dayHigh.avg.toFixed(1)}${suffix}`,
          highlight,
        })}
      </Grid>
      <Grid item xs={size}>
        {getRowItem({ value: `${metric._30dayLow && metric._30dayLow.avg.toFixed(1)}${suffix}`, highlight })}
      </Grid>
    </>
  );
}

function getMetricValue({ metric, fieldName, suffix }) {
  if (!metric[fieldName]) {
    return undefined;
  } else if (metric[fieldName] === 'n/a') {
    return 'n/a';
  }

  return `${metric[fieldName] && metric[fieldName].toFixed(1)}${suffix}`;
}

function Highlight({ text }) {
  return <span style={{ fontWeight: 'bold', color: '#483D8B' }}>{text}</span>;
}

function getRowItem({ value, highlight = false, paddingRight = 0, fontStyle = 'normal' }) {
  const style = highlight ? { fontWeight: 'bold', color: '#483D8B', paddingRight } : { paddingRight };
  return (
    <Box
      color="textSecondary"
      gutterBottom
      variant="subtitle1"
      fontWeight="500"
      style={style}
      fontStyle={fontStyle}
    >
      {value}
    </Box>
  );
}

function getMetric(metrics = [], name) {
  return metrics.find((m) => m.name === name);
}

function getIcon(iconName) {
  return iconNames[iconName];
}

function getIconColour(movementDirection) {
  return movementDirection === 'up' ? iconColours['green'] : iconColours['orange'];
}

function getActivity(currentValue) {
  return currentValue + '%';
}

function getMovementText(currentValue, previousValue) {
  return ((currentValue - previousValue) / previousValue).toFixed(1) + '%';
}

function getMovementDirection(currentValue, previousValue) {
  return currentValue - previousValue >= 0 ? 'up' : 'down';
}
