import React, { useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { green, blueGrey } from '@material-ui/core/colors';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { getProjectIds, isProjectAdmin } from '../../../config/env';
import { isEmpty } from 'lodash';
import ObjectNotFoundPage from '../ObjectNotFoundPage';
import { globalElevation } from '../../../config/globalStyle';
// import GeoLocation from "./GeoLocation3";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: blueGrey[500],
  },
}));

export default function Project() {
  const classes = useStyles();
  const userData = useSelector((state) => state.appConfig.userData);
  const isAdmin = isProjectAdmin(userData);
  let projectIds = getProjectIds(userData);
  const projectId = projectIds[0];
  let storeAs = `project-${projectId}`;
  const projectData = useSelector(({ firestore: { data } }) => data[storeAs]);

  const projectQuery = [
    {
      collection: 'projects',
      doc: projectIds[0],
      storeAs,
    },
  ];

  useFirestoreConnect(projectQuery);

  if (!isLoaded(projectData)) {
    return 'Loading...';
  }

  if (isEmpty(projectData)) {
    return <ObjectNotFoundPage objectName={'Project'} />;
  }

  // console.log('Project', projectData);

  return <ProjectCard project={projectData} />;
  // return (
  //   <Grid
  //     container
  //     justify={'center'}
  //     spacing={2}
  //     // style={{ paddingTop: "20px" }}
  //   >
  //     {/*<Grid item xs={12}>*/}
  //     {/*  <Container maxWidth="75%">*/}
  //     {/*    <DeviceBreadcrumbs deviceId={deviceId} />*/}
  //     {/*  </Container>*/}
  //     {/*</Grid>*/}
  //     <Grid item xs={12}>
  //       <Container>
  //         <ProjectCard project={projectData} />
  //       </Container>
  //     </Grid>
  //   </Grid>
  // );

  // return (
  //   <Box flexDirection="row" justifyContent="center">
  //     <DeviceBreadcrumbs deviceId={deviceId} />
  //   </Box>
  // );
}

function ProjectCard({ project }) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const lastActivityDateCreated = project && project.latestEvent && project.latestEvent.dateCreated.toDate();
  const firstWeatherLocation =
    project && project.weatherLocations && project.weatherLocations.length > 0 && project.weatherLocations[0];
  const locationText =
    firstWeatherLocation && `Lat: ${firstWeatherLocation.lat} Long: ${firstWeatherLocation.lng}`;
  const location = firstWeatherLocation ? locationText : 'To be added';
  const projectName = project.projectName || project.projectId;
  const showContent = false;

  const elevation = globalElevation;
  return (
    <Card className={classes.root} elevation={elevation}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            {projectName.substring(0, 1)}
          </Avatar>
        }
        // action={
        //   <IconButton aria-label="settings">
        //     <MoreVertIcon />
        //   </IconButton>
        // }
        title={`Project Name: ${projectName}`}
        subheader={`Project ID: ${project.projectId}`}
      />
      {showContent && <CardContent>To be filled out</CardContent>}
    </Card>
  );
}

function CardCollapse({ expanded }) {
  return (
    <Collapse in={expanded} timeout="auto" unmountOnExit>
      <CardContent>
        <Typography paragraph>Method:</Typography>
        <Typography paragraph>
          Heat 1/2 cup of the broth in a pot until simmering, add saffron and set aside for 10 minutes.
        </Typography>
        <Typography paragraph>
          Heat oil in a (14- to 16-inch) paella pan or a large, deep skillet over medium-high heat. Add
          chicken, shrimp and chorizo, and cook, stirring occasionally until lightly browned, 6 to 8 minutes.
          Transfer shrimp to a large plate and set aside, leaving chicken and chorizo in the pan. Add
          pimentón, bay leaves, garlic, tomatoes, onion, salt and pepper, and cook, stirring often until
          thickened and fragrant, about 10 minutes. Add saffron broth and remaining 4 1/2 cups chicken broth;
          bring to a boil.
        </Typography>
        <Typography paragraph>
          Add rice and stir very gently to distribute. Top with artichokes and peppers, and cook without
          stirring, until most of the liquid is absorbed, 15 to 18 minutes. Reduce heat to medium-low, add
          reserved shrimp and mussels, tucking them down into the rice, and cook again without stirring, until
          mussels have opened and rice is just tender, 5 to 7 minutes more. (Discard any mussels that don’t
          open.)
        </Typography>
        <Typography>Set aside off of the heat to let rest for 10 minutes, and then serve.</Typography>
      </CardContent>
    </Collapse>
  );
}
