import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import { blueGrey } from '@material-ui/core/colors';
import { globalElevation } from '../../../config/globalStyle';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: blueGrey[500],
  },
}));

export default function PollyDeviceTitle({ deviceId, projectId, projectName }) {
  const classes = useStyles();
  const subheader = projectName ? `Project: ${projectName} (${projectId})` : `Project: ${projectId}`;
  const showContent = false;

  return (
    <Card className={classes.root} elevation={globalElevation}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            {projectName.substring(0, 1)}
          </Avatar>
        }
        title={`Device ID: ${deviceId}`}
        subheader={subheader}
      />
      {showContent && <CardContent>To be filled out</CardContent>}
    </Card>
  );
}
