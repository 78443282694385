import React from 'react';
import { Avatar, Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { orange, green, red } from '@mui/material/colors';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
// import EmojiNatureIcon from '@mui/icons-material/EmojiNature';
import { globalElevation } from '../../../config/globalStyle';
import PhonelinkRingIcon from '@mui/icons-material/PhonelinkRing';

export default function PollyDeviceHighLowWidget({ totalActivity = '4.5 hours of 75 daylight hours' }) {
  const text1 = `Most activity: Device 474 - 3%`;
  const text2 = `Least activity: Device 6E1 - 0.1%`;
  return (
    <Card elevation={globalElevation}>
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
          <Grid item style={{ marginBottom: '10px'}}>
            <Typography color="textSecondary" gutterBottom variant="h6">
              Device Stats
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={1}>
                <ArrowUpwardIcon sx={{ color: green[900], marginRight: '10px' }} />
              </Grid>
              <Grid item xs={11}>
                <Typography color="textPrimary" variant="subtitle1">
                  {text1}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={1}>
                <ArrowDownwardIcon sx={{ color: red[900], marginRight: '5px' }} />
              </Grid>
              <Grid item xs={11}>
                <Typography color="textPrimary" variant="subtitle1">
                  {text2}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Avatar
              // style={{ backgroundColor: green[600] }}
              sx={{
                backgroundColor: green[600],
                height: 56,
                width: 56,
              }}
            >
              <PhonelinkRingIcon />
            </Avatar>
          </Grid>
        </Grid>
        {/*<Box*/}
        {/*  sx={{*/}
        {/*    alignItems: 'center',*/}
        {/*    display: 'flex',*/}
        {/*    pt: 2,*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <ArrowUpwardIcon sx={{ color: orange[900] }} />*/}
        {/*  <Typography*/}
        {/*    variant="body2"*/}
        {/*    sx={{*/}
        {/*      color: orange[900],*/}
        {/*      mr: 1,*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    16%*/}
        {/*  </Typography>*/}
        {/*  <Typography color="textSecondary" variant="caption">*/}
        {/*    Since last month*/}
        {/*  </Typography>*/}
        {/*</Box>*/}
      </CardContent>
    </Card>
  );
}
