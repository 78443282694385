import { subDays } from 'date-fns';
import { getDateNoTime } from '../util/date-util';
import { alertsConfig } from '../config/user-alerts-config';

export const alertsCollectionName = 'alerts';
export function getDevicesStoreAs() {
  return 'devices';
}

export function getDevicesByProjectQuery({ projectId, storeAs }) {
  return [
    {
      collection: 'projects',
      doc: projectId,
      subcollections: [{ collection: 'devices' }],
      storeAs: storeAs,
    },
  ];
}

export function getDevicesByIdQuery({ deviceId, storeAs }) {
  return {
    collection: 'devices',
    where: [['deviceId', '==', deviceId]],
    storeAs,
  };
}

export function getProjectUsersQuery({ projectId, storeAs }) {
  return [
    {
      collection: 'projects',
      doc: projectId,
      subcollections: [{ collection: 'users' }],
      storeAs: storeAs,
    },
  ];
}
/*
  Dan Stoddart 2021-04-28: we want to pull in all deviceTypes and then hide the ones we don't want to display using client side code (in Hives.js)
 */
export function getDevicesQuery({ projectIds, storeAs, siteName, isAgriSoundOnlyProject }) {
  // console.log('getDevicesQuery', { projectIds, storeAs, siteName, isAgriSoundOnlyProject });
  const where = [
    ['projectIds', 'array-contains-any', projectIds],
    ['active', '==', true],
  ];

  if (siteName && isAgriSoundOnlyProject) {
    where.push(['siteName', '==', siteName]);
  }
  const query = {
    collection: 'devices',
    where,
  };
  if (storeAs) query.storeAs = storeAs;
  return query;
}

export function getAllDevicesQuery({ projectIds, storeAs }) {
  const query = {
    collection: 'devices',
    where: [
      ['projectIds', 'array-contains-any', projectIds],
      ['active', '==', true],
    ],
  };
  if (storeAs) query.storeAs = storeAs;
  return query;
}

export function getAlertsQuery({ projectIds, storeAs, days = 7 }) {
  const dateCreated = getDateNoTime(subDays(new Date(), days));
  const query = {
    collection: alertsCollectionName,
    limit: 500,
    where: [
      ['projectIds', 'array-contains-any', projectIds],
      ['dateCreated', '>=', dateCreated],
    ],
  };
  if (storeAs) query.storeAs = storeAs;
  return query;
}

export function getTheftAlertsTodayQuery({ projectIds, storeAs }) {
  const dateCreated = getDateNoTime(subDays(new Date(), 1));
  // console.log('location alerts date', dateCreated);
  const query = {
    collection: alertsCollectionName,
    // limit: 10,
    where: [
      ['projectIds', 'array-contains-any', projectIds],
      ['dateCreated', '>=', dateCreated],
      ['alertId', '==', alertsConfig.theftAlert.alertId],
    ],
  };
  if (storeAs) query.storeAs = storeAs;
  return query;
}

export function getAlertQuery({ alertId, storeAs }) {
  const query = {
    collection: alertsCollectionName,
    doc: alertId,
  };
  if (storeAs) query.storeAs = storeAs;
  return query;
}

export function getProjectQuery({ projectId, storeAs }) {
  const query = {
    collection: 'projects',
    doc: projectId,
  };
  if (storeAs) query.storeAs = storeAs;
  return query;
}

export function getProjectNoteQuery({ projectId, storeAs }) {
  const query = {
    collection: 'projects',
    doc: projectId,
    subcollections: [{ collection: 'notes' }],
  };
  if (storeAs) query.storeAs = storeAs;
  return query;
}

export function getEventsQuery({ projectIds, storeAs }) {
  return {
    collection: 'events',
    where: [['projectIds', 'array-contains-any', projectIds]],
    storeAs,
  };
}
