import React, { useEffect, useState } from 'react';
import {
  dataStudioFieldNames,
  exportDataStudioWeekly,
  getDataStudioFieldNames,
  getDataStudioWeeklyFileName,
} from './utils/dataStudioExport';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { saveAs } from 'file-saver';
import { toCsv } from '../../beekeeper/Download/DownloadFile';
import { firestore } from '../../../firebase';
import { useSelector } from 'react-redux';

export default function ExportDataStudio({ classes, projectIds }) {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState(new Date());
  const [projectId, setProjectId] = useState();
  const [validationMessages, setValidationMessages] = useState(['placeholder']);
  const project = useSelector((state) => state.appConfig.currentProject);
  console.log('currentProject', project);

  const validate = () => {
    const messages = [];
    if (!projectId) {
      messages.push('Please select a projectId');
    }

    if (!dateFrom) {
      messages.push('Please select a dateFrom value');
    }

    if (!dateTo) {
      messages.push('Please select a dateTo value');
    }

    setValidationMessages(messages);
  };

  useEffect(() => {
    validate();
  }, [projectId, dateFrom, dateTo]);

  const exportDataStudioAction = async () => {
    setLoading(true);
    const fileName = await getDataStudioWeeklyFileName({
      projectId,
      reportStart: dateFrom,
      reportEnd: dateTo,
    });
    const weeklyData = await exportDataStudioWeekly({
      projectId,
      reportStart: dateFrom,
      reportEnd: dateTo,
      isEquinix: project.isEquinix,
      projectHubCode: project.hubCode,
    });
    // const csv = toCsv({ jsonData: weeklyData, fields: dataStudioFieldNames });
    const csv = toCsv({
      jsonData: weeklyData,
      fields: getDataStudioFieldNames({ isEquinix: project.isEquinix }),
    });
    const blob = new Blob([csv], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, fileName);
    setLoading(false);
  };

  const defaultProjectSelectProps = {
    options: projectIds,
    getOptionLabel: (option) => option,
  };
  return (
    <Grid container spacing={0}>
      <Grid container item xs={12}>
        <FormControl className={classes.formControl}>
          <Autocomplete
            value={projectId}
            {...defaultProjectSelectProps}
            id="projectId"
            renderInput={(params) => <TextField {...params} label="Project ID" margin="normal" />}
            onChange={(e, value) => {
              if (value) {
                setProjectId(value);
              } else {
                setProjectId(undefined);
              }
              validate();
            }}
          />
        </FormControl>
      </Grid>
      <Grid container item xs={12}>
        <div style={{ fontSize: '16px', marginLeft: '10px', marginTop: '10px', width: '100px' }}>
          From:
        </div>
        <FormControl className={classes.formControl}>
          <DatePicker selected={dateFrom} onChange={(newValue) => setDateFrom(newValue)} />
        </FormControl>
      </Grid>
      <Grid container item xs={12}>
        <div style={{ fontSize: '16px', marginLeft: '10px', marginTop: '10px', width: '100px' }}>
          To:
        </div>
        <FormControl className={classes.formControl}>
          <DatePicker selected={dateTo} onChange={(newValue) => setDateTo(newValue)} />
        </FormControl>
      </Grid>
      {validationMessages.length > 0 && (
        <Grid container item xs={12}>
          <FormControl className={classes.formControl}>
            <div>
              {validationMessages.map((message) => {
                return (
                  <div key={message} style={{ color: 'red' }}>
                    {message}
                  </div>
                );
              })}
            </div>
          </FormControl>
        </Grid>
      )}
      <Grid container item xs={12}>
        <FormControl className={classes.formControl}>
          <Grid container spacing={0}>
            <Grid container item xs={6}>
              <Button
                color="primary"
                variant="contained"
                // onClick={() => setOpenConfirm(true)}
                onClick={exportDataStudioAction}
                disabled={validationMessages.length > 0}
              >
                Export
              </Button>
            </Grid>
            <Grid container item xs={6}>
              {loading && <CircularProgress className={classes.submitButton} />}
              {message && (
                <div style={{ color: 'blue', fontSize: '16px', fontWeight: 'bold' }}>{message}</div>
              )}
            </Grid>
          </Grid>
        </FormControl>
      </Grid>
    </Grid>
  );
}
