import React from 'react';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@material-ui/core/Typography';

const noop = () => {};

const FileInput = ({ value, onChange = noop, title, tooltipText, ...rest }) => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    {value.length > 0 && (
      <div style={{ marginBottom: '20px' }}>
        <Typography variant="body1" style={{ marginBottom: '10px' }}>
          Files to upload:
        </Typography>
        {value.map((f) => (
          <Typography variant="body2" style={{ fontStyle: 'italic' }}>
            {f.name}
          </Typography>
        ))}
      </div>
    )}
    <label style={{ display: 'flex', flexDirection: 'row' }}>
      <Typography variant="body1" style={{ marginRight: '10px', marginTop: '0px' }}>
        {title}
      </Typography>
      <Tooltip title={tooltipText}>
        <AddAPhotoIcon style={{ cursor: 'pointer' }} />
      </Tooltip>
      <input
        {...rest}
        style={{ display: 'none' }}
        type="file"
        accept="image/png, image/gif, image/jpeg"
        onChange={(e) => {
          onChange([...e.target.files]);
        }}
      />
    </label>
  </div>
);

export default FileInput;
