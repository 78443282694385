import React, { useState } from 'react';
import { useFirestore } from 'react-redux-firebase';
import { auth } from '../../../firebase';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function ActivateGateway({ classes }) {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const [gatewayMac, setGatewayMac] = useState();
  const firestore = useFirestore();
  const collectionName = 'activateGateway';
  const activateGatewayAction = () => {
    if (!gatewayMac || gatewayMac.trim() === '') {
      setMessage(`Please enter a gateway mac address`);
      // setOpenSnackbar(true);
      return;
    }

    setLoading(true);
    const uid = auth.currentUser && auth.currentUser.uid;

    firestore
      .collection(collectionName)
      .add({ mac: gatewayMac, dateCreated: new Date(), updatedBy: uid })
      .then((res) => {
        const docId = res.id;
        // console.log('activateGateway.docId', docId);
        firestore
          .collection(collectionName)
          .doc(docId)
          .onSnapshot((documentSnapshot) => {
            // handle changes to documents in the collection here
            // console.log('activateGateway.docChanges', querySnapshot.docChanges);
            // querySnapshot.forEach((doc) => {
            const docData = documentSnapshot.data();
            // console.log('activateGateway.docChanges', documentSnapshot.data());
            if (docData.message) {
              setMessage(docData.message);
              // setOpenSnackbar(true);
              setLoading(false);
            }
          });
      })
      .catch((err) => {
        setMessage(`Error: ${err.message}`);
        // setOpenSnackbar(true);
        setLoading(false);
      });
  };
  return (
    <Grid container spacing={0}>
      <Grid container item xs={12}>
        <FormControl className={classes.formControl}>
          <TextField placeholder="gatewayMac" onChange={(e) => setGatewayMac(e.target.value)} />
        </FormControl>
      </Grid>
      <Grid container item xs={12}>
        <FormControl className={classes.formControl}>
          <Grid container spacing={0}>
            <Grid container item xs={6}>
              <Button
                color="primary"
                variant="contained"
                // className={classes.button}
                onClick={activateGatewayAction}
                disabled={loading || !gatewayMac}
              >
                Activate Gateway
              </Button>
            </Grid>
            <Grid container item xs={6}>
              {loading && <CircularProgress className={classes.submitButton} />}
              {message && <div>{message}</div>}
            </Grid>
          </Grid>
        </FormControl>
      </Grid>
    </Grid>
  );
}
