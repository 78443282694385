export const getEquinixDeviceNumber = ({ projectHubCode, device }) => {
  const hubCode = device.hubCode || projectHubCode;
  let id = hubCode;
  if(device.quadrant) {
    id += `-${device.quadrant}`;
  }
  if(device.habitat) {
    id += `-${device.habitat}`;
  }
  if(device.equinixDeviceNumber) {
    id += `-${device.equinixDeviceNumber}`;
  }
  return id;
};
