import React, { useEffect, useState, createContext } from 'react';
import { log } from '../../../util/log-util';
import { Snackbar } from '@material-ui/core';
import app from '../../../firebase.js';
import { useDispatch } from 'react-redux';
import { auth, firestore } from '../../../firebase';
const USER_HAS_NO_ASSOCIATED_PROJECT = 'USER_HAS_NO_ASSOCIATED_PROJECT';
const USER_NOT_IN_DB = 'USER_NOT_IN_DB';

export const AuthContext = createContext();
export const userIdOverride = undefined; //'IG0yOgVEhbgTbZw9Xbl4qGeXyAg2';

export function getUserId(user) {
  return userIdOverride || user.uid;
}

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [failType, setFailType] = useState();
  const [pending, setPending] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    app.auth().onAuthStateChanged((user) => {
      log('AuthProvider.onAuthStateChanged', user);
      setCurrentUser(user);
      if (!user) {
        setPending(false);
        const project = {
          projectId: 'none',
        };
        dispatch({ type: 'UPDATE_PROJECT', project });
        return;
      }
      firestore
        .collection('users')
        .doc(getUserId(user))
        .onSnapshot(
          (snapshot) => {
            const userData = snapshot.data();
            log('AuthProvider.userData', userData);

            // The user doesn’t have a data point, equivalent to not signed in.
            if (!snapshot.exists || !userData) {
              const userObject = { user, hasAuth: false };
              dispatch({ type: 'UPDATE_USER', userObject });
              const project = {
                projectId: 'none',
              };
              dispatch({ type: 'UPDATE_PROJECT', project });
              setPending(false);
              setFailType(USER_NOT_IN_DB);
              return;
            }

            //get the project id from the user and then get the project document
            const projectId = userData.selectedProjectId || USER_HAS_NO_ASSOCIATED_PROJECT;
            const project =
              userData.projects && userData.projects.find((project) => project.projectId === projectId);
            let userRoles = ['read'];
            if (project) {
              userRoles = project.userRoles;
            }
            // console.log('Auth', projectId, project);

            if (projectId === USER_HAS_NO_ASSOCIATED_PROJECT) {
              // console.log('USER_HAS_NO_ASSOCIATED_PROJECT');
              // this.resetState(() => {
              //   this.openSnackbar(USER_HAS_NO_ASSOCIATED_PROJECT);
              // });
              const project = {
                projectId: 'none',
              };
              dispatch({ type: 'UPDATE_PROJECT', project });
              setPending(false);
              setFailType(USER_HAS_NO_ASSOCIATED_PROJECT);
              return;
            }

            firestore
              .doc(`projects/${projectId}`)
              .get()
              .then((projectData) => {
                let project = projectData.data();
                project.userRoles = userRoles;
                dispatch({ type: 'UPDATE_PROJECT', project });
                // console.log('Auth.getProject.res', project);
              })
              .catch((err) => {
                console.log('Auth.getProject.err', err);
              });

            firestore
              .doc(`config/global`)
              .get()
              .then((globalConfigData) => {
                let globalConfig = globalConfigData.data();
                dispatch({ type: 'UPDATE_GLOBAL_CONFIG', globalConfig });
              })
              .catch((err) => {
                console.log('Auth.globalConfig.err', err);
              });

            const userObject = { userData, user, hasAuth: true };
            dispatch({ type: 'UPDATE_USER', userObject });

            setPending(false);
          },
          (error) => {
            log('AuthProvider.err', error);
            const userObject = { user, hasAuth: false };
            dispatch({ type: 'UPDATE_USER', userObject });
            setPending(false);
            // this.resetState(() => {
            //   console.log('userDocumentSnapshotListener.err', error);
            //   const code = error.code;
            //   const message = error.message;
            //
            //   switch (code) {
            //     default:
            //       this.openSnackbar(message);
            //       return;
            //   }
            // });
          }
        );
    });
  }, []);

  if (pending) {
    return <>Loading...</>;
  }

  if (failType === USER_NOT_IN_DB) {
    return (
      <Snackbar
        open={openSnackbar}
        // onClose={handleClose}
        // TransitionComponent={state.Transition}
        message={failType}
        // key={state.Transition.name}
      />
    );
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
