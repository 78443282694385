import React from 'react';
import { log } from '../../../util/log-util';
import { useLocation } from 'react-router-dom';
import HivesChartWithData from '../DataOverview/HivesChartWithData';
import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import { useRouteMatch } from 'react-router';
import BreadcrumbNav from '../Nav/BreadcrumbsNav';
import { useSelector } from 'react-redux';
import { getSelectedProjectId } from '../../../config/env';

const useStyles = makeStyles((theme) => ({
  hiveGrid: {
    flexGrow: 1,
  },
  hivePaper: {
    height: 450,
    width: 235,
    fontSize: 12,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function HivePage({ withColour = true }) {
  const classes = useStyles();
  const location = useLocation();
  const match = useRouteMatch('/hives/:deviceId');
  const deviceId = match.params.deviceId;
  const userData = useSelector((state) => state.appConfig.userData);
  const selectedProjectId = getSelectedProjectId(userData);
  log('HivePage', location);
  const showGatewayOnly = (location.state && location.state.showGatewayOnly) || false;

  return (
    <HivesChartWithData
      linesToShow={location.state}
      projectId={selectedProjectId}
      deviceId={deviceId}
      showGatewayOnly={showGatewayOnly}
    />
  );
}

function HiveBreadcrumbs({ deviceId }) {
  const breadcrumbs = [
    {
      path: '/',
      text: 'Home',
    },
    {
      path: '/hives-overview',
      text: 'Hives Overview',
    },
    {
      path: `/hives/${deviceId}`,
      text: 'Hive',
    },
  ];
  return <BreadcrumbNav breadcrumbs={breadcrumbs} />;
}
