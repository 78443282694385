import React, { useEffect, useState } from 'react';
import { cmsSubscribe, cmsGet } from '../../../firebase';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import RouterLink from '../Nav/RouterLink';
import Paper from '@material-ui/core/Paper';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { getDevicesQuery, getAlertsQuery, alertsCollectionName } from '../../../api/queryConfig';
import { getProjectIds } from '../../../config/env';
import ArticleListWidget from './ArticleListWidget';
import AlertsList from '../Alerts/AlertsList';
import { convertFirestoreCollectionToArray } from '../../../data/data-util';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function AlertsWidget({
  linkWidth = 1,
  showLink = true,
  showSubtitle = true,
  isComponentWide = false,
  routePath,
}) {
  const classes = useStyles();
  const userData = useSelector((state) => state.appConfig.userData);
  let projectIds = getProjectIds(userData);
  const alertsQuery = getAlertsQuery({ projectIds });
  const alertsData = useSelector(({ firestore: { data } }) => data.alerts);

  useFirestoreConnect(alertsQuery);

  if (!isLoaded(alertsData)) {
    return 'Loading...';
  }

  let alerts = convertFirestoreCollectionToArray({
    collectionData: alertsData,
    convertDates: true,
    collectionName: alertsCollectionName,
    sortByField: 'dateCreated',
  });
  alerts = alerts.slice(0, 3);

  return (
    <AlertsList
      alerts={alerts}
      routePath={routePath}
      linkWidth={linkWidth}
      showLink={showLink}
      showSubtitle={showSubtitle}
      isComponentWide={isComponentWide}
    />
  );
}
