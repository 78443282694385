import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Fab, Box } from '@material-ui/core';
import { Home as HomeIcon } from '@material-ui/icons';
import EmptyState from '../EmptyState';
import { ReactComponent as NotFoundIllustration } from '../../../illustrations/error.svg';

class ForbiddenPage extends Component {
  render() {
    return (
      <EmptyState
        image={<NotFoundIllustration />}
        title="You do not have access to this page"
        description="The page you’re trying to access is limited to certain roles. Please contact your administrator."
        button={
          <Fab variant="extended" color="primary" component={Link} to="/">
            <Box clone mr={1}>
              <HomeIcon />
            </Box>
            Home
          </Fab>
        }
      />
    );
  }
}

export default ForbiddenPage;
