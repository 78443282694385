import React from 'react';
import { log } from '../../../util/log-util';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import SignIn from '../SignIn';
import DashboardRoot from '../Dashboard/DashboardRoot';
import NoProjectsAssigned from '../Project/NoProjectsAssigned';
import { auth } from '../../../firebase';

HomePage.propTypes = {
  user: PropTypes.object,
};

function HomePage({ user, openSnackbar }) {
  log('HomePage', user && user.email);
  const hasAuth = useSelector((state) => state.appConfig.hasAuth);
  const currentProject = useSelector((state) => state.appConfig.currentProject);

  if (user && hasAuth) {
    return <DashboardRoot />;
  } else if (!auth.currentUser) {
    return <div style={{ marginTop: '0px' }}><SignIn /></div>;
  } else if (currentProject.projectId === 'none') {
    return <NoProjectsAssigned />;
  }
}

export default HomePage;
