import React, { useEffect, useState } from 'react';
import { useFirestore } from 'react-redux-firebase';
import { isEqual } from 'lodash';
import { deviceTypes } from '../../../config/deviceTypes';
import { auth } from '../../../firebase';
import { addRequestToCollection } from '../../../api/adminFunctions';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function MergeDeviceEvents({ classes, projectIds }) {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const [deviceIdFrom, setDeviceIdFrom] = useState();
  const [deviceIdTo, setDeviceIdTo] = useState();
  const [devices, setDevices] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [validationMessages, setValidationMessages] = useState(['placeholder']);
  const firestore = useFirestore();
  const collectionName = 'uiRequests';
  const actionType = 'mergeDeviceEvents';

  const validate = () => {
    const messages = [];
    if (!deviceIdFrom) {
      messages.push('Please select a deviceIdFrom');
    }

    if (!deviceIdTo) {
      messages.push('Please select a deviceIdTo');
    }

    const deviceFrom = devices.find(device => device.deviceId === deviceIdFrom);
    const deviceTo = devices.find(device => device.deviceId === deviceIdTo);
    if(deviceFrom && deviceTo && !isEqual(deviceFrom.projectIds, deviceTo.projectIds)) {
      messages.push(`deviceFrom has projectIds = ${JSON.stringify(deviceFrom.projectIds)}`);
      messages.push(`deviceTo has projectIds = ${JSON.stringify(deviceTo.projectIds)}`);
      messages.push(`You cannot merge devices that are not in the same project`);
    }

    setValidationMessages(messages);
  };

  useEffect(() => {
    validate();
  }, [deviceIdFrom, deviceIdTo]);

  const validDeviceTypes = [deviceTypes.pollyResearch, deviceTypes.agsenzeScaleOne];
  useEffect(() => {
    firestore
      .doc(`cache/devices2`)
      .get()
      .then((snapshot) => {
        let allDevices = snapshot.data().devices;
        let pollyDevices = allDevices.filter((device) => validDeviceTypes.includes(device.deviceType));
        setDevices(pollyDevices);
      });
  }, []);

  const mergeDeviceEvents = () => {
    const performAction = true;
    setOpenConfirm(false);
    if (!performAction) {
      setDevices([]);
      setMessage(`${deviceIdFrom} successfully merged as ${deviceIdTo}`);
      setLoading(false);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      return;
    }
    if (!deviceIdFrom || deviceIdFrom.trim() === '') {
      setMessage(`Please select a deviceIdFrom`);
      return;
    }
    if (!deviceIdTo || deviceIdTo.trim() === '') {
      setMessage(`Please select a deviceIdTo`);
      return;
    }

    const userId = auth.currentUser && auth.currentUser.uid;
    const userEmail = auth.currentUser && auth.currentUser.email;
    let request = {
      type: actionType,
      deviceIdFrom,
      deviceIdTo,
      dateCreated: new Date(),
      updatedBy: userId,
      userEmail
    };
    setLoading(true);

    // console.log('request', request);
    addRequestToCollection({ request, collectionName, firestore, setMessage, setLoading, setOpenConfirm });
  };

  const defaultProjectSelectProps = {
    options: projectIds,
    getOptionLabel: (option) => option,
  };
  const defaultDeviceSelectProps = {
    options: devices,
    getOptionLabel: (device) => `${device.deviceId} ${JSON.stringify(device.projectIds)}`,
    // getOptionLabel: (device) =>
    //   `${device.deviceId} (${deviceTypesConfig[device.deviceType]}) (name: ${device.deviceName})`,
  };
  return (
    <Grid container spacing={0}>
      <Grid container item xs={12}>
        <FormControl className={classes.formControl}>
          <Autocomplete
            {...defaultDeviceSelectProps}
            id="deviceIdFrom"
            renderInput={(params) => <TextField {...params} label="Device ID From (the old device)" margin="normal" />}
            onChange={(e, value) => {
              if (value) {
                setDeviceIdFrom(value.deviceId);
              } else {
                setDeviceIdFrom(undefined);
              }
            }}
            // disabled={projectId === undefined && newProjectId === 'beekeeper-'}
          />
        </FormControl>
      </Grid>
      <Grid container item xs={12}>
        <FormControl className={classes.formControl}>
          <Autocomplete
            value={deviceIdTo}
            {...defaultDeviceSelectProps}
            id="deviceIdTo"
            renderInput={(params) => <TextField {...params} label="Device ID To (the new device)" margin="normal" />}
            onChange={(e, value) => {
              if (value) {
                setDeviceIdTo(value.deviceId);
              } else {
                setDeviceIdTo(undefined);
              }
              validate();
            }}
            // disabled={deviceId === undefined}
          />
        </FormControl>
      </Grid>
      {validationMessages.length > 0 && (
        <Grid container item xs={12}>
          <FormControl className={classes.formControl}>
            <div>
              {validationMessages.map((message) => {
                return (
                  <div key={message} style={{ color: 'red' }}>
                    {message}
                  </div>
                );
              })}
            </div>
          </FormControl>
        </Grid>
      )}
      <Grid container item xs={12}>
        <FormControl className={classes.formControl}>
          <Grid container spacing={0}>
            <Grid container item xs={6}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => setOpenConfirm(true)}
                disabled={validationMessages.length > 0}
              >
                Merge device data
              </Button>
              <Dialog
                open={openConfirm}
                onClose={() => setOpenConfirm(false)}
                aria-labelledby="responsive-dialog-title"
              >
                <DialogTitle id="responsive-dialog-title">{'Merge device data??'}</DialogTitle>
                <DialogContent dividers>
                  <Typography gutterBottom>Are you sure you want update ALL data from {deviceIdFrom} to {deviceIdTo}?</Typography>
                  <br />
                  <Typography gutterBottom>DeviceId From: {deviceIdFrom}</Typography>
                  <Typography gutterBottom>DeviceId To: {deviceIdTo}</Typography>
                </DialogContent>
                <DialogActions>
                  <Button autoFocus onClick={() => setOpenConfirm(false)} color="primary">
                    No
                  </Button>
                  <Button onClick={mergeDeviceEvents} color="primary" autoFocus>
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
            <Grid container item xs={6}>
              {loading && <CircularProgress className={classes.submitButton} />}
              {message && (
                <div style={{ color: 'blue', fontSize: '16px', fontWeight: 'bold' }}>{message}</div>
              )}
            </Grid>
          </Grid>
        </FormControl>
      </Grid>
    </Grid>
  );
}
