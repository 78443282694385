import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Typography, Box, ButtonGroup, Button, IconButton, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UserAvatar from '../UserAvatar';
import { useSelector } from 'react-redux';
import AgrisoundLogo from '../AgrisoundLogo/AgrisoundLogo';
import { getProfileMenuItems } from './barUtil';
import BarMenu from './BarMenu';
import { pollyUserRoles } from '../../../config/userRoles';
import { updateSelectedProjectUserRole } from '../../../api/updateUser';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { getSelectedProjectUserRole } from '../../../config/env';
import { red } from '@material-ui/core/colors';
import { useTheme } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

Bar.defaultProps = {
  performingAction: false,
};

Bar.propTypes = {
  // Properties
  performingAction: PropTypes.bool.isRequired,
  user: PropTypes.object,
  userData: PropTypes.object,

  // Events
  onAboutClick: PropTypes.func.isRequired,
  onSettingsClick: PropTypes.func.isRequired,
  onSignOutClick: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: '100%',
    // width: '500px'
  },
  card: {
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '60%',
    },
  },
  metricSwitch: {
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      padding: theme.spacing(3),
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '60%',
      padding: theme.spacing(3),
    },
  },
  userManagement: {
    padding: theme.spacing(3),
  },
  deviceManagement: {
    padding: theme.spacing(3),
  },
  title: {
    marginBottom: '20px',
    // maxWidth: '75%',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 200,
    height: 70,
  },
  // userProfileButton: {
  //   marginLeft: `calc(window.innerWidth - 50px)`
  // }
}));

export default function Bar({
  performingAction,
  user,
  userData,
  roles,
  onAboutClick,
  onSettingsClick,
  onSignOutClick,
  onSignUpClick,
  onSignInClick,
}) {
  const initialMenu = { anchorEl: null };
  const [menu, setMenu] = useState(initialMenu);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const { height, width } = useWindowDimensions();

  const openMenu = (event) => {
    setMenu({ anchorEl: event.currentTarget });
  };

  const closeMenu = () => {
    setMenu({ anchorEl: null });
  };

  const menuItems = getProfileMenuItems({
    onAboutClick,
    user,
    onSettingsClick,
    onSignOutClick,
  });

  const loggedInStyle = { width: '400px', marginLeft: '140px', marginTop: '-42px' };
  const loggedOutStyle = { width: '400px', marginLeft: '-10px', marginTop: '122px' };
  const style = user ? loggedInStyle : loggedOutStyle;

  return (
    <Grid container justify="flex-start" style={style}>
      <Grid item xs={12}>
        {user && (
          <>
            <IconButton
              color="inherit"
              disabled={performingAction}
              onClick={openMenu}
              style={{ marginTop: 50, marginLeft: matches ? width - 280 : 0 }}
            >
              <UserAvatar user={userData} />
            </IconButton>
            <BarMenu menuItems={menuItems} menu={menu} closeMenu={closeMenu} />
          </>
        )}
        {!user && (
          <ButtonGroup color="inherit" disabled={performingAction} variant="outlined">
            <Button onClick={onSignUpClick}>Sign up</Button>
            <Button onClick={onSignInClick}>Sign in</Button>
          </ButtonGroup>
        )}
      </Grid>
    </Grid>
  );
}

function RoleSelect() {
  const classes = useStyles();
  const currentProject = useSelector((state) => state.appConfig.currentProject);
  const userData = useSelector((state) => state.appConfig.userData);
  const user = useSelector((state) => state.appConfig.user);
  const userId = user && user.uid;
  const selectedProjectUserRole = getSelectedProjectUserRole(userData);

  const [projectUserRole, setProjectUserRole] = useState(selectedProjectUserRole);
  // console.log('RoleSelect', currentProject);
  // const roles = (currentProject && currentProject.userRoles) || [];
  const roles = Object.values(pollyUserRoles);
  const handleUserRoleChangeAutocomplete = (event, value) => {
    const userRole = value;
    if (userRole) {
      updateSelectedProjectUserRole({ userId, selectedProjectUserRole: userRole })
        .then((success) => {
          setProjectUserRole(userRole);
        })
        .catch((err) => console.log('handleUserRoleChangeAutocomplete.err', err));
    }
  };
  const defaultProps = {
    options: roles,
    getOptionLabel: (option) => option,
  };

  return (
    <FormControl className={classes.formControl}>
      <Autocomplete
        {...defaultProps}
        value={projectUserRole}
        id="projectUserRole"
        // debug
        onChange={handleUserRoleChangeAutocomplete}
        renderInput={(params) => <TextField {...params} margin="normal" />}
        // renderInput={(params) => <TextField label="View" {...params} margin="normal" />}
      />
    </FormControl>
  );
}
