import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EmptyState from '../EmptyState';
import AgriSoundImage from '../../../assets/AgrisoundLarge.png';
const Rollbar = require('rollbar');
const domain = window.location.hostname;
// const rollbarOptions = {
//   accessToken: '819ea14143c7465e9a7a5770cf603679',
//   captureUncaught: true,
//   captureUnhandledRejections: true,
//   verbose: true, // This will now log to console.log, as well as Rollbar}
//   logLevel: 'info',
//   reportLevel: 'error',
//   environment: domain === 'localhost' ? 'development' : 'production',
// };
// const rollbar = new Rollbar(rollbarOptions);

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    // console.log('props.userData', props.userData);

    this.state = {
      hasError: false,
      eventId: null,
    };

    // rollbar.configure({
    //   payload: {
    //     person: {
    //       id: props.userData && props.userData.userId,
    //       username: props.userData && props.userData.username,
    //       email: props.userData && props.userData.email,
    //       selectedProjectId: props.userData && props.userData.selectedProjectId,
    //     },
    //   },
    // });
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // rollbar.error(error);
    // Sentry.withScope((scope) => {
    //   scope.setExtras(errorInfo);
    //
    //   const eventId = Sentry.captureException(error);
    //
    //   this.setState({
    //     eventId: eventId,
    //   });
    // });
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    const image = <img src={AgriSoundImage} alt={'Logo'} />;
    if (hasError) {
      return (
        <EmptyState
          size={'small'}
          image={image}
          title={this.props.title || 'The Agrisound app has failed to load'}
          description="We are experiencing some UI issues. We are aware of this and hope to get this resolved ASAP"
        />
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  // Properties
  children: PropTypes.array.isRequired,
};

export default ErrorBoundary;
