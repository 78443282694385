import Highcharts from 'highcharts/highstock';
import HC_more from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import React, { useRef } from 'react';
HC_more(Highcharts);


let options = {
  chart: {
    type: 'columnrange',
    inverted: true,
  },

  accessibility: {
    description:
      'Image description: A column range chart compares the monthly temperature variations throughout 2017 in Vik I Sogn, Norway. The chart is interactive and displays the temperature range for each month when hovering over the data. The temperature is measured in degrees Celsius on the X-axis and the months are plotted on the Y-axis. The lowest temperature is recorded in March at minus 10.2 Celsius. The lowest range of temperatures is found in December ranging from a low of minus 9 to a high of 8.6 Celsius. The highest temperature is found in July at 26.2 Celsius. July also has the highest range of temperatures from 6 to 26.2 Celsius. The broadest range of temperatures is found in May ranging from a low of minus 0.6 to a high of 23.1 Celsius.',
  },

  title: {
    text: 'Temperature variation by Device',
  },
  xAxis: {
    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  },

  yAxis: {
    title: {
      text: 'Temperature ( °C )',
    },
  },

  tooltip: {
    valueSuffix: '°C',
  },

  plotOptions: {
    columnrange: {
      dataLabels: {
        enabled: true,
        format: '{y}°C',
      },
    },
  },

  legend: {
    enabled: false,
  },

  series: [
    {
      name: 'Temperatures',
      data: [
        [-9.9, 10.3],
        [-8.6, 8.5],
        [-10.2, 11.8],
        [-1.7, 12.2],
        [-0.6, 23.1],
        [3.7, 25.4],
        [6.0, 26.2],
        [6.7, 21.4],
      ],
    },
  ],
};

export default function PollyTemperatureComparison({ deviceIdsMinMax, deviceIdsMinMaxMap }) {
  const chartRef = useRef(null);

  const deviceIds = Array.from(deviceIdsMinMaxMap.keys());
  const minMax2 = Array.from(deviceIdsMinMaxMap.values());

  // console.log('xxx', deviceIds, minMax2);

  options.xAxis.categories = deviceIds;
  options.series[0].data = minMax2;

  return (
    <HighchartsReact
      ref={chartRef}
      highcharts={Highcharts}
      options={options}
      // containerProps={{ style: { width: '100%' } }}
    />
  );
}
