import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PollyActivityWidget from '../../beekeeper/Widgets/PollyActivityWidget';
import PollyPageTitle from '../Shared/PollyPageTitle';
import { widgetsConfigSiteOverview } from '../config/widgetConfig';
import { useSelector } from 'react-redux';
import { getDevicesQuery, getDevicesStoreAs } from '../../../api/queryConfig';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { getProjectIds } from '../../../config/env';
import PollyDeviceOverviewWidget from '../../beekeeper/Widgets/PollyDeviceOverviewWidget';
import Highlight from '../../beekeeper/Widgets/Highlight';
import { getDeviceIdWithGreatest, getDeviceIdWithLowest } from '../../beekeeper/Widgets/stats-util';

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: '100%',
    // width: '500px'
  },
  paper: {
    padding: theme.spacing(3),
    color: theme.palette.text.secondary,
  },
  test: {
    fontFamily: 'inherit',
  },
}));

export default function PollyBusinessOverviewSingleSpecies({ businessName = 'Innocent' }) {
  // const classes = useStyles();
  // const theme = useTheme();
  const userData = useSelector((state) => state.appConfig.userData);
  let projectIds = getProjectIds(userData);
  // const hierarchyTree = JSON.parse(project.hierarchy);
  const storeAs = getDevicesStoreAs();
  const devicesQuery = getDevicesQuery({ projectIds, storeAs });
  // const deviceQuery = getDevicesByIdQuery({ deviceId: 'PNET_BED', storeAs });
  const devicesData = useSelector(({ firestore: { data } }) => data[storeAs]);

  useFirestoreConnect([devicesQuery]);

  if (!isLoaded(devicesData)) {
    return 'Loading...';
  }

  const devices = ((devicesData && Object.values(devicesData)) || []).filter((device) => device.metrics);
  const idGreatestTemp = getDeviceIdWithGreatest({
    devices,
    metricName: 'temperature',
    fieldName: '_30dayHigh.avg',
  });
  const idGreatestHumidity = getDeviceIdWithGreatest({
    devices,
    metricName: 'humidity',
    fieldName: '_30dayHigh.avg',
  });
  const idGreatestBeeActivity = getDeviceIdWithGreatest({
    devices,
    metricName: 'beeActivity',
    fieldName: '_30dayAvg',
  });
  const idLowestBattery = getDeviceIdWithLowest({
    devices,
    fieldName: 'battery',
    useLatestEvent: true
  });
  const idLowestLight = getDeviceIdWithLowest({
    devices,
    fieldName: 'light',
    useLatestEvent: true
  });

  // console.log('highs', { deviceIdGreatestTemp, deviceIdGreatestHumidity, deviceIdGreatestBeeActivity });

  // const summaryData = getSiteSummaryData(devices);
  // const widgets = getWidgetData({ config: widgetsConfigSiteOverview, summaryData });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PollyPageTitle title={`Single Species Overview`} subtitle={businessName} icon="AssessmentIcon" />
      </Grid>
      {/*{widgets.map((widgetData) => {*/}
      {/*  return (*/}
      {/*    <Grid*/}
      {/*      item*/}
      {/*      xs={widgetData.grid.xs}*/}
      {/*      sm={widgetData.grid.sm}*/}
      {/*      md={widgetData.grid.md}*/}
      {/*      lg={widgetData.grid.lg}*/}
      {/*    >*/}
      {/*      {widgetData.component}*/}
      {/*      /!*<Widget widgetData={widgetData} />*!/*/}
      {/*    </Grid>*/}
      {/*  );*/}
      {/*})}*/}
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <DeviceWidget
          device={devices.find((device) => device.deviceId === idGreatestBeeActivity)}
          lineToHighlight="beeActivity"
          title={<div>Device with <Highlight text={'greatest bee activity'}/></div>}
          iconName={"beeActivity"}
          iconColourName={"orange"}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <DeviceWidget
          device={devices.find((device) => device.deviceId === idGreatestTemp)}
          lineToHighlight="temperature"
          title={<div>Device recording <Highlight text={'highest temp'}/></div>}
          iconName={"temperature"}
          iconColourName={"blueGrey"}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <DeviceWidget
          device={devices.find((device) => device.deviceId === idGreatestHumidity)}
          lineToHighlight="humidity"
          title={<div>Device recording <Highlight text={'highest humidity'}/></div>}
          iconName={"humidity"}
          iconColourName={"blue"}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <DeviceWidget
          device={devices.find((device) => device.deviceId === idLowestBattery)}
          lineToHighlight="humidity"
          title={<div>Device recording <Highlight text={'lowest battery level'}/></div>}
          iconName={"battery"}
          iconColourName={"red"}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <DeviceWidget
          device={devices.find((device) => device.deviceId === idLowestLight)}
          lineToHighlight="humidity"
          title={<div>Device recording <Highlight text={'lowest light'}/></div>}
          iconName={"light"}
          iconColourName={"yellow"}
        />
      </Grid>
      {/*{devices.map((device) => {*/}
      {/*  return (*/}
      {/*    <Grid item xs={12} sm={6} md={4} lg={4}>*/}
      {/*      <DeviceWidget device={device} />*/}
      {/*    </Grid>*/}
      {/*  );*/}
      {/*})}*/}
      {/*<Grid item xs={6}>*/}
      {/*  <PollyDeviceHighLowWidget />*/}
      {/*</Grid>*/}
    </Grid>
  );
}

function getSiteSummaryData(devices) {
  return {
    "businessId": 1,
    "businessName": "Innocent",
    "latestSummary": [
      {
        "dataName": "siteActivityAverage",
        "dataLabel": "Site Average",
        "value": "57%",
        "currentValue": 46,
        "previousValue": 51
      },
      {
        "dataName": "mostActiveSite",
        "dataLabel": "Most Active Site",
        "text": "Soria Site 1",
        "value": "66%",
        "currentValue": 82,
        "previousValue": 86
      },
      {
        "dataName": "leastActiveSite",
        "dataLabel": "Least Active Site",
        "text": "Soria Site 2",
        "value": "7%",
        "currentValue": 6,
        "previousValue": 6
      }
    ],
    "latestDevicesHealth": {
      "text": "Low Battery: 2 of 48",
      "devices": [
        { "deviceId": "123A", "deviceName": "123A", "siteId": 3, "siteName": "Soria Site 3", "battery": "20%" },
        { "deviceId": "123B", "deviceName": "123B", "siteId": 3, "siteName": "Soria Site 3", "battery": "19%" }
      ]
    }
  };
}

function DeviceWidget({ device, title, lineToHighlight, iconName, iconColourName }) {
  // const beeActivity = getMetric(device.metrics, 'beeActivity');
  return (
    <PollyDeviceOverviewWidget
      lineToHighlight={lineToHighlight}
      title={title || `Device ${device.deviceId}`}
      device={device}
      activity={10}
      movementText={'16%'}
      durationText={'durationText'}
      movementDirection={'up'}
      iconName={iconName}
      iconColourName={iconColourName}
      showIcon={true}
    />
  );
}

function getWidgetData({ config, summaryData, devicesHealth }) {
  let widgetItems = [];
  for (let summaryEntry of summaryData.latestSummary) {
    const conf = config.find((entry) => entry.dataName === summaryEntry.dataName);
    // console.log('conf', conf, summaryEntry);
    /*
      {
        title: 'Test 1',
        dataName: 'mostActiveSite',
        activity: '24%',
        movementText: '16%',
        durationText: 'Since last month',
        movementDirection: 'up',
        grid: {
          xs: 12,
          sm: 6,
          md: 4,
          lg: 4,
        },
        showIcon: false,
        iconName: 'phoneLink',
        iconColourName: 'orange',
      },

     */
    if (conf) {
      // let data = cloneDeep(conf);
      conf.title = summaryEntry.dataLabel;
      if (summaryEntry.text) {
        conf.text = summaryEntry.text;
      }
      conf.currentValue = summaryEntry.currentValue;
      conf.previousValue = summaryEntry.previousValue;
      conf.component = <Widget widgetData={conf} />;
      widgetItems.push(conf);
    }
  }

  if (devicesHealth) {
    const widget = widgetItems.push({});
  }

  return widgetItems;
}

function Widget({ widgetData }) {
  return (
    <PollyActivityWidget
      title={widgetData.title}
      activity={widgetData.activity}
      movementText={widgetData.movementText}
      durationText={widgetData.durationText}
      movementDirection={widgetData.movementDirection}
      iconName={widgetData.iconName}
      iconColourName={widgetData.iconColourName}
      showIcon={widgetData.showIcon}
      currentValue={widgetData.currentValue}
      previousValue={widgetData.previousValue}
      text={widgetData.text}
    />
  );
}
