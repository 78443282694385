import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import PollyActivityWidget from '../../beekeeper/Widgets/PollyActivityWidget';
import PollyDeviceHighLowWidget from '../../beekeeper/Widgets/PollyDeviceHighLowWidget';
import PollyPageTitle from '../Shared/PollyPageTitle';

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: '100%',
    // width: '500px'
  },
  paper: {
    padding: theme.spacing(3),
    color: theme.palette.text.secondary,
  },
  test: {
    fontFamily: 'inherit',
  },
}));

export default function PollyBusinessOverview({ businessName = 'Innocent' }) {
  const classes = useStyles();
  const theme = useTheme();
  // console.log('theme', theme);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <PollyPageTitle title={`Business Overview`} subtitle={businessName} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <PollyActivityWidget
          title={'Total Pollinator Activity'}
          activity={'24%'}
          movementText={'16%'}
          durationText={'Since last month'}
          movementDirection={'up'}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <PollyActivityWidget
          title={'Total Pollinator Activity'}
          activity={'24%'}
          movementText={'16%'}
          durationText={'Since last month'}
          movementDirection={'up'}
          iconName={'phoneLink'}
          iconColourName={'green'}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2} lg={2}>
        <PollyActivityWidget
          title={'Total Pollinator Activity'}
          activity={'24%'}
          movementText={'16%'}
          durationText={'Since last month'}
          movementDirection={'down'}
          showIcon={false}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2} lg={2}>
        <PollyActivityWidget
          title={'Total Pollinator Activity'}
          activity={'24%'}
          movementText={'16%'}
          durationText={'Since last month'}
          movementDirection={'up'}
          showIcon={false}
        />
      </Grid>
      <Grid item xs={6}>
        <PollyDeviceHighLowWidget />
      </Grid>
    </Grid>
  );

  // return (
  //   <Grid justify={'flex-start'} container spacing={2}>
  //     <Grid item xs={12}>
  //       <Paper className={classes.paper}>
  //       <Typography className={classes.test}>
  //         For any support queries of feedback please email us at <a href="mailto:support@agrisound.io">support@agrisound.io</a>
  //       </Typography>
  //       </Paper>
  //     </Grid>
  //   </Grid>
  // );
}
