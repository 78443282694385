export function getDevicesToDisplay(devices) {
  const scaleDeviceIdsToNotDisplay = devices
    .filter((device) => device.hasOwnProperty('scaleDeviceId'))
    .map((device) => device.scaleDeviceId);
  const gatewayDeviceIdsToNotDisplay = devices
    .filter((device) => device.deviceType === 'beeHeroGatewayOne')
    .map((device) => device.deviceId);
  const deviceIdsToNotDisplay = [...scaleDeviceIdsToNotDisplay, ...gatewayDeviceIdsToNotDisplay];
  return devices.filter((device) => !deviceIdsToNotDisplay.includes(device.deviceId));
}
