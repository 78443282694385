import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from './Auth';
import { useSelector } from 'react-redux';
import { pollyUserRoles as roles } from '../../../config/userRoles';
import { getIsAgrisoundAdmin, hasRequiredRole } from '../../../util/user-util';

const pathConfigs = [
  {
    path: '/polly-devices',
    rolesRequired: [
      roles.projectAdmin,
      roles.readOnly,
      roles.business,
      roles.agriSoundAdmin,
      roles.deviceInstaller,
    ],
  },
  {
    path: '/polly-devices-edit/:deviceId',
    rolesRequired: [
      roles.projectAdmin,
      roles.agriSoundAdmin,
      roles.business,
      roles.sponsor,
      roles.projectOverview,
      roles.deviceInstaller,
    ],
  },
  {
    path: '/reports',
    rolesRequired: [
      roles.projectAdmin,
      roles.agriSoundAdmin,
      roles.business,
      roles.sponsor,
      roles.projectOverview,
      roles.readOnly,
    ],
  },
  {
    path: '/project-overview',
    rolesRequired: [
      roles.projectAdmin,
      roles.agriSoundAdmin,
      roles.business,
      roles.sponsor,
      roles.projectOverview,
    ],
  },
  {
    path: '/projects',
    rolesRequired: [
      roles.projectAdmin,
      roles.agriSoundAdmin,
      roles.business,
      roles.sponsor,
      roles.projectOverview,
      roles.deviceInstaller,
    ],
  },
];

const PrivateRoute = ({ component: RouteComponent, path, ...rest }) => {
  const { currentUser } = useContext(AuthContext);

  const userData = useSelector((state) => state.appConfig && state.appConfig.userData);
  let userHasRole = false;
  if (userData && userData.selectedProjectId.startsWith('polly-')) {
    const userProject = userData.projects.find(
      (project) => project.projectId === userData.selectedProjectId
    ) || { userRoles: [] };

    const pathConfig = pathConfigs.find((pathConfig) => pathConfig.path === path);
    if (!pathConfig) {
      userHasRole = true;
    } else {
      userHasRole = hasRequiredRole({
        userRoles: userProject.userRoles,
        rolesRequired: pathConfig.rolesRequired,
      });
    }

    if (getIsAgrisoundAdmin(userData)) {
      userHasRole = true;
    }
  } else if (userData && userData.selectedProjectId.startsWith('beekeeper-')) {
    //allow all routes for beekeeper users until refactored
    userHasRole = true;
  }

  if (!currentUser) {
    return <Redirect to={'/'} />;
  } else if (currentUser && !userHasRole) {
    return <Redirect to={'/forbidden'} />;
  }

  const render = (props) => (currentUser ? <RouteComponent {...rest} /> : <Redirect to={'/'} />);
  return <Route path={path} render={render} {...rest} />;
};

export default PrivateRoute;
