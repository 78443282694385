import React, { useEffect, useState } from 'react';
import { cmsSubscribe, cmsGet } from '../../../firebase';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import RouterLink from '../Nav/RouterLink';
import Paper from '@material-ui/core/Paper';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import ArticleListWidget from './ArticleListWidget';

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '100%',
    // maxWidth: '48ch',
    backgroundColor: theme.palette.background.paper,
  },
  titleWide: {
    [theme.breakpoints.down('sm')]: {
      width: '300px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
      width: '400px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('md')]: {
      width: '700px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('lg')]: {
      width: '500px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      width: '300px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
      width: '400px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('md')]: {
      width: '500px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('lg')]: {
      width: '200px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  paper: {
    width: '100%',
    // maxWidth: '48ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  button: {
    textTransform: 'none',
    paddingTop: '28px',
    paddingLeft: '80px',
  },
  link: {
    marginLeft: '28px',
  },
  linkNoSubtitle: {
    fontSize: '14px',
    marginLeft: '28px',
  },
  listItemSecondary: {
    width: '85%',
    fontWeight: 'normal',
    color: '#7a7a7a',
    overflow: 'hidden',
  },
}));

export default function CmsWidget({
  linkWidth = 1,
  showLink = true,
  showSubtitle = true,
  isComponentWide = false,
  schemaName,
  routePath,
}) {
  const classes = useStyles();
  const articlesData = useSelector(({ firestore: { data } }) => data[schemaName]);
  const articlesQuery = [
    {
      collection: 'fl_content',
      where: [
        ['_fl_meta_.schema', '==', schemaName],
        // ['accountType', '==', 'beekeeper'],
      ],
      storeAs: schemaName,
    },
  ];

  useFirestoreConnect(articlesQuery);

  if (!isLoaded(articlesData)) {
    return 'Loading...';
  }

  let articles = Object.values(articlesData || []);
  articles = articles.slice(0, 3);
  return (
    <ArticleListWidget
      overrideClasses={classes}
      articles={articles}
      routePath={routePath}
      linkWidth={linkWidth}
      showLink={showLink}
      showSubtitle={showSubtitle}
      isComponentWide={isComponentWide}
    />
  );
}
