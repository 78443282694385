import React from 'react';
import { Link } from 'react-router-dom';
import { Fab, Box } from '@material-ui/core';
import { Home as HomeIcon } from '@material-ui/icons';
import EmptyState from '../EmptyState';
import { ReactComponent as NotFoundIllustration } from '../../../illustrations/not-found.svg';
const defaultObjectName = 'Object';

function ObjectNotFoundPage({ title, description, objectName = defaultObjectName }) {
  // const defaultTitle = `Object doesn't exist`;
  // const defaultDescription = `The Object you’re trying to view does not exist`;
  let theTitle = title ? title : `${objectName} doesn't exist`;
  let theDescription = description ? description : `The ${objectName} you’re trying to view does not exist`;
  return (
    <EmptyState
      image={<NotFoundIllustration />}
      title={theTitle}
      description={theDescription}
      button={
        <Fab variant="extended" color="primary" component={Link} to="/">
          <Box clone mr={1}>
            <HomeIcon />
          </Box>
          Home
        </Fab>
      }
    />
  );
}

export default ObjectNotFoundPage;
