import React from 'react';
import { saveAs } from 'file-saver';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import MoveDevice from './MoveDevice';
import CreateProject from './CreateProject';
import AddExistingSensor from './AddExistingSensor';
import AddNewSensor from './AddNewSensor';
import ActivateGateway from './ActivateGateway';
import DeviceExists from './DeviceExists';
import RemoveExistingDevice from './RemoveExistingDevice';
import ExportDataStudio from './ExportDataStudio';
import MergeDeviceEvents from './MergeDeviceEvents';
import RollbackMergeDeviceEvents from './RollbackMergeDeviceEvents';
import DeleteDevice from './DeleteDevice';
import ExportPollyDaily from './ExportPollyDaily';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '95%',
    '& .cell-small': {
      fontSize: '12px',
    },
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: 'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: '#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
  hiveGrid: {
    flexGrow: 1,
  },
  hivePaper: {
    height: 320,
    width: 215,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 400,
    // width: '500px'
  },
  formControl2: {
    margin: theme.spacing(-1),
    minWidth: 400,
    // width: '500px'
  },
  button: {
    width: '300px',
  },
}));
/*

  - Activate Gateway
  - Create new device and add to project (check deviceId)
  - Create new IHS AND new Gateway and add to project (check deviceId and gatewayDeviceId)
      Create gateway first??
  - Create new IHS with existing Gateway and add to project (check deviceId)
  - Add existing device to project
  - Add existing device with existing Gateway to project
  - Get device data from Beehero API
  - Remove device from a project (DevicePage only, less dropdowns),
      action: remove projectId from projectIds array

 */
export default function AdminPage() {
  const classes = useStyles();
  const labelControlWidth = '150px';
  const inputStyle = { backgroundColor: '#E0E0E0', width: '250px' };
  const userData = useSelector((state) => state.appConfig.userData);
  let projectIds = userData.projectIdsReadAccess.sort();
  // let projectIdsReadAccess = userData.projectIdsReadAccess.sort();
  // let projectIds = [projectIdNotAssigned].concat(projectIdsReadAccess);
  const showAddExistingSensor = false;
  const showRemoveDeviceFromProject = true;
  const showAddUnassignedDeviceToProject = true;

  const downloadFile = () => {
    const url2 = "https://farmwildlife.info/wp-content/uploads/bb-plugin/cache/Bracken-Gethin-Davies-1024x768-panorama.jpg";
    const url = "https://firebasestorage.googleapis.com/v0/b/agrisound-7474e.appspot.com/o/genart1.jpeg?alt=media&token=dfce92d4-0823-4fb1-b613-056daa59df54";
    saveAs(url2, "genart2.jpeg");


  };

  return (
    <Grid container spacing={3}>
      <Grid container item xs={12}>
        <Box component="h2">Activate Gateway</Box>
      </Grid>
      <Grid container item xs={12}>
        <ActivateGateway classes={classes} />
      </Grid>
      <Grid container item xs={12}>
        <Box component="h2">Does deviceId exist?</Box>
      </Grid>
      <Grid container item xs={12}>
        <DeviceExists classes={classes} />
      </Grid>
      {/*<Grid container item xs={12}>*/}
      {/*  <Box component="h2">Update Gateway RSSI</Box>*/}
      {/*</Grid>*/}
      {/*<Grid container item xs={12}>*/}
      {/*  <UpdateGatewayRssi classes={classes} />*/}
      {/*</Grid>*/}
      <Grid container item xs={12}>
        <Box component="h2">Add new sensor to project</Box>
      </Grid>
      <Grid container item xs={12}>
        <AddNewSensor classes={classes} projectIds={projectIds} />
      </Grid>
      {showAddExistingSensor && (
        <Grid container item xs={12}>
          <Box component="h2">Add existing sensor to existing project</Box>
        </Grid>
      )}
      {showAddExistingSensor && (
        <Grid container item xs={12}>
          <AddExistingSensor classes={classes} projectIds={projectIds} />
        </Grid>
      )}
      {showRemoveDeviceFromProject && (
        <Grid container item xs={12}>
          <Box component="h2">Remove existing device from project</Box>
        </Grid>
      )}
      {showRemoveDeviceFromProject && (
        <Grid container item xs={12}>
          <RemoveExistingDevice classes={classes} projectIds={projectIds} />
        </Grid>
      )}
      <Grid container item xs={12}>
        <Box component="h2">Delete device from DB (caution!)</Box>
      </Grid>
      <Grid container item xs={12}>
        <DeleteDevice classes={classes} />
      </Grid>
      {/*{showAddUnassignedDeviceToProject && (*/}
      {/*  <Grid container item xs={12}>*/}
      {/*    <Box component="h2">Add Unassigned Device to project</Box>*/}
      {/*  </Grid>*/}
      {/*)}*/}
      {/*{showAddUnassignedDeviceToProject && (*/}
      {/*  <Grid container item xs={12}>*/}
      {/*    <AddUnassignedDeviceToProject classes={classes} projectIds={projectIds} />*/}
      {/*  </Grid>*/}
      {/*)}*/}
      <Grid container item xs={12}>
        <Box component="h2">Move device to another project</Box>
      </Grid>
      <Grid container item xs={12}>
        <MoveDevice classes={classes} projectIds={projectIds} />
      </Grid>
      <Grid container item xs={12}>
        <Box component="h2">Merge device data</Box>
      </Grid>
      <Grid container item xs={12}>
        <MergeDeviceEvents classes={classes} projectIds={projectIds} />
      </Grid>
      <Grid container item xs={12}>
        <Box component="h2">Rollback 'Merge device data'</Box>
      </Grid>
      <Grid container item xs={12}>
        <RollbackMergeDeviceEvents classes={classes} projectIds={projectIds} />
      </Grid>
      <Grid container item xs={12}>
        <Box component="h2">Create New Project (Polly or Beekeeper)</Box>
      </Grid>
      <Grid container item xs={12}>
        <CreateProject classes={classes} projectIds={projectIds} />
      </Grid>
      <Grid container item xs={12}>
        <Box component="h2">Update config for all devices in Project</Box>
      </Grid>
      <Grid container item xs={12}>
        <RemoveExistingDevice classes={classes} projectIds={projectIds} />
      </Grid>
      <Grid container item xs={12}>
        <Box component="h2">Export Weekly CSV for data studio</Box>
      </Grid>
      <Grid container item xs={12}>
        <ExportDataStudio
          classes={classes}
          projectIds={projectIds.filter((projectId) => projectId.startsWith('polly'))}
        />
      </Grid>
      <Grid container item xs={12}>
        <Box component="h2">Export Polly Daily Values CSV</Box>
      </Grid>
      <Grid container item xs={12}>
        <ExportPollyDaily
          classes={classes}
          projectIds={projectIds.filter((projectId) => projectId.startsWith('polly'))}
        />
      </Grid>

      {/*<Grid container item xs={12}>*/}
      {/*  /!*<a href="https://firebasestorage.googleapis.com/v0/b/agrisound-7474e.appspot.com/o/genart1.jpeg?alt=media&token=dfce92d4-0823-4fb1-b613-056daa59df54" download="dan">XXX</a>*!/*/}
      {/*  <Button*/}
      {/*    // style={{ paddingLeft: '20px', paddingRight: '20px' }}*/}
      {/*    color="primary"*/}
      {/*    variant="contained"*/}
      {/*    // onClick={() => setOpenConfirm(true)}*/}
      {/*    onClick={downloadFile}*/}
      {/*    // disabled={validationMessages.length > 0}*/}
      {/*  >*/}
      {/*    Download*/}
      {/*  </Button>*/}
      {/*</Grid>*/}
    </Grid>
  );
}
