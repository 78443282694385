import React, { useState } from 'react';
import { log } from '../../../util/log-util';
import { format } from 'date-fns';
import { isEmpty } from 'lodash';
import Box from '@material-ui/core/Box';
import NavLink from '../Nav/Link';
import Grid from '@material-ui/core/Grid';
import HiveDisplay from './HiveDisplay';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import PageviewIcon from '@material-ui/icons/Pageview';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { useSelector } from 'react-redux';
import { convertDateWithTimezone } from '../../../util/date-util';
/*
  Links in Menus can be handled another way, using the List component and ListItem:

  https://material-ui.com/components/lists/

  function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
  }

  <ListItemLink href="#simple-list">
    <ListItemText primary="Spam" />
  </ListItemLink>
 */

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    fontSize: 'small',
  },
  subheader: {
    fontSize: 'small',
  },
  action: {
    flex: '0 0 auto',
    alignSelf: 'flex-start',
    marginTop: -14,
    marginRight: -20,
  },
}));

export function HiveCard({
  metric,
  deviceId,
  gatewayDeviceId,
  scaleDeviceId,
  withColour,
  isWidget,
  showHeader = true,
  showFooter = true,
}) {
  log('HiveCard', deviceId);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  let history = useHistory();
  //By not using an explicit storeAs value the same redux storage will be used as the getHivesQuery
  //I.e. Once the devices are retrieved (using getHivesQuery) then clicking on a single Hive will mean
  // the browser is loading the data from redux and not the db
  const device = useSelector(({ firestore: { data } }) => data.devices && data.devices[deviceId]);
  const gatewayDevice = useSelector(
    ({ firestore: { data } }) => data.devices && data.devices[gatewayDeviceId]
  );
  const scaleDevice = useSelector(({ firestore: { data } }) => data.devices && data.devices[scaleDeviceId]);
  const project = useSelector((state) => state.appConfig.currentProject);

  let dateCreated = getFormattedDate({ device, fieldName: 'dateCreated', timezone: project.timezone });
  // let dateCreatedDb = getFormattedDate({ device, fieldName: 'dateCreatedDb', timezone: project.timezone });
  let scaleDateCreated =
    scaleDevice &&
    getFormattedDate({
      device: scaleDevice,
      fieldName: 'dateCreated',
      timezone: project.timezone,
    });

  const detailNav = (
    <Breadcrumbs>
      <NavLink
        path={`hives/${device.deviceId}`}
        text={'Detail'}
        padding={'0 1 0 1'}
        border={'2px solid #D3D3D3'}
        borderRadius={'4px'}
        disabled={isEmpty(device.latestEvent)}
      />
    </Breadcrumbs>
  );

  const handleDotsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEditMenuClick = () => {
    setAnchorEl(null);
    const route = `/devices/${device.deviceId}`;
    history.push(route);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const dotsMenu = (
    <>
      <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleDotsClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu id="dots-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleEditMenuClick}>Edit</MenuItem>
      </Menu>
    </>
  );
  const fieldNameWidth = 70;
  const title = (
    <>
      <Box display="flex">
        <Typography style={{ width: fieldNameWidth }} variant={'caption'}>
          Name:
        </Typography>
        <Typography variant={'caption'}>{device.deviceName}</Typography>
      </Box>
      <Box display="flex">
        <Typography style={{ width: fieldNameWidth }} variant={'caption'}>
          Id:
        </Typography>
        <Typography variant={'caption'}>{device.deviceId}</Typography>
      </Box>
      {gatewayDeviceId && (
        <Box display="flex">
          <Typography style={{ width: fieldNameWidth }} variant={'caption'}>
            Gateway:
          </Typography>
          <Typography variant={'caption'}>{gatewayDeviceId}</Typography>
        </Box>
      )}
      {scaleDeviceId && (
        <Box display="flex">
          <Typography style={{ width: fieldNameWidth }} variant={'caption'}>
            Scale:
          </Typography>
          <Typography variant={'caption'}>{scaleDeviceId}</Typography>
        </Box>
      )}
    </>
  );

  let scaleBattery = undefined;
  if (scaleDevice) scaleBattery = scaleDevice && scaleDevice.latestEvent && scaleDevice.latestEvent.battery;
  if (device.deviceType === 'agsenzeScaleOne') {
    scaleBattery = device && device.latestEvent && device.latestEvent.battery;
  }

  return (
    <Card className={classes.root}>
      {showHeader && <HiveCardHeader classes={classes} dotsMenu={dotsMenu} title={title} />}
      {/*<CardContent>*/}
      {/*  <KeyValues device={device} dotsMenu={dotsMenu} />*/}
      {/*</CardContent>*/}
      <CardContent style={{ backgroundColor: '#D3D3D3' }}>
        <HiveDisplay
          metric={metric}
          device={device}
          gatewayDeviceId={device.gatewayDeviceId}
          gatewayTemp={gatewayDevice && gatewayDevice.latestEvent && gatewayDevice.latestEvent.temperature}
          scaleDevice={scaleDevice}
          withColour={withColour}
          latestEvent={device.latestEvent}
          isWidget={isWidget}
        />
      </CardContent>
      {showFooter && (
        <CardActions>
          <CardFooter
            detailNav={detailNav}
            dateCreated={dateCreated}
            scaleDateCreated={scaleDateCreated}
            scaleBattery={scaleBattery}
          />
        </CardActions>
      )}
      {/*{!showHeader && (*/}
      {/*  <Box display="flex" justifyContent="center" style={{ marginTop: 5 }}>*/}
      {/*    {detailNav}*/}
      {/*  </Box>*/}
      {/*)}*/}
    </Card>
  );
}

function HiveCardHeader({ classes, dotsMenu, title }) {
  return (
    <CardHeader
      classes={{
        // root: classes.root, // class name, e.g. `classes-nesting-root-x`
        title: classes.title, // class name, e.g. `classes-nesting-label-x`
        subheader: classes.subheader, // class name, e.g. `classes-nesting-label-x`
        action: classes.action, // class name, e.g. `classes-nesting-label-x`
      }}
      // avatar={
      //   <Avatar aria-label="recipe" className={classes.avatar}>
      //     R
      //   </Avatar>
      // }
      action={dotsMenu}
      title={title}
      // subheader={`Id: ${device.deviceId}`}
    />
  );
}

function getFormattedDate({ device, fieldName, timezone }) {
  let date =
    device && device.latestEvent && device.latestEvent[fieldName] && device.latestEvent[fieldName].toDate();
  if (date) {
    date = convertDateWithTimezone(date, timezone).format('DD-MMM HH:mm');
    // date = format(date, 'd/MM HH:mm');
  } else {
    date = 'n/a';
  }
  return date;
}

function CardFooter({ detailNav, dateCreated, scaleDateCreated, scaleBattery, batteryMax = 5 }) {
  return (
    <Grid container justify={'center'} spacing={0}>
      <Grid item xs={4}>
        {detailNav}
      </Grid>
      <Grid item xs={8}>
        <LastActivity dateCreated={dateCreated} scaleDateCreated={scaleDateCreated} />
      </Grid>
      {/*{scaleBattery !== undefined && (*/}
      {/*  <Grid item xs={12}>*/}
      {/*    <Typography style={{ color: 'green' }} variant={'caption'}>*/}
      {/*      Scale battery level {scaleBattery} / {batteryMax}*/}
      {/*    </Typography>*/}
      {/*  </Grid>*/}
      {/*)}*/}
    </Grid>
  );
}

function LastActivity({ dateCreated, scaleDateCreated }) {
  return (
    <Grid
      container
      justify={'center'}
      spacing={0}
      style={{ marginTop: scaleDateCreated ? 5 : 10, marginLeft: 5 }}
    >
      <Grid item xs={12}>
        {scaleDateCreated ? 'IHS' : 'Updated'} @ {dateCreated}
      </Grid>
      {scaleDateCreated && (
        <Grid item xs={12}>
          Scale @ {scaleDateCreated}
        </Grid>
      )}
      {/*{scaleBattery !== undefined && (*/}
      {/*  <Grid item xs={12}>*/}
      {/*    <Typography style={{ color: 'green' }} variant={'caption'}>*/}
      {/*      Scale battery level {scaleBattery} / {batteryMax}*/}
      {/*    </Typography>*/}
      {/*  </Grid>*/}
      {/*)}*/}
    </Grid>
  );
}

function KeyValues({ device, dotsMenu }) {
  const keyValues = [
    {
      key: 'Name',
      value: device.deviceName,
    },
    {
      key: 'DeviceId',
      value: device.deviceId,
    },
    // {
    //   key: 'Updated',
    //   value: dateCreated,
    // },
    // {
    //   key: 'Updated (DB)',
    //   value: dateCreatedDb,
    // },
  ];

  return (
    <Grid container justify={'center'} spacing={0}>
      {keyValues.map((keyValue, index) => (
        <React.Fragment key={index}>
          <Grid item xs={6}>
            <Typography variant={'caption'}>{keyValue.key}:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={'caption'}>{keyValue.value}</Typography>
          </Grid>
        </React.Fragment>
      ))}
      {/*<Grid item xs={2}>*/}
      {/*  {dotsMenu}*/}
      {/*</Grid>*/}
    </Grid>
  );
}
