import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: '100%',
    // width: '500px'
  },
  paper: {
    padding: theme.spacing(3),
    color: theme.palette.text.secondary,
  },
  test: {
    fontFamily: 'inherit',
  },
}));

export default function BlogPage() {

  return (
    <Typography>
      Click{' '}
      <a href="https://www.arnia.co/blog" target="_blank">
        here
      </a>{' '}
      for the Arnia.co blog page
    </Typography>
  );
}
