import { auth, firestore } from '../firebase';

export async function updateProject({ projectId, updatedObj, dispatch }) {
  addAudit(updatedObj);
  // await firestore.doc(`projects/${projectId}/devices/${deviceId}`).update(updatedObj);
  await firestore.doc(`projects/${projectId}`).update(updatedObj);
  const project = await firestore.doc(`projects/${projectId}`).get();
  dispatch && dispatch({ type: 'UPDATE_PROJECT', project: project.data() });
}

export async function updateProjectMetric({ projectId, metric, dispatch }) {
  const updatedObj = { metric };
  addAudit(updatedObj, `updated metric value to ${metric}`);
  // await firestore.doc(`projects/${projectId}/devices/${deviceId}`).update(updatedObj);
  await firestore.doc(`projects/${projectId}`).update(updatedObj);
  const project = await firestore.doc(`projects/${projectId}`).get();
  dispatch && dispatch({ type: 'UPDATE_PROJECT', project: project.data() });
}

export async function updateProjectTimezone({ projectId, timezone, dispatch }) {
  const updatedObj = { timezone };
  addAudit(updatedObj, `updated timezone value to ${timezone}`);
  await firestore.doc(`projects/${projectId}`).update(updatedObj);
  const project = await firestore.doc(`projects/${projectId}`).get();
  dispatch && dispatch({ type: 'UPDATE_PROJECT', project: project.data() });
}

export async function updateProjectNote({ projectId, noteText }) {
  const updatedObj = { noteText };
  // addAudit(updatedObj, `updated timezone value to ${timezone}`);
  await firestore.doc(`projects/${projectId}/notes/admin`).set(updatedObj);
  // const project = await firestore.doc(`projects/${projectId}`).get();
  // dispatch && dispatch({ type: 'UPDATE_PROJECT', project: project.data() });
}

function addAudit(obj, comment) {
  const uid = auth.currentUser && auth.currentUser.uid;
  obj.updatedBy = uid;
  if (comment) obj.updateComment = comment;
  obj.dateUpdated = new Date();
}
