import React from 'react';
import { log } from '../../../util/log-util';
import { sortBy } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useHistory } from 'react-router-dom';
import { HiveCard } from '../Hive/HiveCard';
import { getProjectIds } from '../../../config/env';
import { getDevicesQuery, getDevicesStoreAs } from '../../../api/queryConfig';
import {getDevicesToDisplay} from "./hivesUtil";

const useStyles = makeStyles((theme) => ({
  hiveGrid: {
    flexGrow: 1,
  },
  hivePaper: {
    height: 150,
    width: 110,
    fontSize: 12,
    cursor: 'default',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function HivesWidget() {
  const classes = useStyles();
  const userData = useSelector((state) => state.appConfig.userData);
  let projectIds = getProjectIds(userData);
  const projectId = projectIds[0];
  const storeAs = getDevicesStoreAs();
  const hivesQuery = getDevicesQuery({ projectIds });
  const devicesData = useSelector(({ firestore: { data } }) => data[storeAs]);
  log('HivesWidget', projectIds);

  useFirestoreConnect([hivesQuery]);

  if (!isLoaded(devicesData)) {
    return 'Loading...';
  }
  let devicesRaw = (devicesData && Object.values(devicesData)) || [];
  let devices = getDevicesToDisplay(devicesRaw);
  devices = devices.filter((device) => device.latestEvent).slice(0, 3); //TODO: should i use limit in the query?
  return <Hives withColour={true} classes={classes} devices={devices} />;
}

function Hives({ withColour, classes, devices }) {
  //sort hives by deviceName
  let history = useHistory();
  const handlePaperClick = (deviceId) => {
    if (deviceId) {
      history.push({
        pathname: `/hives/${deviceId}`,
        state: { sensorTemp: true },
      });
    } else {
      history.push({
        pathname: `/hives-overview`,
      });
    }
  };
  let sortedHives = sortBy(devices, ['deviceName']);
  return (
    <Grid container className={classes.hiveGrid} spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          {sortedHives.map((device, index) => (
            <Grid key={index} item>
              <Paper
                className={classes.hivePaper}
                onClick={() => handlePaperClick()}
              >
                <HiveCard
                  deviceId={device.deviceId}
                  withColour={withColour}
                  isWidget={true}
                  showHeader={false}
                  showFooter={false}
                />
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {/*<HivesChartWithData projectId={projectId} />*/}
      </Grid>
    </Grid>
  );
}
