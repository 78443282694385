import * as React from 'react';
import { GoogleMap, HeatmapLayer } from '@react-google-maps/api';
import { useSelector } from 'react-redux';
import { getEventsCountByLocationForHeatmap } from '../../../data/data-util';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';

const center = {
  lat: 51.562469,
  lng: -0.155922,
};

const onClick = (...args) => {
  console.log('onClick args: ', args);
};

export const mapContainerStyle = {
  height: '700px',
  width: '100%',
};

function EventsHeatmap({ styles, events, projectId }) {
  const storeAs = `${projectId}-devices`;
  const devices = useSelector(({ firestore: { data } }) => data[storeAs]);

  const devicesQuery = [
    {
      collection: 'projects',
      doc: projectId,
      subcollections: [{ collection: 'devices' }],
      storeAs: storeAs,
    },
  ];

  useFirestoreConnect(devicesQuery);

  if (!isLoaded(devices)) {
    return 'Loading...';
  }

  let eventsCountByLocation = getEventsCountByLocationForHeatmap(events, devices);
  // console.log('EventsHeatmap', eventsCountByLocation);

  return (
    <div className="map">
      <div className="map-container">
        <GoogleMap
          id="heatmap-example"
          mapContainerStyle={mapContainerStyle}
          zoom={16}
          center={center}
          onClick={onClick}
        >
          <HeatmapLayer data={eventsCountByLocation} />
        </GoogleMap>
      </div>
    </div>
  );
}

export default React.memo(EventsHeatmap);
