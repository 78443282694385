import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    // minWidth: 650,
  },
  cell: {
    width: 50,
  },
});

export default function PollySummaryTable({ rows, showDay = false }) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell}>Polly ID</TableCell>
            {showDay && <TableCell align="right">Day</TableCell>}
            <TableCell className={classes.cell} align="right">
              {showDay ? 'Bee Count' : 'Total Bee Count'}
            </TableCell>
            <TableCell className={classes.cell} align="right">
              Min Temp
            </TableCell>
            <TableCell className={classes.cell} align="right">
              Max Temp
            </TableCell>
            <TableCell className={classes.cell} align="right">
              Min Hum %
            </TableCell>
            <TableCell className={classes.cell} align="right">
              Max Hum %
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.deviceId}>
              <TableCell component="th" scope="row">
                {row.deviceId}
              </TableCell>
              {showDay && <TableCell align="right">{row.day}</TableCell>}
              <TableCell align="right">{row.beeCount}</TableCell>
              <TableCell align="right">{row.minTemp}</TableCell>
              <TableCell align="right">{row.maxTemp}</TableCell>
              <TableCell align="right">{row.minHumidity}</TableCell>
              <TableCell align="right">{row.maxHumidity}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
