import { combineReducers } from 'redux';
// import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import appConfigReducer from './app-config';

export const rootReducer = combineReducers({
  // firebase: firebaseReducer,
  firestore: firestoreReducer,
  appConfig: appConfigReducer,
});
