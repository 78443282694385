import React from 'react';
import { saveAs } from 'file-saver';
import { format } from 'date-fns';
import {
  getProScaleCalibrationOffsetAndScaleDeviceType,
  getTemperature,
  getWeight,
} from '../../../data/data-util';
import DownloadIcon from '@material-ui/icons/GetAppRounded';
import Button from '@material-ui/core/Button';
const { Parser } = require('json2csv');

export default function DownloadFile({
  buttonText = 'Download',
  eventsRows,
  fileName = 'file.csv',
  isMetric,
  deviceIds,
  csvData,
  csvHeaders,
  devices,
}) {
  const saveFile = () => {
    if (!csvData) {
      [csvData, csvHeaders] = getCsvData({ eventsRows, isMetric, deviceIds, devices });
    }

    const csv = toCsv({ jsonData: csvData, fields: csvHeaders });
    const blob = new Blob([csv], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, fileName);
  };

  return (
    <Button
      variant="outlined"
      color={'secondary'}
      onClick={saveFile}
      endIcon={<DownloadIcon style={{ color: 'blue' }} />}
    >
      {buttonText}
    </Button>
  );
}

// function saveFile({ eventsRows, isMetric, deviceIds, fileName }) {
//   console.log('DownloadFile.saveFile');
//   const [csvData, csvHeaders] = getCsvData({ eventsRows, isMetric, deviceIds });
//   const csv = toCsv({ jsonData: csvData, fields: csvHeaders });
//   // console.log('csv', csv);
//   const blob = new Blob([csv], { type: 'text/plain;charset=utf-8' });
//   saveAs(blob, fileName);
// }

function getCsvData({ eventsRows, isMetric = true, deviceIds, devices }) {
  let csv = [];
  let headers = ['deviceId', 'deviceName', 'dateCreated', 'temperature', 'humidity'];
  let rows = eventsRows;
  if (deviceIds) {
    rows = eventsRows.filter((row) => deviceIds.includes(row.deviceId));
  }
  for (let row of rows) {
    for (let event of row.events) {
      if (event.hasOwnProperty('dateCreated') && event.dateCreated) {
        let dateCreated = format(event.dateCreated, 'dd MMM yyyy HH:mm:ss');
        let dataPoint = {
          deviceId: row.deviceId,
          deviceName: row.deviceName,
          dateCreated,
          temperature: (event.temperature && getTemperature(event.temperature, isMetric)) || 'n/a',
          humidity: (event.hasOwnProperty('humidity') && event.humidity) || 'n/a',
        };
        let [calibrationOffset, scaleDeviceType] = getProScaleCalibrationOffsetAndScaleDeviceType({
          devices,
          deviceId: row.deviceId,
        });

        if (event.hasOwnProperty('weight')) {
          dataPoint.weight = getWeight({
            value: event.weight,
            isMetric,
            deviceType: event.deviceType,
            calibrationOffset,
          });
          if (!headers.includes('weight')) headers.push('weight');
        }
        if (event.hasOwnProperty('inCountDiff') && event.inCountDiff) {
          dataPoint.inCount = (event.hasOwnProperty('inCountDiff') && event.inCountDiff) || 'n/a';
          if (!headers.includes('inCount')) headers.push('inCount');
        }
        if (event.hasOwnProperty('outCountDiff') && event.outCountDiff) {
          dataPoint.outCount = (event.hasOwnProperty('outCountDiff') && event.outCountDiff) || 'n/a';
          if (!headers.includes('outCount')) headers.push('outCount');
        }
        csv.push(dataPoint);
      }
    }
  }

  csv = csv.sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated));

  return [csv, headers];
}

export function toCsv({ jsonData, fields }) {
  const opts = { fields };
  try {
    const parser = new Parser(opts);
    return parser.parse(jsonData);
  } catch (err) {
    console.error(err);
  }
}
