import React, { useState } from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import DownloadFile from '../Download/DownloadFile';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export const rangeOptions = {
  _1day: '24 hours',
  _1week: '1 Week',
  _1month: '1 Month',
  _3months: '3 Months',
  max: 'Max',
};

export const rangeConfig = {
  _1day: 1,
  _1week: 7,
  _1month: 30,
  _3months: 90,
};

export default function ChartDeviceToggle({ deviceIdNameMap, selectedDeviceIds, onDeviceToggleChange, downloadButton }) {
  const classes = useStyles();

  let deviceNameIdsMap = {};

  for (let deviceId of Object.keys(deviceIdNameMap)) {
    let deviceName = deviceIdNameMap[deviceId];
    if (!deviceNameIdsMap[deviceName]) {
      deviceNameIdsMap[deviceName] = [];
    }
    if (!deviceNameIdsMap[deviceName].includes(deviceId)) {
      deviceNameIdsMap[deviceName].push(deviceId);
    }
  }

  // console.log('ChartDeviceToggle', { deviceIds, selectedDeviceIds, deviceIdNameMap, deviceNameIdsMap });
  const deviceNames = Object.keys(deviceNameIdsMap);

  return (
    <Box flexWrap="wrap">
      {downloadButton}
      {deviceNames.map((deviceName, index) => {
        const selected = deviceNameIdsMap[deviceName].some((deviceId) => selectedDeviceIds.includes(deviceId));
        return (
          <ToggleButton
            style={{ backgroundColor: selected ? 'orange': 'white'}}
            key={index}
            onChange={() => onDeviceToggleChange(deviceNameIdsMap[deviceName])}
            size="small"
            value={deviceName}
            selected={selected}
          >
            {deviceName}
          </ToggleButton>
        );
      })}
    </Box>
  );
}
