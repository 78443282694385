import React from 'react';
import EmptyState from '../EmptyState';
import AgriSoundImage from '../../../assets/AgrisoundLarge.png';
import Typography from '@material-ui/core/Typography';
import EmojiEmotionsTwoToneIcon from '@material-ui/icons/EmojiEmotionsTwoTone';
import EmojiNatureTwoToneIcon from '@material-ui/icons/EmojiNatureTwoTone';
import Box from '@material-ui/core/Box';

export default function SignIn({ user }) {
  const image = <img src={AgriSoundImage} height={200} width={200} alt={'Logo'} />;

  // const hasAuth = useSelector((state) => state.appConfig.hasAuth);
  //
  // if (hasAuth) {
  //   return <Redirect to={'/'} />;
  // }

  // return <div id="sign-in">Please sign in</div>;
  return (
    <Box display="flex" flexDirection="row" style={{ marginTop: '40px' }}>
      <Typography variant={'h6'}>Please sign in</Typography>
      <EmojiNatureTwoToneIcon
        style={{ fontSize: 50, color: 'orange', marginTop: '-12px', marginLeft: '10px' }}
      />
      {/*<EmojiEmotionsTwoToneIcon*/}
      {/*  style={{ fontSize: 50, color: 'orange', marginTop: '-11px', marginLeft: '5px' }}*/}
      {/*/>*/}
    </Box>
  );
  // return (
  //   <EmptyState
  //     id="sign-in"
  //     image={image}
  //     title="Please sign in"
  //     // description="Please sign in"
  //     // button={
  //     //   <Fab variant="extended" color="primary" component={Link} to="/">
  //     //     <Box clone mr={1}>
  //     //       <HomeIcon />
  //     //     </Box>
  //     //     Home
  //     //   </Fab>
  //     // }
  //   />
  // );
}
