import React from 'react';
import PropTypes from 'prop-types';
import WeatherIcon from './WeatherIcon';
import useStyles from './Forecast.styles';
import defaultTheme from './defaultTheme';

const Forecast = ({ unitsLabels, current, forecast, theme = defaultTheme }) => {
  const classes = useStyles({ theme });
  if (current && (!forecast || forecast.length === 0)) {
    let day = current;
    return (
      <div className={classes.daysPanel}>
        <div key={day.date} className={classes.day}>
          <div className={classes.date}>{day.date}</div>
          <div className={classes.icon}>
            <WeatherIcon path={day.icon} title={day.description} color={theme.forecastIconColor} size={25} />
          </div>
          <div className={classes.desc}>
            {day.description} ({day.temperature.current} {unitsLabels.temperature})
          </div>
          {/*<div className={classes.range}>*/}
          {/*  {day.temperature.max} / {day.temperature.min}{' '}*/}
          {/*  {unitsLabels.temperature}*/}
          {/*</div>*/}
        </div>
      </div>
    );
  }
  if (!current && (!forecast || forecast.length === 0)) {
    return <div>No weather data</div>;
  }
  return (
    <div className={classes.daysPanel}>
      {forecast.map((day, i) => {
        if (i >= 0) {
          return (
            <div key={day.date} className={classes.day}>
              <div className={classes.date}>{day.date}</div>
              <div className={classes.icon}>
                <WeatherIcon
                  path={day.icon}
                  title={day.description}
                  color={theme.forecastIconColor}
                  size={25}
                />
              </div>
              <div className={classes.desc}>
                {day.description} ({day.temperature.max} / {day.temperature.min} {unitsLabels.temperature})
              </div>
              {/*<div className={classes.range}>*/}
              {/*  {day.temperature.max} / {day.temperature.min}{' '}*/}
              {/*  {unitsLabels.temperature}*/}
              {/*</div>*/}
            </div>
          );
        }
        return '';
      })}
    </div>
  );
};

Forecast.propTypes = {
  unitsLabels: PropTypes.object.isRequired,
  forecast: PropTypes.array.isRequired,
  // theme: PropTypes.object.isRequired,
};

export default Forecast;
