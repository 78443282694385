import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  ButtonGroup,
  Button,
  IconButton,
  Divider,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UserAvatar from '../UserAvatar';
import { useSelector } from 'react-redux';
import AgrisoundLogo from '../AgrisoundLogo/AgrisoundLogo';
import { barColour } from '../../../reducers/app-config';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));
HorizontalBar2021.defaultProps = {
  performingAction: false,
};

HorizontalBar2021.propTypes = {
  // Properties
  performingAction: PropTypes.bool.isRequired,
  user: PropTypes.object,
  userData: PropTypes.object,

  // Events
  onAboutClick: PropTypes.func.isRequired,
  onSettingsClick: PropTypes.func.isRequired,
  onSignOutClick: PropTypes.func.isRequired,
};

export default function HorizontalBar2021({
  performingAction,
  user,
  userData,
  roles,
  onAboutClick,
  onSettingsClick,
  onSignOutClick,
  onSignUpClick,
  onSignInClick,
}) {
  const classes = useStyles();
  const initialMenu = { anchorEl: null };
  const [menu, setMenu] = useState(initialMenu);

  const openMenu = (event) => {
    setMenu({ anchorEl: event.currentTarget });
  };

  const closeMenu = () => {
    setMenu({ anchorEl: null });
  };

  const menuItems = getProfileMenuItems({
    onAboutClick,
    user,
    onSettingsClick,
    onSignOutClick,
  });

  return (
    <>
      {/*<CmsBannerAlert />*/}
      {/*<AppBar position="static" style={{ backgroundColor: '#ececec' }}>*/}
        <>
          {/*<IconButton*/}
          {/*  edge="start"*/}
          {/*  className={classes.menuButton}*/}
          {/*  color="inherit"*/}
          {/*  aria-label="menu"*/}
          {/*  onClick={openBurgerMenu}*/}
          {/*>*/}
          {/*  <MenuIcon />*/}
          {/*</IconButton>*/}
          {/*<BarMenu menuItems={burgerMenuItems} menu={burgerMenu} closeMenu={closeBurgerMenu} />*/}
          {/*<Box display="flex" flexGrow={1} style={{ backgroundColor: 'green' }}>*/}
          <Box display="flex" flexGrow={1}>
            <div style={{ marginRight: 10 }}>
              <AgrisoundLogo />
            </div>
            <Typography color="inherit" variant="h5" style={{ marginTop: 5 }}>
              {process.env.REACT_APP_TITLE}
            </Typography>
          </Box>

          {user && (
            <>
              {roles.includes('admin') && (
                <Box mr={1}>
                  <Button color="inherit" component={Link} to="/admin" variant="outlined">
                    Admin
                  </Button>
                </Box>
              )}

              <IconButton color="inherit" disabled={performingAction} onClick={openMenu}>
                {/*there was an error here Object.assign(user, userData)*/}
                <UserAvatar user={userData} />
              </IconButton>

              <BarMenu menuItems={menuItems} menu={menu} closeMenu={closeMenu} />
            </>
          )}

          {!user && (
            <ButtonGroup color="inherit" disabled={performingAction} variant="outlined">
              <Button onClick={onSignUpClick}>Sign up</Button>
              <Button onClick={onSignInClick}>Sign in</Button>
            </ButtonGroup>
          )}
        </>
      {/*</AppBar>*/}
    </>
  );
}

function getProfileMenuItems({ onAboutClick, user, onSettingsClick, onSignOutClick }) {
  const profileMenuItems = [
    {
      name: 'About',
      key: 'menu-1',
      onClick: onAboutClick,
    },
    {
      name: 'Profile',
      key: 'menu-2',
      to: user ? `/users/${user.uid}` : null,
    },
    {
      name: 'Settings',
      key: 'menu-3',
      onClick: onSettingsClick,
    },
    {
      name: 'Sign out',
      key: 'menu-4',
      divide: true,
      onClick: onSignOutClick,
    },
  ];

  return profileMenuItems;
}

function BarMenu({ menuItems, menu, closeMenu }) {
  if (menuItems && menuItems.length === 0) {
    return <></>;
  }
  return (
    <Menu anchorEl={menu.anchorEl} open={Boolean(menu.anchorEl)} onClose={closeMenu}>
      {menuItems.map((menuItem, index) => {
        let key = menuItem.key || index;
        if (menuItem.hasOwnProperty('condition') && !menuItem.condition) {
          return null;
        }

        let component = null;

        if (menuItem.to) {
          component = (
            <MenuItem key={key} component={Link} to={menuItem.to} onClick={closeMenu}>
              {menuItem.name}
            </MenuItem>
          );
        } else if (menuItem.onClick) {
          component = (
            <MenuItem
              key={key}
              onClick={() => {
                closeMenu();
                menuItem.onClick();
              }}
            >
              {menuItem.name}
            </MenuItem>
          );
        }

        if (menuItem.divide) {
          return (
            <span key={'divider' + key}>
              <Divider />
              {component}
            </span>
          );
        }

        return component;
      })}
    </Menu>
  );
}
