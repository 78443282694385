import React, { useEffect, useState } from 'react';
import { cloneDeep, sortBy } from 'lodash';
import { DataGrid } from '@material-ui/data-grid';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { eventsColumns } from '../../../data/beekeperDataUtil';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { Hidden } from '@material-ui/core';
import { getDevicesQuery, getDevicesStoreAs } from '../../../api/queryConfig';
import { convertAdcToKg } from '../../../data/data-util';
import { globalElevation } from '../../../config/globalStyle';
import { PollyDeviceCard } from './PollyDeviceCard';
// import { flatten } from './tree/treeUtil';
import PollyDeviceOverviewWidget from '../../beekeeper/Widgets/PollyDeviceOverviewWidget';
import PollyDeviceOverviewLiteWidget from '../../beekeeper/Widgets/PollyDeviceOverviewLiteWidget';
import Highlight from '../../beekeeper/Widgets/Highlight';
import MapControl from '../Maps/MapControl';
import { getDeviceStats } from '../../beekeeper/Widgets/stats-util';
import { getFilterByPossibleValues, uniqObject } from '../Filter/filterUtil';
import PollyAutocomplete from '../Filter/PollyAutocomplete';
import { getSiteOptionsFromProject } from '../util/hierarchyUtil';
import { getPermission } from '../../../util/permission-util';
import { cloneAndInitialise, getDeviceMapInfo, hasLocation } from '../util/devicesUtil';
import PollyMapBasic from '../Maps/PollyMapBasic';
import { updateSelectedSiteName } from '../../../api/updateUser';
import { getSelectedSiteName } from '../../../config/env';
import { getIsAgrisoundAdmin } from '../../../util/user-util';

const useStyles = makeStyles((theme) => ({
  deltaGrid: {
    maxWidth: '95%',
    '& .cell-small': {
      fontSize: '12px',
    },
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: 'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: '#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
  hiveGrid: {
    flexGrow: 1,
  },
  hivePaper: {
    // height: 500,
    width: 260,
    fontSize: 12,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  formControl: {
    // margin: theme.spacing(1),
    // minWidth: '300px',
    // marginTop: -14,
  },
  mapControl: {
    width: '100%',
  },
}));

//TODO: what is the diff between PollyDevices2 and PollyDevices
export default function PollyDevices({ metric, showDeltaGrid = false }) {
  const classes = useStyles();
  const userData = useSelector((state) => state.appConfig.userData);
  const user = useSelector((state) => state.appConfig.user);
  const userId = user && user.uid;
  const project = useSelector((state) => state.appConfig && state.appConfig.currentProject);
  const projectId = project.projectId;
  const projectIds = [projectId];
  const selectedSiteName = getSelectedSiteName(userData);
  const storeAsDefault = `devices-${selectedSiteName}`; //getDevicesStoreAs();
  const [siteName, setSiteName] = useState(selectedSiteName);
  const [storeAs, setStoreAs] = useState(storeAsDefault);
  const devicesData = useSelector(({ firestore: { data } }) => data[storeAs]);
  const [deviceIds, setDeviceIds] = useState();
  const [deviceId, setDeviceId] = useState();
  const [devices, setDevices] = useState([]);
  const [siteNameOptions, setSiteNameOptions] = useState([]);
  const [mapHeight, setMapHeight] = useState('0px');
  const isAgriSoundOnlyProject = projectId.includes('polly-test');
  const devicesQuery = getDevicesQuery({ projectIds, storeAs, siteName, isAgriSoundOnlyProject });
  const isAgrisoundAdmin = getIsAgrisoundAdmin(userData);
  // console.log('XXX1.devicesQuery', devicesQuery, storeAs);

  const handleSiteNameChangeAutocomplete = (event, value) => {
    const selectedSiteName = value.value;
    // console.log('handleProjectIdChangeAutocomplete', newSelectedProjectId);
    if (selectedSiteName) {
      updateSelectedSiteName({ userId, selectedSiteName })
        .then((success) => {
          setSiteName(selectedSiteName);
          const storeAsDefault = `devices-${selectedSiteName}`; //getDevicesStoreAs();
          setStoreAs(storeAsDefault);
        })
        .catch((err) => console.log('handleSiteNameChangeAutocomplete.err', err));
    }
  };

  const getSites = (devices) => {
    return uniqObject(
      devices
        .filter((device) => device.siteName)
        .map((device) => {
          return {
            siteId: device.siteName,
            siteName: device.siteName,
          };
        }),
      'site'
    );
  };

  const filterDevices = ({ devices, siteName, deviceId }) => {
    // console.log('filterDevices', siteName, deviceId);
    return devices.filter((device) => {
      if (siteName && deviceId) {
        return device.siteName === siteName && device.deviceId === deviceId;
      } else if (!siteName && deviceId) {
        return device.deviceId === deviceId;
      } else if (siteName && !deviceId) {
        return device.siteName === siteName;
      } else if (!siteName && !deviceId) {
        return true;
      }
    });
  };

  useEffect(() => {
    // console.log('XXX1.useEffect.devicesData', devicesData);
    let devices = cloneAndInitialise({ devicesData });
    // devices = filterDevices({ devices, siteName, deviceId: deviceId && deviceId.value });
    setDevices(devices);
    let deviceIds = devices.map((device) => {
      return {
        label: device.deviceId,
        value: device.deviceId,
      };
    });
    setDeviceIds(deviceIds);
    // const sites = getSites(devices);
    const getSites2 = (siteNames) => {
      return siteNames.map((siteName) => {
        return {
          label: siteName,
          value: siteName,
        };
      });
    };

    const siteOptions = getSiteOptionsFromProject(project);
    const hierarchySiteNameOptions = getSites2(siteOptions);
    // const siteNameOptions = getFilterByPossibleValues({ option: 'siteName', sites });
    setSiteNameOptions(hierarchySiteNameOptions);
  }, [devicesData, siteName]);

  useEffect(() => {
    // console.log('useEffect', { deviceId, siteName });
    let devices = cloneAndInitialise({ devicesData });
    // devices = filterDevices({ devices, siteName, deviceId: deviceId && deviceId.value });

    setDevices(devices);
    if (siteName) {
      let deviceIds = devices.map((device) => {
        return {
          label: device.deviceId,
          value: device.deviceId,
        };
      });
      setDeviceIds(deviceIds);
    }
  }, [deviceId, siteName]);

  useFirestoreConnect([devicesQuery]);

  if (!isLoaded(devicesData)) {
    return 'Loading...';
  }

  let devicesWithLocation = devices
    .filter((device) => hasLocation(device))
    .map((device) => {
      let dev = cloneDeep(device);
      dev.infoText = getDeviceMapInfo(device);
      return dev;
    });
  const deltaGridRows = getRows(devices, metric);
  const siteOptions = getSiteOptionsFromProject(project);

  const onDeviceIdChange = (event, value) => {
    setDeviceId(value);
  };

  const onSiteNameChange = (event, value) => {
    if (value) {
      setSiteName(value.value);
    } else {
      setSiteName(undefined);
    }
  };

  let showSiteSearch = getPermission({
    component: 'PollyDevices',
    permission: 'showSiteSearch',
    userData,
    currentProjectId: project.projectId,
  });
  let showDeviceSearch = getPermission({
    component: 'PollyDevices',
    permission: 'showDeviceSearch',
    userData,
    currentProjectId: project.projectId,
  });

  return (
    <Grid container className={classes.hiveGrid} spacing={1}>
      {devicesWithLocation.length > 0 && (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MapControl mapHeight={mapHeight} setMapHeight={setMapHeight} classes={classes} />
        </Grid>
      )}
      {devicesWithLocation.length > 0 && (
        <Grid item xs={12}>
          <PollyMapBasic devices={devicesWithLocation} mapHeight={mapHeight} />
        </Grid>
      )}
      {devicesWithLocation.length === 0 && (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Alert severity="info">Cannot display the map, no locations are set</Alert>
        </Grid>
      )}
      {showSiteSearch && isAgriSoundOnlyProject && (
        <Grid item xs={12} lg={4}>
          <PollyAutocomplete
            options={siteNameOptions}
            defaultValue={undefined}
            onChange={handleSiteNameChangeAutocomplete}
            value={siteName}
            // sx={{ minWidth: '300px' }}
            multiple={false}
            label={'Site'}
            placeholder={'Site'}
          />
        </Grid>
      )}
      {showDeviceSearch && isAgriSoundOnlyProject && (
        <Grid item xs={12} lg={4}>
          <PollyAutocomplete
            options={deviceIds}
            defaultValue={undefined}
            onChange={onDeviceIdChange}
            value={deviceId}
            // sx={{ minWidth: '300px' }}
            multiple={false}
            label={'Device'}
            placeholder={'Device'}
          />
        </Grid>
      )}
      <Grid item xs={0} lg={2}>
        <></>
      </Grid>
      <Grid item xs={12}>
        <DeviceCards
          cardType={'normal'}
          metric={metric}
          withColour={true}
          classes={classes}
          devices={devices}
          projectId={projectId}
          siteOptions={siteOptions}
          isAgrisoundAdmin={isAgrisoundAdmin}
        />
      </Grid>
      <Hidden smDown>
        {showDeltaGrid && deltaGridRows.length > 0 && (
          <Grid item xs={12}>
            <div style={{ height: 800 }}>
              <DataGrid rows={deltaGridRows} columns={eventsColumns} pageSize={50} />
            </div>
          </Grid>
        )}
      </Hidden>
    </Grid>
  );
}

export function DeviceCards({
  metric,
  withColour,
  classes,
  devices,
  siteOptions,
  cardType,
  showSite,
  showTrends,
  isAgrisoundAdmin,
}) {
  //sort hives by deviceName to maintain the same hive order every time the page loads
  // console.log('XXX.DeviceCards', devices);
  let sortedDevices = sortBy(devices, ['deviceName']);
  let deviceStats = getDeviceStats(devices);
  return (
    <Grid container spacing={1}>
      {sortedDevices.map((device, index) => (
        <Grid key={index} item>
          <Paper elevation={globalElevation} className={classes.hivePaper}>
            <TheDeviceCard
              deviceStats={deviceStats.find((stat) => stat.deviceId === device.deviceId)}
              cardType={cardType}
              device={device}
              metric={metric}
              deviceId={device.deviceId}
              gatewayDeviceId={device.gatewayDeviceId}
              scaleDeviceId={device.scaleDeviceId}
              withColour={withColour}
              siteOptions={siteOptions}
              showSite={showSite}
              showTrends={showTrends}
              isAgrisoundAdmin={isAgrisoundAdmin}
            />
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}

function TheDeviceCard({
  cardType,
  device,
  deviceStats,
  lineToHighlight,
  title,
  iconName,
  iconColourName,
  metric,
  withColour,
  siteOptions,
  showSite,
  showTrends,
  isAgrisoundAdmin,
}) {
  if (!device) {
    return <></>;
  }
  if (cardType === 'overview-lite') {
    const titleText =
      device.deviceName && device.deviceId !== device.deviceName
        ? `${device.deviceName} (${device.deviceId})`
        : device.deviceId;
    return (
      <PollyDeviceOverviewLiteWidget
        deviceStats={deviceStats}
        title={<Highlight text={titleText} />}
        device={device}
        showSite={showSite}
        showTrends={showTrends}
      />
    );
  } else if (cardType === 'overview') {
    return (
      <PollyDeviceOverviewWidget
        lineToHighlight={lineToHighlight}
        title={title || `Device ${device.deviceId}`}
        // title={<Highlight text={device.deviceId} />}
        device={device}
        activity={10}
        movementText={'16%'}
        durationText={'durationText'}
        movementDirection={'up'}
        iconName={iconName || 'temperature'}
        iconColourName={iconColourName || 'orange'}
        showIcon={true}
      />
    );
  } else {
    return (
      <PollyDeviceCard
        metric={metric}
        device={device}
        deviceId={device.deviceId}
        gatewayDeviceId={device.gatewayDeviceId}
        scaleDeviceId={device.scaleDeviceId}
        withColour={withColour}
        siteOptions={siteOptions}
        isAgrisoundAdmin={false}
      />
    );
  }
}

function getRows(devices, metric) {
  let rows = [];
  for (let device of devices) {
    const deviceRows = getDeviceRows(device, metric);
    rows = rows.concat(deviceRows);
  }

  return rows;
}

function getDeviceRows(device, metric) {
  let rows = [];
  // if (device.latestEvent && device.latestEvent.temperature) {
  //   let converter = (value) => getTemperature(value, metric);
  //   let row = getRow({ device, dataType: 'temperature', converter });
  //   rows.push(row);
  // }
  // if (device.latestEvent && device.latestEvent.humidity) {
  //   let converter = (value) => value;
  //   let row = getRow({ device, dataType: 'humidity', converter });
  //   rows.push(row);
  // }

  if (device.latestEvent && device.latestEvent.weight) {
    let converter = (value) => (device.deviceType === 'agsenzeScaleOne' ? value : convertAdcToKg(value));
    let row = getRow({ device, dataType: 'weight', converter });
    rows.push(row);
  }

  return rows;
}

function getRow({ device, dataType, converter }) {
  let row = getEmptyRow(device);

  row.id = device.deviceId + '-' + dataType;
  row.dataType = dataType;
  row.valueToday = converter(device.latestEvent[dataType]);
  row._1dayDiff = getDiff({ row, deltaReport: device.deltaReport, fieldName: '_1day', converter, dataType });
  row._2dayDiff = getDiff({ row, deltaReport: device.deltaReport, fieldName: '_2days', converter, dataType });
  row._3dayDiff = getDiff({ row, deltaReport: device.deltaReport, fieldName: '_3days', converter, dataType });
  row._4dayDiff = getDiff({ row, deltaReport: device.deltaReport, fieldName: '_4days', converter, dataType });
  row._5dayDiff = getDiff({ row, deltaReport: device.deltaReport, fieldName: '_5days', converter, dataType });
  row._6dayDiff = getDiff({ row, deltaReport: device.deltaReport, fieldName: '_6days', converter, dataType });
  row._7dayDiff = getDiff({ row, deltaReport: device.deltaReport, fieldName: '_7days', converter, dataType });
  // prettier-ignore
  row._30dayDiff = getDiff({row, deltaReport: device.deltaReport, fieldName: '_30days', converter, dataType,});
  row.valid = device.latestEvent.dateCreated;

  return row;
}

function getDiff({ row, deltaReport, fieldName, converter, dataType }) {
  return deltaReport && deltaReport[fieldName] && deltaReport[fieldName].dateCreated
    ? row.valueToday - converter(deltaReport[fieldName][dataType])
    : 'n/a';
}

function getEmptyRow(device) {
  return {
    id: 0,
    deviceName: device.deviceName,
    dataType: '',
    valueToday: 0,
    _1dayDiff: 0,
    _7dayDiff: 0,
    _30dayDiff: 0,
  };
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
