import React, { useEffect, useState } from 'react';
import { useFirestore } from 'react-redux-firebase';
import { auth } from '../../../firebase';
import { addRequestToCollection } from '../../../api/adminFunctions';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';

export default function CreateProject({ classes, projectIds }) {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const [newProjectId, setNewProjectId] = useState('polly-');
  const [newProjectName, setNewProjectName] = useState('Polly ');
  const [isEquinix, setIsEquinix] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [validationMessages, setValidationMessages] = useState(['placeholder']);
  const firestore = useFirestore();
  const collectionName = 'uiRequests';
  const actionType = 'createNewProjectNoDevices';
  const validProjectIdPrefixes = ['polly-', 'beekeeper-'];

  const isValidProjectIdPrefix = (projectId) => {
    for (let validPrefix of validProjectIdPrefixes) {
      if (projectId.startsWith(validPrefix)) {
        return true;
      }
    }
    return false;
  };
  const validate = () => {
    const messages = [];
    if (!newProjectId) {
      messages.push('Please enter a new ProjectId (eg polly-jones or beekeeper-jones)');
    }
    if (!newProjectName) {
      messages.push('Please enter a new ProjectName (eg Polly Jones)');
    }
    if (newProjectId && /\s/.test(newProjectId)) {
      messages.push('No spaces allowed in the new ProjectId');
    }
    if (newProjectId && !isValidProjectIdPrefix(newProjectId)) {
      messages.push('A projectId must begin with polly- or beekeeper-');
    }
    if (newProjectId && projectIds.includes(newProjectId)) {
      messages.push(`${newProjectId} already exists`);
    }
    setValidationMessages(messages);
  };

  useEffect(() => {
    validate();
  }, [newProjectId, newProjectName]);

  const createProjectAction = () => {
    const performAction = true;
    setOpenConfirm(false);
    if (!performAction) {
      setMessage(`${newProjectId} successfully created`);
      setLoading(false);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      return;
    }

    const uid = auth.currentUser && auth.currentUser.uid;
    let request = {
      projectId: newProjectId,
      projectName: newProjectName,
      type: actionType,
      userId: uid,
      dateCreated: new Date(),
    };
    setLoading(true);

    addRequestToCollection({
      request,
      collectionName,
      firestore,
      setMessage,
      setLoading,
      setOpenConfirm,
    });
  };

  const defaultProjectSelectProps = {
    options: projectIds,
    getOptionLabel: (option) => option,
  };

  return (
    <Grid container spacing={0}>
      <Grid container item xs={12}>
        <FormControl className={classes.formControl}>
          <TextField
            value={newProjectId}
            placeholder="newProjectId"
            onChange={(e) => {
              setNewProjectId(e.target.value);
              validate();
            }}
          />
          <FormHelperText>New Project ID (no spaces)</FormHelperText>
        </FormControl>
      </Grid>
      <Grid container item xs={12}>
        <FormControl className={classes.formControl}>
          <TextField
            value={newProjectName}
            placeholder="newProjectName"
            onChange={(e) => {
              setNewProjectName(e.target.value);
              validate();
            }}
          />
          <FormHelperText>New Project Name</FormHelperText>
        </FormControl>
      </Grid>
      <Grid container item xs={12}>
        <FormControl className={classes.formControl}>
          <Checkbox
            checked={isEquinix}
            value={isEquinix}
            onChange={(e) => {
              setIsEquinix(!isEquinix);
              validate();
            }}
          />
          <FormHelperText>Is Equinix?</FormHelperText>
        </FormControl>
      </Grid>
      {validationMessages.length > 0 && (
        <Grid container item xs={12}>
          <FormControl className={classes.formControl}>
            <div>
              {validationMessages.map((message) => {
                return (
                  <div key={message} style={{ color: 'red' }}>
                    {message}
                  </div>
                );
              })}
            </div>
          </FormControl>
        </Grid>
      )}
      <Grid container item xs={12}>
        <FormControl className={classes.formControl}>
          <Grid container spacing={0}>
            <Grid container item xs={6}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => setOpenConfirm(true)}
                disabled={validationMessages.length > 0}
              >
                Create New Project
              </Button>
              <Dialog
                open={openConfirm}
                onClose={() => setOpenConfirm(false)}
                aria-labelledby="responsive-dialog-title"
              >
                <DialogTitle id="responsive-dialog-title">{'Create project?'}</DialogTitle>
                <DialogContent dividers>
                  <Typography gutterBottom>
                    Are you sure you want add create this Project?
                  </Typography>
                  <br />
                  <Typography gutterBottom>ProjectId: {newProjectId}</Typography>
                  <Typography gutterBottom>ProjectName: {newProjectName}</Typography>
                </DialogContent>
                <DialogActions>
                  <Button autoFocus onClick={() => setOpenConfirm(false)} color="primary">
                    No
                  </Button>
                  <Button onClick={createProjectAction} color="primary" autoFocus>
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
            <Grid container item xs={6}>
              {loading && <CircularProgress className={classes.submitButton} />}
              {message && (
                <div style={{ color: 'blue', fontSize: '16px', fontWeight: 'bold' }}>{message}</div>
              )}
            </Grid>
          </Grid>
        </FormControl>
      </Grid>
    </Grid>
  );
}
