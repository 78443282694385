import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Fab, Box } from '@material-ui/core';
import { Home as HomeIcon } from '@material-ui/icons';
import EmptyState from '../EmptyState';
import AgriSoundImage from '../../../assets/AgriSoundLinkedIn.jpg';
import { ReactComponent as NotFoundIllustration } from '../../../illustrations/not-found.svg';
import { useSelector } from 'react-redux';

function SignIn({ user }) {
  const image = <img src={AgriSoundImage} height={200} width={200} alt={'Logo'} />;

  // const hasAuth = useSelector((state) => state.appConfig.hasAuth);
  //
  // if (hasAuth) {
  //   return <Redirect to={'/'} />;
  // }

  return (
    <EmptyState
      image={image}
      title="You do not have access to any AgriSound projects"
      description="Please request an invite to a project by a project admin."
      // button={
      //   <Fab variant="extended" color="primary" component={Link} to="/">
      //     <Box clone mr={1}>
      //       <HomeIcon />
      //     </Box>
      //     Home
      //   </Fab>
      // }
    />
  );
}

export default SignIn;
