import React, { useState } from 'react';
import { log } from '../../../util/log-util';
import PropTypes from 'prop-types';
import { BrowserRouter, Switch, Redirect, Route, Link, useHistory } from 'react-router-dom';
import PrivateRoute from '../PrivateRoute';
import HomePage from '../HomePage';
import AdminPage from '../../common/Admin';
import UserPage from '../UserPage';
import NotFoundPage from '../NotFoundPage';
import FieldOverviewPage from '../FieldOverviewPage';
import DeviceManagement from '../DeviceManagement';
import Device from '../Device';
import ProjectPage from '../Project/ProjectPage';
import HivePage from '../Hive/HivePage';
import UserManagement from '../UserManagement';
import HivesOverview from '../HiveOverview/HivesOverview';
import DashboardBeekeeper from '../Dashboard/DashboardBeekeeper2';
import KnowledgeBaseSummaryPage from '../KnowledgeBase/KnowledgeBaseSummaryPage';
import KnowledgeBaseArticlePage from '../KnowledgeBase/KnowledgeBaseArticlePage';
import CmsWidget from '../Widgets/CmsWidget';
import NewsArticlePage from '../KnowledgeBase/NewsArticlePage';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box, Divider, Typography } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import { useSelector } from 'react-redux';
import AlertPage from '../Alerts/AlertPage';
import AlertsPage from '../Alerts/AlertsPage';
import NewsListPage from '../News/NewsListPage';
import ContactPage from '../Contact/ContactPage';
import { getMenuItems } from '../../../util/menu-util';
import PollyCanterbury from '../../polly/Experimental/PollyCanterbury';
import PollyDashboard from '../../polly/Experimental/PollyDashboard';
import InventoryPage from '../../common/Admin/InventoryPage';
import { getIsAgrisoundAdmin, getIsBardsleyUser } from '../../../util/user-util';
import PollyBusinessOverview from '../../polly/Experimental/PollyBusinessOverview';
import PollyBusinessOverviewSingleSpecies from '../../polly/Experimental/PollyBusinessOverviewSingleSpecies';
import PollyDevicesOverview from '../../polly/Devices/PollyDevicesOverview';
import PollyDeviceEditPage from '../../polly/Devices/PollyDeviceEditPage';
import PollyMapPage from '../../polly/Maps/PollyMapPage';
import PollinatorChartPage from '../../polly/Charts/PollinatorChartPage';
import ListItemIcon from '@mui/material/ListItemIcon';
import HomeIcon from '@material-ui/icons/Home';
import SettingsIcon from '@material-ui/icons/Settings';
import DevicesIcon from '@material-ui/icons/Devices';
import MapIcon from '@material-ui/icons/Map';
import AssessmentIcon from '@material-ui/icons/Assessment';
//TODO: do we need PollySitesOverview2 and PollySitesOverview
import PollySitesOverview from '../../polly/Sites/PollySitesOverview2';
import PollyDeviceOverviewPage from '../../polly/Devices/PollyDeviceOverviewPage';
import AgrisoundLogo from '../AgrisoundLogo/AgrisoundLogo';
import { accountTypes } from '../../../config/accountTypes';
import { accountTypesNewStyle, barColour, showMenuItemIcon } from '../../../reducers/app-config';
import { NoteTable } from '../../polly/Notes/NoteTable';
import DataStudioReport from '../../polly/DataStudio/DataStudioReport';
import BlogPage from '../Contact/BlogPage';
import ForbiddenPage from '../PrivateRoute/ForbiddenPage';

const iconMap = {
  HomeIcon: <HomeIcon />,
  SettingsIcon: <SettingsIcon />,
  DevicesIcon: <DevicesIcon />,
  MapIcon: <MapIcon />,
  AssessmentIcon: <AssessmentIcon />,
};

Router.propTypes = {
  user: PropTypes.object,
  roles: PropTypes.array.isRequired,
  bar: PropTypes.element,
  openSnackbar: PropTypes.func.isRequired,
};

const drawerWidth = 250;
const breakpoint = 'md';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up(breakpoint)]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    [theme.breakpoints.up(breakpoint)]: {
      // width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    backgroundColor: 'red',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(breakpoint)]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
}));

const agriSoundOrange = 'white';
/*
  Collapsible side menu example: https://codesandbox.io/s/31wwxmz9x6?file=/src/Main.js:1139-1170
 */
export default function Router({ user, roles, bar, openSnackbar }) {
  // log('Router', user);
  // const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const currentProject = useSelector((state) => state.appConfig.currentProject);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const userData = useSelector((state) => state.appConfig.userData);
  const isAgrisoundAdmin = getIsAgrisoundAdmin(userData);
  const isCanterbury = getIsBardsleyUser(userData);
  // const userRole = getSelectedProjectUserRole(userData);
  // const userRoles = [userRole];
  const userRoles = (currentProject && currentProject.userRoles) || [];
  const accountType = (currentProject && currentProject.accountType) || 'none';

  const menuItems = getMenuItems({
    accountType,
    isAgrisoundAdmin,
    isCanterbury,
    userRoles,
  });

  const sideMenu = getSideMenu({ accountType, menuItems, setMobileOpen });
  const drawer = getDrawer({ accountType, classes, sideMenu });

  const container = window !== undefined ? () => window.document.body : undefined;
  const routes = (
    <Switch>
      <Route path="/forbidden" exact component={() => <ForbiddenPage />} />
      <PrivateRoute
        path="/field-overview-heatmap"
        exact
        component={() => <FieldOverviewPage type={'heatmap'} />}
      />
      <PrivateRoute path="/kb-widget" exact component={() => <CmsWidget />} />
      <PrivateRoute
        path="/field-overview-circles"
        exact
        component={() => <FieldOverviewPage type={'circles'} />}
      />
      <PrivateRoute path="/admin" exact component={() => <AdminPage />} />
      <PrivateRoute path="/inventory" exact component={() => <InventoryPage />} />
      <PrivateRoute path="/canterbury" exact component={() => <PollyCanterbury />} />
      <PrivateRoute path="/polly-dashboard" exact component={() => <PollyDashboard />} />
      <PrivateRoute path="/hives-overview" exact component={() => <HivesOverview />} />
      <PrivateRoute path="/kb" exact component={() => <KnowledgeBaseSummaryPage />} />
      <PrivateRoute path="/kb/:articleId" exact component={() => <KnowledgeBaseArticlePage />} />
      <PrivateRoute path="/alerts" exact component={() => <AlertsPage />} />
      <PrivateRoute path="/reports" exact component={() => <DataStudioReport />} />
      <PrivateRoute path="/alerts/:alertId" exact component={() => <AlertPage />} />
      <PrivateRoute path="/news" exact component={() => <NewsListPage />} />
      <PrivateRoute path="/news/:articleId" exact component={() => <NewsArticlePage />} />
      <PrivateRoute path="/dashboard-beekeeper" exact component={() => <DashboardBeekeeper />} />
      <PrivateRoute path="/user-management" exact component={UserManagement} />
      <PrivateRoute path="/device-management" exact component={DeviceManagement} />
      <PrivateRoute path="/devices/:deviceId" exact component={Device} />
      <PrivateRoute path="/polly-devices" exact component={PollyDevicesOverview} />
      <PrivateRoute path="/polly-devices-edit/:deviceId" exact component={PollyDeviceEditPage} />
      <PrivateRoute path="/polly-devices-overview/:deviceId" exact component={PollyDeviceOverviewPage} />
      <PrivateRoute path="/projects" exact component={ProjectPage} />
      <PrivateRoute path="/contact" exact component={ContactPage} />
      <PrivateRoute path="/blog" exact component={BlogPage} />
      <PrivateRoute path="/hives/:deviceId" exact component={HivePage} />
      <PrivateRoute path="/users/:userId" exact component={() => <UserPage user={user} />} />
      <PrivateRoute path="/polly-map" exact component={() => <PollyMapPage user={user} />} />
      <PrivateRoute path="/polly-chart" exact component={() => <PollinatorChartPage user={user} />} />
      <PrivateRoute path="/business-overview" exact component={PollyBusinessOverview} />
      <PrivateRoute path="/yield-notes" exact component={NoteTable} />
      <PrivateRoute path="/project-overview" exact component={PollySitesOverview} />
      <PrivateRoute path="/business-overview-single" exact component={PollyBusinessOverviewSingleSpecies} />
      <Route path="/" exact component={() => <HomePage user={user} />} />
      <Route>
        <NotFoundPage />
      </Route>
    </Switch>
  );

  return (
    <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar className={classes.appBar} style={{ height: 0 }}>
          <Toolbar
            style={{
              width: '300px',
              backgroundColor: accountType === accountTypes.oldBeekeeper ? barColour : undefined,
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            {bar}
          </Toolbar>
        </AppBar>
        <Nav
          classes={classes}
          container={container}
          theme={theme}
          handleDrawerToggle={handleDrawerToggle}
          mobileOpen={mobileOpen}
          drawer={drawer}
        />
        <MainContent classes={classes} content={routes} accountType={accountType} />
      </div>
    </BrowserRouter>
  );
}

function Nav({ classes, container, theme, handleDrawerToggle, mobileOpen, drawer }) {
  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden mdUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          // style={{ backgroundColor: 'red' }}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          // style={{ backgroundColor: 'red' }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
}

function MainContent({ classes, content, accountType }) {
  return (
    <>
      <Hidden mdUp implementation="css">
        <div style={{ backgroundColor: 'orange', height: 65, opacity: '92%' }}></div>
        <main className={classes.content}>
          {accountType === accountTypes.oldBeekeeper && <div className={classes.toolbar} />}
          {content}
        </main>
      </Hidden>
      <Hidden smDown implementation="css">
        <main className={classes.content}>
          {accountType === accountTypes.oldBeekeeper && <div className={classes.toolbar} />}
          {content}
        </main>
      </Hidden>
    </>
  );
}

function getDrawer({ accountType, classes, sideMenu }) {
  return (
    <div style={{ backgroundColor: agriSoundOrange, paddingTop: 15, paddingBottom: 0 }}>
      <Box display="flex" flexGrow={1}>
        <div style={{ marginLeft: 5, marginRight: 5, marginBottom: 20 }}>
          <AgrisoundLogo imageFilePath={'../polly/polly-assets/AgriSound2022.png'} />
        </div>
        <Typography color="inherit" variant="h5" style={{ marginTop: 5, marginLeft: 0 }}>
          {process.env.REACT_APP_TITLE}
        </Typography>
      </Box>
      {sideMenu}
    </div>
  );

  // return (
  //   <div style={{ backgroundColor: barColour }}>
  //     <div className={classes.toolbar} />
  //     {sideMenu}
  //   </div>
  // );
}

function getSideMenu({ accountType, menuItems, setMobileOpen }) {
  if (accountTypesNewStyle.includes(accountType)) {
    return <PollySideMenu menuItems={menuItems} setMobileOpen={setMobileOpen} />;
  }
  // else if (accountType === accountTypes.beekeeper) {
  //   return <BeekeeperSideMenu menuItems={menuItems} setMobileOpen={setMobileOpen} />;
  // }
}

function PollySideMenu({ menuItems, setMobileOpen }) {
  const [menuItemClicked, setMenuItemClicked] = useState('home');
  if (menuItems && menuItems.length === 0) {
    return <></>;
  }
  // console.log('menuItems: ', menuItems);
  return (
    <MenuList style={{ backgroundColor: agriSoundOrange, marginTop: '-10px' }}>
      {menuItems.map((menuItemConfig, index) => {
        let key = menuItemConfig.key || index;
        if (menuItemConfig.hasOwnProperty('condition') && !menuItemConfig.condition) {
          return null;
        }

        let component = null;
        // const color1 = '#e6f2ff';
        // const color2 = '#0080ff';
        const color1 = '#e6f2ff';
        const color2 = 'orange';

        // if (menuItemConfig.externalTo) {
        //   component = (
        //     <MenuItem key={key}>
        //       <Link path={menuItemConfig.externalTo} text={menuItemConfig.name} bold={true} />
        //     </MenuItem>
        //   );
        // }
        //
        if (menuItemConfig.to) {
          component = (
            <MenuItem
              key={key}
              component={Link}
              to={menuItemConfig.to}
              onClick={() => {
                setMobileOpen(false);
                setMenuItemClicked(menuItemConfig.key);
              }}
              style={{ backgroundColor: menuItemClicked === menuItemConfig.key ? color1 : undefined }}
            >
              {showMenuItemIcon && (
                <ListItemIcon style={{ color: menuItemClicked === menuItemConfig.key ? color2 : undefined }}>
                  {menuItemConfig.icon ? iconMap[menuItemConfig.icon] : <HomeIcon />}
                </ListItemIcon>
              )}
              <div style={{ color: menuItemClicked === menuItemConfig.key ? color2 : undefined }}>
                {menuItemConfig.name}
              </div>
            </MenuItem>
          );
        } else if (menuItemConfig.onClick) {
          component = (
            <MenuItem
              key={key}
              onClick={() => {
                // closeMenu();
                menuItemConfig.onClick();
              }}
            >
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              {menuItemConfig.name}
            </MenuItem>
          );
        }

        if (menuItemConfig.divide) {
          return (
            <span key={'divider' + key}>
              {component}
              <Divider style={{ backgroundColor: agriSoundOrange }} />
            </span>
          );
        }

        return component;
      })}
    </MenuList>
  );
}

function BeekeeperSideMenu({ menuItems, setMobileOpen }) {
  if (menuItems && menuItems.length === 0) {
    return <></>;
  }
  return (
    <MenuList style={{ backgroundColor: '#F8F8F8' }}>
      {menuItems.map((menuItemConfig, index) => {
        let key = menuItemConfig.key || index;
        if (menuItemConfig.hasOwnProperty('condition') && !menuItemConfig.condition) {
          return null;
        }

        let component = null;

        if (menuItemConfig.to) {
          component = (
            <MenuItem key={key} component={Link} to={menuItemConfig.to} onClick={() => setMobileOpen(false)}>
              {menuItemConfig.name}
            </MenuItem>
          );
        } else if (menuItemConfig.onClick) {
          component = (
            <MenuItem
              key={key}
              onClick={() => {
                // closeMenu();
                menuItemConfig.onClick();
              }}
            >
              {menuItemConfig.name}
            </MenuItem>
          );
        }

        if (menuItemConfig.divide) {
          return (
            <span key={'divider' + key}>
              {component}
              <Divider style={{ backgroundColor: '#E8E8E8' }} />
            </span>
          );
        }

        return component;
      })}
    </MenuList>
  );
}
