// import React from 'react';

export const eventsColumns = [
  // { field: 'deviceType', headerName: 'DataOverview Type', width: 130 },
  {
    field: 'deviceName',
    headerName: 'Device',
    width: 150,
    cellClassName: 'cell-small',
    headerClassName: 'cell-small',
    flex: 1,
  },
  // {
  //   field: 'lastUpdated',
  //   headerName: 'Last Updated',
  //   width: 150,
  //   cellClassName: 'cell-small',
  //   headerClassName: 'cell-small',
  //   renderCell: (params) => {
  //     const diff = differenceInDays(new Date(), params.row.lastUpdatedDt);
  //     if (diff > 1) {
  //       return <div style={{ color: 'red' }}>{params.row.lastUpdated}</div>;
  //     }
  //     return <div>{params.row.lastUpdated}</div>;
  //   },
  // },
  {
    field: 'dataType',
    headerName: 'Type',
    width: 80,
    cellClassName: 'cell-small',
    headerClassName: 'cell-small',
    flex: 1,
    // renderCell: (params) => {
    //   console.log('rows', params);
    //   return <div>{params.row.dataType}</div>;
    // },
    // valueFormatter: (params) => JSON.stringify(params.getValue('location')),
  },
  {
    field: 'valueToday',
    headerName: 'Today',
    width: 200,
    cellClassName: 'cell-small',
    headerClassName: 'cell-small',
    flex: 1,
    // renderCell: (params) => {
    //   return <div>{params.row.valueToday}</div>;
    // },
    // valueFormatter: (params) => JSON.stringify(params.getValue('location')),
  },
  {
    field: '_1dayDiff',
    headerName: '1 day diff',
    width: 150,
    type: 'number',
    cellClassName: 'cell-small',
    headerClassName: 'cell-small',
    flex: 1,
    // renderCell: (params) => {
    //   return <div>{params.row._1dayDiff}</div>;
    // },
  },
  {
    field: '_2dayDiff',
    headerName: '2 days',
    width: 150,
    type: 'number',
    cellClassName: 'cell-small',
    headerClassName: 'cell-small',
    flex: 1,
  },
  {
    field: '_3dayDiff',
    headerName: '3 days',
    width: 150,
    type: 'number',
    cellClassName: 'cell-small',
    headerClassName: 'cell-small',
    flex: 1,
  },
  {
    field: '_4dayDiff',
    headerName: '4 days',
    width: 150,
    type: 'number',
    cellClassName: 'cell-small',
    headerClassName: 'cell-small',
    flex: 1,
  },
  {
    field: '_5dayDiff',
    headerName: '5 days',
    width: 150,
    type: 'number',
    cellClassName: 'cell-small',
    headerClassName: 'cell-small',
    flex: 1,
  },
  {
    field: '_6dayDiff',
    headerName: '6 days',
    width: 150,
    type: 'number',
    cellClassName: 'cell-small',
    headerClassName: 'cell-small',
    flex: 1,
  },
  {
    field: '_7dayDiff',
    headerName: '7 days',
    width: 150,
    type: 'number',
    cellClassName: 'cell-small',
    headerClassName: 'cell-small',
    flex: 1,
  },
  {
    field: '_30dayDiff',
    headerName: '30 days',
    width: 150,
    type: 'number',
    cellClassName: 'cell-small',
    headerClassName: 'cell-small',
    flex: 1,
    // renderCell: (params) => {
    //   return <div>{params.row._30dayDiff}</div>;
    // },
  },
];
