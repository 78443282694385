export const projectIdFlower = 'pollen-net-test-C-100';
const useUserProjectSettings = true;
export function getFlowerProjectIds(userData) {
  return [projectIdFlower];
}

export function getSelectedProject(userData) {
  return userData.projects.find((project) => project.projectId === userData.selectedProjectId);
}

export function getSelectedProjectId(userData) {
  return userData && userData.selectedProjectId;
}

export function getSelectedSiteName(userData) {
  return userData && userData.selectedSiteName;
}

export function getSelectedProjectUserRole(userData) {
  return (userData && userData.selectedProjectUserRole) || 'agriSoundAdmin';
}

export function isProjectAdmin(userData) {
  const project = userData.projects.find(
    (project) => project.projectId === userData.selectedProjectId
  );
  return project && project.userRoles.includes('admin');
}

export function getProjectIds(userData) {
  if (useUserProjectSettings) {
    const projectId = userData && userData.selectedProjectId;
    return projectId ? [projectId] : ['none'];
  }

  return ['none'];
}
