import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  ButtonGroup,
  Button,
  IconButton,
  Divider,
  Menu,
  MenuItem, Grid
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UserAvatar from '../UserAvatar';
import { useSelector } from 'react-redux';
import AgrisoundLogo from '../AgrisoundLogo/AgrisoundLogo';
import { getProfileMenuItems } from './barUtil';
import BarMenu from './BarMenu';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));
Bar.defaultProps = {
  performingAction: false,
};

Bar.propTypes = {
  // Properties
  performingAction: PropTypes.bool.isRequired,
  user: PropTypes.object,
  userData: PropTypes.object,

  // Events
  onAboutClick: PropTypes.func.isRequired,
  onSettingsClick: PropTypes.func.isRequired,
  onSignOutClick: PropTypes.func.isRequired,
};

export default function Bar({
  performingAction,
  user,
  userData,
  roles,
  onAboutClick,
  onSettingsClick,
  onSignOutClick,
  onSignUpClick,
  onSignInClick,
}) {
  const classes = useStyles();
  const initialMenu = { anchorEl: null };
  const [menu, setMenu] = useState(initialMenu);

  const openMenu = (event) => {
    setMenu({ anchorEl: event.currentTarget });
  };

  const closeMenu = () => {
    setMenu({ anchorEl: null });
  };

  const menuItems = getProfileMenuItems({
    onAboutClick,
    user,
    onSettingsClick,
    onSignOutClick,
  });

  return (
    <Grid container justify="flex-end">
      {user && (
        <>
          {roles.includes('admin') && (
            <Box mr={1}>
              <Button color="inherit" component={Link} to="/admin" variant="outlined">
                Admin
              </Button>
            </Box>
          )}
          {/*<RoleSelect />*/}
          <IconButton color="inherit" disabled={performingAction} onClick={openMenu} style={{ marginTop: 50 }}>
            {/*there was an error here Object.assign(user, userData)*/}
            <UserAvatar user={userData} />
          </IconButton>

          <BarMenu menuItems={menuItems} menu={menu} closeMenu={closeMenu} />
        </>
      )}

      {!user && (
        <ButtonGroup color="inherit" disabled={performingAction} variant="outlined">
          <Button onClick={onSignUpClick}>Sign up</Button>
          <Button onClick={onSignInClick}>Sign in</Button>
        </ButtonGroup>
      )}
    </Grid>
  );

  // return (
  //   <>
  //     {/*<CmsBannerAlert />*/}
  //     {/*<AppBar position="static" style={{ backgroundColor: '#ececec' }}>*/}
  //     <>
  //       {/*<IconButton*/}
  //       {/*  edge="start"*/}
  //       {/*  className={classes.menuButton}*/}
  //       {/*  color="inherit"*/}
  //       {/*  aria-label="menu"*/}
  //       {/*  onClick={openBurgerMenu}*/}
  //       {/*>*/}
  //       {/*  <MenuIcon />*/}
  //       {/*</IconButton>*/}
  //       {/*<BarMenu menuItems={burgerMenuItems} menu={burgerMenu} closeMenu={closeBurgerMenu} />*/}
  //       <Box display="flex" flexGrow={1}>
  //         <div style={{ marginRight: 10 }}>
  //           <AgrisoundLogo />
  //         </div>
  //         <Typography color="inherit" variant="h6">
  //           {process.env.REACT_APP_TITLE}
  //         </Typography>
  //       </Box>
  //
  //       {user && (
  //         <>
  //           {roles.includes('admin') && (
  //             <Box mr={1}>
  //               <Button color="inherit" component={Link} to="/admin" variant="outlined">
  //                 Admin
  //               </Button>
  //             </Box>
  //           )}
  //
  //           <IconButton color="inherit" disabled={performingAction} onClick={openMenu}>
  //             {/*there was an error here Object.assign(user, userData)*/}
  //             <UserAvatar user={userData} />
  //           </IconButton>
  //
  //           <BarMenu menuItems={menuItems} menu={menu} closeMenu={closeMenu} />
  //         </>
  //       )}
  //
  //       {!user && (
  //         <ButtonGroup color="inherit" disabled={performingAction} variant="outlined">
  //           <Button onClick={onSignUpClick}>Sign up</Button>
  //           <Button onClick={onSignInClick}>Sign in</Button>
  //         </ButtonGroup>
  //       )}
  //     </>
  //     {/*</AppBar>*/}
  //   </>
  // );
}
