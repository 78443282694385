import React, { useState } from 'react';
import { useFirestore } from 'react-redux-firebase';
import { Button, Grid, CircularProgress } from '@mui/material';
import { addImageToStorage } from './share';
import FileInput from './CustomFileInput';
import { format } from 'date-fns';

export const AddImageForm = ({ deviceId }) => {
  const firestore = useFirestore();
  const [noteFormFilters, setNoteFormFilters] = useState({
    text: '',
  });
  const [error, setError] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(false);

  const onTextAreaChange = (e) => {
    const { value } = e.target;
    setNoteFormFilters({ ...noteFormFilters, text: value });
  };

  const handlePhotoChange = (files) => {
    setPhotos([...photos, ...files]);
  };

  const clearNoteForm = () => {
    setNoteFormFilters({
      text: '',
    });
    setPhotos([]);
  };

  const getFileExtension = (fileName) => {
    return fileName.slice(fileName.lastIndexOf('.') + 1);
  };
  const getNewFileName = ({ fileName, index }) => {
    return `${deviceId}-${format(new Date(), 'dd MMM hh:mm')}-${index}.${getFileExtension(
      fileName
    )}`;
  };

  const addNotesData = (e) => {
    e.preventDefault();
    const imgPath = `deviceImages/${deviceId}/`;
    setLoading(true);
    if (photos.length <= 5) {
      try {
        Promise.all(
          photos.map((img) => {
            return addImageToStorage({ img, imgPath });
          })
        ).then((images) => {
          for (let image of images) {
            firestore.doc(`devices/${deviceId}`).update({
              [`photos.${image.id}`]: image,
            });
          }

          clearNoteForm();
          setLoading(false);
          setError(false);
        });
      } catch (err) {
        console.log('addNotesData.err', err);
        setError(true);
        setLoading(false);
      }
    } else {
      setError(true);
      setLoading(false);
      setPhotos([]);
    }
  };

  return (
    <form style={{ padding: '20px' }} onSubmit={addNotesData}>
      <Grid container spacing={2}>
        {/*<Grid item xs={12}>*/}
        {/*  <TextareaAutosize*/}
        {/*    aria-label="minimum height"*/}
        {/*    value={noteFormFilters.text}*/}
        {/*    minRows={3}*/}
        {/*    placeholder="Note..."*/}
        {/*    style={{ width: '100%', margin: '0px' }}*/}
        {/*    onChange={onTextAreaChange}*/}
        {/*    data-testid="note-text"*/}
        {/*  />*/}
        {/*</Grid>*/}
        <Grid item xs={12}>
          <FileInput
            title={'Click to add photo'}
            tooltipText={'Click to add photo'}
            accept="image/*"
            id="imgs"
            onChange={handlePhotoChange}
            name="upload-photo"
            type="file"
            multiple
            // ref={imgRef}
            value={photos}
            data-testid="photos"
          />
          {error && <span style={{ color: 'red' }}>Images Must Be Less than or Equal to 5</span>}
        </Grid>
        <Grid item xs={12}>
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <Button type={'submit'} variant={'contained'} disabled={photos.length === 0}>
                Submit Photo(s) (5 max)
              </Button>
              {photos.length > 0 && (
                <Button
                  style={{ marginLeft: '4px' }}
                  onClick={clearNoteForm}
                  disabled={photos.length === 0}
                >
                  Remove photos
                </Button>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </form>
  );
};
