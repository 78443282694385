import * as React from 'react';
import lodash from 'lodash';
import { Circle } from '@react-google-maps/api';
import { getDateNoTime } from '../util/date-util';
import { deviceTypes } from '../config/deviceTypes';
const { cloneDeep, transform, isEqual, isObject, round, isNumber } = lodash;
/*
  Values from "BioBee Calibration and conversion formula" google sheet
 */
// const M_gradient = 0.00008442159749;
// const conversionCoefficient = -1.363274275;
const M_gradient = 0.0030075188;
const conversionCoefficient = -23.56;
export function convertAdcToKg(adcReading) {
  // console.log('adcReading', adcReading, adcReading * M_gradient);
  if (adcReading === 'n/a') {
    return adcReading;
  }

  return round(adcReading * M_gradient + conversionCoefficient, 2);
}
export function convertFirestoreCollectionToArray({
  collectionData,
  convertDates = false,
  collectionName,
  sortByField,
}) {
  if (!collectionData) {
    return [];
  }
  let data = []; //Object.values(collectionData || []);
  let keys = Object.keys(collectionData) || [];
  for (let key of keys) {
    if (convertDates && collectionName) {
      const dateFieldNames = getDateFieldNames(collectionName);
      const entry = { ...collectionData[key] };
      for (let dateField of dateFieldNames) {
        entry[dateField] = convertDate(entry[dateField]);
      }
      data.push({ ...entry, id: key });
    } else {
      data.push({ ...collectionData[key], id: key });
    }
  }
  if (sortByField) {
    data = data.sort((a, b) => b[sortByField] - a[sortByField]);
  }

  return data;
}

function getDateFieldNames(collectionName) {
  switch (collectionName) {
    case 'alerts':
      return ['dateCreated'];
    default:
      return ['dateCreated'];
  }
}
export function addIdToTableData(tableData) {
  if (!tableData) {
    return;
  }
  for (let i = 0; i < tableData.length; i++) {
    let entry = tableData[i];
    if (!entry.id) {
      entry.id = i;
    }
  }
}

export async function getEvents(firestore) {
  // const date = firestore.Timestamp.fromDate(new Date("2020-12-20"));
  const minutes = 6 * 60;
  const date = new Date(Date.now() - minutes * 60 * 1000);
  const snapshot = await firestore
    .collection('events')
    .where('dateCreated', '>', date)
    .orderBy('dateCreated', 'desc')
    .get();
  let data = [];
  snapshot.docs.map((doc) => data.push(doc.data()));
  let index = 0;
  for (let entry of data) {
    if (entry && entry.dateCreated) {
      const dateCreated = convertDate(entry.dateCreated);
      entry.dateCreated = dateCreated;
      entry.dateOnly = getDateNoTime(dateCreated);
      entry.dateCreatedDb = convertDate(entry.dateCreatedDb);
      entry.id = index;
      index++;
    }
  }
  return data;
}

export function whereClause(fieldName, operator, value) {
  return [fieldName, operator, value];
}

export function convertDate(dt, event, entry) {
  if (!dt) {
    return undefined;
  }
  if (dt instanceof Date) {
    return dt;
  }

  // if(typeof dt.toDate === 'function') {
  //   return dt.toDate();
  // }
  //
  let date = new Date();
  // let date = dt.toDate();
  try {
    date = dt.toDate();
  } catch (err) {
    console.log('convertDate.err', dt, event, entry);
  }
  return date;
}

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function objectDiff(object, base) {
  function changes(object, base) {
    return transform(object, function (result, value, key) {
      if (!isEqual(value, base[key])) {
        result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value;
      }
    });
  }
  return changes(object, base);
}

export function getAnnotationsData(rows) {
  let tableDataRaw = rows.filter((entry) => entry !== null); //when a document is deleted the value is set to null
  let annotations = [];

  for (let entry of tableDataRaw) {
    if (entry.comments) {
      annotations = annotations.concat(getAnnotations(entry.deviceId, entry.comments || []));
    }
  }

  // console.log('getAnnotationsData', tableDataRaw, annotations);
  return annotations;
}

export function getFlagsData(rows, deviceIds = []) {
  let tableDataRaw = rows.filter((entry) => entry !== null); //when a document is deleted the value is set to null
  let comments = [];

  for (let entry of tableDataRaw) {
    if (entry.comments && deviceIds.includes(entry.deviceId)) {
      comments = comments.concat(getFlagEntries(entry.comments || []));
    }
  }

  return comments.sort((a, b) => a.x - b.x);
}

function getFlagEntries(comments = []) {
  if (comments.length === 0) {
    return comments;
  }
  return comments.map((comment) => {
    return {
      x: comment.x,
      title: 'info',
      text: comment.text,
    };
  });
}

function getAnnotations(deviceId, annotations = []) {
  if (annotations.length === 0) {
    return annotations;
  }
  return annotations.map((annotation) => {
    return {
      deviceId,
      point: { x: annotation.x, y: annotation.y, xAxis: 0, yAxis: 0 },
      text: annotation.text,
      // borderColor: '#00E396',
      // label: {
      //   borderColor: '#00E396',
      //   orientation: 'horizontal',
      //   text: annotation.text,
      // },
    };
  });
}

export function getEventTableDataFlowers(eventsRows, deviceIds = undefined) {
  //cloneDeep is required as the data coming from firestore/redux is read-only
  let filterFn = (entry) => entry !== null;
  if (deviceIds) filterFn = (entry) => entry !== null && deviceIds.includes(entry.deviceId);
  let tableDataRaw = eventsRows.filter((entry) => filterFn(entry)); //when a document is deleted the value is set to null
  let tableData = [];
  let counter = 0;
  for (let event of tableDataRaw) {
    if (event.dateCreated) {
      event.dateCreated = convertDate(event.dateCreated);
      event.dateOnly = getDateNoTime(event.dateCreated);
      event.dateCreatedOrig = event.dateCreated;
      event.dateCreatedDb = convertDate(event.dateCreatedDb);
      event.id = counter;
      counter++;
      tableData.push(event);
    }
  }

  return tableData;
}

export function getEventTableData(eventsRows, deviceIds = undefined, accountType) {
  if (accountType === 'national-trust') {
    return getEventTableDataFlowers(eventsRows, deviceIds);
  }
  // console.log(
  //   'getEventTableData.deviceData',
  //   eventsRows.filter((row) => row.deviceId === '84:71:27:43:2e:7e' && row.month === '2022-0')
  // );
  // console.log(
  //   'getEventTableData.eventsRows',
  //   eventsRows
  // );
  let filterFn = (entry) => entry !== null;
  if (deviceIds) filterFn = (entry) => entry !== null && deviceIds.includes(entry.deviceId);
  let tableDataRaw = eventsRows.filter((entry) => filterFn(entry)); //when a document is deleted the value is set to null
  let tableData = [];
  let counter = 0;
  for (let entry of tableDataRaw) {
    if (entry && entry.events) {
      for (let event of entry.events) {
        if (event.dateCreated) {
          event.deviceId = entry.deviceId;
          event.deviceType = entry.deviceType;
          event.deviceName = entry.deviceName;
          event.location = entry.location;
          event.dateCreatedOrig = event.dateCreated;
          event.dateCreated = convertDate(event.dateCreated, event, entry);
          event.dateOnly = getDateNoTime(event.dateCreated);
          event.dateCreatedDb = convertDate(event.dateCreatedDb);
          //how to create the horizontal lines bugs for beekeeper-sheridan 68:0a:e2:ae:f1:f6
          event.id = counter;
          counter++;
          tableData.push(event);
        }
      }
    }
  }

  return tableData;
}

export function getAudioEventTableData(eventsRows, deviceIds = undefined) {
  let filterFn = (entry) => entry !== null;
  if (deviceIds) filterFn = (entry) => entry !== null && deviceIds.includes(entry.deviceId);
  let tableDataRaw = eventsRows.filter((entry) => filterFn(entry)); //when a document is deleted the value is set to null
  let tableData = [];
  let counter = 0;
  for (let entry of tableDataRaw) {
    if (entry && entry.audioEvents) {
      for (let event of entry.audioEvents) {
        event.deviceId = entry.deviceId;
        // event.deviceType = entry.deviceType;
        // event.deviceName = entry.deviceName;
        // event.location = entry.location;
        // event.dateCreatedOrig = event.dateCreated;
        event.dateCreated = convertDate(event.dateCreated);
        // event.dateOnly = getDateNoTime(event.dateCreated);
        // event.dateCreatedDb = convertDate(event.dateCreatedDb);
        event.id = counter;
        counter++;
        tableData.push(event);
      }
    }
  }

  return tableData;
}

export function getTableData(data, convertDates = true) {
  //cloneDeep is required as the data coming from firestore/redux is read-only
  let rawData = Object.values((data && cloneDeep(data)) || {});
  // console.log('getTableData', data, rawData);
  return getTableData2(rawData, convertDates);
}

export function getTableData2(data, convertDates = true) {
  let tableData = data.filter((entry) => entry !== null); //when a document is deleted the value is set to null

  for (let i = 0; i < tableData.length; i++) {
    let entry = tableData[i];
    if (entry && entry.events) {
      if (convertDates) {
        entry.dateCreated = convertDate(entry.dateCreated);
        entry.dateCreatedDb = convertDate(entry.dateCreatedDb);
      }
    }
    entry.id = i;
  }
  return tableData;
}

export function getTableDataDevices(devices, convertDates = true) {
  const copyOfDevices = cloneDeep(devices);
  let tableData = copyOfDevices.filter((entry) => entry !== null); //when a document is deleted the value is set to null
  for (let i = 0; i < tableData.length; i++) {
    let entry = tableData[i];
    if (convertDates) {
      if (entry.dateCreated) entry.dateCreated = convertDate(entry.dateCreated);
      if (entry.dateCreatedDb) entry.dateCreatedDb = convertDate(entry.dateCreatedDb);
      if (entry.dateUpdated) entry.dateUpdated = convertDate(entry.dateUpdated);
    }
    entry.id = i;
  }
  return tableData;
}

export function getEventsCountByLocationForHeatmap(events, devices) {
  let eventsTableData = getEventTableData(events);
  let devicesArray = getTableData(devices, false);

  const result = eventsTableData.reduce(function (acc, val) {
    const device = devicesArray.find((device) => device.deviceId === val.serialNumber);
    // console.log('device', device);
    // let coords = { lat: val.location.lat, lng: val.location.lng };
    // let latLng = new window.google.maps.LatLng(
    //   val.location.lat,
    //   val.location.lng
    // );
    // let location = val.location.lng * val.location.lat;
    if (device) {
      let coords = { lat: device.location.lat, lng: device.location.lng };
      let latLng = new window.google.maps.LatLng(device.location.lat, device.location.lng);
      let location = device.location.lng * device.location.lat;
      acc[location] = {
        weight: ((acc[location] && acc[location].weight) || 0) + val.count,
        location: latLng,
        coords,
      };
    }
    return acc;
  }, {});

  return Object.values(result);
}
export function getCircleComponents({
  events,
  devices,
  days = 1,
  center = {
    lat: 51.562469,
    lng: -0.155922,
  },
}) {
  if (!events || events.length === 0) {
    return [{}, []];
  }
  let eventsCount = getEventsCountByLocationForHeatmap(events, devices);
  let extraDevice = getExtraDevice();
  // eventsCount.push(extraDevice);

  const circles = eventsCount.map((entry, index) => {
    const options = {
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.35,
      clickable: false,
      draggable: false,
      editable: false,
      visible: true,
      radius: Math.sqrt(entry.weight / days),
      zIndex: 1,
    };
    return <Circle key={index} center={entry.coords} options={options} />;
  });

  // console.log("eventsCount", eventsCount);

  // return [eventsCount && eventsCount[0].coords, circles];
  return [center, circles];
}

function getExtraDevice() {
  const location = {
    lat: 51.560309,
    lng: -0.158082,
  };
  let coords = { lat: location.lat, lng: location.lng };
  let latLng = new window.google.maps.LatLng(location.lat, location.lng);
  return {
    weight: 5000,
    location: latLng,
    coords,
  };
}

export function isWeatherLocationSetUp(weatherLocations) {
  // console.log('isWeatherLocationSetUp.weatherLocations', weatherLocations);
  if (!weatherLocations || weatherLocations.length === 0) {
    return false;
  }

  return !(weatherLocations[0].lat === 0 || weatherLocations[0].lng === 0);
}

export function getTemperature(value, metric) {
  if (!value) {
    return undefined;
  }
  if (!metric) {
    return (value * 9) / 5 + 32;
  }

  return value;
}

export function getProScaleCalibrationOffsetAndScaleDeviceType({ devices, deviceId }) {
  if (!devices) {
    return [0, undefined];
  }
  const device = devices.find((device) => device.deviceId === deviceId);
  let scaleDeviceId = device && device.scaleDeviceId;
  let scaleDevice = devices.find((device) => device.deviceId === scaleDeviceId);
  return [
    (scaleDevice && scaleDevice.calibrationOffset) || (device && device.calibrationOffset) || 0,
    (scaleDevice && scaleDevice.deviceType) || (device && device.deviceType) || deviceTypes.beeHeroOne,
  ];
}

export function getWeight({ value, metric = true, deviceType, calibrationOffset = 0 }) {
  if (!isNumber(value)) {
    return undefined;
  }
  let weight = value;
  if (deviceType && deviceType !== deviceTypes.agsenzeScaleOne) {
    weight = convertAdcToKg(value) + calibrationOffset;
  }
  if (!metric) {
    return weight * 2.204622;
  }

  return weight;
}

export function getDeviceTypeText(deviceType) {
  switch (deviceType) {
    case 'beeHeroGatewayOne':
      return 'Gateway';
    case 'beeHeroOne':
      return 'In-hive Sensor';
    case 'agsenzeScaleOne':
      return 'Bar Scale';
    default:
      return 'n/a';
  }
}

// export function isDeviceDataExists(deviceId) {
//   const device = useSelector(({ firestore: { data } }) => data.devices && data.devices[deviceId]);
// }
