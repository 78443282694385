import { flatten } from '../tree/treeUtil';

export function getSiteOptionsFromProject(project) {
  const defaultHierarchy = getDefaultHierarchy(project);
  // console.log('hierarchyTree1', defaultHierarchy);
  const hierarchyTree = JSON.parse(project.hierarchy || defaultHierarchy);
  // console.log('hierarchyTree2', hierarchyTree, project.hierarchy);
  return flatten(hierarchyTree)
    .filter((item) => item.level === 'Site')
    .map((item) => item.name);
}

export function getDefaultHierarchy(project) {
  return `{"id":"root","name":"${project.projectName}","children":[]}`;
}
