import firebase from 'firebase/app';
import flamelink from 'flamelink/app';
import 'flamelink/content';
import 'flamelink/storage';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/performance';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const firebaseApp = firebase.initializeApp(config);
export const flameLinkApp = flamelink({
  firebaseApp, // required
  dbType: 'cf', // can be either 'rtdb' or 'cf' for Realtime DB or Cloud Firestore
  env: 'production', // optional, default shown
  locale: 'en-US', // optional, default shown
  precache: true, // optional, default shown. Currently it only precaches "schemas" for better performance
});

export const cmsSubscribe = ({ schemaKey, callback }) => {
  return flameLinkApp.content.subscribe({ schemaKey, callback });
};

export const cmsGet = async ({ schemaKey }) => {
  return flameLinkApp.content.get({ schemaKey });
};

export const firestore = firebase.firestore();
firestore.settings({ experimentalForceLongPolling: true });
// const settings = { timestampsInSnapshots: false }; // force Timestamp instead of Date
// firestore.settings(settings);
export const analytics = firebase.analytics();
export const auth = firebase.auth();
export const storage = firebase.storage();
export const performance = firebase.performance();

// eslint-disable-next-line no-restricted-globals
if (location.hostname === 'localhost') {
  // firestore.useEmulator('localhost', 8080);
  // auth.useEmulator('http://localhost:9099/');
}


export default firebase;
