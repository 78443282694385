import React from 'react';
import { useSelector } from 'react-redux';
import DashboardBeekeeper from './DashboardBeekeeper2';
import DashboardNationalTrust from './DashboardNationalTrust';
import DashboardPolly from './DashboardPolly';
import PollyCanterbury from '../../polly/Experimental/PollyCanterbury';
import { getIsBardsleyUser } from '../../../util/user-util';
import PollyDevicesOverview from '../../polly/Devices/PollyDevicesOverview';

export default function DashboardRoot() {
  const userData = useSelector((state) => state.appConfig.userData);
  const currentProject = useSelector((state) => state.appConfig.currentProject);
  const accountType = (currentProject && currentProject.accountType) || 'none';
  const userRoles = (currentProject && currentProject.userRoles) || ['read'];
  const isCanterbury = getIsBardsleyUser(userData);
  return getDashboard({ accountType, userRoles, isCanterbury });
}

export function getDashboard({ accountType, userRoles, isCanterbury }) {
  if(isCanterbury) {
    return <PollyCanterbury />;
  }

  //TODO: define the other accountType menus and default menu
  switch (accountType) {
    case 'national-trust': {
      return <DashboardNationalTrust />;
    }
    case 'beekeeper': {
      return <DashboardBeekeeper />;
    }
    case 'polly': {
      return <PollyDevicesOverview />;
    }
    case 'none': {
      return <div>accountType is 'none'</div>;
    }
    default:
      return <DashboardBeekeeper />;
  }
}
