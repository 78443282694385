import React from 'react';
import { log } from '../../../util/log-util';
import { useSelector } from 'react-redux';
import HighCharts from '../../beekeeper/DataOverview/HighCharts';
import { convertDates } from '../../../util/date-util';
import { parse, subHours } from 'date-fns';

export default function PollyChartWithData({
  projectId,
  deviceId = undefined,
  linesToShow = {
    sensorTemp: true,
    gatewayTemp: true,
    humidity: true,
    weight: false,
    voltage: false,
    battery: false,
    peakFlight: false,
    peakFanning: false,
    hiveActivity: false,
    beeActivity: true
  },
  showGatewayOnly = false,
  showToggleGroup = false,
  showChartLineToggles = false,
  eventsRows,
  showBeeActivity = true
}) {
  const currentProject = useSelector((state) => state.appConfig.currentProject);
  const accountType = (currentProject && currentProject.accountType) || 'none';
  const storeAsEvents = `events`;
  const eventsData = useSelector(({ firestore: { data } }) => data[storeAsEvents]);
  const weatherData = [];

  log('PollyChartWithData.devicesData', eventsData);

  return (
    <HighCharts
      projectId={currentProject.projectId}
      accountType={accountType}
      weatherData={weatherData}
      eventsRows={eventsRows}
      devicesData={[]}
      deviceId={deviceId}
      title={`Project ${projectId}`}
      initialToggleState={{
        weatherTemp: false,
        sensorTemp: linesToShow.sensorTemp || false,
        gatewayTemp: linesToShow.gatewayTemp || false,
        humidity: linesToShow.humidity || false,
        weight: linesToShow.weight || false,
        acoustics: linesToShow.acoustics || false,
        voltage: linesToShow.voltage || false,
        battery: linesToShow.battery || false,
        peakFlight: linesToShow.peakFlight || false,
        peakFanning: linesToShow.peakFanning || false,
        hiveActivity: linesToShow.hiveActivity || false,
        beeActivity: showBeeActivity,
      }}
      showGatewayOnly={showGatewayOnly}
      showToggleGroup={showToggleGroup}
      showChartLineToggles={showChartLineToggles}
    />
  );
}

function getPollyEventsPlanBExploded({ rawEvents }) {
  // { time: '20211222T221032', batt: '4.10', hum: '45.87', light: '0', temp: '21.88', alertCount: '0' },

  let events = [];
  for (let rawEvent of rawEvents) {
    convertDates(rawEvent);
    let sensors = rawEvent.sensors || [];
    if (rawEvent.init === 'OK') {
      let event = {
        docId: rawEvent.docId,
        deviceId: rawEvent.id || rawEvent.name,
        init: rawEvent.init,
        rssi: rawEvent.rssi,
        sdCardPercent: rawEvent.sdCardPercent || 'n/a',
        dateCreated: rawEvent.dateCreated || 'n/a',
        dateCreatedDb: rawEvent.dateCreated || 'n/a',
        sensorReadingDate: 'n/a',
        temperature: 'n/a',
        humidity: 'n/a',
        light: 'n/a',
        alertCount: 'n/a',
      };
      events.push(event);
    } else {
      let sensorReadings = [];
      for (let sensorReading of sensors) {
        let event = {
          docId: rawEvent.docId,
          deviceId: rawEvent.id || rawEvent.name,
          init: rawEvent.init,
          rssi: rawEvent.rssi,
          sdCardPercent: rawEvent.sdCardPercent,
          // dateCreated: rawEvent.dateCreated || 'n/a',
          dateCreatedDb: rawEvent.dateCreated || 'n/a',
          dateCreated: subHours(convertFirmwareDate(sensorReading.time), 1),
          temperature: Number.parseFloat(sensorReading.temp),
          humidity: Number.parseFloat(sensorReading.hum),
          light: Number.parseFloat(sensorReading.light),
          alertCount: Number.parseFloat(sensorReading.alertCount),
        };
        sensorReadings.push(event);
        sensorReadings.sort((a, b) => b.sensorReadingDate - a.sensorReadingDate);
      }
      events = events.concat(sensorReadings);
    }
  }

  return events.sort((a, b) => b.dateCreated - a.dateCreated);
}

function convertFirmwareDate(dateString) {
  const isString = typeof dateString;
  // console.log('convertFirmwareDate', event);

  if (!dateString || isString !== 'string' || !dateString.includes('T')) {
    return dateString;
  }
  const timestamp = dateString.replace('T', '');
  return parse(timestamp, 'yyyyMMddHHmmss', new Date());
}
