import { storage } from '../../../../firebase';
import { v4 as uuidv4 } from 'uuid';

export const getDateByNumberOfDays = (days = 1) => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - days);
  return yesterday;
};

export const getDateFromTimeStamp = (timestamp) => {
  return new Date(timestamp * 1000).toLocaleDateString();
};

export const addImageToStorage = async ({ img, imgPath }) => {
  const ref = `${imgPath}${img.name}`;
  // console.log('addImageToStorage', ref);
  const metadata = {
    customMetadata: {
      id: uuidv4(),
    },
  };
  try {
    const snapshot = await storage.ref(ref).put(img, metadata);
    //let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    const url = await snapshot.ref.getDownloadURL();
    return {
      url,
      name: img.name,
      id: metadata.customMetadata.id
    };
  } catch (error) {
    console.log('One failed:', img, error.message);
  }
};

export const deleteImageFromStorage = async (path) => {
  try {
    await storage.ref(path).delete();
  } catch (error) {
    console.log('deleteImageFromStorage failed:', path, error.message);
  }
};
