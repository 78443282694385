import { Divider, Menu, MenuItem } from '@material-ui/core';
import { Link } from 'react-router-dom';
import React from 'react';

export default function BarMenu({ menuItems, menu, closeMenu }) {
  if (menuItems && menuItems.length === 0) {
    return <></>;
  }
  return (
    <Menu anchorEl={menu.anchorEl} open={Boolean(menu.anchorEl)} onClose={closeMenu}>
      {menuItems.map((menuItem, index) => {
        let key = menuItem.key || index;
        if (menuItem.hasOwnProperty('condition') && !menuItem.condition) {
          return null;
        }

        let component = null;

        if (menuItem.to) {
          component = (
            <MenuItem key={key} component={Link} to={menuItem.to} onClick={closeMenu}>
              {menuItem.name}
            </MenuItem>
          );
        } else if (menuItem.onClick) {
          component = (
            <MenuItem
              key={key}
              onClick={() => {
                closeMenu();
                menuItem.onClick();
              }}
            >
              {menuItem.name}
            </MenuItem>
          );
        }

        if (menuItem.divide) {
          return (
            <span key={'divider' + key}>
              <Divider />
              {component}
            </span>
          );
        }

        return component;
      })}
    </Menu>
  );
}
