import { auth } from '../firebase';
import { getYearMonth } from '../util/date-util';

export async function updateComment({ firestore, deviceId, x, y, comment }) {
  const date = new Date(x);
  let updatedObj = { x, y, text: comment };
  addAudit(updatedObj);
  const month = getYearMonth(date);
  console.log('updateComment', { deviceId, x, y, date, month, comment });
  // await firestore.doc(`projects/${projectId}/devices/${deviceId}`).update(updatedObj);
  const snapshot = await firestore
    .collection(`events`)
    .where('month', '==', month)
    .where('deviceId', '==', deviceId)
    .get();
  console.log('updateComment', snapshot, snapshot.exists);
  let events = [];
  snapshot.docs.map((doc) => events.push({ ...doc.data(), ...{ id: doc.id } }));
  if (events.length === 1) {
    const event = events[0];
    const docId = event.id;
    let comments = event.comments || [];
    comments.push({ x, y, text: comment });
    await firestore.doc(`events/${docId}`).update({ comments });
    console.log('updateComment.comments', comments);
  }
  // await firestore.doc(`devices/${deviceId}`).update(updatedObj);
}

export async function updateCommentOld({ firestore, deviceId, date, comment }) {
  console.log('updateCommentOld', { deviceId, date, comment });
  let updatedObj = { date, text: comment };
  addAudit(updatedObj);
  const month = getYearMonth(date);
  // await firestore.doc(`projects/${projectId}/devices/${deviceId}`).update(updatedObj);
  const snapshot = await firestore
    .collection(`events`)
    .where('month', '==', month)
    .where('deviceId', '==', deviceId)
    .get();
  console.log('updateComment', snapshot, snapshot.exists);
  let events = [];
  snapshot.docs.map((doc) => events.push({ ...doc.data(), ...{ id: doc.id } }));
  if (events.length === 1) {
    const event = events[0];
    const docId = event.id;
    let comments = event.comments || [];
    comments.push({ date, text: comment });
    await firestore.doc(`events/${docId}`).update({ comments });
    console.log('updateComment.comments', comments);
  }
  // await firestore.doc(`devices/${deviceId}`).update(updatedObj);
}

function addAudit(obj) {
  const uid = auth.currentUser && auth.currentUser.uid;
  obj.updatedBy = uid;
  obj.dateUpdated = new Date();
}
