import React from 'react';
import DevicesMap from '../Maps/DevicesMap';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { getProjectIds } from '../../../config/env';
import { getDevicesQuery, getDevicesStoreAs } from '../../../api/queryConfig';
import siteSampleData from '../data/pollySitesWeekly.json';
import PollinatorChart from './PollinatorChart';
import { pollinatorReportSampleData } from "../data/sampleData";

export default function PollinatorChartPage() {
  // const userData = useSelector((state) => state.appConfig.userData);
  // let projectIds = getProjectIds(userData);
  // const storeAs = getDevicesStoreAs();
  // const devicesQuery = getDevicesQuery({ projectIds, storeAs });
  // const devicesData = useSelector(({ firestore: { data } }) => data[storeAs]);
  //
  // useFirestoreConnect([devicesQuery]);
  //
  // if (!isLoaded(devicesData)) {
  //   return 'Loading...';
  // }

  let allWeeksData = [];
  for(let site of siteSampleData) {
    allWeeksData = allWeeksData.concat(site.weeks);
  }
  const aggregatedData = {
    weeks: allWeeksData,
    days: allWeeksData,
    months: allWeeksData
  };
  return <PollinatorChart aggregatedData={aggregatedData} />;
}
