import { addDays, format, isEqual as dateIsEqual } from 'date-fns';
import { cloneDeep, uniq, uniqWith } from 'lodash';
import {
  convertDate,
  getProScaleCalibrationOffsetAndScaleDeviceType,
  getTemperature,
  getWeight,
} from '../../../data/data-util';
import { deviceTypesConfig } from '../../../config/deviceTypes';
import { getEstimatedBeesPerSquareMetre } from '../../polly/util/polly-calculation-util';

export const getRandomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export function getRechartsData(eventData) {
  let chartData = [];
  let dataNames = [];
  for (let event of eventData) {
    let entry = chartData.find((e) => dateIsEqual(e.name, event.dateOnly));
    let entry2 = dataNames.find((e) => e.name === event.deviceName);
    if (!entry2) {
      dataNames.push({
        name: event.deviceName,
        axisId: event.deviceName,
        colour: '#8884d8',
        offset: 1,
      });
    }
    if (!entry) {
      chartData.push({
        name: event.dateOnly,
      });
    }
    entry = chartData.find((e) => dateIsEqual(e.name, event.dateOnly));
    entry[event.deviceName] = event.count;
  }
  return [dataNames, chartData];
}

export function getFlowerChartDataApex(eventData, maxLines) {
  let deviceIds = uniq(eventData.map((event) => event.deviceId)).slice(0, maxLines);
  let events = eventData.filter((event) => deviceIds.includes(event.deviceId));
  const uniqDates = uniqWith(
    events.map((event) => event.dateOnly),
    dateIsEqual
  );
  //initialise chartData
  let chartData = uniqDates.map((dateOnly) => {
    let dataPoint = { dateOnly };
    deviceIds.forEach((deviceName) => (dataPoint[deviceName] = 0));
    return dataPoint;
  });

  for (let event of events) {
    let dataPoint = chartData.find((entry) => dateIsEqual(entry.dateOnly, event.dateOnly));
    dataPoint[event.deviceId] = dataPoint[event.deviceId] + event.count;
  }
  const sortedByDate = chartData.sort((a, b) => b.dateOnly - a.dateOnly);
  const dates = sortedByDate.map((entry) => format(entry.dateOnly, 'yyyy-MM-dd'));

  //initialise series
  let series = deviceIds.map((deviceId) => {
    return {
      name: deviceId,
      data: [],
    };
  });
  series.forEach((line) => (line.data = sortedByDate.map((entry) => entry[line.name])));
  return [dates, series];
}

function componentToHex(c) {
  let hex = c.toString(16);
  return hex.length === 1 ? '0' + hex : hex;
}

function rgbToHex(r, g, b) {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

const palette = {
  humidity: ['#7CB9E8', '#00308F', '#1F75FE', '#1F75FE', '#5F9EA0', '#008B8B', '#008B8B'],
  temperature: ['#E25822', '#A50021', '#823a03', '#6a0900', '#17B169', '#008B8B'],
  weight: ['#72a51f', '#587032', '#a0e398', '#3bc30a'],
  battery: [
    '#7d1fa5',
    '#985dbf',
    '#542457',
    '#9e44d5',
    '#7d1fa5',
    '#985dbf',
    '#542457',
    '#9e44d5',
    '#7d1fa5',
    '#985dbf',
    '#542457',
    '#9e44d5',
    '#7d1fa5',
    '#985dbf',
    '#542457',
    '#9e44d5',
  ],
  inCount: ['#36d4ce', '#36d4ce', '#36d4ce', '#36d4ce'],
  outCount: ['#206d6b', '#206d6b', '#206d6b', '#206d6b'],
  beeActivity: ['#ab2b77', '#ab2b77', '#ab2b77', '#ab2b77'],
  all: [
    '#E25822',
    '#A50021',
    '#568203',
    '#006A4E',
    '#17B169',
    '#008B8B',
    '#7CB9E8',
    '#00308F',
    '#1F75FE',
    '#1F75FE',
    '#5F9EA0',
    '#008B8B',
    '#008B8B',
  ],
};
export function getHiveChartData({
  eventData,
  audioEvents,
  weatherData,
  deviceIdNameMap,
  includeDeviceNameInLabels = false,
  isMetric,
  devices,
}) {
  if (!eventData || eventData.length === 0) {
    return [new Date(), new Date(), [], []];
  }

  const dateField = 'dateCreated';
  const weatherDateField = 'date';
  const convert = (weatherEvents) => {
    for (let event of weatherEvents) {
      event.dateCreated = convertDate(event.date);
    }
    return weatherEvents;
  };
  let weather = Object.values((weatherData && cloneDeep(weatherData)) || {});
  let weatherEventsByLabel = weather.reduce((obj, entry) => {
    if (!obj[entry.label]) {
      obj[entry.label] = convert(entry.weatherEvents);
    } else {
      obj[entry.label] = (obj[entry.label] || []).concat(convert(entry.weatherEvents));
    }
    return obj;
  }, {});

  let weatherLabels = Object.keys(weatherEventsByLabel);
  for (let label of weatherLabels) {
    weatherEventsByLabel[label] = weatherEventsByLabel[label].sort((a, b) => b[dateField] - a[dateField]);
  }
  let sensorDeviceIds = uniq(eventData.map((event) => event.deviceId));

  const sortByDateAsc = (a, b) => a[dateField] - b[dateField];
  const sortedByDate = eventData.sort(sortByDateAsc);
  const audioSortedByDate = audioEvents.sort(sortByDateAsc);

  // const maxDate = sortedByDate[0][dateField]; //used by the Chart date range buttons (1 week, 1 month, 3month, Max)
  // const minDate = sortedByDate[sortedByDate.length - 1][dateField]; //used by the Chart date range buttons (1 week, 1 month, 3month, Max)
  const minDate = sortedByDate[0][dateField]; //used by the Chart date range buttons (1 week, 1 month, 3month, Max)
  const maxDate = sortedByDate[sortedByDate.length - 1][dateField]; //used by the Chart date range buttons (1 week, 1 month, 3month, Max)
  let colourCounter = -1;
  let counters = {
    temp: -1,
    humidity: -1,
    weight: -1,
    battery: -1,
    inCount: -1,
    outCount: -1,
    beeActivity: -1,
  };

  const getNumberValuesOnly = (data) => {
    return data.filter((entry) => entry[1] !== undefined).map((entry) => entry[1]);
  };

  let seriesWeatherTemp = weatherLabels.map((label, index) => {
    counters.temp++;
    colourCounter++;
    const weatherEvents = weatherEventsByLabel[label];
    return {
      name: `API Temp - ${label}`,
      // showInNavigator: true,
      type: 'line',
      min: Math.min(...weatherEvents.map((event) => event.temp)),
      max: Math.max(...weatherEvents.map((event) => event.temp)),
      data: weatherEvents.map((event) => {
        return [event[dateField].getTime(), event.temp];
      }),
      yAxis: 0,
      // id: 'weatherTemp',
      dataType: 'weatherTemp',
      dataGroup: 'temperature',
      axisLabel: 'Temperature (°C)',
      color: palette.temperature[counters.temp],
      // color: palette.all[colourCounter],
    };
  });
  // let seriesWeatherRain = weather.map((entry, index) => {
  //   const dateField = 'date';
  //   const weatherEvents = entry.weatherEvents;
  //   const weatherEventsWithHourly = weatherEvents.filter(weatherEvent => weatherEvent.hourly);
  //   const weatherEventsWithRain = weatherEventsWithHourly.filter(entry => {
  //     const withRain = entry.hourly.filter(entry => entry.rain);
  //     return withRain.length > 0;
  //   });
  //   console.log('eventsWithRain', entry.label, weatherEventsWithRain);
  //   const sortedByDate = weatherEventsWithRain.sort((a, b) => b[dateField].toDate() - a[dateField].toDate());
  //   tempCounter++;
  //   return {
  //     name: `Rain - ${entry.label}`,
  //     // type: 'line',
  //     // min: Math.min(...sortedByDate.map((event) => event.temp)),
  //     // max: Math.max(...sortedByDate.map((event) => event.temp)),
  //     data: sortedByDate.map((event) => {
  //       const withRain = event.hourly.filter(entry => entry.rain);
  //       console.log('withRain', withRain);
  //       const totalRain = withRain.reduce((total, entry) => {
  //         console.log('rain', entry.rain);
  //         return total + sum(Object.values(entry.rain));//((entry.rain && sum(Object.values(entry.rain))) || 0);
  //         // return total;
  //       }, 0);
  //       console.log('totalRain',totalRain);
  //       return [event[dateField].toDate().getTime(), totalRain ];
  //     }),
  //     dataType: 'weatherRain',
  //     dataGroup: 'rain',
  //     color: palette.temperature[tempCounter]
  //   };
  // });
  const tempSymbol = isMetric ? 'C' : 'F';
  let dataGroup = isMetric ? 'temperature' : 'temperatureImperial';
  let seriesTemp = sensorDeviceIds.map((deviceId, index) => {
    counters.temp++;
    colourCounter++;
    const sortedByDateDevice = sortedByDate
      .filter(
        (event) => event.deviceId === deviceId && event.hasOwnProperty('temperature') && event[dateField]
      )
      .sort(sortByDateAsc);
    const deviceName = (deviceIdNameMap && deviceIdNameMap[deviceId]) || deviceId;
    const data = sortedByDateDevice.map((event) => {
      return [event[dateField].getTime(), getTemperature(event.temperature, isMetric)];
    });
    const valuesOnly = getNumberValuesOnly(data);
    return {
      deviceId,
      name: includeDeviceNameInLabels ? `Sensor Temp (${deviceName})` : `Sensor Temp`,
      // showInNavigator: true,
      type: 'line',
      yAxis: 0,
      min: Math.min(...valuesOnly),
      max: Math.max(...valuesOnly),
      data,
      // id: 'sensorTemp',
      dataType: 'sensorTemp',
      dataGroup,
      axisLabel: `Temperature (°${tempSymbol})`,
      // color: palette.temperature[tempCounter],
      // color: palette.all[colourCounter],
      color: palette.temperature[counters.temp],
    };
  });

  const weightSymbol = isMetric ? 'Kg' : 'lbs';
  dataGroup = isMetric ? 'weight' : 'weightImperial';
  let seriesWeight = sensorDeviceIds.map((deviceId, index) => {
    counters.weight++;
    colourCounter++;
    const filterFn = (event) => {
      return event.deviceId === deviceId && event.hasOwnProperty('weight');
    };
    let sortedByDateDevice = sortedByDate.filter(filterFn).sort(sortByDateAsc);
    let [calibrationOffset, scaleDeviceType] = getProScaleCalibrationOffsetAndScaleDeviceType({
      devices,
      deviceId,
    });

    const data = sortedByDateDevice.map((event) => {
      let weight = event.weight;
      return [
        event[dateField].getTime(),
        getWeight({
          value: weight,
          metric: isMetric,
          deviceType: scaleDeviceType,
          calibrationOffset,
        }),
      ];
    });
    const valuesOnly = getNumberValuesOnly(data);
    const deviceName = (deviceIdNameMap && deviceIdNameMap[deviceId]) || deviceId;
    return {
      deviceId,
      name: includeDeviceNameInLabels ? `Sensor Weight (${deviceName})` : `Sensor Weight`,
      type: 'line',
      yAxis: 0,
      // min: Math.min(...sortedByDateDevice.map((event) => getWeight(event.weight, isMetric))),
      // max: Math.max(...sortedByDateDevice.map((event) => getWeight(event.weight, isMetric))),
      min: Math.min(...valuesOnly),
      max: Math.max(...valuesOnly),
      data: data,
      // id: 'weight',
      dataType: 'weight',
      dataGroup: dataGroup,
      axisLabel: `Weight (${weightSymbol})`,
      color: palette.weight[counters.weight],
      // color: palette.all[colourCounter],
    };
  });

  //HUMIDITY
  let seriesHumidity = sensorDeviceIds.map((deviceId) => {
    counters.humidity++;
    colourCounter++;
    const sortedByDateDevice = sortedByDate
      .filter((event) => event.deviceId === deviceId && event.hasOwnProperty('humidity') && event[dateField])
      .sort(sortByDateAsc);
    const deviceName = (deviceIdNameMap && deviceIdNameMap[deviceId]) || deviceId;
    const data = sortedByDateDevice.map((event) => {
      return [event[dateField].getTime(), event.humidity];
    });
    const valuesOnly = getNumberValuesOnly(data);
    return {
      deviceId,
      name: includeDeviceNameInLabels ? `Sensor Humidity (${deviceName})` : `Sensor Humidity`,
      // showInNavigator: true,
      type: 'line',
      yAxis: 0,
      min: Math.min(...valuesOnly),
      max: Math.max(...valuesOnly),
      data,
      // id: 'humidity',
      dataType: 'humidity',
      dataGroup: 'humidity',
      axisLabel: 'Humidity (%)',
      // color: palette.humidity[humidityCounter],
      // color: palette.all[colourCounter],
      color: palette.humidity[counters.humidity],
    };
  });

  //VOLTAGE
  let seriesVoltage = sensorDeviceIds.map((deviceId) => {
    counters.battery++;
    colourCounter++;
    const sortedByDateDevice = sortedByDate
      .filter((event) => event.deviceId === deviceId && event.hasOwnProperty('voltage'))
      .sort(sortByDateAsc);
    const deviceType = sortedByDateDevice.length > 0 && deviceTypesConfig[sortedByDateDevice[0].deviceType];
    const deviceName = (deviceIdNameMap && deviceIdNameMap[deviceId]) || deviceId;
    const data = sortedByDateDevice.map((event) => {
      return [event[dateField].getTime(), event.voltage / 1000];
    });
    const valuesOnly = getNumberValuesOnly(data);
    const chartLineLabel = includeDeviceNameInLabels ? `Battery (${deviceName} - ${deviceType})` : `Battery`;
    return {
      deviceId,
      name: chartLineLabel,
      // showInNavigator: true,
      type: 'line',
      yAxis: 0,
      min: Math.min(...valuesOnly),
      max: Math.max(...valuesOnly),
      data,
      // id: 'voltage',
      // dataType: 'voltage',
      // dataGroup: 'voltage',
      // axisLabel: 'Voltage (V)',
      dataType: 'battery',
      dataGroup: 'battery',
      axisLabel: 'Battery',
      // color: palette.all[colourCounter],
      color: palette.battery[counters.battery],
    };
  });

  //BATTERY
  let seriesBattery = sensorDeviceIds.map((deviceId) => {
    counters.battery++;
    colourCounter++;
    const sortedByDateDevice = sortedByDate
      .filter((event) => event.deviceId === deviceId && event.hasOwnProperty('battery'))
      .sort(sortByDateAsc);
    const deviceType = sortedByDateDevice.length > 0 && deviceTypesConfig[sortedByDateDevice[0].deviceType];
    const deviceName = (deviceIdNameMap && deviceIdNameMap[deviceId]) || deviceId;
    const data = sortedByDateDevice.map((event) => {
      return [event[dateField].getTime(), event.battery];
    });
    const valuesOnly = getNumberValuesOnly(data);
    const chartLineLabel = includeDeviceNameInLabels ? `Battery (${deviceName} - ${deviceType})` : `Battery`;
    return {
      deviceId,
      name: chartLineLabel,
      // showInNavigator: true,
      type: 'line',
      yAxis: 0,
      min: Math.min(...valuesOnly),
      max: Math.max(...valuesOnly),
      data,
      // id: 'battery',
      dataType: 'battery',
      dataGroup: 'battery',
      axisLabel: 'Battery',
      // color: palette.all[colourCounter],
      color: palette.battery[counters.battery],
    };
  });

  //PEAK FLIGHT
  let seriesPeakFlight = sensorDeviceIds.map((deviceId) => {
    colourCounter++;
    const sortedByDateDevice = audioSortedByDate
      .filter((event) => event.deviceId === deviceId && event.hasOwnProperty('peakFlight'))
      .sort(sortByDateAsc);
    const deviceName = (deviceIdNameMap && deviceIdNameMap[deviceId]) || deviceId;
    const data = sortedByDateDevice.map((event) => {
      return [event[dateField].getTime(), event.peakFlight];
    });
    const valuesOnly = getNumberValuesOnly(data);
    return {
      deviceId,
      name: includeDeviceNameInLabels ? `Peak Flight (${deviceName})` : `Peak Flight`,
      // showInNavigator: true,
      type: 'line',
      yAxis: 0,
      min: Math.min(...valuesOnly),
      max: Math.max(...valuesOnly),
      data,
      // id: 'peakFlight',
      dataType: 'peakFlight',
      dataGroup: 'peakFlight',
      axisLabel: 'Peak Flight',
      color: palette.all[colourCounter],
    };
  });

  //PEAK FANNING
  let seriesPeakFanning = sensorDeviceIds.map((deviceId) => {
    colourCounter++;
    const sortedByDateDevice = audioSortedByDate
      .filter((event) => event.deviceId === deviceId && event.hasOwnProperty('peakFanning'))
      .sort(sortByDateAsc);
    const deviceName = (deviceIdNameMap && deviceIdNameMap[deviceId]) || deviceId;
    const data = sortedByDateDevice.map((event) => {
      return [event[dateField].getTime(), event.peakFanning];
    });
    const valuesOnly = getNumberValuesOnly(data);
    return {
      deviceId,
      name: includeDeviceNameInLabels ? `Peak Fanning (${deviceName})` : `Peak Fanning`,
      // showInNavigator: true,
      type: 'line',
      yAxis: 0,
      min: Math.min(...valuesOnly),
      max: Math.max(...valuesOnly),
      data,
      // id: 'peakFanning',
      dataType: 'peakFanning',
      dataGroup: 'peakFanning',
      axisLabel: 'Peak Fanning',
      color: palette.all[colourCounter],
    };
  });

  let seriesHiveActivity = sensorDeviceIds.map((deviceId) => {
    colourCounter++;
    const sortedByDateDevice = audioSortedByDate
      .filter((event) => event.deviceId === deviceId && event.hasOwnProperty('hiveActivity'))
      .sort(sortByDateAsc);
    const deviceName = (deviceIdNameMap && deviceIdNameMap[deviceId]) || deviceId;
    const data = sortedByDateDevice.map((event) => {
      return [event[dateField].getTime(), event.hiveActivity];
    });
    const valuesOnly = getNumberValuesOnly(data);
    return {
      deviceId,
      name: includeDeviceNameInLabels ? `Hive Activity (${deviceName})` : `Hive Activity`,
      // showInNavigator: true,
      type: 'line',
      yAxis: 0,
      min: Math.min(...valuesOnly),
      max: Math.max(...valuesOnly),
      data,
      // id: 'hiveActivity',
      dataType: 'hiveActivity',
      dataGroup: 'hiveActivity',
      axisLabel: 'Hive Activity',
      color: palette.all[colourCounter],
    };
  });

  let seriesBeeInCount = sensorDeviceIds.map((deviceId, index) => {
    counters.inCount++;
    colourCounter++;
    const fieldName = 'inCountDiff';
    // const fieldName = 'temperature';  USE THIS TO TEST
    const sortedByDateDevice = sortedByDate
      .filter((event) => event.deviceId === deviceId && event.hasOwnProperty(fieldName) && event[dateField])
      .sort(sortByDateAsc);
    const deviceName = (deviceIdNameMap && deviceIdNameMap[deviceId]) || deviceId;
    const data = sortedByDateDevice.map((event) => {
      return [event[dateField].getTime(), event[fieldName]];
      // return [event[dateField].getTime(), event[fieldName] * Math.random()];
    });
    const valuesOnly = getNumberValuesOnly(data);
    return {
      deviceId,
      name: includeDeviceNameInLabels ? `Bee In Count (${deviceName})` : `Bee In Count`,
      type: 'line',
      yAxis: 0,
      min: Math.min(...valuesOnly),
      max: Math.max(...valuesOnly),
      data,
      dataType: 'inCount',
      dataGroup: 'inCount',
      axisLabel: `In Count`,
      color: palette.inCount[counters.inCount],
    };
  });

  let seriesBeeOutCount = sensorDeviceIds.map((deviceId, index) => {
    counters.outCount++;
    colourCounter++;
    const sortedByDateDevice = sortedByDate
      .filter(
        (event) => event.deviceId === deviceId && event.hasOwnProperty('outCountDiff') && event[dateField]
      )
      .sort(sortByDateAsc);
    const deviceName = (deviceIdNameMap && deviceIdNameMap[deviceId]) || deviceId;
    const data = sortedByDateDevice.map((event) => {
      return [event[dateField].getTime(), event.outCountDiff];
    });
    const valuesOnly = getNumberValuesOnly(data);
    return {
      deviceId,
      name: includeDeviceNameInLabels ? `Bee Out Count (${deviceName})` : `Bee Out Count`,
      type: 'line',
      yAxis: 0,
      min: Math.min(...valuesOnly),
      max: Math.max(...valuesOnly),
      data,
      dataType: 'outCount',
      dataGroup: 'outCount',
      axisLabel: `Out Count`,
      color: palette.outCount[counters.outCount],
    };
  });

  //BEE ACTIVITY
  let seriesBeeActivity = sensorDeviceIds.map((deviceId) => {
    counters.beeActivity++;
    colourCounter++;
    const sortedByDateDevice = sortedByDate
      .filter((event) => event.deviceId === deviceId && event.hasOwnProperty('beeActivity'))
      .sort(sortByDateAsc);
    const deviceType = sortedByDateDevice.length > 0 && deviceTypesConfig[sortedByDateDevice[0].deviceType];
    const deviceName = (deviceIdNameMap && deviceIdNameMap[deviceId]) || deviceId;
    const data = sortedByDateDevice.map((event) => {
      return [event[dateField].getTime(), getEstimatedBeesPerSquareMetre(event.beeActivity)];
    });
    const valuesOnly = getNumberValuesOnly(data);
    const chartLineLabel = includeDeviceNameInLabels
      ? `Bee Activity (${deviceName} - ${deviceType})`
      : `Bee Activity`;
    return {
      deviceId,
      name: chartLineLabel,
      // showInNavigator: true,
      type: 'column',
      yAxis: 0,
      min: Math.min(...valuesOnly),
      max: Math.max(...valuesOnly),
      // min: 0,
      // max: 100,
      data,
      // id: 'battery',
      dataType: 'beeActivity',
      dataGroup: 'beeActivity',
      axisLabel: 'Bees / sq m',
      // color: palette.all[colourCounter],
      color: palette.beeActivity[counters.beeActivity],
    };
  });

  seriesTemp = cleanUp(seriesTemp);
  seriesHumidity = cleanUp(seriesHumidity);
  seriesWeight = cleanUp(seriesWeight);
  seriesVoltage = cleanUp(seriesVoltage);
  seriesBattery = cleanUp(seriesBattery);
  seriesWeatherTemp = cleanUp(seriesWeatherTemp);
  seriesPeakFlight = cleanUp(seriesPeakFlight);
  seriesPeakFanning = cleanUp(seriesPeakFanning);
  seriesHiveActivity = cleanUp(seriesHiveActivity);
  seriesBeeInCount = cleanUp(seriesBeeInCount);
  seriesBeeOutCount = cleanUp(seriesBeeOutCount);
  seriesBeeActivity = cleanUp(seriesBeeActivity);

  let series = [
    ...seriesTemp,
    ...seriesHumidity,
    ...seriesWeight,
    ...seriesVoltage,
    ...seriesBattery,
    ...seriesWeatherTemp,
    ...seriesPeakFlight,
    ...seriesPeakFanning,
    ...seriesHiveActivity,
    ...seriesBeeInCount,
    ...seriesBeeOutCount,
    ...seriesBeeActivity,
  ];

  const seriesCount = series.length;

  const weatherCount = seriesWeatherTemp.length;
  const markersSizes = series.map((entry) => (entry.data.length === 1 ? 4 : 0));
  const curveType = 'straight';
  const lineWidth = 2;
  const weatherWidth = 2;
  const dashSizeLine = 0;
  const dashSizeWeather = 3;
  let stroke = {
    width: [...Array(seriesCount).fill(lineWidth), ...Array(weatherCount).fill(weatherWidth)],
    curve: [...Array(seriesCount).fill(curveType), ...Array(weatherCount).fill(curveType)],
    dashArray: [...Array(seriesCount).fill(dashSizeLine), ...Array(weatherCount).fill(dashSizeWeather)],
    markers: {
      size: markersSizes,
    },
  };

  return [maxDate, minDate, stroke, series];
}

function cleanUp(series) {
  let cleanSeriesData = [];
  for (let s of series) {
    if (s.data.length > 0) {
      cleanSeriesData.push(s);
    }
  }

  return cleanSeriesData;
}

export const generateData = (count = 100) => {
  const data = [];
  const values = {
    value: [100, 105, 115, 120],
    value1: [100, 105, 115, 120],
    value2: [100, 105, 115, 120],
    value3: [100, 105, 115, 120],
  };
  for (let i = 0; i < count; i++) {
    let index = 3;
    if (i <= count / 3) index = 1;
    if (i >= count / 3 && i <= (count / 3) * 2) index = 2;

    data.push({
      name: addDays(new Date(), i),
      value: getRandomNumber(values.value[index - 1], values.value[index]),
      value1: getRandomNumber(values.value1[index - 1], values.value1[index]),
      value2: getRandomNumber(values.value2[index - 1], values.value2[index]),
      value3: getRandomNumber(values.value3[index - 1], values.value3[index]),
    });
  }
  return data;
};

export function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function getFinalCount() {
  return getParameterByName('count') ? parseInt(getParameterByName('count')) : 10;
}
