import React from 'react';

export const getDateFromTimeStamp = (timestamp) => {
  return new Date(timestamp * 1000).toLocaleDateString();
};

export const yieldTableColumns = [
  { title: 'Type', name: 'type', field: 'type' },
  {
    title: 'Date',
    name: 'dateFrom',
    filtering: false,
    field: 'noteDateFrom.seconds',
    render: ({ noteDateFrom, noteDateTo }) => (
      <span>
        {getDateFromTimeStamp(noteDateFrom.seconds)}-{getDateFromTimeStamp(noteDateTo.seconds)}
      </span>
    ),
  },
  {
    title: 'Date Created',
    name: 'Datecreated',
    field: 'dateCreated.seconds',
    filtering: false,
    defaultSort: 'desc',
    render: ({ dateCreated }) => <span>{getDateFromTimeStamp(dateCreated.seconds)}</span>,
  },
  { title: 'Text', name: 'text', field: 'text' },
  { title: 'Report Level', name: 'groupingLevelValue', field: 'groupingLevelValue' },
];
