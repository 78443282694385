import React from 'react';
import { Link } from 'react-router-dom';
import { Fab, Box } from '@material-ui/core';
import { Home as HomeIcon } from '@material-ui/icons';
import EmptyState from '../EmptyState';

import { ReactComponent as NotFoundIllustration } from '../../../illustrations/not-found.svg';

export default function DeviceNotFoundPage({ deviceId }) {
  const title = `The deviceId ${deviceId} cannot be found`;
  const description = 'Please check you are using the correct deviceId';
  return (
    <EmptyState
      image={<NotFoundIllustration />}
      title={title}
      description={description}
      button={
        <Fab variant="extended" color="primary" component={Link} to="/">
          <Box clone mr={1}>
            <HomeIcon />
          </Box>
          Home
        </Fab>
      }
    />
  );
}
