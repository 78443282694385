import moment from 'moment-timezone';
import { format } from 'date-fns';
export const defaultHighChartOptions = {
  navigator: {
    enabled: false,
  },
  rangeSelector: {
    enabled: true,
    verticalAlign: 'top',
    buttons: [
      {
        type: 'day',
        count: 1,
        text: '1d',
      },
      {
        type: 'week',
        count: 1,
        text: '1w',
      },
      {
        type: 'month',
        count: 1,
        text: '1m',
      },
      {
        type: 'month',
        count: 3,
        text: '3m',
      },
      {
        type: 'month',
        count: 6,
        text: '6m',
      },
      {
        type: 'ytd',
        text: 'YTD',
      },
      {
        type: 'year',
        count: 1,
        text: '1y',
      },
      {
        type: 'all',
        text: 'All',
      },
    ],
  },
  animation: false,
  time: {
    // timezone: 'Europe/Moscow',
    // timezoneOffset: 5 * 60,
    /**
     * Use moment-timezone.js to return the timezone offset for individual
     * timestamps, used in the X axis labels and the tooltip header.
     */
    getTimezoneOffset: function (timestamp) {
      // const zone = 'Europe/Moscow';
      const zone = 'Europe/London';
      const timezoneOffset = -moment.tz(timestamp, zone).utcOffset();

      return timezoneOffset;
    },
  },
  chart: {
    // backgroundColor: 'grey',
    // type: 'spline',
    height: '45%',
    zoomType: 'x',
    // events: {
    //   // load() {
    //   //   console.log('reflow');
    //   //   setTimeout(this.reflow.bind(this), 0);
    //   // },
    //   // load: () => {
    //   //   console.log('reflow');
    //   //   // setTimeout(this.reflow.bind(this), 0);
    //   // },
    // },
  },
  title: {
    text: undefined,
  },
  // subtitle: {
  //   text: 'Irregular time data in Highcharts JS',
  // },
  xAxis: {
    crosshair: true,
    type: 'datetime',
    dateTimeLabelFormats: {
      // don't display the dummy year
      month: '%e. %b',
      year: '%b',
    },
    title: {
      text: 'Date',
    },
  },
  yAxis: {
    crosshair: true,
    title: {
      text: 'Snow depth (m)',
    },
    // min: -10,
  },
  // tooltip: {
  //   headerFormat: '<b>{series.name}</b><br>',
  //   pointFormat: '{point.x:%e-%b %H:%M:%S}, Value: {point.y:.2f}',
  //   // pointFormat: '{point.x}: {point.y:.2f}',
  // },
  tooltip: {
    // headerFormat: `${format(new Date({point.key}), 'dd-MMM hh:mm')}`,
    // headerFormat: '<table><tr><th colspan="2">{format(new Date({point.key}), "dd-MMM hh:mm"}</th></tr>',
    // pointFormat: '<tr><td style="color: {series.color}">{series.name} </td>' +
    //   '<td style="text-align: right"><b>{point.y} EUR</b></td></tr>',
    // footerFormat: '</table>',
    // useHTML: true,
    formatter: function () {
      let y = this.y ? this.y.toFixed(2) : '';
      //for the flag series which represents user comments
      if (this.series.name === 'comments') {
        y = this.point.text;
      }
      return format(new Date(this.x), 'E, dd-MMM HH:mm') + '<br/><b>' + this.series.name + ': ' + '</b>' + y;
    },
    // return `${this.series.name}: ${this.y.toFixed(2)} on ${format(new Date(this.x), 'dd-MMM hh:mm')}`;

    // style: {
    //   width: '200px',
    // },
    // valueDecimals: 4,
    // shared: true,
  },

  plotOptions: {
    // xrange: {
    //   // shared options for all xrange series
    //     min: new Date('1 Mar 2022'),
    //     max: new Date('30 Mar 2022')
    // },
    series: {
      // flags: {
      //   visible: true,
      //   allowOverlapX: true
      // },
      /*
        https://www.highcharts.com/forum/viewtopic.php?t=38849 set `getExtremesFromAll` so
        Highcharts will render all points always when zoomed in
       */
      getExtremesFromAll: true,
      // showInNavigator: true,
      point: {
        events: {
          click: function (e) {
            const seriesName = e.point.series.name;
            // console.log(`${seriesName} clicked`);
          },
        },
      },
      marker: {
        enabled: false,
      },
    },
  },

  colors: ['blue', 'green', 'red', 'orange', 'cyan'],
  series: [],
  //the chart.height value is 45% and when viewed on a mobile it switches to 400
  responsive: {
    rules: [{
      condition: {
        maxWidth: 500
      },
      chartOptions: {
        chart: {
          height: 400
        },
        subtitle: {
          text: null
        },
        navigator: {
          enabled: false
        }
      }
    }]
  }
};
