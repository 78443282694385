import React, { useEffect, useState } from 'react';
import { deviceTypes, deviceTypesConfigDropdown } from '../../../config/deviceTypes';
import { useFirestore } from 'react-redux-firebase';
import { auth } from '../../../firebase';
import { defaultSiteName, projectIdNotAssigned } from '../../../config/projectConfig';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Checkbox, InputLabel } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { GatewayDeviceIdsSelect } from './GatewayDeviceIdsSelect';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function AddNewSensor({ classes, projectIds }) {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const [deviceId, setDeviceId] = useState();
  const [deviceName, setDeviceName] = useState();
  const [stickerName, setStickerName] = useState();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [gatewayDeviceId, setGatewayDeviceId] = useState('');
  const [gatewayDeviceIds, setGatewayDeviceIds] = useState([]);
  const [gatewayDeviceName, setGatewayDeviceName] = useState('Gateway');
  const [isNewGateway, setIsNewGateway] = useState(false);
  const [isNewProject, setIsNewProject] = useState(false);
  const [isUnassigned, setIsUnassigned] = useState(false);
  const [calibrationOffsetRaw, setCalibrationOffsetRaw] = useState(undefined);
  const [calibrationCoefficientRaw, setCalibrationCoefficientRaw] = useState(undefined);
  const [deviceType, setDeviceType] = useState(deviceTypes.beeHeroOne);
  const [projectId, setProjectId] = useState();
  const [simId, setSimId] = useState();
  const [newProjectId, setNewProjectId] = useState('beekeeper-');
  const [newProjectName, setNewProjectName] = useState('Beekeeper ');
  const [validationMessages, setValidationMessages] = useState(['placeholder']);
  const firestore = useFirestore();
  const collectionName = 'addNewDeviceToProject';
  const dropdownDeviceTypes = deviceTypesConfigDropdown;

  useEffect(() => {
    if (deviceType === deviceTypes.pollyResearch && newProjectId.startsWith('beekeeper-')) {
      setNewProjectId('polly-');
      setNewProjectName('polly ');
    } else if (deviceType !== deviceTypes.pollyResearch && newProjectId.startsWith('polly-')) {
      setNewProjectId('beekeeper-');
      setNewProjectName('Beekeeper ');
    }
    validate();
  }, [
    projectId,
    deviceId,
    deviceName,
    stickerName,
    deviceType,
    gatewayDeviceId,
    isNewProject,
    newProjectId,
    newProjectName,
    calibrationCoefficientRaw,
    calibrationOffsetRaw,
  ]);

  const validate = () => {
    const messages = [];
    if (deviceType === deviceTypes.beeHeroOne) {
      if (!gatewayDeviceId || gatewayDeviceId === 'Choose GatewayId') {
        messages.push('Please enter a GatewayDeviceId');
      }
    }

    // if (deviceType === deviceTypes.agsenzeScaleOne) {
    //   if (!calibrationOffsetRaw) {
    //     messages.push('Please enter a calibrationOffsetRaw');
    //   }
    //   if (!calibrationCoefficientRaw) {
    //     messages.push('Please enter a calibrationCoefficientRaw');
    //   }
    // }

    if (!deviceType) {
      messages.push('Please select a DeviceType');
    }
    if (deviceType !== deviceTypes.agsenzeScaleOne && !isNewProject && !projectId) {
      messages.push('Please select a ProjectId');
    }
    if (deviceType === deviceTypes.agsenzeScaleOne && !projectId) {
      messages.push('Please select a ProjectId or tick "Is Unassigned"');
    }
    if (isNewProject && !newProjectId) {
      messages.push('Please enter a new ProjectId (eg beekeeper-jones)');
    }
    if (isNewProject && !newProjectName) {
      messages.push('Please enter a new ProjectName (eg Beekeeper Jones)');
    }
    if (isNewProject && newProjectId && /\s/.test(newProjectId)) {
      messages.push('No spaces allowed in the new ProjectId');
    }
    if (
      isNewProject &&
      newProjectId &&
      !newProjectId.startsWith('beekeeper-') &&
      deviceType !== deviceTypes.pollyResearch
    ) {
      messages.push('The ProjectId should begin with beekeeper-');
    }
    if (
      isNewProject &&
      newProjectId &&
      !newProjectId.startsWith('polly-') &&
      deviceType === deviceTypes.pollyResearch
    ) {
      messages.push('The ProjectId should begin with polly-');
    }
    if (!deviceId) {
      messages.push('Please enter a DeviceId');
    }
    if (deviceType !== deviceTypes.pollyResearch && !deviceName) {
      messages.push('Please enter a DeviceName');
    }

    if (deviceType === deviceTypes.pollyResearch && !simId) {
      messages.push('Please enter a simId');
    }

    setValidationMessages(messages);
  };
  const beeHeroDevices = [deviceTypes.beeHeroOne, deviceTypes.beeHeroGatewayOne];
  const addSensorAction = async () => {
    let finalDeviceId = beeHeroDevices.includes(deviceType) ? deviceId.toLowerCase() : deviceId;
    //first check if the deviceId already exists

    const deviceRef = await firestore.doc(`devices/${finalDeviceId}`).get();
    if (deviceRef.exists) {
      setValidationMessages([`${deviceId} already exists`]);
      setOpenConfirm(false);
      return;
    }

    const performAction = true;
    setOpenConfirm(false);
    const uid = auth.currentUser && auth.currentUser.uid;
    let device = {
      projectId: isNewProject ? newProjectId.toLowerCase() : projectId,
      isNewProject,
      deviceId: finalDeviceId,
      deviceType,
      deviceName: deviceType === deviceTypes.pollyResearch ? deviceId : deviceName,
      isNewGateway,
      dateCreated: new Date(),
      updatedBy: uid,
    };

    if (device.deviceType === deviceTypes.pollyResearch) {
      device.siteName = defaultSiteName;
    }

    if (isNewProject) {
      device.projectName = newProjectName;
    }
    if (stickerName) {
      device.stickerName = stickerName;
    }
    if (simId) {
      device.simId = simId;
    }
    if (deviceType === deviceTypes.beeHeroOne) {
      device.gatewayDeviceId = gatewayDeviceId.toLowerCase();
    }
    if (gatewayDeviceName) {
      device.gatewayDeviceName = gatewayDeviceName;
    }

    if (isUnassigned) {
      device.projectId = projectIdNotAssigned;
    }

    if (deviceType === deviceTypes.agsenzeScaleOne) {
      if (calibrationOffsetRaw) device.calibrationOffsetRaw = calibrationOffsetRaw;
      if (calibrationCoefficientRaw) device.calibrationCoefficientRaw = calibrationCoefficientRaw;
    }

    if (!performAction) {
      console.log('addSensorAction.device', device);
      setMessage('No action performed');
      setLoading(false);
      setOpenConfirm(false);
      return;
    }
    setLoading(true);

    firestore
      .collection(collectionName)
      .add(device)
      .then((res) => {
        const docId = res.id;
        firestore
          .collection(collectionName)
          .doc(docId)
          .onSnapshot((documentSnapshot) => {
            const docData = documentSnapshot.data();
            if (docData.message) {
              setMessage(docData.message);
              setLoading(false);
              setOpenConfirm(false);
            }
          });
      })
      .catch((err) => {
        setMessage(`Error: ${err.message}`);
        setLoading(false);
        setOpenConfirm(false);
      });
  };

  const defaultProps = {
    options: projectIds,
    getOptionLabel: (option) => option,
  };

  // const defaultGatewayDeviceIdProps = {
  //   options: gatewayDeviceIds,
  //   getOptionLabel: (option) => option,
  // };
  return (
    <Grid container spacing={0}>
      <Grid container item xs={12}>
        <FormControl className={classes.formControl}>
          <Autocomplete
            {...defaultProps}
            value={projectId}
            id="projectId"
            debug
            disabled={isNewProject || isUnassigned}
            renderInput={(params) => <TextField {...params} label="Project ID" margin="normal" />}
            onChange={(e, value) => {
              setProjectId(value);
              setGatewayDeviceId(undefined);
              validate();
              firestore
                .collection(`projects/${value}/devices`)
                .where('deviceType', '==', deviceTypes.beeHeroGatewayOne)
                .get()
                .then((snapshot) => {
                  let docs = [];
                  snapshot.docs.map((doc) => docs.push({ ...doc.data(), ...{ docId: doc.id } }));
                  setGatewayDeviceIds(docs.map((doc) => doc.deviceId));
                  // console.log('devices', docs);
                });
            }}
          />
        </FormControl>
      </Grid>
      <Grid container item xs={12}>
        <FormControl className={classes.formControl}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isNewProject}
                onChange={(e) => {
                  const value = e.target.checked;
                  if (value) {
                    setProjectId(undefined);
                  }
                  setIsNewProject(!isNewProject);
                  validate();
                }}
                name="isNewProject"
              />
            }
            label="Is New Project?"
          />
        </FormControl>
      </Grid>
      <Grid container item xs={12}>
        <FormControl className={classes.formControl}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isUnassigned}
                onChange={(e) => {
                  const value = e.target.checked;
                  if (value) {
                    setProjectId(projectIdNotAssigned);
                  }
                  setIsUnassigned(!isUnassigned);
                  validate();
                }}
                name="isUnassigned"
              />
            }
            label="Is Unassigned?"
          />
        </FormControl>
      </Grid>
      {isNewProject && (
        <Grid container item xs={12}>
          <FormControl className={classes.formControl}>
            <TextField
              value={newProjectId}
              placeholder="newProjectId"
              onChange={(e) => {
                setNewProjectId(e.target.value);
                validate();
              }}
            />
            <FormHelperText>New Project ID (no spaces)</FormHelperText>
          </FormControl>
        </Grid>
      )}
      {isNewProject && (
        <Grid container item xs={12}>
          <FormControl className={classes.formControl}>
            <TextField
              value={newProjectName}
              placeholder="newProjectName"
              onChange={(e) => {
                setNewProjectName(e.target.value);
                validate();
              }}
            />
            <FormHelperText>New Project Name</FormHelperText>
          </FormControl>
        </Grid>
      )}
      <Grid container item xs={12}>
        <FormControl className={classes.formControl}>
          <Select
            disabled={false}
            placeholder={'Device Type'}
            value={deviceType}
            onChange={(e) => {
              setDeviceType(e.target.value);
              validate();
            }}
          >
            {dropdownDeviceTypes.map((deviceType) => {
              return (
                <MenuItem key={deviceType.id} value={deviceType.id}>
                  {deviceType.label}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>Device Type</FormHelperText>
        </FormControl>
      </Grid>
      <Grid container item xs={12}>
        <FormControl className={classes.formControl}>
          <TextField
            value={deviceId}
            placeholder="deviceId"
            onChange={(e) => {
              setDeviceId(e.target.value);
              validate();
            }}
          />
        </FormControl>
      </Grid>
      {deviceType && deviceType !== deviceTypes.pollyResearch && (
        <Grid container item xs={12}>
          <FormControl className={classes.formControl}>
            <TextField
              value={deviceName}
              placeholder="deviceName"
              onChange={(e) => {
                setDeviceName(e.target.value);
                validate();
              }}
            />
          </FormControl>
        </Grid>
      )}
      <Grid container item xs={12}>
        <FormControl className={classes.formControl}>
          <TextField
            value={stickerName}
            placeholder="stickerName"
            onChange={(e) => {
              setStickerName(e.target.value);
              validate();
            }}
          />
        </FormControl>
      </Grid>
      <Grid container item xs={12}>
        <FormControl className={classes.formControl}>
          <TextField
            value={simId}
            placeholder="simId"
            onChange={(e) => {
              setSimId(e.target.value);
              validate();
            }}
          />
        </FormControl>
      </Grid>
      {deviceType && deviceType === deviceTypes.beeHeroOne && (
        <Grid container item xs={12}>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Select Gateway Id</InputLabel>
            <GatewayDeviceIdsSelect
              gatewayDeviceId={gatewayDeviceId}
              gatewayDeviceIds={gatewayDeviceIds}
              setGatewayDeviceId={setGatewayDeviceId}
              validate={validate}
            />
            {/*<Autocomplete*/}
            {/*  value={gatewayDeviceId}*/}
            {/*  id="gatewayDeviceId"*/}
            {/*  debug*/}
            {/*  // options={gatewayDeviceIds}*/}
            {/*  // disabled={isNewProject || isUnassigned}*/}
            {/*  renderInput={(params) => <TextField {...params} label="Gateway ID" margin="normal" />}*/}
            {/*  onChange={(e, value) => {*/}
            {/*    setGatewayDeviceId(value);*/}
            {/*    validate();*/}
            {/*  }}*/}
            {/*/>*/}
          </FormControl>
        </Grid>
      )}
      {deviceType && isNewGateway && deviceType === deviceTypes.beeHeroOne && (
        <Grid container item xs={12}>
          <FormControl className={classes.formControl}>
            <TextField
              value={gatewayDeviceName}
              placeholder="gatewayDeviceName"
              onChange={(e) => {
                setGatewayDeviceName(e.target.value);
                validate();
              }}
            />
          </FormControl>
        </Grid>
      )}
      {deviceType && deviceType === deviceTypes.beeHeroOne && (
        <Grid container item xs={12}>
          <FormControl className={classes.formControl}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isNewGateway}
                  onChange={() => setIsNewGateway(!isNewGateway)}
                  name="isNewGateway"
                />
              }
              label="Is New Gateway?"
            />
          </FormControl>
        </Grid>
      )}
      {deviceType === deviceTypes.agsenzeScaleOne && (
        <Grid container item xs={12}>
          <FormControl className={classes.formControl}>
            <TextField
              value={calibrationOffsetRaw}
              placeholder="calibrationOffsetRaw"
              onChange={(e) => {
                setCalibrationOffsetRaw(e.target.value);
                validate();
              }}
            />
          </FormControl>
        </Grid>
      )}
      {deviceType === deviceTypes.agsenzeScaleOne && (
        <Grid container item xs={12}>
          <FormControl className={classes.formControl}>
            <TextField
              value={calibrationCoefficientRaw}
              placeholder="calibrationCoefficientRaw"
              onChange={(e) => {
                setCalibrationCoefficientRaw(e.target.value);
                validate();
              }}
            />
          </FormControl>
        </Grid>
      )}
      {validationMessages.length > 0 && (
        <Grid container item xs={12}>
          <FormControl className={classes.formControl}>
            <div>
              {validationMessages.map((message) => {
                return (
                  <div key={message} style={{ color: 'red' }}>
                    {message}
                  </div>
                );
              })}
            </div>
          </FormControl>
        </Grid>
      )}
      <Grid container item xs={12}>
        <FormControl className={classes.formControl}>
          <Grid container spacing={0}>
            <Grid container item xs={6}>
              <Button
                color="primary"
                variant="contained"
                // className={classes.button}
                onClick={() => setOpenConfirm(true)}
                disabled={validationMessages.length > 0 || loading}
              >
                Add New Device to Project
              </Button>
              <Dialog
                open={openConfirm}
                onClose={() => setOpenConfirm(false)}
                aria-labelledby="responsive-dialog-title"
              >
                <DialogTitle id="responsive-dialog-title">{'Add sensor?'}</DialogTitle>
                <DialogContent dividers>
                  <Typography gutterBottom>
                    Are you sure you want add this new device to {isNewProject ? newProjectId : projectId}?
                  </Typography>
                  <br />
                  <Typography gutterBottom>isNewProject: {isNewProject.toString()}</Typography>
                  {projectId && <Typography gutterBottom>projectId: {projectId}</Typography>}
                  <Typography gutterBottom>DeviceId: {deviceId}</Typography>
                  <Typography gutterBottom>DeviceType: {deviceType}</Typography>
                  <Typography gutterBottom>DeviceName: {deviceName}</Typography>
                  <Typography gutterBottom>StickerName: {stickerName}</Typography>
                  {deviceType === deviceTypes.pollyResearch && (
                    <Typography gutterBottom>SimId: {simId}</Typography>
                  )}
                  {deviceType === deviceTypes.beeHeroOne && (
                    <Typography gutterBottom>GatewayDeviceId: {gatewayDeviceId}</Typography>
                  )}
                  {deviceType === deviceTypes.beeHeroOne && (
                    <Typography gutterBottom>IsNewGateway: {isNewGateway.toString()}</Typography>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button autoFocus onClick={() => setOpenConfirm(false)} color="primary">
                    No
                  </Button>
                  <Button onClick={addSensorAction} color="primary" autoFocus>
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
            <Grid container item xs={6}>
              {loading && <CircularProgress className={classes.submitButton} />}
              {message && <div style={{ marginLeft: '10px' }}>{message}</div>}
            </Grid>
          </Grid>
        </FormControl>
      </Grid>
    </Grid>
  );
}
