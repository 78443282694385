import { auth, firestore } from '../firebase';
import firebase from 'firebase/app';

export async function updateDevice({ deviceId, updatedObj, auditDescription, log }) {
  addAudit(updatedObj);
  await firestore.doc(`devices/${deviceId}`).update(updatedObj);
  if(auditDescription) {
    const userId = auth.currentUser && auth.currentUser.uid;
    const userEmail = auth.currentUser && auth.currentUser.email;
    const entry = {
      dateCreated: new Date(),
      userId,
      userEmail,
      description: auditDescription
    }
    await firestore.doc(`devices/${deviceId}`).update({  auditHistory: firebase.firestore.FieldValue.arrayUnion(entry) });
  }
  if(log) {
    await firestore.doc(`devices/${deviceId}`).update({  logs: firebase.firestore.FieldValue.arrayUnion(log) });
  }
}

export async function deleteDeviceLog({ deviceId, newLogs, logDescription }) {
  const userId = auth.currentUser && auth.currentUser.uid;
  const userEmail = auth.currentUser && auth.currentUser.email;
  const description = `log with description ${logDescription} deleted`;
  const entry = {
    dateCreated: new Date(),
    userId,
    userEmail,
    description
  }
  await firestore.doc(`devices/${deviceId}`).update({  logs: newLogs });
  await firestore.doc(`devices/${deviceId}`).update({  auditHistory: firebase.firestore.FieldValue.arrayUnion(entry) });
}

function addAudit(obj) {
  const uid = auth.currentUser && auth.currentUser.uid;
  obj.updatedBy = uid;
  obj.dateUpdated = new Date();
}
