import { cloneDeep } from 'lodash';
import { log } from '../util/log-util';
import { accountTypes } from '../config/accountTypes';

export const showMenuItemIcon = false;
export const showNewMenuLayout = false;
export const barColour = '#ffc400';
export const accountTypesNewStyle = [accountTypes.beekeeper, accountTypes.polly];
export const chooseGatewayIdText = 'Choose GatewayId';

const defaultState = {
  limit: 10000,
};

const appConfig = (state = defaultState, action) => {
  // log('appConfig.action', action);
  switch (action.type) {
    case 'UPDATE_LIMIT':
      return {
        ...state,
        limit: parseInt(action.limit),
      };
    case 'UPDATE_USER':
      log('UPDATE_USER', action);
      return {
        ...state,
        userData: action.userObject.userData,
        user: action.userObject.user,
        hasAuth: action.userObject.hasAuth,
      };
    case 'UPDATE_PROJECT':
      log('UPDATE_PROJECT', action.project, new Date());
      let project = cloneDeep(action.project);
      return {
        ...state,
        currentProject: project,
      };
    case 'UPDATE_GLOBAL_CONFIG':
      log('UPDATE_GLOBAL_CONFIG', action.globalConfig, new Date());
      let globalConfig = cloneDeep(action.globalConfig);
      return {
        ...state,
        globalConfig,
      };
    case 'CLOSE_SLIDING_PANE':
      state = {
        ...state,
        slidingPane: {
          ...state.slidingPane,
          isOpen: false,
        },
      };
      return state;
    case 'OPEN_FILTER_SLIDING_PANE':
      return {
        ...state,
        filterPane: {
          ...state.filterPane,
          isOpen: true,
        },
      };
    case 'CLOSE_FILTER_SLIDING_PANE':
      state = {
        ...state,
        filterPane: {
          ...state.filterPane,
          isOpen: false,
        },
      };
      return state;
    default:
      return state;
  }
};

export default appConfig;
