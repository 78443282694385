import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import renderHTML from 'react-render-html';
import { useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { Typography } from '@material-ui/core';
import BreadcrumbNav from '../Nav/BreadcrumbsNav';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // maxWidth: '48ch',
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: '100%',
    // maxWidth: '48ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  button: {
    textTransform: 'none',
    paddingTop: '28px',
    paddingLeft: '80px',
  },
  link: {
    marginLeft: '28px',
  },
  linkNoSubtitle: {
    fontSize: '14px',
    marginLeft: '28px',
  },
  article: {
    minHeight: '300px',
  },
}));

export default function ArticlePage({ breadcrumbsPath, breadcrumbsText, routePath }) {
  const classes = useStyles();
  const match = useRouteMatch(`/${routePath}/:articleId`);
  const articleId = match.params.articleId;
  // const storeAs = `${schemaName}-articleId`;
  const article = useSelector(({ firestore: { data } }) => data.fl_content && data.fl_content[articleId]);
  const articlesQuery = [
    {
      collection: 'fl_content',
      doc: articleId,
      // storeAs,
    },
  ];

  useFirestoreConnect(articlesQuery);

  if (!isLoaded(article)) {
    return 'Loading...';
  }

  // return (
  //   <Box m={2}>
  //     <Paper className={classes.paper} variant="outlined">
  //       <Article article={article} classes={classes} />
  //     </Paper>
  //   </Box>
  // );

  return (
    <Grid justify={'flex-start'} container spacing={2}>
      <Grid item xs={12}>
        <Breadcrumbs path={breadcrumbsPath} text={breadcrumbsText} articleId={articleId} />
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper} variant="outlined">
          <Article article={article} classes={classes} />
        </Paper>
      </Grid>
    </Grid>
  );
}

function Article({ article, classes }) {
  return (
    <Grid container spacing={1} className={classes.article}>
      {article && article.title && (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography color="textPrimary" variant="h6">
            {article.title}
          </Typography>
        </Grid>
      )}
      {article && article.body && (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {renderHTML(article.body)}
        </Grid>
      )}
    </Grid>
  );
}

function Breadcrumbs({ path, text, articleId, showArticleId = false }) {
  let breadcrumbs = [
    {
      path: '/',
      text: 'Home',
    },
    {
      path,
      text,
    },
  ];
  if (showArticleId) {
    breadcrumbs.push({
      path: `${path}/${articleId}`,
      text: articleId,
    });
  }
  return <BreadcrumbNav breadcrumbs={breadcrumbs} />;
}
